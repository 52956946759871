import styled from "styled-components";

interface LangStyleProps {}

export const PreviewWrap = styled.div<LangStyleProps>`
  width: 50px;
  height: 28px;
  position: relative;
  margin-left: 5px;
  margin-bottom: 0px;

  i {
    position: absolute;
    transform: rotate(-90deg);
    font-size: 1rem;
    top: 6px;
    right: 5px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
    &:hover {
      ul {
        background: ${(props) => props.theme.colors.thirdly};
      }
    }
  }
  ul {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary};
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;

    &.close li:not(.active) {
      display: none;
    }

    &:hover {
      background: ${(props) => props.theme.colors.thirdly};
    }
    li {
      cursor: pointer;
      padding: 5px 10px;
      text-transform: capitalize;
      display: flex;
      order: 2;
      font-size: 1rem;
      &:hover {
        background: ${(props) => props.theme.colors.primary};
      }
      &.active {
        order: 1;
      }
      &:hover,
      &.active {
        display: flex;
      }
    }
  }
`;
