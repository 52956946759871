import logoAfrica from "../img/logos-channel/trace-africa.png";
import logoUrban from "../img/logos-channel/trace-urban.png";
import logoCaribbean from "../img/logos-channel/trace-caribbean.jpg";
import logoLatina from "../img/logos-channel/trace-latina.png";
import logoMziki from "../img/logos-channel/trace-mziki.png";
import logoToca from "../img/logos-channel/trace-toca.png";
import logoNaija from "../img/logos-channel/trace-naija.png";
import logoAfrikora from "../img/logos-channel/trace-afrikora.png";
import logoAyiti from "../img/logos-channel/trace-ayiti.png";
import logoBrazuca from "../img/logos-channel/trace-brazuca.png";
import logoGospel from "../img/logos-channel/trace-gospel.png";
import logoJama from "../img/logos-channel/trace-jama.png";
import logoKitoko from "../img/logos-channel/trace-kitoko.png";
import logoVanilla from "../img/logos-channel/trace-vanilla.png";
import logoMuzica from "../img/logos-channel/trace-muzica.png";
import logoHits from "../img/logos-channel/trace-hits.png";
import logoVault from "../img/logos-channel/trace-vault.png";

const channels: { name: string; img: string }[] = [
  { name: 'Trace Africa (Fr)', img: logoAfrica },
  { name: 'Trace Africa (Eng)', img: logoAfrica },
  { name: 'Trace Urban (Afr)', img: logoUrban },
  { name: 'Trace Urban (France)', img: logoUrban },
  { name: 'Trace Urban (Drom)', img: logoUrban },
  { name: 'Trace Urban (South Africa)', img: logoUrban },
  { name: 'Trace Urban (International)', img: logoUrban },
  { name: 'Trace Caribbean', img: logoCaribbean },
  { name: 'Trace Latina', img: logoLatina },
  { name: 'Trace Mziki', img: logoMziki },
  { name: 'Trace Toca', img: logoToca },
  { name: 'Trace Naija', img: logoNaija },
  { name: 'Trace Afrikora', img: logoAfrikora },
  { name: 'Trace Ayiti', img: logoAyiti },
  { name: 'Trace Brazuca', img: logoBrazuca },
  { name: 'Trace Gospel (Fr)', img: logoGospel },
  { name: 'Trace Gospel (South Africa)', img: logoGospel },
  { name: 'Trace Gospel (International)', img: logoGospel },
  { name: 'Trace Jama', img: logoJama },
  { name: 'Trace Kitoko', img: logoKitoko },
  { name: 'Trace Vanilla', img: logoVanilla },
  { name: 'Trace Muzika', img: logoMuzica },
  { name: 'Trace Hits', img: logoHits },
  { name: 'Trace Vault', img: logoVault },
];

export default channels;
