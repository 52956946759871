import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

import Container, { Box } from "../styles/Container";
import { Title2, Title4 } from "../typography/Titles";
import { Service } from "./services.style";

// import imgDiffuser from "../../img/diffusion-youtube.png";
// import imgPromo from "../../img/trace-social.png";
import imgRealiser from "../../img/maitre-gims.png";

function ServicesShowcase() {
  function isOdd(num) {
    return num % 2 !== 0;
  }
  const { t } = useTranslation();
  const services = [
    {
      title: t("makeMyVideo.title"),
      list: t("makeMyVideo.list"),
      img: imgRealiser,
      link2: "services/realisation",
    },
    // {
    //   title: t("shareMyVideo.title"),
    //   list: t("digitalPromo.list"),
    //   img: imgPromo,
    //   link2: "services/promo-tv",
    // },
    // {
    //   title: t("digitalPromo.title"),
    //   list: t("shareMyVideo.list"),
    //   img: imgDiffuser,
    //   // link2: "services/diffusion",
    // },
  ];
  return (
    <Container my={[4, 6]}>
      <Box center>
        <Title2>
          {t("home.title2.part5")}{" "}
          <span>
            {t("home.title2.part6")}
            <br />
          </span>
          {t("home.title2.part7")}
        </Title2>
      </Box>
      {services.map((service, i) => (
        service.link2 ?
      <Service key={i}>
        <Fade left={isOdd(i)} right={isOdd(i + 1)}>
          <div className='image'>
            <img src={service.img} alt={service.title} />
          </div>
        </Fade>
        <Box className='content'>
          <Fade>
            <Title4 className='title'>{service.title}</Title4>
            <ul dangerouslySetInnerHTML={{ __html: service.list }} />
            <Link to={service.link2}>{t("knowMore")} &gt;</Link>
          </Fade>
        </Box>
      </Service>
       :
      <Service key={i}>
        <Fade left={isOdd(i)} right={isOdd(i + 1)}>
          <div className='image'>
            <img src={service.img} alt={service.title} />
          </div>
        </Fade>
        <Box className='content'>
          <Fade>
            <Title4 className='title'>{service.title}</Title4>
            <ul dangerouslySetInnerHTML={{ __html: service.list }} />
          </Fade>
        </Box>
      </Service>
      ))}
    </Container>
  );
}

export default ServicesShowcase;
