import { TextS } from "../typography/Texts";
import artistes from "./data";
import { ArtistsWrap, ArtistItem } from "./artists.style";
import Container, { Box } from "../styles/Container";
import { Title3, Title5 } from "../typography/Titles";
import { useTranslation } from "react-i18next";


function Artists() {
  const { t } = useTranslation();

  return (
    <Container my={[4, 6]}>
      <Box center mb={4}>
        <Title3>
          {t("ArtistTitleTrust")}<span> {t("ArtistTitleTrust2")}</span>
        </Title3>
        <Title5>
        {t("descriptionTrust")}
        </Title5>
      </Box>
      <ArtistsWrap>
        {artistes.map((el, i) => (
          <ArtistItem key={i}>
            <img src={el.img} alt='artist' />
            <TextS>{el.name}</TextS>
          </ArtistItem>
        ))}
      </ArtistsWrap>
    </Container>
  );
}

export default Artists;
