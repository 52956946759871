import { useState } from "react";
import { useTranslation } from "react-i18next";

import Container, { Box } from "../Components/styles/Container";
import { BannerImg, BannerWrapper } from "../Components/banner/Banner";
import LoginForm from "../Components/login/LoginForm";
import ResetPassword from "../Components/login/ResetPassword";
import { AvantageSlider } from "../Components/avantages/Avantages";
import { Title2 } from "../Components/typography/Titles";
import { userContext } from "../context/userContext";

import bgImg from "../img/connexion.jpg";
import Link from "../Components/styles/Link";
import { TextS } from "../Components/typography/Texts";

export default function Login() {
  const { t } = useTranslation();
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  function resetPasswordHandler() {
    setResetPassword(!resetPassword);
  }

  return (
    <userContext.Consumer>
      {({ loginUser }) => {
        return (
          <>
            <BannerWrapper>
              <BannerImg
                src={bgImg}
                alt="YouTrace Inscription"
                className="index"
              />
              <Box py={[2, 3]} pb={4}>
                <Container small>
                  {resetPassword ? (
                    <ResetPassword
                      resetPasswordHandler={resetPasswordHandler}
                    />
                  ) : (
                    <>
                      <Box p={4}>
                        <Title2 bold>{t("login")}</Title2>
                      </Box>
                      <LoginForm handleLogin={() => loginUser} />
                      <Box mt={4} center>
                        <Link orange href="/inscription">
                          {t("register")}
                        </Link>
                      </Box>
                      <Box mt={2}>
                        <TextS
                          center
                          onClick={() => resetPasswordHandler()}
                          pointer
                        >
                          {t("login.lost")}
                        </TextS>
                      </Box>
                    </>
                  )}
                </Container>
              </Box>
            </BannerWrapper>
            <AvantageSlider />
          </>
        );
      }}
    </userContext.Consumer>
  );
}
