import React from "react";

import { useTranslation } from "react-i18next";
import { Bloc, BlocCol, BlocCols } from "../styles/Bloc";
import { TextS, TextXXS } from "../typography/Texts";
import Container, { Box } from "../styles/Container";
import { UserProps } from "../../interfaces/UserProps";
import Link from "../styles/Link";

export default function AccountInfo({
  user,
  toggleModifying,
}: {
  user: UserProps;
  toggleModifying?: any;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Container small my={4}>
        <Bloc>
          <Box>
            <BlocCols>
              <BlocCol>
                <TextXXS className='opacity' mb={2}>
                  {t("registration.surname")}
                </TextXXS>
                <TextS>{user ? user.lastName : ""}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className='opacity' mb={2}>
                  {t("registration.firstName")}
                </TextXXS>
                <TextS>{user ? user.firstName : ""}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className='opacity' mb={2}>
                  {t("registration.phone")}
                </TextXXS>
                <TextS>{user ? user.phone : ""}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className='opacity' mb={2}>
                  {t("registration.email")}
                </TextXXS>
                <TextS>{user ? user.emailAddress : ""}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className='opacity' mb={2}>
                  {t("registration.country")}
                </TextXXS>
                <TextS>{user ? user.country?.country : ""}</TextS>
              </BlocCol>
            </BlocCols>
          </Box>
          <Box center my={4}>
            <Link
              href={!toggleModifying ? "/mon-compte" : null}
              onClick={toggleModifying}
              style={{ cursor: "pointer" }}
            >
              {t("edit")}
            </Link>
          </Box>
        </Bloc>
      </Container>
    </>
  );
}
