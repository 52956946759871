import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";

interface CustomButtonProps {
  active?: boolean;
  disabled?: boolean;
}

const ButtonAlt = styled.a<SpaceProps & CustomButtonProps>`
  ${space}
  position: relative;
  display: inline-block;
  white-space: normal;
  height: auto;
  line-height: 1;
  padding: 15px 10px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  box-sizing: border-box;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  color: white;
  outline: none;
  transition: all 250ms ${(props) => props.theme.transitions.easeInOutCubic};
  text-decoration: none;
  &:hover {
    background: ${(props) => props.theme.colors.orange};
    border-color: ${(props) => props.theme.colors.orange};
  }
  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.colors.orange};
      border-color: ${(props) => props.theme.colors.orange};
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      &:hover {
        background: ${(props) => props.theme.colors.primary};
        border: 1px solid ${(props) => props.theme.colors.secondary};
      }
    `}
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
  }
`;

export default ButtonAlt;
