import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import Container, { Box, Flex } from "../Components/styles/Container";
import { Title4, Title2, Title5 } from "../Components/typography/Titles";
import { AvantageSlider } from "../Components/avantages/Avantages";
import { CartProps } from "../interfaces/CartProps";
import { UserProps } from "../interfaces/UserProps";
import OrderState from "../Components/orders/OrderState";
import { Text } from "../Components/typography/Texts";

import InfosIcon from "../img/icons-confirmation/informations.png";
import AccountIcon from "../img/icons-confirmation/compte.png";
import HelpIcon from "../img/icons-confirmation/help.png";
import PayIcon from "../img/icons-confirmation/paiement.png";
import RepresentantIcon from "../img/icons-confirmation/representant.png";
import ConfirmationTitle from "../Components/confirmation/ConfirmationTitle";
import Link from "../Components/styles/Link";

export default function Confirmation() {
  const { t } = useTranslation();
  const { state }: { state: { cart: CartProps; user: UserProps } } =
    useLocation();
  const { user, cart } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const status = [
    { color: "255, 137, 0", title: t("waitpayment") },
    { color: "19, 234, 168", title: t("payed") },
    { color: "1, 130, 1", title: t("orderdelivered") },
    { color: "83, 83, 83", title: t("canceled") },
    { color: "247, 114, 74", title: t("paymentMismatch") },
    { color: "47, 151, 220", title: t("initialized") },
    { color: "255, 0, 0", title: t("failed") },
    { color: "41, 72, 245", title: t("inprogress") },
    { color: "1, 168, 104", title: t("prodDone") },
    { color: "117, 5, 150", title: t("customerContacted") },
  ];

  const payLater =
    cart.paymentMethod === "wari" || cart.paymentMethod === "coordinateur";

  if (cart.cru) {
    return (
      <>
        <Container my={4}>
          <Box center mb={4}>
            <Title2 bold>
              <span>{user.firstName}</span>
              ,
              <br /> {t("confirmation.thanks")}
            </Title2>
          </Box>
          <Box border="1px solid" p={4}>
            <Box center mb={4}>
              <Title4 bold>
                {t("orderSummary")} :
                <br />
                n° {cart.cru}
              </Title4>

              <OrderState
                className="open"
                style={{ background: `rgb(${status[0]?.color})` }}
              >
                <p>{status[cart?.status]?.title}</p>
              </OrderState>
            </Box>

            {cart.informations && (
              <ConfirmationTitle title={t("infosClip.title")} icon={InfosIcon}>
                <Flex px={2} py={4} flexWrap="wrap">
                  {cart.informations.artistName && (
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("yourVideo")}</Title5>
                      <Text>{cart.informations.artistName}</Text>
                      <Text>{cart.informations.songName}</Text>
                    </Box>
                  )}
                  {cart.informations.clipUrl && (
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("linkVideo")}</Title5>
                      <Text>{cart.informations.clipUrl}</Text>
                    </Box>
                  )}
                </Flex>
              </ConfirmationTitle>
            )}

            {payLater && (
              <ConfirmationTitle title={t("paymentMethod")} icon={PayIcon}>
                <Box px={2} py={4} style={{ width: "90%", margin: "auto" }}>
                  <Box mb={4}>
                    <Title5>{t("finPay")}</Title5>
                    {cart.paymentMethod === "coordinateur" && (
                      <Text>{t("finPay.message.coordinateur")}</Text>
                    )}
                    {cart.paymentMethod === "wari" && (
                      <Text>{t("finPay.message.wari")}</Text>
                    )}
                  </Box>
                  <Box>
                    <Title5>{t("finPay.ref")}</Title5>
                    <Text>{cart.cru}</Text>
                  </Box>
                </Box>
              </ConfirmationTitle>
            )}

            {cart.informations && (
              <ConfirmationTitle title={t("repYt")} icon={RepresentantIcon}>
                <Box px={2} py={4}>
                  {user.country.coordinator && (
                    <>
                      <Flex mb={4}>
                        <Box style={{ flex: 1 }}>
                          <Title5>{t("registration.firstName")}</Title5>
                          <Text>{user.country.coordinator.firstName}</Text>
                        </Box>
                        <Box style={{ flex: 1 }}>
                          <Title5>{t("registration.surname")}</Title5>
                          <Text>{user.country.coordinator.lastName}</Text>
                        </Box>
                      </Flex>
                      <Flex mb={4}>
                        <Box style={{ flex: 1 }}>
                          <Title5>{t("registration.email")}</Title5>
                          <Text>{user.country.coordinator.email}</Text>
                        </Box>
                        <Box style={{ flex: 1 }}>
                          <Title5>{t("registration.phone")}</Title5>
                          <Text>{user.country.coordinator.phone}</Text>
                        </Box>
                      </Flex>
                    </>
                  )}
                  {cart.status === 0 && (
                    <Box>
                      <Title5>{t("informations")}</Title5>
                      <Text>{t("informations.msg")}</Text>
                    </Box>
                  )}
                </Box>
              </ConfirmationTitle>
            )}

            {user && (
              <ConfirmationTitle
                title={t("customerInformation")}
                icon={AccountIcon}
              >
                <Box px={2} py={4}>
                  <Flex style={{ marginBottom: "32px" }}>
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("registration.firstName")}</Title5>
                      <Text>{user.firstName}</Text>
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("registration.surname")}</Title5>
                      <Text>{user.lastName}</Text>
                    </Box>
                  </Flex>
                  <Flex style={{ marginBottom: "32px" }}>
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("registration.email")}</Title5>
                      <Text>{user.emailAddress}</Text>
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("registration.phone")}</Title5>
                      <Text>{user.phone}</Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box style={{ flex: 1 }}>
                      <Title5>{t("registration.country")}</Title5>
                      <Text>{user.country.country}</Text>
                    </Box>
                  </Flex>
                </Box>
              </ConfirmationTitle>
            )}

            <ConfirmationTitle title={t("needHelp")} icon={HelpIcon}>
              <Box px={2} py={4} style={{ width: "90%", margin: "auto" }}>
                <Box mb={4}>
                  <Text bold>{t("consultFaq")}</Text>
                  <Link href={"/faq"}>{t("consultHelp")}</Link>
                </Box>
                <Box>
                  <Text bold>{t("contactEmail")} :</Text>
                  <Link href="mailto:contact@youtrace.tv">
                    contact@youtrace.tv
                  </Link>
                </Box>
              </Box>
            </ConfirmationTitle>
          </Box>
        </Container>
        <AvantageSlider />
      </>
    );
  } else {
    return null;
  }
}
