function fetchAccessToken(cbSuccess, cbError, login, password) {
  const url = `https://staging.pro.trace.tv/oauth/token?grant_type=password&username=${login}&password=${password}`;
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Basic d2ViYXBwOm1hcndlbg==",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        throw new Error();
      }
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("expires_in", data.expires_in);
      cbSuccess();
    })
    .catch((error) => cbError(error));
}

const api = {
  fetchAccessToken,
};

export default api;
