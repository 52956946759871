import { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import Container, { Box } from "../Components/styles/Container";
import { Title2, Title4 } from "../Components/typography/Titles";
import { Form, FormCol } from "../Components/styles/Form";
import Button from "../Components/buttons/Button";
import Link from "../Components/styles/Link";
import { Text } from "../Components/typography/Texts";
import InputText from "../Components/form/InputText";
import api from "../client/api";

class AddClip extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      artist: "",
      title: "",
      producer: "",
      link: "",
      label: "",
      success: false,
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    api.fetchClip(this.props.match.params.id, (order) => {
      this.setState({
        artist: order.artist,
        title: order.titre,
        producer: order.producer,
        label: order.label,
      });
    });
  }

  handleChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  handleSubmit() {
    this.setState({ success: true });
  }

  render() {
    const SignUpSchema = Yup.object().shape({
      artist: Yup.string()
        .min(2, "Nom trop court")
        .max(50, "Nom trop long")
        .required("Merci de renseigner ton nom d'artiste"),
      title: Yup.string()
        .min(2, "Titre trop court")
        .max(50, "Titra trop long")
        .required("Merci de renseigner ton titre"),
      producer: Yup.string()
        .min(2, "Producteur trop court")
        .max(50, "Producteur trop long")
        .required("Merci de renseigner le producteur"),
      link: Yup.string()
        .url("Merci de renseigner un lien valide")
        .required("Merci de renseigner le lien"),
      label: Yup.string()
        .min(2, "Label trop court")
        .max(50, "Label trop long")
        .required("Merci de renseigner le label"),
    });
    return (
      <div>
        <Container fluid>
          <Box py={4} center>
            <Title2>
              <span>Ajouter</span> un clip
            </Title2>
          </Box>
        </Container>
        <Container small>
          {this.state.success ? (
            <Box center>
              <Title4>Merci, le clip a bien été envoyé !</Title4>
              <Box mt={4} center>
                <Button fullWidth href='/dashboard'>
                  Dashboard
                </Button>
              </Box>
            </Box>
          ) : (
            <Formik
              initialValues={{
                artist: this.state.artist || "",
                title: this.state.title,
                producer: this.state.producer,
                link: "",
                label: this.state.label,
              }}
              validationSchema={SignUpSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values) => this.handleSubmit()}
            >
              {({ errors, handleChange, handleBlur, handleSubmit }) => (
                <Form white>
                  <FormCol>
                    <InputText
                      name='artist'
                      label='Artiste* :'
                      value={this.state.artist || ""}
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChange(e, "artist");
                      }}
                    />
                    {errors.artist && <Text error>{errors.artist}</Text>}
                  </FormCol>

                  <FormCol>
                    <InputText
                      name='title'
                      label='Titre* :'
                      value={this.state.title || ""}
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChange(e, "title");
                      }}
                    />
                    {errors.title && <Text error>{errors.title}</Text>}
                  </FormCol>
                  <FormCol>
                    <InputText
                      name='producer'
                      label='Nom/Prénom du producteur* :'
                      value={this.state.producer || ""}
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChange(e, "producer");
                      }}
                    />
                    {errors.producer && <Text error>{errors.producer}</Text>}
                  </FormCol>
                  <FormCol>
                    <InputText
                      name='label'
                      label='Label* :'
                      value={this.state.label || ""}
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChange(e, "label");
                      }}
                    />
                    {errors.label && <Text error>{errors.label}</Text>}
                  </FormCol>
                  <FormCol>
                    <InputText
                      name='link'
                      label='Lien du clip* :'
                      value={this.state.link || ""}
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleChange(e, "link");
                      }}
                    />
                    {errors.link && <Text error>{errors.link}</Text>}
                  </FormCol>
                  <FormCol dSNone />
                  <Box center>
                    <Button fullWidth onClick={handleSubmit}>
                      Envoyer
                    </Button>
                    <Box mt={3} center>
                      <Link orange href='/dashboard'>
                        Dashboard
                      </Link>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </div>
    );
  }
}

export default AddClip;
