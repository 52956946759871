import styled from "styled-components";

export const VideosWrapper = styled.div`
  margin-top: 50px;
  max-width: 100vw;
  padding-bottom: 25px;
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-slide {
    border-radius: 16px;
    overflow: hidden;
  }
  a {
    opacity: 0;
    transition: opacity 1s;
  }
  .slick-slide.slick-active.slick-center {
    transform: scale(1.15);
    position: relative;
    z-index: 9;
    transition: all 0.6s;
    a {
      opacity: 1;
    }
  }
  .slick-prev,
  .slick-next {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0.75));
    width: 50px;
    height: 50px;
    z-index: 9;
    @media ${(props) => props.theme.mediaQueries.xSmallWidth} {
      display: none !important;
    }
    &:before {
      font-size: 3rem;
    }
    path  {
      fill: white;
    }
  }
  .slick-list {
    overflow: visible;
  }
`;

export const Video = styled.div`
  img {
    max-width: 100%;
  }
  a {
    &:hover {
      svg {
        opacity: 1;
      }
    }
    svg {
      fill: white;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      transition: opacity 1s;
      @media ${(props) => props.theme.mediaQueries.xSmallWidth} {
        width: 60px;
        height: 60px;
      }
    }
  }
`;
