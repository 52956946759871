import trailerVideo from "../../img/distribution.mp4";
import imgDiffuser from "../../img/diffusion-youtube.png";
import imgPromo from "../../img/trace-social.png";
import imgRealiser from "../../img/maitre-gims.png";
import iconReconnu from "../../img/landing/icon-reconnu.png";
import iconCadeau from "../../img/landing/icon-cadeau2.png";
import iconRoyalties from "../../img/landing/icon-royalties.png";
import iconSociaux from "../../img/landing/icon-sociaux.png";
import iconTournage from "../../img/landing/icon-tournage.png";
import iconDistribution from "../../img/landing/icon-distribution.png";
import iconEspaceDistrib from "../../img/landing/icon-espace-distribution.png";

const fr = {
  video: trailerVideo,
  title: "Vendre ma musique",
  subtitle:
    "<span>Distribue ta musique sur les plateformes de streaming</span>",
  callToAction: "Commencer",
  advantages: {
    img: imgPromo,
    title: "<span>Pourquoi distribuer ta musique</span> avec TracePro",
    advantagesList: [
      {
        title: "Ta musique dans le monde entier",
        icon: iconReconnu,
        text: `sur + de 200 plateformes en quelques clics (Spotify, Apple Music, Deezer, etc.)`,
      },
      {
        title: "Ta distribution offerte",
        icon: iconCadeau,
        text: `pour la commande d’un de nos services`,
      },
      {
        title: "Tes royalties disponibles sous 48h",
        icon: iconRoyalties,
        text: `Il te suffit juste d’en faire la demande en ligne`,
      },
      {
        title: "Conseils et supports personnalisés",
        icon: iconSociaux,
        text: `Notre service client est à ton écoute`,
      },
    ],
  },
  steps: {
    title: "Comment <span>distribuer ta musique</span> avec TracePro",
    subtitle:
      "Découvre toutes les étapes pour vendre ta musique sur les plateformes de streaming",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne l'offre de ton choix",
        paragraph: "parmi la réalisation, la promotion ou encore la diffusion",
      },
      {
        icon: iconDistribution,
        title: "Nous t’offrons la distribution",
        paragraph:
          "de ta musique avec l’achat d’au moins un des services de TracePro",
      },
      {
        icon: iconEspaceDistrib,
        title: "Accède à ton espace distribution",
        paragraph: "grâce à notre partenaire Sonosuite",
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Fais ta promo dès maintenant avec l’aide de TracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services TracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};

const en = {
  video: trailerVideo,
  title: "Vendre ma musique",
  subtitle:
    "<span>Distribue ta musique sur les plateformes de streaming</span>",
  callToAction: "Commencer",
  advantages: {
    img: imgPromo,
    title: "<span>Pourquoi distribuer ta musique</span> avec TracePro",
    advantagesList: [
      {
        title: "Ta musique dans le monde entier",
        icon: iconReconnu,
        text: `sur + de 200 plateformes en quelques clics (Spotify, Apple Music, Deezer, etc.)`,
      },
      {
        title: "Ta distribution offerte",
        icon: iconCadeau,
        text: `pour la commande d’un de nos services`,
      },
      {
        title: "Tes royalties disponibles sous 48h",
        icon: iconRoyalties,
        text: `Il te suffit juste d’en faire la demande en ligne`,
      },
      {
        title: "Conseils et supports personnalisés",
        icon: iconSociaux,
        text: `Notre service client est à ton écoute`,
      },
    ],
  },
  steps: {
    title: "Comment <span>distribuer ta musique</span> avec TracePro",
    subtitle:
      "Découvre toutes les étapes pour vendre ta musique sur les plateformes de streamin",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne une des offres de ton choix",
        paragraph: "parmi la réalisation, la promotion ou encore la diffusion",
      },
      {
        icon: iconDistribution,
        title: "Nous t’offrons la distribution",
        paragraph:
          "de ta musique avec l’achat d’au moins un des services de TracePro",
      },
      {
        icon: iconEspaceDistrib,
        title: "Accède à ton espace distribution",
        paragraph: "grâce à notre partenaire Sonosuite",
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Fais ta promo dès maintenant avec l’aide de TracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services TracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};
const pt = {
  video: trailerVideo,
  title: "Vendre ma musique",
  subtitle:
    "<span>Distribue ta musique sur les plateformes de streaming</span>",
  callToAction: "Commencer",
  advantages: {
    img: imgPromo,
    title: "<span>Pourquoi distribuer ta musique</span> avec TracePro",
    advantagesList: [
      {
        title: "Ta musique dans le monde entier",
        icon: iconReconnu,
        text: `sur + de 200 plateformes en quelques clics (Spotify, Apple Music, Deezer, etc.)`,
      },
      {
        title: "Ta distribution offerte",
        icon: iconCadeau,
        text: `pour la commande d’un de nos services`,
      },
      {
        title: "Tes royalties disponibles sous 48h",
        icon: iconRoyalties,
        text: `Il te suffit juste d’en faire la demande en ligne`,
      },
      {
        title: "Conseils et supports personnalisés",
        icon: iconSociaux,
        text: `Notre service client est à ton écoute`,
      },
    ],
  },
  steps: {
    title: "Comment <span>distribuer ta musique</span> avec TracePro",
    subtitle:
      "Découvre toutes les étapes pour vendre ta musique sur les plateformes de streamin",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne une des offres de ton choix",
        paragraph: "parmi la réalisation, la promotion ou encore la diffusion",
      },
      {
        icon: iconDistribution,
        title: "Nous t’offrons la distribution",
        paragraph:
          "de ta musique avec l’achat d’au moins un des services de TracePro",
      },
      {
        icon: iconEspaceDistrib,
        title: "Accède à ton espace distribution",
        paragraph: "grâce à notre partenaire Sonosuite",
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Fais ta promo dès maintenant avec l’aide de TracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services TracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};
export { fr, en, pt };
