import { Box, Flex } from "../styles/Container";
import { Title4 } from "../typography/Titles";

export default function ConfirmationTitle({
  icon,
  title,
  children,
}: {
  icon: string;
  title: string;
  children: React.ReactChild | React.ReactChild[];
}) {
  return (
    <Box>
      <Flex alignItems='center' flexWrap='wrap' pb={3} borderBottom='1px solid'>
        <img
          src={icon}
          alt='icon'
          style={{ height: "35px", marginRight: "20px" }}
        />
        <Title4 mb='0' bold orange>
          {title}
        </Title4>
      </Flex>
      <Box>{children}</Box>
    </Box>
  );
}
