import styled, { css } from "styled-components";
import { BsArrowLeft } from 'react-icons/bs';
import {
  flexbox,
  border,
  space,
  color,
  ColorProps,
  BorderProps,
  SpaceProps,
  FlexboxProps,
} from "styled-system";
interface PropsInterface {
  fluid?: boolean;
  small?: boolean;
  XS?: boolean;
  flex?: boolean;
  bgWhite?: boolean;
  center?: boolean;
  inlineBlock?: boolean;
  secondary?: boolean;
  tRight?: boolean;
  hideMedium?: boolean;
  showMedium?: boolean;
  fWidth?: boolean;
  width?: number | string;
  height?: number | string;
}

type BaseContainerProps = SpaceProps &
  PropsInterface &
  FlexboxProps &
  BorderProps &
  ColorProps;

export const RIDesktopHeaderGobackBtn = styled(BsArrowLeft)`
  @media screen and (max-width:767px) {
    width:40px;
    height:40px;
    margin-top: 23px;
    margin-left: 25px;
  }
 @media screen and (min-width:768px) and (max-width:991px) {
   width:40px;
   height:40px;
   margin-top: 23px;
    margin-left: 25px;
 }
 @media screen and (min-width:992px) and (max-width:1199px) {
    width: 52px;
    height: 50px;
    margin-top: 23px;
    margin-left: 125px;
 }
 @media screen and (min-width:1200px){
    width: 52px;
    height: 60px;
    margin-top: 23px;
    margin-left: 125px;
 }
`;
const Container = styled.div<BaseContainerProps>`
  margin: 0 auto;
  width: 1087px;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 20px;
  ${space}
  ${border}
	${(props) =>
    props.fluid &&
    css`
      width: 100%;
      padding: 0 60px;
    `}
	${(props) =>
    props.small &&
    css`
      max-width: 580px;
    `}

	${(props) =>
    props.XS &&
    css`
      max-width: 450px;
    `}

	${(props) =>
    props.center &&
    css`
      text-align: center;
      width: 100%;
    `}
    
	${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    `}
		&,
		&--fluid {
    @media ${(props) => props.theme.mediaQueries.mediumWidth} {
      width: 100%;
      padding: 0 25px;
    }
  }

  &,
  &--fluid {
    @media ${(props) => props.theme.mediaQueries.smallWidth} {
      padding: 0 15px;
    }
  }
`;

const Box = styled.div<BaseContainerProps>`
  ${space}
  ${border}
  ${color}
	${(props) =>
    props.bgWhite &&
    css`
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.secondary};
    `}
	${(props) =>
    props.flex &&
    css`
      display: flex;
    `}
	${(props) =>
    props.inlineBlock &&
    css`
      display: inline-block;
    `}
	${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.colors.secondary};
    `}
	${(props) =>
    props.center &&
    css`
      text-align: center;
      width: 100%;
    `}

    ${(props) =>
    props.tRight &&
    css`
      text-align: right;
    `}
	${(props) =>
    props.hideMedium &&
    css`
      @media ${(props) => props.theme.mediaQueries.mediumWidth} {
        display: none;
      }
    `}

	${(props) =>
    props.showMedium &&
    css`
      display: none;
      @media ${(props) => props.theme.mediaQueries.mediumWidth} {
        display: block;
      }
    `}
	${(props) =>
    props.fWidth &&
    css`
      width: 100%;
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
      ${(props) =>
    props.height &&
    css`
      width: ${props.height};
    `}
`;
const Flex = styled.div<BaseContainerProps>`
  display: flex;
  flex-wrap: wrap;
  ${border}
  ${flexbox};
  ${space}
  margin: auto;
`;

export default Container;
export { Box, Flex };
