import styled from "styled-components";

export const BreadcrumbWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.orange};
  display: flex;
  justify-content: center;
  max-width: 80%;
  counter-reset: section;
  margin: auto;
  margin-bottom: 20px;
  li {
    border-right: 1px solid;
    padding: 5px 20px;
    margin: 10px 0;
    opacity: 0.5;
    &::before {
      counter-increment: section;
      content: counter(section) ".";
      margin-right: 5px;
    }
    &:nth-last-child(1) {
      border-right: 0;
    }
    &.active {
      opacity: 1;
      font-weight: bold;
    }
  }
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    flex-wrap: wrap;
  }
`;
