import React from "react";
import { useTranslation } from "react-i18next";
import Container, { Box } from "../styles/Container";
import { Title2, Title4 } from "../typography/Titles";
import ServiceCard from "../serviceCard/ServiceCard";
// import PromoDigitaleImg from "../../img/promo-digitale.jpg";
import RealisationImg from "../../img/option-clip.png";
// import DiffusionImg from "../../img/option-diffusion.jpg";
import { CartProps } from "../../interfaces/CartProps";

export default function SelectService({
  setCart,
}: {
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
}) {
  const { t } = useTranslation();

  const services = [
    {
      title: t("makeMyVideo.title"),
      text: t("makeMyVideo.resume"),
      info: t("makeMyVideo.inf"),
      id: "PACK",
      bgUrl: RealisationImg,
    },
    // {
    //   title: t("promoTv.title"),
    //   text: t("promoTv.resume"),
    //   id: "PROMOTV",
    //   bgUrl: DiffusionImg,
    // },
    // {
    //   title: t("shareMyVideo.title"),
    //   text: t("shareMyVideo.resume"),
    //   id: "UPLOAD",
    //   bgUrl: PromoDigitaleImg,
    // },
  ];
  return (
    <Container small>
      <Box center my={5}>
        <Title2
          dangerouslySetInnerHTML={{ __html: t("services.title.part1") }}
        />
        <Title4>{t("services.title.part2")}</Title4>
      </Box>
      {services.map((el, i) => {
        return (
          <ServiceCard
            content={{
              title: el.title,
              text: el.text,
              info: el.info,
              bgUrl: el.bgUrl,
            }}
            onClick={() => {
              setCart({
                services: [{ name: el.id }],
              });
            }}
            key={i}
          />
        );
      })}
    </Container>
  );
}
