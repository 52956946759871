import { useState } from "react";
import styled from "styled-components";

import { Input } from "../styles/Form";
import { Text } from "../typography/Texts";

export default function InputPassword({
  label,
  name,
  handleChange,
  value,
  error,
}) {
  const [hidden, setHidden] = useState(true);
  // const [password, setPassword] = useState("");

  function toggleShow() {
    setHidden(!hidden);
  }

  return (
    <div style={{ position: "relative" }}>
      <label>{label}</label>
      <Input
        type={hidden ? "password" : "text"}
        value={value}
        name={name}
        onChange={(e) => {
          handleChange(e);
        }}
        style={{ paddingRight: 35 }}
      />
      <ShowHide
        onClick={toggleShow}
        className={`icon-${hidden ? "eye" : "hide"}`}
      />
      {error && <Text error>{error}</Text>}
    </div>
  );
}

const ShowHide = styled.i`
  position: absolute;
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary};
  right: 0px;
  padding: 11px;
  z-index: 99;
  margin-top: -5px;
  bottom: 0;
`;
