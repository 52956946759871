import produce from "immer";
import { useTranslation } from "react-i18next";
import { CartProps } from "../../interfaces/CartProps";

import Button from "../buttons/Button";
import { Box, Flex } from "../styles/Container";
import { Text } from "../typography/Texts";

export default function ResponsiveTotal({
  totalPrice,
  currency,
  openCart,
  setCart,
  cart,
  service,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  totalPrice: number;
  currency: string;
  service: string;
  openCart: () => void;
}) {
  const { t } = useTranslation();

  function nextStep() {
    setCart(
      produce((draft) => {
        draft.step += 1;
        draft.modify = false;
      })
    );
  }
  return (
    <Box
      fWidth
      p="20px"
      borderTop="1px solid"
      style={{
        background: "#0E0E0E",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 999999,
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text>
          Total : <br />
          <b>
            {totalPrice} {currency}
          </b>
        </Text>
        {cart.step === 0 ? (
          <Button
            style={{
              fontSize: "0.98rem",
              padding: "0 15px",
              minWidth: "140px",
            }}
            onClick={() => {
              nextStep();
            }}
          >
            {cart.services.some((el) => el.name === service)
              ? t("continue")
              : service.startsWith("PROMOTV")
              ? t("continueWithoutPromo")
              : service.startsWith("UPLOAD") && t("continueWithoutUpload")}
          </Button>
        ) : (
          <Button
            onClick={() => openCart()}
            style={{
              fontSize: "0.98rem",
              padding: "0 15px",
              minWidth: "140px",
            }}
          >
            {t("seeDetails")}
          </Button>
        )}
      </Flex>
    </Box>
  );
}
