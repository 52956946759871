import styled, { css } from "styled-components";
import { space } from "styled-system";

const Bloc = styled.div`
  background: ${(props) => props.theme.colors.primary};
  padding: 30px 40px 0 40px;
  text-align: left;
  color: ${(props) => props.theme.colors.secondary};
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  ${space}
  ${(props) =>
    props.grey &&
    css`
      background: ${(props) => props.theme.colors.primaryalt};
      padding-bottom: 40px !important;
    `}
	${(props) =>
    props.fWidth &&
    css`
      max-width: 100%;
    `}
		
	@media ${(props) => props.theme.mediaQueries.smallWidth} {
    padding: 20px 20px 0 20px;
  }
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    max-width: 100% !important;
  }
  button {
    width: 100%;
  }
`;
const BlocCols = styled.div`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryalt};
  width: 100%;
  padding-bottom: 10px;
  ${space}
`;
const BlocCol1 = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 20px;
`;
const BlocCol = styled(BlocCol1)`
  width: 50%;
  ${space}
  @media ${(props) => props.theme.mediaQueries.xSmallWidth} {
    width: 100%;
  }
`;
const BlocColBis = styled(BlocCol)`
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    width: 100%;
  }
`;
const BlocCol3 = styled(BlocCol1)`
  width: 33%;
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    width: 100%;
  }
`;
const BlocCol4 = styled(BlocCol1)`
  width: 25%;
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    width: 50%;
  }
`;
const BlocRadio = styled.div`
  margin-bottom: 18px;
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }
`;
const BlocCheckboxes = styled.div`
  margin-bottom: 10px;
  > div {
    &:not(:last-child) {
      margin-bottom: 23px;
    }
    > div {
      display: flex;
      width: 100%;
      margin-top: 7px;
      span {
        margin-left: auto;
      }
    }
  }
`;
const BlocCols1 = styled(BlocCols)`
  padding: 0;
  border: none;
`;

const BlocS = styled(Bloc)`
  padding: 15px 20px;
`;

export {
  Bloc,
  BlocS,
  BlocRadio,
  BlocCheckboxes,
  BlocCols,
  BlocCols1,
  BlocCol,
  BlocColBis,
  BlocCol1,
  BlocCol3,
  BlocCol4,
};
