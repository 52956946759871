import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AvantageSlider } from "../Components/avantages/Avantages";
import { Box, RIDesktopHeaderGobackBtn } from "../Components/styles/Container";
import Link from "../Components/styles/Link";
import Options from "../Components/subscription/Options";
import SelectService from "../Components/subscription/SelectService";
import { CartProps } from "../interfaces/CartProps";

export default function Subscription() {
  const [cart, setCart] = useState<CartProps>({});
  const { t } = useTranslation();

  function goBack() {
    setCart({});
  }

  return (
    <>
      {!cart.services ? (
        <SelectService setCart={setCart} />
      ) : (
        <>
          <RIDesktopHeaderGobackBtn onClick={() => goBack()}>{t("backToPrev")}</RIDesktopHeaderGobackBtn>
          <Options setCart={setCart} cart={cart} />
          <Box center>
            <Link onClick={() => goBack()}>{t("backToPrev")}</Link>
          </Box>
        </>
      )}
      <AvantageSlider />
    </>
  );
}
