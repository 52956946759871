import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

import Container, { Box } from "../Components/styles/Container";
import Button from "../Components/buttons/Button";
import { Title2 } from "../Components/typography/Titles";
import { AvantageSlider } from "../Components/avantages/Avantages";
import { Text } from "../Components/typography/Texts";
import { BannerImg, BannerWrapper } from "../Components/banner/Banner";
import api from "../client/api";
import ResetPasswordForm from "../Components/login/ResetPasswordForm";

import bgImg from "../img/connexion.jpg";

export default function Password(props) {
  const [verified, setVerified] = useState(false);
  const [expired, setExpired] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    api.checkToken(props.match.params.token, (response) => {
      if (response.reponse === "OK") {
        setVerified(true);
      } else {
        setExpired(true);
      }
    });
  }, [props]);

  return (
    <>
      <BannerWrapper>
        <BannerImg src={bgImg} alt='YouTrace Inscription' className='index' />

        <Box py={[2, 3]}>
          <Container>
            <Box pt={4} center>
              <Title2 bold>{t("resetYourPassword")}</Title2>
            </Box>
          </Container>
          <Container XS>
            {success ? (
              <>
                <Text center pb={2} mb={2}>
                  {t("passwordSaved")}.
                </Text>
                <Button m='auto' mt={2} href='/connexion'>
                  {t("login")}
                </Button>
              </>
            ) : expired ? (
              <Text center pb={2}>
                {t("expired")}.
              </Text>
            ) : verified ? (
              <ResetPasswordForm
                tokenFromProps={props.match.params.token}
                setSuccess={setSuccess}
              />
            ) : (
              <Loader
                type='TailSpin'
                color='#fff'
                height={25}
                width={25}
                style={{ textAlign: "center", margin: "5rem" }}
              />
            )}
          </Container>
        </Box>
      </BannerWrapper>
      <AvantageSlider />
    </>
  );
}
