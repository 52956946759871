import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PaypalExpressBtn from "react-paypal-express-checkout";
import styled from "styled-components";
import Loader from "react-loader-spinner";

import api from "../client/api";
import Container, { Box } from "../Components/styles/Container";
import { TextL, TextS, TextXS, TextXXS } from "../Components/typography/Texts";
import { Title2 } from "../Components/typography/Titles";
import { Bloc, BlocCol, BlocCol1, BlocCols } from "../Components/styles/Bloc";
import { numberToDevice } from "../utils/numberToDevice";
import Link from "../Components/styles/Link";
import Button from "../Components/buttons/Button";
import { userContext } from "../context/userContext";
import worldCountries from "../utils/worldCountries";
import Comment from "../Components/orders/Comment";
import OrderState from "../Components/orders/OrderState";

import iconComment from "../img/comment.svg";
import { UserProps } from "../interfaces/UserProps";

export default function OrderDetails(props) {
  const { t, i18n } = useTranslation();
  const [order, setOrder] = useState<any>({});
  const [user, setUser] = useState<UserProps | any>({});
  const [openComment, setOpenComment] = useState(false);
  const [note, setNote] = useState("");

  const status = [
    { color: "255, 137, 0", title: t("waitpayment") },
    { color: "19, 234, 168", title: t("payed") },
    { color: "1, 130, 1", title: t("orderdelivered") },
    { color: "83, 83, 83", title: t("canceled") },
    { color: "247, 114, 74", title: t("paymentMismatch") },
    { color: "47, 151, 220", title: t("initialized") },
    { color: "255, 0, 0", title: t("failed") },
    { color: "41, 72, 245", title: t("inprogress") },
    { color: "1, 168, 104", title: t("prodDone") },
    { color: "117, 5, 150", title: t("customerContacted") },
  ];

  const locale = i18n.language.slice(0, 2);
  const paypalCurrencies = ["USD", "CAD"];
  const currentCurrency = order.country ? order.country.currency : "";
  const currency = paypalCurrencies.includes(currentCurrency)
    ? currentCurrency
    : "EUR";
  const paypalTotal = order.country
    ? order.amount * order.country.euroRate * 0.4
    : 0;
  const env =
    user && user.connectedOn === "production" ? "production" : "sandbox";

  const activeLang = i18n?.language.slice(0, 2);
  const valuesWorldCountries: any = Object.values(worldCountries[activeLang]);
  const translateCountryName = valuesWorldCountries.find(
    (el) => el.alpha_2 === order.country?.code
  )?.name;

  const client = {
    sandbox:
      "AQQEf9dLkKelP77KXDd1aUCGXT2LNhG4TWbuPMWyKL7A3gkOQqRT9eh0UGivsa0XlW32esuSY-jQhBtS",
    production:
      "AbsUCzukK8SE8CyeYGSffqo98Fzw3rMk0biu33HVJw3oit_5CAQQ8tjutwuhQzb4l7C0gQxImgFRvQli",
  };

  const stylePaypal = {
    size: "responsive",
    color: "gold",
    shape: "rect",
  };

  useEffect(() => {
    insertScript();
    api.fetchClip(props.match.params.id, (order) => {
      setOrder(order);
    });
    api.getUser((user) => {
      setUser(user);
    });
    if (!localStorage.getItem("access_token")) {
      window.location.href = "login";
    }
  }, [props.match.params.id]);

  function insertScript() {
    const script = document.createElement("script");
    script.src = `https://touchpay.gutouch.com/touchpay/script/prod_touchpay-0.0.1.js`;
    script.type = "text/javascript";
    document.body.appendChild(script);
  }

  function handleInvoice(locale) {
    api.invoice(
      props.match.params.id,
      (blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
      },
      locale
    );
  }

  function handleClickGetPaid() {
    api.repGetPaid(order, (order) => {
      setOrder(order);
      window.location.reload();
    });
  }

  function handleClickCancelOrder() {
    api.cancelOrder(order, (data) => {
      window.location.reload();
    });
  }

  function terminateOrder() {
    api.terminateOrder(order, (data) => {
      window.location.reload();
    });
  }

  function prodOrder() {
    api.prodOrder(order, (data) => {
      window.location.reload();
    });
  }

  function progressOrder() {
    api.progressOrder(order, (data) => {
      window.location.reload();
    });
  }

  function customerContacted() {
    api.customerContacted(order, (data) => {
      window.location.reload();
    });
  }

  function handleCCProd(locale) {
    api.ccprod(
      props.match.params.id,
      (blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Music_Video_Coproduction_Contract.pdf");
        document.body.appendChild(link);
        link.click();
      },
      locale
    );
  }

  function handleCgv(locale) {
    api.cgv((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "Terms_And_Conditions.pdf");
      document.body.appendChild(link);
      link.click();
    }, locale);
  }

  function calltouchpay() {
    // TODO: Fix function behind
    // window.sendPaymentInfos(
    //   order.identity,
    //   user.country.agency,
    //   user.country.token,
    //   "trace.tv",
    //   window.location.origin + "/touchpay/success",
    //   window.location.origin + "/touchpay/failed",
    //   order.amount,
    //   "",
    //   user.emailAddress,
    //   user.firstName,
    //   user.lastName,
    //   ""
    // );
  }

  function save() {
    order.comment = note;
    api.updateComment(order, (order) => {
      setOpenComment(false);
    });
  }

  const optionRealisation = [
    {
      id: "silver",
      name: "option-realisation",
      title: "Silver",
      subtitle: t("option1"),
      content: (
        <ul className='tick-list'>
          <li>
            <TextS>{t("option4")}</TextS>
          </li>
          <li>
            <TextS>{t("option5")}</TextS>
          </li>
          <li>
            <TextS>{t("option6")}</TextS>
          </li>
          <li>
            <TextS>{t("option9")}</TextS>
          </li>
        </ul>
      ),
      price: 450000,
    },
    {
      id: "gold",
      name: "option-realisation",
      title: "Gold",
      subtitle: t("option2"),
      content: (
        <ul className='tick-list'>
          <li>
            <TextS>{t("option4")}</TextS>
          </li>
          <li>
            <TextS>{t("option5")}</TextS>
          </li>
          <li>
            <TextS>{t("option7")}</TextS>
          </li>
          <li>
            <TextS>{t("option9")}</TextS>
          </li>
        </ul>
      ),
      price: 900000,
    },
    {
      id: "platinium",
      name: "option-realisation",
      title: "Platinium",
      subtitle: t("option3"),
      content: (
        <ul className='tick-list'>
          <li>
            <TextS>{t("option4")}</TextS>
          </li>
          <li>
            <TextS>{t("option5")}</TextS>
          </li>
          <li>
            <TextS>{t("option8")}</TextS>
          </li>
          <li>
            <TextS>{t("option10")}</TextS>
          </li>
        </ul>
      ),
      price: 1350000,
    },
  ];

  const CommentButton = styled.img`
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  `;

  const onSuccess = (payment) => {
    api.pay(
      {
        idTransaction: payment.paymentID,
        montant: paypalTotal,
        service: "paypal",
        order: order,
      },
      () => {
        const newOrder = { ...order };
        newOrder.status = 1;
        setOrder(newOrder);
      }
    );
  };

  const onCancel = (data) => {
    console.log("The payment was cancelled!", data);
  };

  const onError = (err) => {
    console.log("Error!", err);
  };

  if (Object.keys(order).length > 0 && order.user) {
    const { firstName, lastName, emailAddress, phone } = order.user;
    const worldCountriesToArr: any = Object.values(worldCountries[activeLang]);
    const translateUserCountryName: any = worldCountriesToArr.find(
      (el) => el.alpha_2 === order.user.countryCode
    )?.name;
    return (
      <Box pb={5}>
        <Container small>
          <Box center my={5}>
            <OrderState
              className='open'
              style={{ background: `rgb(${status[order.status]?.color})` }}
            >
              <p>{status[order.status]?.title}</p>
            </OrderState>
            <p>{order.updatedBy}</p>
            <p>{order.lastUpdate}</p>
            <Title2 mb={1}>{order.title}</Title2>
            <TextL>{order.cru}</TextL>
          </Box>
          <Bloc>
            {openComment && (
              <Comment
                save={save}
                setNote={setNote}
                note={note === "" ? order.comment : note}
                close={() => setOpenComment(false)}
              />
            )}
            <Box>
              <CommentButton
                onClick={() => setOpenComment(true)}
                src={iconComment}
                alt='comment icon'
              />
              <TextXXS orange bold>
                {t("videoInformation")}
              </TextXXS>
              <BlocCols>
                <BlocCol>
                  <TextXXS mb={1}>{t("title")}</TextXXS>
                  <TextS>{order.title}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextXXS mb={1}>{t("artist")}</TextXXS>
                  <TextS>{order.artist}</TextS>
                </BlocCol>
                {order.producer && (
                  <BlocCol>
                    <TextXXS mb={1}>{t("producer")}</TextXXS>
                    <TextS>{order.producer}</TextS>
                  </BlocCol>
                )}
                {order.createdAt && (
                  <BlocCol>
                    <TextXXS mb={1}>{t("transactionDate")}</TextXXS>
                    <TextS>{order.createdAt}</TextS>
                  </BlocCol>
                )}
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.country")}</TextXXS>
                  <TextS>{translateCountryName}</TextS>
                </BlocCol>
              </BlocCols>
              {/* firstName, lastName, emailAddress, phone, country */}
              <TextXXS mt={3} orange bold>
                {t("customerInformation")}
              </TextXXS>
              <BlocCols>
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.firstName")}</TextXXS>
                  <TextS>{firstName}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.lastName")}</TextXXS>
                  <TextS>{lastName}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.email")}</TextXXS>
                  <TextS>{emailAddress}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.phone")}</TextXXS>
                  <TextS>{phone}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextXXS mb={1}>{t("registration.country")}</TextXXS>
                  <TextS>{translateUserCountryName}</TextS>
                </BlocCol>
              </BlocCols>
              <TextXXS mt={3} orange bold>
                {t("offerAndOption")}
              </TextXXS>
              <BlocCols>
                <BlocCol>
                  <TextS>
                    {order.production
                      ? order.orderServices
                        .find((s) => s.serviceType.startsWith("PACK"))
                        .serviceType.replace("PACK_", t("real") + " ")
                      : order.promotion
                        ? order.orderServices
                          .find((s) => s.serviceType.startsWith("PROMOTV"))
                          .serviceType.replace(
                            "PROMOTV_",
                            t("promoteYourVideo") + " "
                          )
                        : order.orderServices}
                  </TextS>
                  <TextXS>
                    {order.production
                      ? optionRealisation.find(
                        (o) =>
                          "PACK_" + o.id.toUpperCase() ===
                          order.orderServices.find(
                            (s) =>
                              s.serviceType !== "UPLOAD" &&
                              !s.serviceType.startsWith("PROMOTV") &&
                              !s.serviceType.startsWith("DIST")
                          ).serviceType
                      ).subtitle
                      : ""}
                  </TextXS>
                </BlocCol>
                <BlocCol>
                  <TextS style={{ textAlign: "right" }}>
                    {numberToDevice(Number(order.amount))}{" "}
                    {order.country ? order.country.currency : ""}
                  </TextS>
                </BlocCol>
                {/* <BlocCol>
                  <TextS>{t("broadcastYourVideo")}</TextS>
                </BlocCol>
                <BlocCol>
                  <TextS style={{ textAlign: "right" }}>
                    {order.orderServices &&
                      order.orderServices.find(
                        (s) => s.serviceType === "UPLOAD"
                      ) != null
                      ? "Oui"
                      : "Non"}
                  </TextS>
                  <TextS style={{ textAlign: "right" }}>
                    {order.orderServices &&
                      order.orderServices.find(
                        (s) => s.serviceType === "UPLOAD"
                      ) != null
                      ? order.orderServices.find(
                        (s) => s.serviceType === "UPLOAD"
                      ).serviceDeliveredDate
                      : ""}
                  </TextS>
                </BlocCol>
                <BlocCol>
                  <TextS>{t("promoteYourVideo")}</TextS>
                  <TextS style={{ textAlign: "right" }}>
                    {order.promotion
                      ? order.orderServices.find((s) =>
                        s.serviceType.startsWith("PROMOTV")
                      ).serviceDeliveredDate
                      : ""}
                  </TextS>
                </BlocCol>
                <BlocCol>
                  <TextS style={{ textAlign: "right" }}>
                    {order.promotion ? "Oui" : "Non"}
                  </TextS>
                </BlocCol> */}
              </BlocCols>
              {order.country && order.country.coordinator && (
                <>
                  <TextXXS mt={3} orange bold>
                    {t("myYoutracerRep")}
                  </TextXXS>
                  <BlocCols>
                    <BlocCol1>
                      <TextXS>
                        Mr{" "}
                        {order.country
                          ? order.country.coordinator.firstName +
                          " " +
                          order.country.coordinator.lastName
                          : ""}
                      </TextXS>
                      <TextS mt={2}>
                        Tél. :{" "}
                        {order.country ? order.country.coordinator.phone : ""}
                      </TextS>
                    </BlocCol1>
                  </BlocCols>
                </>
              )}
              <TextXXS mt={3} orange bold>
                {t("paymentMethod")}
              </TextXXS>
              <BlocCols>
                <BlocCol1>
                  {order.service && (
                    <TextS mt={2}>
                      {order.service.charAt(0).toUpperCase() +
                        order.service.slice(1)}
                    </TextS>
                  )}
                  <TextS mt={2}>
                    {t("paymentno")} {order.identity}
                  </TextS>
                  {order.paymentDate && (
                    <TextS mt={2}>
                      {t("paymentDate")} {order.paymentDate}
                    </TextS>
                  )}
                </BlocCol1>
              </BlocCols>
            </Box>
            <Box center my={4}>
              <userContext.Consumer>
                {({ user }) => {
                  if (user && user.idRole !== "1") {
                    return (
                      <>
                        {order.status === 2 ? (
                          <>
                            <TextS orange my={3}>
                              <Link orange>{t("downloadCustomerInvoice")}</Link>
                            </TextS>
                            <TextS orange my={3}>
                              <Link orange>{t("downloadMyInvoice")}</Link>
                            </TextS>
                          </>
                        ) : order.status === 1 ? (
                          <>
                            <Button
                              fullWidth
                              href={"/ajouter-clip/" + order.identity}
                            >
                              {t("sendClip")}
                            </Button>
                            <TextS
                              orange
                              my={3}
                              onClick={() => handleInvoice(locale)}
                            >
                              <Link orange>{t("downloadMyInvoice")}</Link>
                            </TextS>
                            <BlocCols />
                            <Box mt={4} style={{ position: "relative" }}>
                              <Button fullWidth onClick={customerContacted}>
                                {t("customerContacted")}
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <Box>
                            <Button fullWidth onClick={handleClickGetPaid}>
                              {t("sendReceipt")}
                            </Button>
                            {order.status !== 3 && (
                              <Box mt={4} style={{ position: "relative" }}>
                                <Button
                                  fullWidth
                                  onClick={handleClickCancelOrder}
                                >
                                  {t("cancelOrder")}
                                </Button>
                              </Box>
                            )}
                            <BlocCols />
                            {order.status === 9 && (
                              <Box mt={4} style={{ position: "relative" }}>
                                <Button fullWidth onClick={progressOrder}>
                                  {t("inprogress")}
                                </Button>
                              </Box>
                            )}
                            {order.status === 7 && (
                              <Box mt={4} style={{ position: "relative" }}>
                                <Button fullWidth onClick={prodOrder}>
                                  {t("prodDone")}
                                </Button>
                              </Box>
                            )}
                            {order.status === 8 && (
                              <Box mt={4} style={{ position: "relative" }}>
                                <Button fullWidth onClick={terminateOrder}>
                                  {t("orderdone")}
                                </Button>
                              </Box>
                            )}
                            {order.status === 0 && (
                              <Box mt={4} style={{ position: "relative" }}>
                                <PaypalExpressBtn
                                  style={stylePaypal}
                                  client={client}
                                  currency={currency}
                                  total={paypalTotal}
                                  onError={onError}
                                  env={env}
                                  onSuccess={onSuccess}
                                  onCancel={onCancel}
                                />
                                <Button
                                  style={{ width: "100%" }}
                                  onClick={() => alert()}
                                >
                                  {t("paypal")}
                                </Button>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    );
                  } else if (user && user.idRole === "1") {
                    return (
                      <>
                        {order.production && (
                          <TextS
                            orange
                            my={3}
                            onClick={() => handleCCProd(locale)}
                          >
                            <Link orange>{t("seeCP")}</Link>
                          </TextS>
                        )}
                        <TextS orange my={3} onClick={() => handleCgv(locale)}>
                          <Link orange>{t("seeTC")}</Link>
                        </TextS>
                        <TextS orange onClick={() => handleInvoice(locale)}>
                          <Link orange>{t("downloadMyInvoice")}</Link>
                        </TextS>
                        {order.status === 6 && order.service === "touchpay" && (
                          <Box mt={4} style={{ position: "relative" }}>
                            <Button fullWidth onClick={() => calltouchpay()}>
                              {t("mobilePayment")}
                            </Button>
                          </Box>
                        )}
                      </>
                    );
                  }
                }}
              </userContext.Consumer>
            </Box>
          </Bloc>
          <Box mt={4} center>
            <Link orange href='/commandes'>
              {t("backToOrders")}
            </Link>
          </Box>
        </Container>
      </Box>
    );
  } else {
    return (
      <Loader
        type='TailSpin'
        color='#fff'
        height={25}
        width={25}
        timeout={3000}
        style={{ marginRight: 20 }}
      />
    );
  }
}
