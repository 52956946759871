import { useTranslation } from "react-i18next";

// import { HeaderIcons } from "../Components/layouts/header/Header.style";
// import { useDarkMode } from "../hooks/useDarkMode";
// import LangSelect from "../Components/langSelect/LangSelect";
import Container, { Box } from "../Components/styles/Container";
// import Container, { Flex } from "../Components/styles/Container";
import Banner from "../Components/banner/Banner";
import { Title4 } from "../Components/typography/Titles";
import imgCover from "../img/cover-trace.jpg";
import AccountWrapper from "../Components/account/AccountWrapper";
import { userContext } from "../context/userContext";
import { UserProps } from "../interfaces/UserProps";

export default function Account() {
  // const [theme, themeToggler] = useDarkMode();

  const { t } = useTranslation();

  return (
    <userContext.Consumer>
      {({ user }: { user: UserProps }) => (
        <Box pb={5}>
          <Banner
            children={
              <Box center>
                <Title4>
                  {user ? `${user.firstName} ${user.lastName}` : ""}
                </Title4>
              </Box>
            }
            img={imgCover}
            alt="Beyonce YouTrace Pro"
            imgHeight="150px"
          />
          {/* <Container mt="25px" small>
            <Box center>
              <h3>{t("settingsYt")}</h3>
            </Box>
            <Flex>
              <Box style={{ flex: 1 }}>
                <p>{t("lang")} :</p>
                <LangSelect />
              </Box>
              <Box style={{ flex: 1 }}>
                <p>Theme :</p>
                <HeaderIcons onClick={() => themeToggler()}>
                  {theme} mode
                  <i
                    className={theme ? "icon-sun" : "icon-moon"}
                    style={{ marginLeft: "10px" }}
                  />
                </HeaderIcons>
              </Box>
            </Flex>
          </Container> */}
          <Container mt="25px" small>
            <Box center>
              <h3>{t("myInfos")} </h3>
            </Box>
          </Container>
          <AccountWrapper />
        </Box>
      )}
    </userContext.Consumer>
  );
}
