import { CartProps } from "../../interfaces/CartProps";
import { Flex } from "../styles/Container";
import { Input } from "../styles/Form";
import { Text, TextXS } from "../typography/Texts";

import { PaymentSolutionWrapper } from "./PaymentSolution.style";

export default function PaymentSolution({
  cart,
  id,
  label,
  smallText,
  minText,
  handleChange,
  img,
}: {
  cart: CartProps;
  id: string;
  label: string;
  smallText?: string;
  minText?: string;
  handleChange: any;
  img: string[];
}) {
  return (
    <PaymentSolutionWrapper>
      <Input
        onChange={() => handleChange(id)}
        checked={cart.paymentMethod === id}
        type="radio"
        id={id}
        name="price-mode"
      />
      <label htmlFor={id}>
        <span></span>
        <Flex style={{margin: 0}}>
          <Text>
            {label}
            {minText}
          </Text>
          {img.length > 0 &&
            img.map((el, i) => (
              <img
                alt="paiement"
                src={el}
                style={{ marginRight: "4px" }}
                key={i}
              />
            ))}
          <TextXS>{smallText}</TextXS>
        </Flex>
      </label>
    </PaymentSolutionWrapper>
  );
}
