import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container, { Box, Flex } from "./styles/Container";
import { Title2 } from "./typography/Titles";
import cash from "../img/logo-payment/cash.png";
import free from "../img/logo-payment/free.png";
import mobicash from "../img/logo-payment/mobicash.png";
import moov from "../img/logo-payment/moov.png";
import mtn from "../img/logo-payment/mtn.png";
import orange from "../img/logo-payment/orange.png";
import wizall from "../img/logo-payment/wizall.png";
import yup from "../img/logo-payment/yup.png";

export default function PaymentSolutions() {
  const settings = {
    speed: 4500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  const logos = [cash, free, mobicash, moov, mtn, orange, wizall, yup];
  const { t } = useTranslation();
  return (
    <Box my={[4, 6]}>
      <Container>
        <Flex>
          <Box style={{ maxWidth: "30%", minWidth: 300, flex: 1, zIndex: 99 }}>
            <Title2>{t("paymentTitle")}</Title2>
          </Box>
          <Flex
            style={{
              width: "60%",
              minWidth: 300,
              flex: 1,
              alignItems: "center",
            }}
          >
            <LogoContainer>
              <Filter />
              <Slider {...settings}>
                {logos.map((el, i) => {
                  return <Logo src={el} key={i} alt='logo financial partner' />;
                })}
              </Slider>
            </LogoContainer>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

const LogoContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const Logo = styled.img`
  height: 50px;
  width: auto !important;
  margin: 10px;
`;

const Filter = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent
    linear-gradient(
      90deg,
      #000000 0%,
      #00000000 44%,
      #00000000 56%,
      #000000 100%
    )
    0% 0% no-repeat padding-box;
  z-index: 9;
`;
