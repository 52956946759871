import styled, { css } from "styled-components";

interface PricingTableServiceProps {
  included?: boolean;
}

export const PricingTableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 40px 20px;
  max-width: 100%;
  width: 320px;
  margin: 10px;
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    padding: 20px;
  }
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    width: 240px;
    text-align: center;
  }
  @media ${(props) => props.theme.mediaQueries.xSmallWidth} {
    width: 100%;
  }
  a,
  p {
    width: 100%;
  }
`;

export const PricingTableHeading = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.orange};
  margin-bottom: 40px;
  h4 {
    margin-top: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  h1 {
    color: ${(props) => props.theme.colors.secondary};
    @media ${(props) => props.theme.mediaQueries.mediumWidth} {
      font-size: 2rem;
    }
  }
  p {
    font-style: italic;
    margin-bottom: 40px;
  }
`;

export const PricingTableService = styled.li<PricingTableServiceProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  span {
    color: ${(props) => props.theme.colors.orange};
  }
  &::before {
    content: "✕";
    color: ${(props) => props.theme.colors.red};
    font-size: 1.5rem;
    margin: -0.3rem 1rem 0 0;
    width: 30px;
  }
  ${(props) =>
    props.included &&
    css`
      &::before {
        content: "✓";
        color: ${(props) => props.theme.colors.secondary};
        font-size: 1.8rem;
      }
    `}
`;
