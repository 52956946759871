import channels from "../../utils/channels";
import { ChannelsWrapper, Channel } from "./Channels.style";
import Container from "../styles/Container";

export default function Channels({
  handleClick,
  selected,
  channelsList,
}: {
  channelsList: any[];
  handleClick: any;
  selected: {
    channel: string;
    identity: number;
    platinum: number;
    premium: number;
  };
}) {
  const filtredChannels = channels.filter(
    (item, i) =>
      channelsList.find((c) => c.channel.startsWith(item.name)) != null
  );

  return (
    <Container className="Channels">
      <ChannelsWrapper>
        {filtredChannels.map((el, i) => {
          return (
            <Channel
              key={i}
              onClick={async () => {
                await handleClick(
                  channelsList.find((c) => c.channel.startsWith(el.name))
                );
              }}
              isSelected={selected.channel === el.name}
            >
              <img src={el.img} alt={el.name} />
            </Channel>
          );
        })}
      </ChannelsWrapper>
    </Container>
  );
}
