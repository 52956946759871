import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AvantageSlider } from "../Components/avantages/Avantages";
import TrailerVideo from "../img/trailer.mp4";
import { BannerWrapper } from "../Components/banner/Banner.style";
import VideoWrapper, { Filter } from "../Components/styles/VideoWrapper";
import Container, { Box } from "../Components/styles/Container";
import RegistrationForm from "../Components/registration/RegistrationForm";
import { Title2 } from "../Components/typography/Titles";
import { userContext } from "../context/userContext";
import Link from "../Components/styles/Link";

export default function Registration() {
  const { t } = useTranslation();
  if (localStorage.getItem("access_token")) {
    return <Redirect to={"/services"} />;
  } else {
    return (
      <userContext.Consumer>
        {({ loginUser }) => {
          return (
            <>
              <BannerWrapper>
                {TrailerVideo && (
                  <VideoWrapper
                    dangerouslySetInnerHTML={{
                      __html: `
									<video playsInline autoPlay muted loop>
										<source src="${TrailerVideo}" type='video/mp4' />
									</video>
								`,
                    }}
                  />
                )}
                <Filter />
                <Box py={4}>
                  <Container small>
                    <Box pb={4}>
                      <Title2>
                        <span>{t("registration.create")}</span>{" "}
                        {t("registration.account")}
                      </Title2>
                    </Box>
                  </Container>
                  <Container small>
                    <RegistrationForm handleValidation={() => loginUser} />
                    <Box center mt={4}>
                      <Link href='/connexion'>{t("registration.already")}</Link>
                    </Box>
                  </Container>
                </Box>
              </BannerWrapper>
              <AvantageSlider />
            </>
          );
        }}
      </userContext.Consumer>
    );
  }
}
