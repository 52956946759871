import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TextS } from "../typography/Texts.tsx";
import { Title2 } from "../typography/Titles.tsx";

export default function Status({ data, filters, allStatus }) {
  return (
    <StatusWrapper
      style={{ boxShadow: `inset 0 0 999px rgba(${data.color},0.5)` }}
      to={{
        pathname: "/commandes",
        state: {
          filters: {
            status: data.status,
            date: filters.date,
            country: filters.country,
          },
        },
      }}
    >
      <StatusContent style={{ borderColor: `rgb(${data.color})` }}>
        <Title2 mb="5px" mt="0">
          {data.number}
        </Title2>
        <TextS>{data.title}</TextS>
      </StatusContent>
    </StatusWrapper>
  );
}

const StatusWrapper = styled(Link)`
  width: calc(33% - 10px);
  min-width: 100px;
  margin: 5px;
  text-align: center;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: relative;
    padding-bottom: 100%;
  }
`;

const StatusContent = styled.div`
  position: absolute;
  color: #fff;
  border: 1px solid;
  padding: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
