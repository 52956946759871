import styled, { css } from "styled-components";
import Link from "./Link";
import { space, SpaceProps } from "styled-system";
import { Box } from "./Container";

interface FormsProps {
  white?: boolean;
  dSNone?: boolean;
  light?: boolean;
  tShadow?: boolean;
}

const Form = styled.form<FormsProps & SpaceProps>`
  text-align: left;
  margin: 20px 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
    -webkit-text-fill-color: ${(props) => props.theme.colors.secondary};
    background: transparent;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  ${(props) =>
    props.white &&
    css`
      input {
        color: #ffffff !important;
        border-bottom: 1px solid #ffffff !important;
      }
      i {
        color: #ffffff !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: white !important;
      }
    `}
`;

const FormCol = styled(Box)<FormsProps>`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  margin-bottom: 20px;
  &:nth-child(odd) {
    padding-right: 17px;
  }
  &:nth-child(even) {
    padding-left: 17px;
  }
  ${(props) =>
    props.dSNone &&
    css`
      @media ${(props) => props.theme.mediaQueries.smallWidth} {
        display: none;
      }
    `}
  ${(props) =>
    props.fWidth &&
    css`
      flex-basis: 100%;
      padding-right: 0 !important;
    `}
	@media ${(props) => props.theme.mediaQueries.smallWidth} {
    flex-basis: 100%;
    padding: 0;
    margin: 0;
    min-height: 37px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:nth-child(odd) {
      padding-right: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }
  }
  ${space}
`;

const Input = styled.input<FormsProps>`
  height: 40px;
  padding: 10px;
  outline: none;
  font-size: 1rem;
  width: 100%;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.inter};
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  background: transparent;
  transition: 0.18s ${(props) => props.theme.transitions.easeInCubic};
  &.opacity {
    opacity: 0;
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.secondary};
    opacity: 0.7;
  }
  &::-moz-placeholder {
    color: ${(props) => props.theme.colors.secondary};
    opacity: 0.7;
  }
`;
const TextArea = styled.textarea<FormsProps>`
  background: ${(props) => props.theme.colors.primary};
  border: none;
  padding: 12px;
  outline: none;
  font-size: 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.secondary};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.inter};
  box-sizing: border-box;
  transition: 0.18s ${(props) => props.theme.transitions.easeInCubic};
  min-width: 100%;
  min-height: 100px;
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.secondary};
    opacity: 0.7;
  }
  &::-moz-placeholder {
    color: ${(props) => props.theme.colors.secondary};
    opacity: 0.7;
  }
`;

const Radio = styled.div<FormsProps>`
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    background: #000;
    &:checked + label {
      > span {
        background: ${(props) => props.theme.colors.orange};
      }
    }
  }
  label {
    font-size: 1rem;
    cursor: pointer;
    padding: 0;
    transition: background 0.2s linear;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background: ${(props) => props.theme.colors.greyLight};
    z-index: 1;
    margin: 0 10px;
    position: relative;
    height: 18px;
    min-width: 18px;
    border-radius: 50%;
    color: ${(props) => props.theme.colors.secondary};
    font-size: 8px;
    font-family: "icomoon" !important;
    &:before {
      content: "\\e911";
      line-height: 99%;
    }
  }
`;

const Switcher = styled.div<FormsProps>`
  ${space}
  span {
    width: 28px;
    height: 18px;
    border-radius: 25px;
    background-color: ${(props) => props.theme.colors.grey};
    content: "";
    transition: background-color 0.2s linear;
    line-height: 2;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 13px;
    cursor: pointer;

    &:before {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      border-radius: 30px;
      background-color: #fff;
      display: block;
      content: "";
      float: left;
      margin-left: 1px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    }
  }
  ${(props) =>
    props.light &&
    css`
      span {
        background-color: ${(props) => props.theme.colors.greyLight};
      }
    `}

  label {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  input {
    display: none;

    &:hover {
      cursor: pointer;
    }

    &:checked + label {
      span {
        background-color: ${(props) => props.theme.colors.orange};

        &:before {
          margin: 0 0 0 11px;
        }
      }
    }
  }
`;

const Checkbox = styled.div<FormsProps>`
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked + label {
      > span {
        background: ${(props) => props.theme.colors.orange};
      }
    }
  }

  label {
    font-size: 1rem;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: flex-start;

    > span {
      display: inline-block;
      vertical-align: middle;
      background: ${(props) => props.theme.colors.greyLight};
      width: 13px;
      height: 13px;
      z-index: 1;
      border: 1px solid ${(props) => props.theme.colors.greyLight};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.secondary} inset;
      margin-right: 10px;
      position: relative;
      flex-shrink: 0;
      margin-top: 2px;
    }

    > div {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }
`;

const Label = styled.div<FormsProps>`
  font-size: 1rem;
  margin-bottom: 5px;
`;

const AddInput = styled(Link)<FormsProps>`
  cursor: pointer;
  margin-left: auto;
  margin-top: 16px;
  background: transparent;
  color: ${(props) => props.theme.colors.orange};
  border: none;
  font-size: 0.98rem;
  &:after {
    background: ${(props) => props.theme.colors.orange};
  }
  span {
    margin-right: 10px;
  }
`;
const SimpleSwitchItem = styled.div<FormsProps>`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
`;

const SwitchItem = styled.div<FormsProps>`
  min-height: 100px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #fff;
  > * {
    z-index: 1;
  }
  ${(props) =>
    props.tShadow &&
    css`
      p,
      h1,
      h2,
      h3,
      h4 {
        text-shadow: 0 0 3px black;
      }
    `}
`;
const RadioItem = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
`;
const RadioItemText = styled.div`
  line-height: 118%;
  > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;
const FormWrapper = styled.div`
  width: 610px;
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    width: 100% !important;
  }
`;

const Select = styled(Box)`
  height: 40px;
  padding: 10px;
  outline: none;
  font-size: 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.inter};
  box-sizing: border-box;
  transition: 0.18s ${(props) => props.theme.transitions.easeInCubic};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  border: 1px solid;
  color: ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.primary};
`;

const List = styled.ul`
  border: 1px solid;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.primary};
  li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid;
    &::before {
      content: none;
    }
  }
`;

export {
  Form,
  FormCol,
  Input,
  Radio,
  Switcher,
  Checkbox,
  Label,
  AddInput,
  SwitchItem,
  SimpleSwitchItem,
  RadioItem,
  RadioItemText,
  FormWrapper,
  TextArea,
  Select,
  List,
};
