import { useTranslation } from "react-i18next";
import Container, { Box, Flex } from "../styles/Container";
import { Title3 } from "../typography/Titles";
import { FaqDropdown } from "../Dropdown";

function Faq() {
  const { t } = useTranslation();

  const questions = [
    {
      title: t("faq.question7.title"),
      text: t("faq.question7.text"),
    },
    {
      title: t("faq.question11.title"),
      text: t("faq.question11.text"),
    },
    {
      title: t("faq.question1.title"),
      text: t("faq.question1.text"),
    },
    {
      title: t("faq.question9.title"),
      text: t("faq.question9.text"),
    },
    // {
    //   title: t("faq.question10.title"),
    //   text: t("faq.question10.text"),
    // },
    // {
    //   title: t("faq.question12.title"),
    //   text: t("faq.question12.text"),
    // },
    // {
    //   title: t("faq.question13.title"),
    //   text: t("faq.question13.text"),
    // },
  ];

  return (
    <Container my={[4, 6]}>
      <Flex alignItems="center" flexWrap="wrap">
        <Box center fWidth mb={4}>
          <Title3>{t("questions")}</Title3>
        </Box>
        <Box>
          {questions.map(
            (question, i) =>
              question.title.length >= 1 && (
                <FaqDropdown
                  key={i}
                  title={question.title}
                  text={question.text}
                />
              )
          )}
        </Box>
      </Flex>
    </Container>
  );
}

export default Faq;
