import styled from "styled-components";
import { Box } from "./Container";

const VideoWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Filter = styled(Box)`
  position: absolute;
  height: calc(100% + 1px);
  width: 100%;
  background: transparent
    linear-gradient(
      180deg,
      #000000 0%,
      rgba(0, 0, 0, 0.8) 33%,
      rgba(0, 0, 0, 0.8) 66%,
      #000000 100%
    )
    0% 0% no-repeat padding-box;
`;
export default VideoWrapper;
export { Filter };
