import produce from "immer";
import { Redirect, useLocation, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "../buttons/Button";

import Container, { Box } from "../styles/Container";
import { Title2, Title4 } from "../typography/Titles";
import PricingTablesContainer from "../pricingTablesContainer/PricingTablesContainer";
import { CartProps } from "../../interfaces/CartProps";
import {
  fr as promotvFr,
  en as promotvEn,
  pt as promotvPt,
} from "../../lang/services/promo-tv";
import {
  fr as diffuFr,
  en as diffuEn,
  pt as diffuPt,
} from "../../lang/services/diffusion";
import {
  fr as realFr,
  en as realEn,
  pt as realPt,
} from "../../lang/services/realisation";
import { userContext } from "../../context/userContext";
import { UserProps } from "../../interfaces/UserProps";
import { useState } from "react";

export default function Options({
  setCart,
  cart,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
}) {
  const { i18n, t } = useTranslation();
  let lang = i18n.language.slice(0, 2);

  const PROMOTV = {
    fr: promotvFr,
    en: promotvEn,
    pt: promotvPt,
  }[lang].tarifs;
  const UPLOAD = {
    fr: diffuFr,
    en: diffuEn,
    pt: diffuPt,
  }[lang].tarifs;
  const PACK = {
    fr: realFr,
    en: realEn,
    pt: realPt,
  }[lang].tarifs;
  const services = [PROMOTV, UPLOAD, PACK];
  const history = useHistory();
  let location = useLocation();

  const isModif = cart.modify?.toString();
  const serviceToModify = isModif?.slice(8);
  const [isCart, setIsCart] = useState<boolean>(false);
  let getIndex = cart.services.indexOf(
    cart.services.filter((serv) => serv.name === serviceToModify)[0]
  );
  if (getIndex === -1) {
    getIndex = 0;
  }

  const title =
    cart.services[getIndex].name === "PROMOTV"
      ? t("promoTv.options.title")
      : cart.services[getIndex].name === "PACK"
      ? t("realisation.options.title")
      : cart.services[getIndex].name === "UPLOAD"
      ? t("diffusion.options.title")
      : "";

  const subtitle =
    cart.services[getIndex].name === "PROMOTV"
      ? t("promoTv.options.subtitle")
      : cart.services[getIndex].name === "PACK"
      ? t("realisation.options.subtitle")
      : cart.services[getIndex].name === "UPLOAD"
      ? t("diffusion.options.subtitle")
      : "";

  let serviceOptions = services.filter(
    (el) => el.serviceName === cart.services[getIndex].name
  )[0];

  function handleSelectOption(name, price, country, channel) {
    setCart(
      produce((draft) => {
        draft.services[getIndex].offer = name;
        draft.services[getIndex].price = price;
        draft.country = country;
        draft.modify = false;
        if (cart.services[getIndex].name === "UPLOAD") {
          draft.services[getIndex].options = [
            // { name: "Youtube", description: t("upload.youtube"), active: true },
            // {
            //   name: "Youtrace.tv",
            //   description: t("upload.youtrace"),
            //   active: true,
            // },
          ];
        } else if (cart.services[getIndex].name === "PROMOTV") {
          draft.services[getIndex].channel = channel;
        }
      })
    );
  }
  if (isCart) {
    history.push("/services");
    return (
      <Redirect
        to={{
          pathname: "/panier",
          state: { cart: cart },
        }}
      />
    );
  }

  return (
    <Box marginBottom={50}>
      <Container
        small
        style={{
          padding: location.pathname === "/panier" ? 0 : "20px",
        }}
      >
        <Box center mt={5} mb={4}>
          <Title2 dangerouslySetInnerHTML={{ __html: title }} />
          {!cart.country && <Title4>{subtitle}</Title4>}
        </Box>
      </Container>
      <userContext.Consumer>
        {({ user }: { user: UserProps }) => {
          return (
            <PricingTablesContainer
              content={serviceOptions}
              handleSelectOption={handleSelectOption}
              cart={cart}
              user={user}
            />
          );
        }}
      </userContext.Consumer>
      <div id="go-to-cart"></div>
      {location.pathname !== "/panier" && cart.country && (
        <Box style={{ display: "flex" }}>
          <Button onClick={() => setIsCart(true)} style={{ margin: "auto" }}>
            {t("goToCart")}
          </Button>
        </Box>
      )}
    </Box>
  );
}
