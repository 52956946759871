import { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useTranslation } from "react-i18next";

import ROUTES, { RenderRoutes } from "./routes";
import { Box } from "./Components/styles/Container";
import Header from "./Components/layouts/header/Header";
import Footer from "./Components/layouts/footer/Footer";
import { userContext } from "./context/userContext";
import interceptor from "./interceptor";
import api from "./client/api";
import useScrollToTop from "./hooks/useScrollToTop";
import CookieNotice from "./Components/cookieNotice";
import { useDarkMode } from "./hooks/useDarkMode";
import Theme from "./theme";
import "./theme.css";

export default function App() {
  // Try to fix cache browser issue
  const header = new Headers();
  header.set("Cache-Control", "no-store, no-cache");

  const [user, setUser] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [responsiveMenuOpen, setResponsiveMenuOpen] = useState(false);

  const [theme] = useDarkMode();
  const { t, i18n } = useTranslation();

  useEffect(() => {

    const isLogged = localStorage.getItem("access_token") != null;

    if (isLogged) {
      if (window.location.href.lastIndexOf("return_to") > 0) {
        window.location.href = "/sso";
      }
      api.getUser((cb) => {
          setUser(cb);
          setCurrency(cb.currency);
      });
    }
    return () => {
      interceptor.unregister();
    };
  }, []);

  function setOpen() {
    setResponsiveMenuOpen(!responsiveMenuOpen);
    responsiveMenuOpen
      ? document.body.classList.remove("overflowHidden")
      : document.body.classList.add("overflowHidden");
  }

  function login() {
    if (localStorage.getItem("access_token") != null) {
      api.loadHeaders();
      api.getUser((user) => {
        localStorage.setItem("idRole", user.idRole);
        setUser(user);
        if (window.location.pathname !== "/panier") {
          if (window.location.href.lastIndexOf("return_to") > 0) {
            window.location.href = "/sso";
          } else if (user?.idRole === "1") {
            window.location.href = "/services";
          } else if (user?.idRole !== "1") {
            window.location.href = "/dashboard";
          }
        }
      });
    }
  }

  function logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("fb_Token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("idRole");
    window.location.href = "/";
  }

  const value = {
    loginUser: login,
    user: user,
    currency: currency,
  };

  setTimeout(() => {
    ZendeskAPI("webWidget", "setLocale", i18n.language);
  }, 1000);

  useScrollToTop();
  return (
    <userContext.Provider value={value}>
      <Helmet>
        <title>YouTracePro — {t("document.title")}</title>
        <meta name="description" content={t("document.description")} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ThemeProvider theme={theme === "dark" ? Theme.dark : Theme.light}>
        <AppWrapper data-testid="app-wrapper">
          <Header
            logout={logout}
            setOpen={setOpen}
            responsiveMenuOpen={responsiveMenuOpen}
            theme={theme}
          />
          <Box pb={[0, 0]} className="App">
            <Switch>
              <RenderRoutes routes={ROUTES} />
            </Switch>
          </Box>
          <Footer />
          <CookieNotice />
          <Zendesk zendeskKey="ce7ed18a-e03e-453d-bda5-9764f9a1617d" />
        </AppWrapper>
      </ThemeProvider>
    </userContext.Provider>
  );
}

const AppWrapper = styled.div`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.inter};
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  transition: all 250ms ${(props) => props.theme.transitions.easeInOutCubic};
  display: flex;
  min-height: calc(100vh + 130px);
  .content {
    flex: 1;
  }
  .App {
    flex: 1;
  }
`;
