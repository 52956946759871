import React from "react";

import { ServiceCardWrapper, ServiceCardContent } from "./ServiceCard.style";
import { Text, TextL, TextS } from "../typography/Texts";

export default function ServiceCard({
  content,
  onClick,
}: {
  content: { title: string; text: string; info?: string; bgUrl: string };
  onClick: any;
}) {
  const { title, text, info, bgUrl } = content;
  return (
    <ServiceCardWrapper
      style={{ backgroundImage: `url(${bgUrl || ""})` }}
      onClick={onClick}
    >
      <div className='overlay' />
      <ServiceCardContent>
        <TextL>
          {title}
          <span className='icon icon-chevron-right'></span>
        </TextL>
        <Text>{text}</Text>
        {info && (
          <TextS mt={2} orange>
            {info}
          </TextS>
        )}
      </ServiceCardContent>
    </ServiceCardWrapper>
  );
}
