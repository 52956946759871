import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import {
  isFuture,
  addQuarters,
  subDays,
  startOfYear,
  startOfQuarter,
} from "date-fns";
import { Redirect } from "react-router";

import Container, { Box, Flex } from "../../Components/styles/Container.tsx";
import { Title2 } from "../../Components/typography/Titles.tsx";
import api from "../../client/api";
import Dropdown from "../../Components/form/Dropdown";
import worldCountries from "../../utils/worldCountries";
import Status from "../../Components/dashboard/Status";
import { Text } from "../../Components/typography/Texts.tsx";
import { userContext } from "../../context/userContext";

export default function Orders() {
  const [countriesList, setCountriesList] = useState(null);
  const [orders, setOrders] = useState(null);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [filters, setFilters] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    api.fetchClips((orders) => {
      setOrders(orders);
    });
    setFilters({ date: null, country: null });
  }, []);

  useEffect(() => {
    if (orders) {
      let unfiltredCountriesList = [];
      orders.forEach((order) => {
        unfiltredCountriesList.push(order.country.code);
      });
      setCountriesList([...new Set(unfiltredCountriesList)]);
      sort(orders);
    }
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, filters]);

  function alphaToCountryObj(alpha) {
    return Object.values(worldCountries["fr"]).filter(
      (el) => el.alpha_2 === alpha
    )[0];
  }

  function onDropdownChange(name, field) {
    setFilters({
      ...filters,
      [field]: {
        code: name.target.getAttribute("code"),
        name:
          alphaToCountryObj(name.target.getAttribute("values")?.toString()) ||
          name.target.getAttribute("values"),
        label: name.target.getAttribute("name"),
      },
    });
  }

  function sort(orders) {
    if (orders) {
      for (let i = 0; i < allTitles.length; i++) {
        setSortedOrders((sortedOrders) => [
          ...sortedOrders,
          {
            number: orders.filter((el) => el.status === i).length,
            color: allColorsRgb[i],
            title: allTitles[i],
            status: i,
          },
        ]);
      }
    }
  }

  function convertDateToTimestamp(date) {
    let timestamp = date.split("/");
    timestamp.filter((item) => item !== timestamp[1]);
    timestamp.unshift(timestamp[1]);
    timestamp.splice(2, 1);
    timestamp = timestamp.join("/");
    return new Date(timestamp);
  }

  function filter() {
    if (filters) {
      let filtredOrders = orders;
      if (filters.country) {
        filtredOrders = filtredOrders.filter(
          (el) => el.country.code === filters.country.name.alpha_2
        );
      }
      if (filters.date) {
        filtredOrders = filtredOrders.filter((el) => {
          const d1 = convertDateToTimestamp(el.createdAt).getTime();
          const d2 = new Date(filters.date.name).getTime();
          return d1 >= d2;
        });
      }
      setSortedOrders([]);
      sort(filtredOrders);
    }
  }

  const startOfSemester = isFuture(addQuarters(startOfYear(new Date()), 2))
    ? startOfYear(new Date())
    : addQuarters(startOfYear(new Date()), 2);

  const dateFilters = [
    {
      label: t("lastMonth"),
      value: subDays(new Date(), 30),
    },
    { label: t("trimestre"), value: startOfQuarter(new Date()) },
    { label: t("semestre"), value: startOfSemester },
    { label: t("ytd"), value: startOfYear(new Date()) },
  ];

  const allColorsRgb = [
    "255, 137, 0",
    "19, 234, 168",
    "1, 130, 1",
    "83, 83, 83",
    "247, 114, 74",
    "47, 151, 220",
    "255, 0, 0",
    "41, 72, 245",
    "1, 168, 104",
    "117, 5, 150",
  ];
  const allTitles = [
    t("waitpayment"),
    t("payed"),
    t("orderdelivered"),
    t("canceled"),
    t("paymentMismatch"),
    t("initialized"),
    t("failed"),
    t("inprogress"),
    t("prodDone"),
    t("customerContacted"),
  ];

  let total = null;

  return (
    <userContext.Consumer>
      {({ user }) => {
        if (user) {
          if (user.idRole === "1") {
            return <Redirect to='/' />;
          } else {
            if (!orders) {
              return (
                <Loader
                  type='TailSpin'
                  color='#fff'
                  height={25}
                  width={25}
                  style={{ textAlign: "center", margin: "5rem" }}
                />
              );
            } else {
              return (
                <Box py={[2, 3]} pb={4}>
                  <Container>
                    <Box p={4} center>
                      <Title2 bold>{t("orderStatusTitle")}</Title2>
                    </Box>
                  </Container>
                  <Box
                    style={{
                      borderTop: "1px solid rgba(255,255,255,0.33)",
                      borderBottom: "1px solid rgba(255,255,255,0.33)",
                    }}
                    py={2}
                  >
                    <Container>
                      <Box mb={2}>
                        <Dropdown
                          label={
                            filters.date?.label ||
                            filters.date?.name ||
                            t("date")
                          }
                          values={dateFilters.map((el) => el.value)}
                          options={dateFilters.map((el) => el.label)}
                          handleClick={(name) => {
                            onDropdownChange(name, "date");
                          }}
                        />
                      </Box>
                      {countriesList && countriesList.length > 1 && (
                        <Dropdown
                          label={
                            filters.country?.label ||
                            filters.country?.name ||
                            t("registration.country")
                          }
                          values={countriesList}
                          options={countriesList.map((country, i) => {
                            const translatedCountries = Object.values(
                              worldCountries[i18n.language.slice(0, 2)]
                            ).find((el) => el.alpha_2 === country);
                            return translatedCountries?.name;
                          })}
                          handleClick={(name) => {
                            onDropdownChange(name, "country");
                          }}
                        />
                      )}
                      <Box center mt={2}>
                        <Text
                          orange
                          onClick={() => {
                            setFilters({ date: null, country: null });
                          }}
                          style={{ cursor: "pointer", display: "inline-block" }}
                        >
                          {t("resetFilters")}
                        </Text>
                      </Box>
                    </Container>
                  </Box>
                  <Container mt={4}>
                    <Flex>
                      {sortedOrders?.map((el, i) => {
                        if (user.idRole === "3") {
                          if ([1, 2, 7, 9].includes(el.status)) {
                            if (![3, 5, 6].includes(sortedOrders[i].number)) {
                              total = total += sortedOrders[0].number;
                            }
                            return (
                              <Status data={el} filters={filters} key={i} />
                            );
                          } else {
                            return null;
                          }
                        } else {
                          return <Status data={el} filters={filters} key={i} />;
                        }
                      })}
                      {total && (
                        <Status
                          data={{
                            number: total,
                            color: "250,250,250",
                            title: t("totalOrder"),
                          }}
                          filters={filters}
                        />
                      )}
                    </Flex>
                  </Container>
                </Box>
              );
            }
          }
        }
      }}
    </userContext.Consumer>
  );
}
