import styled from "styled-components";

export const CallToActionWrapper = styled.div`
  margin-top: 100px;
  padding: 50px 0;
  background: #0e0e0e;
  text-align: center;
  p {
    margin-bottom: 40px;
  }
`;
