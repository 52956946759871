import styled from "styled-components";

export interface ChannelProps {
  isSelected: any;
}

export const ChannelsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Channel = styled.li<ChannelProps>`
  cursor: pointer;
  padding: 16px;
  opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
  transition: opacity 0.4s;
  &.active,
  &:hover {
    opacity: 1;
  }
  img {
    height: 70px;
  }
`;
