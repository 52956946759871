import styled from "styled-components";

const OrderState = styled.div`
  max-width: 20px;
  min-height: 20px;
  position: absolute;
  right: 20px;
  border-radius: 20px;
  transition: all 0.4s;

  &.open {
    right: auto;
    display: inline-block;
    position: relative;
  }

  &:hover,
  &.open {
    max-width: 100%;
  }

  & > * {
    transition: all 0.4s;
    padding: 0 10px;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    white-space: nowrap;
  }

  &:hover > *,
  &.open > * {
    max-width: 100%;
    opacity: 1;
  }
`;

export default OrderState;
