import { InputTextProps } from "../../interfaces/InputTextProps";
import { Input } from "../styles/Form";
import { Text } from "../typography/Texts";

export default function InputText({
  name,
  type,
  placeholder,
  label,
  className,
  value,
  handleChange,
  error,
}: InputTextProps) {
  return (
    <>
      <label>{label}</label>
      <Input
        type={type || "text"}
        name={name}
        id={name}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={handleChange}
      />
      {error && <Text error>{error}</Text>}
    </>
  );
}
