import styled from "styled-components";

export const ToggleSwitchWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 25px;
  height: 15px;
  background-color: #4e4e4e;
  color: #000;
  transition: all 0.6s;
  border-radius: 25px;
  padding: 2px;
  cursor: pointer;

  &::before {
    display: block;
    position: absolute;
    content: "";
    background-color: white;
    height: 15px;
    width: 15px;
    left: 0px;
    top: 0px;
    transition: all 0.6s;
    transition-delay: 0.01s;
    border-radius: 50%;
  }
  &.on {
    background-color: #01d26d;
    &::before {
      transform: translateX(10px);
    }
  }
`;
