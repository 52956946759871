import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Social, FooterTop, FooterNav } from "./Footer.style";
import { Box, Flex } from "../../styles/Container";

function Footer() {
  const { t } = useTranslation();
  return (
    <div data-testid='footer' >
      <FooterTop>
        <a
          href='https://youtrace.tv/'
          className='b2c'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t("backTo")} <span className='b2c-orange'>YouTrace</span>
        </a>
        <Box>
          <Social
            href='https://www.facebook.com/youtrace.officiel/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ margin: 0 }}
          >
            <i className='icon-facebook' />
          </Social>{" "}
          <Social
            href='https://www.instagram.com/youtrace_officiel/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='icon-instagram' />
          </Social>
          <Social
            href='https://www.youtube.com/channel/UCQbbxLP4yfIIy9exVtdipUQ/?sub_confirmation=1'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='icon-youtube' />
          </Social>
          <Social
            href='https://twitter.com/YouTrace_off'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='icon-twitter' />
          </Social>
        </Box>
      </FooterTop>
      <FooterNav>
        <Flex justifyContent='space-between'>
          <Box mb={1}>
            <Link to='/foire-aux-questions'> FAQ </Link>
            <Link to='/mentions-legales'> {t("legalNotices")} </Link>
          </Box>
          <Box>
            <Flex
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              style={{ cursor: "pointer" }}
            >
              {t("backToTop")}
              <i
                className='icon-chevron-bottom'
                style={{
                  transform: "rotate(180deg)",
                  display: "block",
                  marginLeft: 15,
                }}
              />
            </Flex>
          </Box>
        </Flex>
      </FooterNav>
    </div>
  );
}

export default Footer;
