import { useTranslation } from "react-i18next";
import { Box } from "../Components/styles/Container";
import Banner from "../Components/banner/Banner";
import PaymentSolutions from "../Components/PaymentSolutions";
import BannerOffer from "../Components/bannerOffer/BannerOffer";
import ServicesShowcase from "../Components/servicesShowcase/ServicesShowcase";
import Faq from "../Components/faq/Faq";
import Button from "../Components/buttons/Button";
import { Title1, Title2 } from "../Components/typography/Titles";
import imgCover from "../img/music.jpg";
import VideoShowcase from "../Components/videoShowcase/VideoShowcase";

export default function Index() {
  const isLogged = localStorage.getItem("access_token");
  const isAdmin = isLogged && localStorage.getItem("idRole") !== "1";

  const { t } = useTranslation();
  return (
    <>
      <Banner img={imgCover} alt="Beyonce YouTrace Pro" imgHeight="400px">
        <Title1 dangerouslySetInnerHTML={{ __html: t("home.title1") }} />
        <Box mb={4}>
          <Title2 light>
            {t("home.title2.part1")}
            <span>{t("home.title2.part2")}</span>
            <br /> {t("home.title2.part3")}{" "}
            <span>{t("home.title2.part4")}</span>
          </Title2>
        </Box>
        {isAdmin ? (
          <Button href="/dashboard">Dashboard</Button>
        ) : (
          <Button href="/services">{t("services")}</Button>
        )}
      </Banner>
      <ServicesShowcase />
      <BannerOffer />
      <VideoShowcase />
      <Faq />
      <PaymentSolutions />
    </>
  );
}
