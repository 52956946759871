import trailerVideo from "../../img/realisation.mp4";
import imgDiffuser from "../../img/diffusion-youtube.png";
import imgPromo from "../../img/trace-social.png";
import iconReseau from "../../img/landing/icon-reseau.png";
import iconProfessionnel from "../../img/landing/icon-professionnel.png";
import iconPrix from "../../img/landing/icon-prix.png";
import iconTournage from "../../img/landing/icon-tournage.png";
import iconDiffusion from "../../img/landing/icon-diffusion.png";
import iconInscription from "../../img/landing/icon-inscription.png";
import iconCadeau from "../../img/landing/icon-cadeau.png";
import iconValidation from "../../img/landing/icon-validation.png";
import iconDate from "../../img/landing/icon-data.png";
import iconTourner from "../../img/landing/icon-tourner.png";
import iconDiffusons from "../../img/landing/icon-diffusons.png";

const en = {
  video: trailerVideo,
  title: "Shoot your videoclip",
  subtitle:
    "YouTracePro makes your video clip in <span>less than 8 days</span> and in the country of your choice",
  callToAction: "Start",
  advantages: {
    title: "<span>Why shooting my clip </span></br> with Youtrace Pro?",
    advantagesList: [
      {
        title: "Competitive pricing",
        icon: iconPrix,
        text: "adapted to your specific needs",
      },
      {
        title: "An international network",
        icon: iconReseau,
        text: `of experts to create your video`,
      },
      {
        title: "A professional video clip",
        icon: iconProfessionnel,
        text: "shooted by official Trace's film directors",
      },
      {
        title: "Youtube broadcast",
        icon: iconDiffusion,
        text: "of your clip on a +200K subscribers channel",
      },
    ],
  },
  steps: {
    title: "<span>How to shoot </span></br> your video clip",
    subtitle: "Discover all the steps to shoot and share your videoclip",
    step: [
      {
        icon: iconTournage,
        title: "Select in which country to shoot",
        paragraph:
          "Our teams will help you find the best place for your shooting",
      },
      {
        icon: iconInscription,
        title: "sign up",
        paragraph: "Fill in some information about you and your clip",
      },
      {
        icon: iconCadeau,
        title: "Select your pack",
        paragraph: "You can choose between different offers",
      },
      {
        icon: iconValidation,
        title: "Confirm your order",
        paragraph:
          "Once you have selected your package, you can either pay online or pay directly your official Youtrace Pro representative",
      },
      {
        icon: iconDate,
        title: "Plan your video shoot",
        paragraph: `Your YouTrace representative will call you to introduce him/herself. He or she is your contact point through the journey of your video creation.`,
      },
      {
        icon: iconTourner,
        title: "Shoot Your Video",
        paragraph: `Once everything is ready, our staff will join you at the shooting location. The director will kick off the project and coordinate all the operations.`,
      },
      {
        icon: iconDiffusons,
        title: "We deliver and feature your video",
        paragraph: `Our team edits the video and delivers the final cut within 8 days. You can broadcast for free your clip on our YouTube channel`,
      },
    ],
  },
  tarifs: {
    title: `Select an offer`,
    subtitle: `In which country do you want to shoot your video :`,
    serviceName: "PACK",
    serviceVar: [
      {
        title: "Silver",
        featureList: [
          { included: true, name: `<span>1/2 Day</span> filming` },
          { included: true, name: `<span>2 Days</span> editing` },
          { included: true, name: `Preparation for filming` },
          { included: true, name: `HD filming equipment provided` },
          {
            included: true,
            name: `YouTracePro Team = <span>1 person</span> (director)`,
          },
          { included: false, name: `No decors included` },
        ],
      },
      {
        title: "Gold",
        featureList: [
          { included: true, name: `<span>1 day</span> filming` },
          { included: true, name: `<span>3 days</span> editing` },
          { included: true, name: `Preparation for filming` },
          { included: true, name: `HD filming equipment provided` },
          {
            included: true,
            name: `YouTracePro team = <span>3 people</span> (director + coordinator + makeup artist )`,
          },
          { included: false, name: `No decors included` },
        ],
      },
      {
        title: "Platinium",
        featureList: [
          { included: true, name: `<span>2 days</span> filming` },
          { included: true, name: `<span>5 days</span> editing` },
          { included: true, name: `Preparation for filming` },
          { included: true, name: `HD filming equipment provided` },
          {
            included: true,
            name: `YouTracePro team = <span>4 people</span> (director + assistant director + coordinator + makeup artist)`,
          },
          {
            included: true,
            name: `Sets and extras of your choice (within budget)`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Ready</span> to get started`,
    subtitle: `Make your music video now with YouTracePro`,
    button: `let's go !`,
  },
  services: {
    title: "Other YouTracePro services",
    items: [
      {
        title: "Broadcast my music video",
        cta: "Find out",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Make my digital promo",
        cta: "Find out",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};

const fr = {
  video: trailerVideo,
  title: "Réaliser mon clip",
  subtitle:
    "YouTracePro réalise <span>ton clip vidéo en un temps record</span> et dans le pays de ton choix",
  callToAction: "Commencer",
  advantages: {
    title:
      "<span>Pourquoi passer par YouTrace </span></br>pour réaliser mon clip",
    advantagesList: [
      {
        title: "Des prix compétitifs",
        icon: iconPrix,
        text: "avec des tarifs adaptés à ta région",
      },
      {
        title: "Un réseau international",
        icon: iconReseau,
        text: `d’experts en production audiovisuelle pour t'accompagner`,
      },
      {
        title: "Un clip professionnel",
        icon: iconProfessionnel,
        text: "produit par des réalisateurs sélectionnés par Trace",
      },
      {
        title: "La diffusion sur Youtube",
        icon: iconDiffusion,
        text: "de ton clip auprès d’une communauté de + 200 000 abonnés",
      },
    ],
  },
  steps: {
    title:
      "<span>Comment se passe la</span></br> réalisation de ton clip vidéo",
    subtitle:
      "Découvre toutes les étapes de la création de ton compte à la diffusion de ton clip",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne ton lieu de tournage",
        paragraph:
          "et ton offre parmi les accompagnements que nous proposons pour réaliser ton clip",
      },
      {
        icon: iconInscription,
        title: "Inscris-toi",
        paragraph: "Remplis quelques infos sur toi et sur ton clip",
      },
      {
        icon: iconCadeau,
        title: "Choisis les options de ton choix",
        paragraph:
          "En réalisant ton clip avec YouTracePro, nous t’offrons sa distribution et sa diffusion",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Une fois ton offre sélectionnée, tu peux payer ta commande en ligne ou directement au représentant officiel de YouTracePro",
      },
      {
        icon: iconDate,
        title: "Planifie le tournage de ton clip",
        paragraph: `Ton représentant YouTracePro te contacte par téléphone pour se présenter. Il/elle t'accompagne durant toutes les étapes de la création de ta vidéo.`,
      },
      {
        icon: iconTourner,
        title: "Tourne ton clip",
        paragraph: `Quand tout est prêt, nos équipes te rejoignent sur le lieu de tournage. Le réalisateur connaît ton projet et encadre l’ensemble des opérations.`,
      },
      {
        icon: iconDiffusons,
        title: "Nous livrons et diffusons ton clip",
        paragraph: `Nos équipes font le montage et livrent ton clip étalonné en maximum 8 jours après le tournage. Tu peux bénéficier du partage gratuit de ton clip sur notre chaîne Youtube et sur pro.trace.tv.`,
      },
    ],
  },

  tarifs: {
    title: `<span>Nos tarifs pour</span></br> réaliser ton clip vidéo`,
    subtitle: `Sélectionne le pays où tu souhaites produire ton clip pour afficher les prix :`,
    serviceName: "PACK",
    serviceVar: [
      {
        title: "Silver",
        featureList: [
          { included: true, name: `<span>1/2 journée</span> de tournage` },
          { included: true, name: `<span>2 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>1 personne</span> (réalisateur)`,
          },
          { included: false, name: `Pas de décors inclus` },
        ],
      },
      {
        title: "Gold",
        featureList: [
          { included: true, name: `<span>1 journée</span> de tournage` },
          { included: true, name: `<span>3 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>3 personnes</span> (réalisateur + coordinateur + maquilleuse )`,
          },
          { included: false, name: `Pas de décors inclus` },
        ],
      },
      {
        title: "Platinium",
        featureList: [
          { included: true, name: `<span>2 jours</span> de tournage` },
          { included: true, name: `<span>5 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>4 personnes</span> (réalisateur + assistant réalisateur + coordinateur + maquilleuse)`,
          },
          {
            included: true,
            name: `Décors et figurants au choix (dans la limite du budget)`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Réalise dès maintenant ton clip avec YouTracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services YouTracePro",
    items: [
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};

const pt = {
  video: trailerVideo,
  title: "Réaliser mon clip",
  subtitle:
    "YouTracePro réalise <span>ton clip vidéo en un temps record</span> et dans le pays de ton choix",
  callToAction: "Commencer",
  advantages: {
    title: "<span>Pourquoi réaliser mon clip</span></br>avec YouTracePro",
    advantagesList: [
      {
        title: "Des prix compétitifs",
        icon: iconPrix,
        text: "avec des tarifs adaptés à ta région",
      },
      {
        title: "Un réseau international",
        icon: iconReseau,
        text: `d’experts en production audiovisuelle pour t'accompagner`,
      },
      {
        title: "Un clip professionnel",
        icon: iconProfessionnel,
        text: "produit par des réalisateurs sélectionnés par Trace",
      },
      {
        title: "La diffusion sur Youtube",
        icon: iconDiffusion,
        text: "de ton clip auprès d’une communauté de + 200 000 abonnés",
      },
    ],
  },
  steps: {
    title:
      "<span>Comment se passe la</span></br> réalisation de ton clip vidéo",
    subtitle:
      "Découvre toutes les étapes de la création de ton compte à la diffusion de ton clip",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne ton lieu de tournage",
        paragraph:
          "et ton offre parmi les accompagnements que nous proposons pour réaliser ton clip",
      },
      {
        icon: iconInscription,
        title: "Inscris-toi",
        paragraph: "Remplis quelques infos sur toi et sur ton clip",
      },
      {
        icon: iconCadeau,
        title: "Choisis les options de ton choix",
        paragraph:
          "En réalisant ton clip avec YouTracePro, nous t’offrons sa distribution et sa diffusion",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Une fois ton offre sélectionnée, tu peux payer ta commande en ligne ou directement au représentant officiel de YouTracePro",
      },
      {
        icon: iconDate,
        title: "Planifie le tournage de ton clip",
        paragraph: `Ton représentant YouTracePro te contacte par téléphone pour se présenter. Il/elle t'accompagne durant toutes les étapes de la création de ta vidéo.`,
      },
      {
        icon: iconTourner,
        title: "Tourne ton clip",
        paragraph: `Quand tout est prêt, nos équipes te rejoignent sur le lieu de tournage. Le réalisateur connaît ton projet et encadre l’ensemble des opérations.`,
      },
      {
        icon: iconDiffusons,
        title: "Nous livrons et diffusons ton clip",
        paragraph: `Nos équipes font le montage et livrent ton clip étalonné en maximum 8 jours après le tournage. Tu peux bénéficier du partage gratuit de ton clip sur notre chaîne Youtube et sur pro.trace.tv.`,
      },
    ],
  },

  tarifs: {
    title: `<span>Nos tarifs pour</span></br> réaliser ton clip vidéo`,
    subtitle: `Sélectionne le pays où tu souhaites produire ton clip pour afficher les prix :`,
    serviceName: "PACK",
    serviceVar: [
      {
        title: "Silver",
        featureList: [
          { included: true, name: `<span>1/2 journée</span> de tournage` },
          { included: true, name: `<span>2 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>1 personne</span> (réalisateur)`,
          },
          { included: false, name: `Pas de décors inclus` },
        ],
      },
      {
        title: "Gold",
        featureList: [
          { included: true, name: `<span>1/2 journée</span> de tournage` },
          { included: true, name: `<span>2 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>3 personnes</span> (réalisateur + coordinateur + maquilleuse )`,
          },
          { included: false, name: `Pas de décors inclus` },
        ],
      },
      {
        title: "Platinium",
        featureList: [
          { included: true, name: `<span>1/2 journée</span> de tournage` },
          { included: true, name: `<span>2 jours</span> de montage` },
          { included: true, name: `Préparation du tournage du clip` },
          { included: true, name: `Matériel HD de tournage fourni` },
          {
            included: true,
            name: `Équipe YouTracePro = <span>4 personne</span> (réalisateur + assistant réalisateur + coordinateur + maquilleuse)`,
          },
          {
            included: true,
            name: `Décors et figurants au choix (dans la limite du budget)`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Réalise dès maintenant ton clip avec YouTracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services YouTracePro",
    items: [
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};
export { fr, en, pt };
