import styled from "styled-components";

export const CartStepWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  margin-bottom: 20px;
  padding: 40px;

  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    flex-wrap: wrap;
    padding: 20px;
  }

  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 20px 0;
  }
`;

export const CartStepTitle = styled.div`
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;

  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    border-bottom: 0;
    padding-bottom: 0;
  }
  h3 {
    margin: 0;
    text-align: left;
    @media ${(props) => props.theme.mediaQueries.mediumWidth} {
      font-size: 20px;
    }
  }
  img {
    margin-right: 20px;
    height: 38px;
    width: auto;
    @media ${(props) => props.theme.mediaQueries.mediumWidth} {
      height: 18px;
    }
  }
`;
