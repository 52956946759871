import { useState } from "react";
import { Box } from "../styles/Container";
import { Text } from "../typography/Texts";
import { Title5 } from "../typography/Titles";

import {
  StepsWrap,
  StepsItem,
  StepIcon,
  StepsText,
  StepsBullet,
} from "./steps.style";

function Steps({
  itemsList,
}: {
  itemsList: { title: string; paragraph: string; icon: string }[];
}) {
  const [open, setOpen] = useState(null);
  return (
    <StepsWrap my={[4, 6]}>
      {itemsList.map((el, i) => (
        <StepsItem key={i}>
          <StepsBullet className='bullet'>{i + 1}</StepsBullet>
          <Box mx={[0, 4]} mt={[0, "-40px"]} style={{ width: "100%", display: 'flex' }}>
            <StepIcon>
              <img src={el.icon} alt='step' />
            </StepIcon>
            <StepsText>
              <Title5
                onClick={() => {
                  open !== i ? setOpen(i) : setOpen(null);
                }}
                style={{ display: "flex" }}
              >
                <div>
                  <span className='numberResp'>{i + 1 + "."} </span> {el.title}
                </div>
                <i
                  className={
                    open !== i
                      ? "chevron icon-chevron-bottom"
                      : "chevron icon-chevron-top"
                  }
                />
              </Title5>
              <Text
                className={open !== i && "close"}
                dangerouslySetInnerHTML={{ __html: el.paragraph }}
              />
            </StepsText>
          </Box>
        </StepsItem>
      ))}
    </StepsWrap>
  );
}

export default Steps;
