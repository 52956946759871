import i18next from "i18next";
import fr from "../lang/fr.json";
import worldCountries from "./worldCountries";

// Know active lang
const activeLang = i18next?.language?.slice(0, 2);

// French services name in array to translated
const translateServicesName = (dataArray) => {
  if (Array.isArray(dataArray)) {
    // We create a copy of the array
    const copyArray = dataArray?.map((u) => {
      const copiedProperty = Object.assign({}, u);
      return copiedProperty;
    });
    // We translate the name of the service and store it into an array
    let wordsKey = copyArray.map((word) => {
      const valueName = Object.values(fr).filter(
        (serviceName) => serviceName === word.name
      );
      return valueName.map((el) =>
        Object.keys(fr).find((key) => fr[key] === el)
      );
    });
    const translatedName = wordsKey.map((el) => i18next.t(el[0]));
    // We change the name from the store previously created
    translatedName.map((el, i) => (copyArray[i].name = el));
    // We return the array modified
    return copyArray;
  }
};

// French country name to translated
const translateCountryName = (countryName) => {
  const alpha = Object.values(worldCountries["fr"]).find(
    (el) => el.name === countryName
  )?.alpha_2;
  return Object.values(worldCountries[activeLang]).find(
    (el) => el.alpha_2 === alpha
  )?.name;
};

// French to translated
const translateWord = (word) => {
  return i18next.t(Object.keys(fr).find((key) => fr[key] === word));
};

export {
  activeLang,
  translateServicesName,
  translateCountryName,
  translateWord,
};
