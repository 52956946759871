import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoPlayCircle } from "@react-icons/all-files/io5/IoPlayCircle";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";

import { Title2 } from "../typography/Titles";
import { Video, VideosWrapper } from "./VideoShowcase.style";
import { Box } from "../styles/Container";
import clipGims from "../../img/clip-gims2.jpg";
import clipAriel from "../../img/clip-ariel.jpg";
import clipFally from "../../img/clip-fally.jpg";
import clipToofan from "../../img/clip-toofan.jpg";
import { useLocation } from "react-router-dom";

export default function VideoShowcase() {
  const { t } = useTranslation();
  const location = useLocation();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <FaChevronLeft
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    />
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <FaChevronRight
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    />
  );

  const settings = {
    focusOnSelect: true,
    dots: false,
    speed: 300,
    infinite: true,
    slidesToShow: 3,
    centerPadding: "150px",
    centerMode: true,
    arrows: true,
    cssEase: "linear",
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 1,
          centerPadding: "400px",
        },
      },
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1,
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "140px",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
        },
      },
    ],
  };
  const videos =
    location.pathname === "/"
      ? [
          {
            img: clipGims,
            url: "https://www.youtube.com/watch?v=HQGMsJigFJc&ab_channel=YouTRACE",
          },
          {
            img: clipAriel,
            url: "https://www.youtube.com/watch?v=i1F12-HArU4&ab_channel=YouTRACE",
          },
          {
            img: clipFally,
            url: "https://www.youtube.com/watch?v=2Oo4C5TDAc0&ab_channel=YouTRACE",
          },
          {
            img: clipToofan,
            url: "https://www.youtube.com/watch?v=oyJY0DjidrY&ab_channel=YouTRACE",
          },
        ]
      : [
          {
            img: clipGims,
            url: "https://youtu.be/otu3Tj5NY5o",
          },
          {
            img: clipAriel,
            url: "https://youtu.be/LomeVhVv0y4",
          },
          {
            img: clipFally,
            url: "https://youtu.be/sZafDfpy5Zc",
          },
        ];
  return (
    <Box my={[4, 6]} style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <Title2
        style={{
          maxWidth: "500px",
          margin: "0 auto 100px",
          textAlign: "center",
        }}
        dangerouslySetInnerHTML={{ __html: t("home.clips") }}
      />
      <VideosWrapper>
        <Slider {...settings}>
          {videos.map((video, i) => (
            <Video key={i} className="video">
              <img src={video.img} alt="" />
              <a href={video.url} target="_blank" rel="noreferrer">
                <IoPlayCircle />
              </a>
            </Video>
          ))}
        </Slider>
      </VideosWrapper>
    </Box>
  );
}
