import React, { ReactNode } from "react";

import Container from "../styles/Container";
import { BannerWrapper, BannerImg } from "./Banner.style";

function Banner({
  img,
  alt,
  imgHeight,
  children,
}: {
  img: string;
  alt?: string;
  imgHeight?: string | number;
  children: ReactNode;
}) {
  return (
    <BannerWrapper center style={{ minHeight: imgHeight }}>
      <BannerImg src={img} alt={alt} />
      <Container>{children}</Container>
    </BannerWrapper>
  );
}

export default Banner;
export { BannerWrapper, BannerImg };
