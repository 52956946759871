import { useTranslation } from "react-i18next";
import {
  PricingTableWrapper,
  PricingTableHeading,
  PricingTableService,
} from "./PricingTable.style";
import { Title1, Title4 } from "../typography/Titles";
import { Text } from "../typography/Texts";
import ButtonAlt from "../buttons/ButtonAlt";
import CountryProps from "../../interfaces/CountryProps";
import { formatPrice } from "../../utils/format";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import $ from "jquery";

export default function PricingTable({
  title,
  pricing,
  selectedCountry,
  serviceList,
  handleSelectOption,
  active,
  channel,
}: {
  title: string;
  pricing?: { price: string | number; title: string };
  selectedCountry?: CountryProps;
  serviceList: { name: string; included: boolean }[];
  handleSelectOption: any;
  active: boolean;
  channel?: string;
}) {
  const allowClick = Boolean(pricing?.title);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const location = useLocation();

  $(".ButtonAlt").click(function () {
    if (
      location.pathname !== "/panier" ||
      (location.pathname === "/panier" && width > 992)
    ) {
      $("html,body").animate(
        {
          scrollTop: $("#go-to-cart").offset().top - 200,
        },
        "slow"
      );
      $("html,body").clearQueue();
    }
  });

  return (
    <PricingTableWrapper>
      <PricingTableHeading>
        <Title4 orange>{title}</Title4>
        {pricing && selectedCountry?.currency ? (
          <Title1>
            {formatPrice(pricing.price)} {selectedCountry.currency}
          </Title1>
        ) : (
          <Text orange>{t("selectCountryPrice")}</Text>
        )}
        <ButtonAlt
          className="ButtonAlt"
          onClick={() =>
            allowClick &&
            handleSelectOption(
              title,
              pricing.price,
              selectedCountry,
              channel || null
            )
          }
          disabled={!allowClick}
          active={active}
        >
          {t("chooseOffer")}
        </ButtonAlt>
      </PricingTableHeading>
      <ul>
        {serviceList.map((el, i) => (
          <PricingTableService included={el.included} key={i}>
            <Text dangerouslySetInnerHTML={{ __html: el.name }} />
          </PricingTableService>
        ))}
      </ul>
    </PricingTableWrapper>
  );
}
