import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Form } from "../styles/Form";
import { Box, Flex } from "../styles/Container";
import InputPassword from "../form/InputPassword";
import Button from "../buttons/Button";
import api from "../../client/api";

export default function ResetPasswordForm({ tokenFromProps, setSuccess }) {
  const { t } = useTranslation();

  function reset(password) {
    api.updatePassword(
      {
        password,
        token: tokenFromProps,
      },
      (response) => {
        setSuccess(true);
      }
    );
  }
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    onSubmit: (values) => {
      reset(values.password);
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, t("passwordLeast"))
        .required(t("enterPassword"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          t("passwordIncorrect")
        ),
    }),
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Flex justifyContent='center'>
        <InputPassword
          name='password'
          label={t("registration.password") + "* :"}
          handleChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />
        <Box mt='20px' center>
          <button type='submit'>
            <Button fullWidth>{t("login.connect")}</Button>
          </button>
        </Box>
      </Flex>
    </Form>
  );
}
