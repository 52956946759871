import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation, useParams } from "react-router";
// import { Link } from "react-router-dom";

import produce from "immer";
import { BannerWrapper } from "../Components/banner/Banner";
import Button from "../Components/buttons/Button";
import Container, { Box, Flex } from "../Components/styles/Container";
import VideoWrapper, { Filter } from "../Components/styles/VideoWrapper";
import {
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
} from "../Components/typography/Titles";
import { Text } from "../Components/typography/Texts";
import Steps from "../Components/steps/Steps";
import Artists from "../Components/artists/Artists";
// import OtherServices from "../Components/otherServices/OtherServices";
import PaymentSolutions from "../Components/PaymentSolutions";
import PricingTablesContainer from "../Components/pricingTablesContainer/PricingTablesContainer";
import CallToAction from "../Components/callToAction/CallToAction";
import { CartProps } from "../interfaces/CartProps";
import { userContext } from "../context/userContext";
import { UserProps } from "../interfaces/UserProps";
import VideoShowcase from "../Components/videoShowcase/VideoShowcase";

export default function ServicesLanding() {
  const [cart, setCart] = useState<CartProps>({});
  const [isCart, setIsCart] = useState<boolean>(false);

  let location = useLocation();
  let { id } = useParams<{ id?: string }>();
  let { fr, en } = require("../lang/services/" + id + ".js");
  // let { fr, en, pt } = require("../lang/services/" + id + ".js");
  const { i18n, t } = useTranslation();
  const langs = {
    fr: fr,
    en: en,
    // pt: pt,
  };
  let lang = i18n.language.slice(0, 2);
  const content = langs[lang];

  useEffect(() => {
    setCart(
      produce((draft) => {
        draft.services = [{ name: content.tarifs?.serviceName }];
      })
    );
  }, [content.tarifs?.serviceName]);

  function handleSelectOption(name, price, country, channel) {
    setCart(
      produce((draft) => {
        draft.services[0].offer = name;
        draft.services[0].price = price;
        draft.country = country;
        draft.modify = false;
        if (cart.services[0].name === "PROMOTV") {
          draft.services[0].channel = channel;
        } else if (cart.services[0].name === "UPLOAD") {
          draft.services[0].options = [
            { name: "Youtube", description: t("upload.youtube"), active: true },
            // {
            //   name: "Youtrace.tv",
            //   description: t("upload.youtrace"),
            //   active: true,
            // },
          ];
        }
      })
    );
  }

  if (isCart) {
    return (
      <Redirect
        to={{
          pathname: "/panier",
          state: { cart: cart },
        }}
      />
    );
  }

  return (
    <userContext.Consumer>
      {({ user }: { user: UserProps }) => {
        return (
          <Box>
            <BannerWrapper>
              <VideoWrapper
                dangerouslySetInnerHTML={{
                  __html: `
              <video playsInline autoPlay muted loop>
                <source src="${content.video}" type='video/mp4' />
              onChange2</video>
            `,
                }}
              />
              <Filter />
              <Container style={{ position: "relative" }} py={5}>
                <Title1>{content.title}</Title1>
                <Box mb={4}>
                  <Title2
                    light
                    dangerouslySetInnerHTML={{ __html: content.subtitle }}
                  />
                </Box>
                <Button href="#go-to-start">{content.callToAction}</Button>
              </Container>
            </BannerWrapper>
            {content.advantages && (
              <Box center my={[4, 6]}>
                <Container mb={4}>
                  <Title3
                    dangerouslySetInnerHTML={{
                      __html: content.advantages.title,
                    }}
                  />
                </Container>
                <Container>
                  <Flex flexWrap="wrap">
                    {content.advantages.advantagesList.map((el, i) => {
                      return (
                        <Flex
                          flexGrow={1}
                          flexBasis={["100%", "45%", "0%"]}
                          border={1}
                          flexDirection="column"
                          justifyContent="center"
                          borderColor="#fff"
                          borderStyle="solid"
                          padding={4}
                          key={i}
                          margin={[0, 2, 0, 2]}
                        >
                          <Title5 orange>{el.title}</Title5>
                          <Box my={2}>
                            <img src={el.icon} alt={`icon ${el.title}`} />
                          </Box>
                          <Text>{el.text}</Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Container>
              </Box>
            )}
            {content.steps && (
              <Container my={[4, 6]}>
                <Box center mb={4}>
                  <Title3
                    dangerouslySetInnerHTML={{ __html: content.steps.title }}
                  />
                  <Title4
                    dangerouslySetInnerHTML={{
                      __html: content.steps.subtitle,
                    }}
                  />
                </Box>
                <Steps itemsList={content.steps.step} />
              </Container>
            )}
            {content.tarifs && (
              <>
                <Container small mt={[4, 6]} center id="go-to-start">
                  <Title3
                    dangerouslySetInnerHTML={{ __html: content.tarifs.title }}
                  />
                  <Title4
                    dangerouslySetInnerHTML={{
                      __html: content.tarifs.subtitle,
                    }}
                  />
                </Container>
                <PricingTablesContainer
                  handleSelectOption={handleSelectOption}
                  content={content.tarifs}
                  user={user}
                  cart={cart}
                />
                <div id="go-to-cart"></div>
                {location.pathname !== "/panier" && cart.country && (
                  <Box
                    style={{
                      display: "flex",
                      paddingTop: "35%",
                      marginTop: "-30%",
                    }}
                  >
                    <Button
                      onClick={() => setIsCart(true)}
                      style={{ margin: "auto" }}
                    >
                      {t("goToCart")}
                    </Button>
                  </Box>
                )}
              </>
            )}
            <CallToAction content={content.cta} />
            <Artists />
            {location.pathname === "/services/promo-tv" && <VideoShowcase />}
            {/* <OtherServices
              title={content.services.title}
              itemsList={content.services.items}
            /> */}
            <PaymentSolutions />
          </Box>
        );
      }}
    </userContext.Consumer>
  );
}
