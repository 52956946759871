import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Swipeable } from "react-swipeable";
import Loader from "react-loader-spinner";

import { Title2, Title4 } from "../Components/typography/Titles";
import Container, { Box, Flex } from "../Components/styles/Container";
import Button from "../Components/buttons/Button";
import Link from "../Components/styles/Link";
import SimpleLine from "../Components/dashboard/graphics/SimpleLine";
import Pie from "../Components/dashboard/graphics/Pie";
import api from "../client/api";
import { translateCountryName } from "../utils/translate";
import { fixerApiKey } from "../config/config";

class CountryStats extends Component<any, any> {
  constructor(props) {
    const t: any = new Date();
    super(props);
    this.state = {
      caGlobal: null,
      commandStateData: [],
      year: t.getFullYear(),
      currencyRate: null,
    };
    this.convertCurrency = this.convertCurrency.bind(this);
    this.prevYear = this.prevYear.bind(this);
    this.nextYear = this.nextYear.bind(this);
  }

  convertCurrency(data) {
    return (data.caGlobal[this.state.year] = data.caGlobal[this.state.year].map(
      (item) => {
        item.data = item.data.map((el) => {
          el =
            Math.round((el * this.state.currencyRate + Number.EPSILON) * 100) /
            100;
          return el;
        });
        return item;
      }
    ));
  }

  componentDidMount() {
    fetch(`https://data.fixer.io/api/latest?access_key=${fixerApiKey}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        this.setState({ currencyRate: data.rates[this.props.currency] });
      })
      .then((data) => {
        api.countryOverview(this.props.match.params.country, (data) => {
          this.setState({
            caGlobal: data.caGlobal[this.state.year],
            commandStateData: data.chartCountryLine[this.state.year],
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  prevYear() {
    if (this.state.year > 2020) {
      this.setState((prevState) => ({
        year: prevState.year - 1,
      }));
      api.countryOverview(this.props.match.params.country, (data) => {
        this.setState({
          caGlobal: data.caGlobal[this.state.year],
          commandStateData: data.chartCountryLine[this.state.year],
        });
      });
    }
  }

  nextYear() {
    if (this.state.year < new Date().getFullYear()) {
      this.setState((prevState) => ({
        year: prevState.year + 1,
      }));
      api.countryOverview(this.props.match.params.country, (data) => {
        this.setState({
          caGlobal: data.caGlobal[this.state.year],
          commandStateData: data.chartCountryLine[this.state.year],
        });
      });
    }
  }

  render() {
    const { commandStateData, caGlobal, year } = this.state;
    const { currency } = this.props;
    return (
      <Container>
        <Swipeable
        // onSwipedRight={() => this.prevMonth()}
        // onSwipedLeft={() => this.nextMonth()}
        >
          <Box mb={4} center>
            <Box pt={4} center>
              <Title2>
                {translateCountryName(this.props.match.params.country)}
              </Title2>
            </Box>
            <Flex justifyContent='space-between'>
              <i
                style={{ cursor: "pointer" }}
                className='icon-chevron-left'
                onClick={this.prevYear}
              />
              <Title4 bold mt={0}>
                {year}
              </Title4>
              <i
                style={{ cursor: "pointer" }}
                className='icon-chevron-right'
                onClick={this.nextYear}
              />
            </Flex>
          </Box>

          <Box mb={4} center>
            <Title4 bold>{this.props.t("bo.caservice")}</Title4>
            {caGlobal ? (
              <SimpleLine
                theme={this.props.theme}
                chartData={caGlobal}
                unit={currency}
              />
            ) : (
              <Loader
                type='TailSpin'
                color='#fff'
                height={25}
                width={25}
                style={{ textAlign: "center", margin: "5rem" }}
              />
            )}
          </Box>
          <Box center>
            <Title4 bold>{this.props.t("bo.orderstate")}</Title4>
            {commandStateData ? (
              <Pie theme={this.props.theme} chartData={commandStateData} />
            ) : (
              <Loader
                type='TailSpin'
                color='#fff'
                height={25}
                width={25}
                style={{ textAlign: "center", margin: "5rem" }}
              />
            )}
          </Box>
          <Box my={4} center>
            <Box>
              <Link orange href='/dashboard/ventes'>
                {this.props.t("bo.seeSells")}
              </Link>
            </Box>
            <Button fullWidth mt={4} href='/dashboard'>
              Dashboard
            </Button>
          </Box>
        </Swipeable>
      </Container>
    );
  }
}

export default withTranslation()(CountryStats);
