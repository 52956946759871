import React from "react";

import { ToggleSwitchWrapper } from "./ToggleSwitch.style";

export default function ToggleSwitch({
  toggleState,
  toggle,
}: {
  toggleState?: boolean;
  toggle?: any;
}) {
  return (
    <ToggleSwitchWrapper
      className={`switch ${toggleState ? "on" : "off"}`}
      onClick={toggle}
    />
  );
}
