import styled, { css } from "styled-components";

interface LinksProps {
  orange?: boolean;
  red?: boolean;
  green?: boolean;
  grey?: boolean;
}

const Link = styled.a<LinksProps>`
  padding-bottom: 2px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;
  white-space: nowrap;
  &:after {
    content: "";
    position: absolute;
    transform: scaleX(0);
    right: 0;
    bottom: 1px;
    height: 1px;
    transition: transform 0.3s
      ${(props) => props.theme.transitions.easeInOutCubic};
    transition-delay: 0.2s;
    transform-origin: right;
    z-index: -1;
    width: 100%;
    background: ${(props) => props.theme.colors.secondary};
  }
  &:hover {
    &:after {
      transform: scaleX(1);
      transition-delay: 0s;
      z-index: 1;
      left: 0;
      right: auto;
      transform-origin: left;
    }
  }
  ${(props) =>
    props.orange &&
    css`
      color: ${(props) => props.theme.colors.orange};

      &:after {
        background: ${(props) => props.theme.colors.orange};
      }
    `}

  ${(props) =>
    props.red &&
    css`
      color: red;

      &:after {
        background: red;
      }
    `}
	${(props) =>
    props.green &&
    css`
      color: ${(props) => props.theme.colors.green};

      &:after {
        background: ${(props) => props.theme.colors.green};
      }
    `}
	${(props) =>
    props.grey &&
    css`
      color: ${(props) => props.theme.colors.grey};
      opacity: 0.5;
      &:after {
        background: ${(props) => props.theme.colors.grey};
        opacity: 0.5;
      }
    `}
`;

export default Link;
