import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { translateServicesName } from "../../../utils/translate";
import { useDarkMode } from "../../../hooks/useDarkMode";

const StackedBar = (props) => {
  const translatedChartData = translateServicesName(props.chartData);
  const [theme] = useDarkMode();
  const options = {
    chart: {
      type: "bar",
      backgroundColor: theme === "light" ? "#fff" : "#000",
      style: {
        fontFamily: '"Inter",Sans-Serif',
        fontWeight: "bold",
      },
    },
    title: {
      text: "",
    },
    // series: translateServicesName(props.chartData),
    series: translatedChartData,
    xAxis: {
      categories: props.chartCategories,
      title: {
        text: "",
      },
      labels: {
        style: {
          color: theme === "light" ? "#000" : "#fff",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
        style: {
          color: theme === "light" ? "#000" : "#fff",
        },
      },
      labels: {
        style: {
          color: theme === "light" ? "#000" : "#fff",
        },
      },
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: theme === "light" ? "#000" : "#fff",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        cursor: "pointer",
      },
    },
    credits: {
      enabled: false,
    },
  };
  return (
    <HighchartsReact
      containerProps={{ style: { height: "1000px" } }}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default StackedBar;
