import trailerVideo from "../../img/promotion.mp4";
import imgDiffuser from "../../img/diffusion-youtube.png";
import imgPromo from "../../img/trace-social.png";
import imgRealiser from "../../img/maitre-gims.png";
import iconReconnu from "../../img/landing/icon-reconnu.png";
import iconArticle from "../../img/landing/icon-article.png";
import iconMiseAvant from "../../img/landing/icon-avant.png";
import iconSociaux from "../../img/landing/icon-sociaux.png";
import iconTournage from "../../img/landing/icon-tournage.png";
import iconClip from "../../img/landing/icon-clip.png";
import iconDate from "../../img/landing/icon-data.png";
import iconInscription from "../../img/landing/icon-inscription.png";
import iconValidation from "../../img/landing/icon-validation.png";
import iconPartager from "../../img/landing/icon-partager.png";

const fr = {
  video: trailerVideo,
  title: "Faire ma promo TV sur Trace",
  subtitle:
    "<span>Fais connaître ta musique </span> sur la chaîne de ton choix",
  callToAction: "Commencer",
  advantages: {
    img: imgPromo,
    title: "<span>Pourquoi faire ma promo TV</span> </br> sur Trace",
    advantagesList: [
      {
        title: "1ère chaîne",
        icon: iconReconnu,
        text: `afro-urbaine en Afrique, Europe et Brésil`,
      },
      {
        title: "28 chaînes de télévision",
        icon: iconArticle,
        text: `par style musical pour mieux
        cibler ton audience`,
      },
      {
        title: "168 pays",
        icon: iconMiseAvant,
        text: `de diffusion pour faire
        connaître ta musique dans le monde`,
      },
      {
        title: "350 millions",
        icon: iconSociaux,
        text: `de téléspectateurs dans le monde`,
      },
    ],
  },
  steps: {
    title: "Comment <span>faire ta promo TV</span> </br>sur Trace",
    subtitle:
      "Découvre toutes les étapes pour promouvoir ton clip sur les chaînes de Trace",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne ton pays",
        paragraph: "Choisis dans quel pays tu veux faire ta promo TV",
      },
      {
        icon: iconInscription,
        title: "Inscris-toi",
        paragraph: "Donne nous quelques infos sur toi",
      },
      {
        icon: iconClip,
        title: "Envoie nous ton clip",
        paragraph:
          "Envoie nous l’URL de ta vidéo et quelques infos complémentaires",
      },
      {
        icon: iconDate,
        title: "Choisis la date de diffusion",
        paragraph:
          "Sélectionne le jour où tu souhaites que ta pub TV soit diffusée",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Tu peux payer en ligne (minimum 8 jours avant la diffusion de ton clip)",
      },
      {
        icon: iconPartager,
        title: "Nous diffusons ta publicité",
        paragraph:
          "Ta pub est mise en avant sur la chaîne Trace de ton choix après validation de nos équipes",
      },
    ],
  },
  tarifs: {
    title: `<span>Nos tarifs pour</span></br> faire ta promo TV`,
    subtitle: `Sélectionne le pays où tu souhaites faire ta promo TV pour afficher les prix :`,
    serviceName: "PROMOTV",
    serviceVar: [
      {
        title: "Classic",
        featureList: [
          {
            included: true,
            name: `<span>1 semaine</span> de diffusion`,
          },
          {
            included: true,
            name: `<span>35 diffusions</span> TV`,
          },
          {
            included: true,
            name: `Conseils des équipes de Trace`,
          },
          {
            included: true,
            name: `Création du teaser vidéo (montage + habillage)`,
          },
          {
            included: true,
            name: `Création des visuels`,
          },
          {
            included: true,
            name: `Partage sur les réseaux sociaux de Trace`,
          },
          {
            included: true,
            name: `Redirections vers les plateformes de l'artiste`,
          },
        ],
      },
      {
        title: "Premium",
        featureList: [
          {
            included: true,
            name: `<span>2 semaines</span> de diffusion`,
          },
          {
            included: true,
            name: `<span>70 diffusions</span> TV`,
          },
          {
            included: true,
            name: `Conseils des équipes de Trace`,
          },
          {
            included: true,
            name: `Création du teaser vidéo (montage + habillage)`,
          },
          {
            included: true,
            name: `Création des visuels`,
          },
          {
            included: true,
            name: `Partage sur les réseaux sociaux de Trace`,
          },
          {
            included: true,
            name: `Redirections vers les plateformes de l'artiste`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Fais ta promo dès maintenant avec l’aide de YouTracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services YouTracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
    ],
  },
};

const en = {
  video: trailerVideo,
  title: "Promote my music on Trace's TV channels",
  subtitle: "<span>Stand out on a </span> premium urban media",
  callToAction: "Start",
  advantages: {
    img: imgPromo,
    title: "<span>Why I should promote my music</span></br>on Trace",
    advantagesList: [
      {
        title: "1st urban channel",
        icon: iconReconnu,
        text: `in Africa, Europe and Brasil`,
      },
      {
        title: "28 TV channels",
        icon: iconArticle,
        text: `specialized by musical genres, to better target your public`,
      },
      {
        title: "168 countries",
        icon: iconMiseAvant,
        text: `to get your music discovered worldwide`,
      },
      {
        title: "350 millions",
        icon: iconSociaux,
        text: `of viewers worldwide`,
      },
    ],
  },
  steps: {
    title: "How <span>to promote your music</span> on </br>Trace TV channels ?",
    subtitle: "Discover all the steps to shoot and share your videoclip",
    step: [
      {
        icon: iconTournage,
        title: "Select your country",
        paragraph: "Select the country where you want to promote your music",
      },
      {
        icon: iconClip,
        title: "Send us your clip",
        paragraph:
          "Send us your URL files and some extra info to confirm your order",
      },
      {
        icon: iconDate,
        title: "Choose a date to broadcast your teaser",
        paragraph: "Select the day you want to broadcast your video clip",
      },
      {
        icon: iconInscription,
        title: "Log-in",
        paragraph: "Tell us more about you",
      },
      {
        icon: iconValidation,
        title: "Confirm your order",
        paragraph: "You can pay online (8 days before broadcast)",
      },
      {
        icon: iconPartager,
        title: "We broadcast your teaser",
        paragraph: "Your ad is broadcast after our team validation",
      },
    ],
  },
  tarifs: {
    title: `<span>Our pricing</span>`,
    subtitle: `Select a country where to promote your TV ad`,
    serviceName: "PROMOTV",
    serviceVar: [
      {
        title: "Premium",
        featureList: [
          {
            included: true,
            name: `<span>1 week </span> campaign`,
          },
          {
            included: true,
            name: `<span>35 TV broadcasts</span>`,
          },
          {
            included: true,
            name: `Advices from Trace teams`,
          },
          {
            included: true,
            name: `Video teaser creation (mounting + designs)`,
          },
          {
            included: true,
            name: `Graphics' creation`,
          },
          {
            included: true,
            name: `Sharing on Trace's social networks`,
          },
          {
            included: true,
            name: `Redirecting to the artist's plateform`,
          },
        ],
      },
      {
        title: "Platinium",
        featureList: [
          {
            included: true,
            name: `<span>2 weeks</span> campaign`,
          },
          {
            included: true,
            name: `<span>70 TV broadcasts</span>`,
          },
          {
            included: true,
            name: `Trace's teams support`,
          },
          {
            included: true,
            name: `Creation of your video teaser`,
          },
          {
            included: true,
            name: `Creation of thumbnails (for socials)`,
          },
          {
            included: true,
            name: `Broadcast on Trace's socials`,
          },
          {
            included: true,
            name: `We tag your socials in our captions`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Ready</span> to start?`,
    subtitle: `Promote yourself right now with Trace's advices`,
    button: `Here we go!`,
  },
  services: {
    title: "Other YouTracePro services",
    items: [
      {
        title: "Shoot your videoclip",
        cta: "Discover",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Broadcast my video clip",
        cta: "Discover",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
    ],
  },
};

const pt = {
  video: trailerVideo,
  title: "Faire ma promo digitale",
  subtitle:
    "<span>Fais-toi connaître</span> grâce aux réseaux sociaux de YouTrace",
  callToAction: "Commencer",
  advantages: {
    img: imgPromo,
    title: "<span>Pourquoi faire ma promo</span> sur </br>YouTrace",
    advantagesList: [
      {
        title: "Un média reconnu",
        icon: iconReconnu,
        text: `pour la qualité de ses contenus`,
      },
      {
        title: "La publication d’un article",
        icon: iconArticle,
        text: `sur le site de YouTrace.tv, visité par des milliions de fans`,
      },
      {
        title: "Une vraie mise en avant",
        icon: iconMiseAvant,
        text: `de ta musique dans le monde entier auprès d’un public averti`,
      },
      {
        title: "Des réseaux sociaux",
        icon: iconSociaux,
        text: `de plus en plus puissants avec + de 200 000 abonnés sur Youtube`,
      },
    ],
  },
  steps: {
    title: "Comment <span>faire ta promo</span> sur </br>YouTrace",
    subtitle:
      "Découvre toutes les étapes pour promouvoir ton clip avec YouTrace",
    step: [
      {
        icon: iconTournage,
        title: "Sélectionne ton pays de résidence",
        paragraph:
          "puis ton offre parmi les différents packs promo que nous proposons",
      },
      {
        icon: iconClip,
        title: "Envoies nous ton clip",
        paragraph:
          "Envoies nous l’URL de ta vidéo et quelques infos complémentaires",
      },
      {
        icon: iconDate,
        title: "Choisis la date de diffusion",
        paragraph:
          "Sélectionne le jour où tu souhaites que ton clip soit diffusé",
      },
      {
        icon: iconInscription,
        title: "Inscris-toi",
        paragraph: "Donne nous quelques infos sur toi",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Tu peux payer en ligne ou directement au représentant officiel de YouTracePro (minimum 72H avant la diffusion de ton clip)",
      },
      {
        icon: iconPartager,
        title: "Nous partageons ton clip",
        paragraph:
          "Ton clip est mis en avant sur les réseaux sociaux de Youtrace",
      },
    ],
  },
  tarifs: {
    title: `<span>Nos tarifs pour</span></br> faire ta promo`,
    subtitle: `Sélectionne le pays où tu souhaites faire ta promo pour afficher les prix :`,
    serviceName: "PROMOTV",
    serviceVar: [
      {
        title: "Premium",
        featureList: [
          {
            included: true,
            name: `<span>1 semaine</span> de diffusion`,
          },
          {
            included: true,
            name: `<span>35 diffusions</span> TV`,
          },
          {
            included: true,
            name: `Conseils des équipes de Trace`,
          },
          {
            included: true,
            name: `Création du teaser vidéo (montage + habillage)`,
          },
          {
            included: true,
            name: `Création des visuels`,
          },
          {
            included: true,
            name: `Partage sur les réseaux sociaux de Trace`,
          },
          {
            included: true,
            name: `Redirections vers les plateformes de l'artiste`,
          },
        ],
      },
      {
        title: "Platinium",
        featureList: [
          {
            included: true,
            name: `<span>2 semaines</span> de diffusion`,
          },
          {
            included: true,
            name: `<span>70 diffusions</span> TV`,
          },
          {
            included: true,
            name: `Conseils des équipes de Trace`,
          },
          {
            included: true,
            name: `Création du teaser vidéo (montage + habillage)`,
          },
          {
            included: true,
            name: `Création des visuels`,
          },
          {
            included: true,
            name: `Partage sur les réseaux sociaux de Trace`,
          },
          {
            included: true,
            name: `Redirections vers les plateformes de l'artiste`,
          },
        ],
      },
    ],
  },
  cta: {
    title: `<span>Prêt(e)</span> à te lancer ?`,
    subtitle: `Fais ta promo dès maintenant avec l’aide de YouTracePro`,
    button: `C'est parti !`,
  },
  services: {
    title: "Les autres services YouTracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Diffuser mon clip",
        cta: "Découvrir",
        link: "/services/diffusion",
        img: imgDiffuser,
      },
    ],
  },
};

export { fr, en, pt };
