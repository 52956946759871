import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Logo from "../Logo";
import { TextS } from "../../typography/Texts";
import LangSelect from "../../langSelect/LangSelect";
import Burger from "../responsive/Burger";
import ResponsiveMenu from "../responsive/ResponsiveMenu";
import { userContext } from "../../../context/userContext";
import {
  HeaderTop,
  HeaderIcons,
  HeaderNav,
  HeaderIconsWrapper,
  LangWrapperMobile,
  NavLinkDropdown,
  NavLinkWrapper,
  NavLinkChild,
} from "./Header.style";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function Header({ logout, theme, setOpen, responsiveMenuOpen }) {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const getFirstLetter = (word) => {
    return word.charAt(0).toUpperCase();
  };
  const isLogged = localStorage.getItem("access_token");
  const isAdmin =
    isLogged &&
    localStorage.getItem("idRole") &&
    localStorage.getItem("idRole") !== "1";
  return (
    <userContext.Consumer>
      {({ user }) => {
        return (
          <div data-testid="header">
            <HeaderTop>
              <Logo theme={theme} />
              <HeaderNav>
                {isAdmin && width > 512 && (
                  <NavLink to="/dashboard">Dashboard</NavLink>
                )}
                {window.location.pathname !== "/services" ? (
                  <NavLink to="/services">{t("order")}</NavLink>
                ) : (
                  <a href="/services">{t("order")}</a>
                )}
                <NavLinkWrapper>
                  <span style={{ marginRight: 20 }}>
                    {t("services")}
                    <i className="icon-chevron-bottom" />
                  </span>
                  <NavLinkDropdown>
                    <NavLinkChild to="/services/realisation">
                      {t("realisation")}
                    </NavLinkChild>
                    {/* <NavLinkChild to="/services/promo-tv">
                      {t("promotion")}
                    </NavLinkChild> */}
                    {/* <NavLinkChild to="/services/diffusion">
                      {t("diffusion")}
                    </NavLinkChild> */}
                  </NavLinkDropdown>
                </NavLinkWrapper>
                <NavLinkWrapper className="user">
                  {isLogged ? (
                    <>
                      <HeaderIcons>
                        <TextS mr={2}>
                          {user &&
                            `${user.firstName} ${getFirstLetter(
                              user.lastName
                            )}.`}
                        </TextS>
                        <i className="icon-user opacity" />
                      </HeaderIcons>
                      <NavLinkDropdown>
                        <NavLinkChild to="/mon-compte">
                          {t("settings")}
                        </NavLinkChild>
                        <NavLinkChild to="/commandes">
                          {t("myOrders")}
                        </NavLinkChild>
                        <NavLinkChild as="a" onClick={logout}>
                          {t("logout")}
                        </NavLinkChild>
                      </NavLinkDropdown>
                    </>
                  ) : (
                    <NavLinkWrapper>
                      <NavLink to="/connexion">{t("logIn")}</NavLink>
                    </NavLinkWrapper>
                  )}
                </NavLinkWrapper>
              </HeaderNav>

              <HeaderIconsWrapper>
                <HeaderIcons>
                  <LangSelect />
                </HeaderIcons>
              </HeaderIconsWrapper>
            </HeaderTop>
            <>
              <LangWrapperMobile>
                <LangSelect />
              </LangWrapperMobile>
              <Burger open={responsiveMenuOpen} setOpen={setOpen} />
              <ResponsiveMenu
                logout={logout}
                isLogged={isLogged}
                open={responsiveMenuOpen}
                setOpen={setOpen}
                theme={theme === "dark"}
                user={user}
              />
            </>
          </div>
        );
      }}
    </userContext.Consumer>
  );
}

export default Header;
