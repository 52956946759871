import trailerVideo from "../../img/diffusion.mp4";
import imgPromo from "../../img/trace-social.png";
import imgRealiser from "../../img/maitre-gims.png";
import iconDecouvrir from "../../img/landing/icon-decouvrir.png";
import iconRoyalties from "../../img/landing/icon-royalties.png";
import iconNational from "../../img/landing/icon-nationale.png";
import iconInternational from "../../img/landing/icon-international.png";
import iconEnvoyer from "../../img/landing/icon-message.png";
import iconDate from "../../img/landing/icon-data.png";
import iconValidation from "../../img/landing/icon-validation.png";
import iconPartager from "../../img/landing/icon-partager.png";
import iconRoyalties2 from "../../img/landing/icon-royalties2.png";

const fr = {
  video: trailerVideo,
  title: "Diffuser mon clip",
  subtitle:
    "<span>Partage ton clip</span> sur la chaîne Youtube de YouTrace et touche un public de <span>plus de 250 000 abonnés</span>",
  callToAction: "Commencer",
  advantages: {
    title: "<span>Pourquoi diffuser ton clip</span> sur </br>YouTrace",
    advantagesList: [
      {
        title: "Fais découvrir ton clip",
        icon: iconDecouvrir,
        text: "à plus de 200K abonnés sur notre chaine YouTube",
      },
      {
        title: "Touche tes royalties",
        icon: iconRoyalties,
        text: "grâce aux vues sur notre chaîne YouTube",
      },
      {
        title: "Représente ton pays",
        icon: iconNational,
        text: "avec ton clip sur nos playlists nationales",
      },
      {
        title: "Profite d’une visibilité internationale",
        icon: iconInternational,
        text: "et fais connaître ta musique dans le monde entier",
      },
    ],
  },
  steps: {
    title: "<span>Comment diffuser</span> ton clip sur</br> YouTrace",
    subtitle:
      "Si tu as déjà réalisé ton clip, tu peux le diffuser directement sur notre chaîne Youtube et sur le site Youtrace.tv",
    step: [
      {
        icon: iconEnvoyer,
        title: "Envoie-nous ton clip",
        paragraph:
          "Envoie-nous ton clip avec l'URL de ta vidéo* et quelques infos pour permettre sa validation.<br><br>*Tous les clips seront vérifiés",
      },
      {
        icon: iconDate,
        title: "Choisis la date de diffusion",
        paragraph: "Sélectionne le jour où tu souhaites diffuser ton clip",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Pour valider ta commande tu peux payer* en ligne directement sur YouTracePro.<br><br>*Minimum 72H avant la diffusion de ton clip ",
      },
      {
        icon: iconPartager,
        title: "Nous diffusons ton clip ",
        paragraph:
          "Sur notre chaîne YouTube (YouTrace) et sur notre site Youtrace.tv",
      },
      {
        icon: iconRoyalties2,
        title: "Nous te reversons tes royalties",
        paragraph: `YouTracePro te verse* tes royalties (à partir de 20$).<br><br>*YouTracePro te communiquera tes gains 2 fois par an`,
      },
    ],
  },
  tarifs: {
    title: `<span>Nos tarifs pour</span> diffuser </br> ton clip sur YouTrace`,
    subtitle: `Sélectionne le pays où tu souhaites réaliser ton clip pour afficher les prix :`,
    serviceName: "UPLOAD",
    serviceVar: [
      {
        title: "Classic",
        featureList: [
          {
            included: true,
            name: `Partage sur la <span>chaîne Youtube de YouTrace</span>`,
          },
          { included: true, name: `Partage sur <span>YouTrace.tv</span>` },
          { included: true, name: `Mise en avant sur des playlists par pays` },
        ],
      },
    ],
  },
  cta: {
    title: `YouTrace <span>t'offre la diffusion</span> de ton clip !`,
    subtitle: `Si tu réalises ton clip avec nos équipes, nous t’offrons sa diffusion de notre chaîne YouTube et sur YouTrace.tv`,
    button: `Je réalise mon clip avec YouTracePro`,
  },
  services: {
    title: "Les autres services YouTracePro",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};
const en = {
  video: trailerVideo,
  title: "Broadcast my music video",
  subtitle:
    "<span>Get your video featured</span> on our YouTube channel<span>with its +200K subscribers</span>",
  callToAction: "Start",
  advantages: {
    title: "<span>Why broadcasting my clip</span> on </br>YouTrace ?",
    advantagesList: [
      {
        title: "Get your clip discovered ",
        icon: iconDecouvrir,
        text: "By our +200 K YouTube subcribers",
      },
      {
        title: "Get your royalties",
        icon: iconRoyalties,
        text: "through monetization on our YouTube channel",
      },
      {
        title: "Represent your country",
        icon: iconNational,
        text: "with your clip on our national playlists",
      },
      {
        title: "Enjoy our international audience",
        icon: iconInternational,
        text: "and get you music discovered around the world",
      },
    ],
  },
  steps: {
    title: "<span>How to broadcast</span> your clip on</br> YouTrace",
    subtitle:
      "If you already have a video, you can share it directly on our YouTube channel and YouTrace.tv website",
    step: [
      {
        icon: iconEnvoyer,
        title: "Send us your clip",
        paragraph: "Send us your URL file and some extra info to confirm your order, *Each clip will be checked",
      },
      {
        icon: iconDate,
        title: "Choose when to broadcast",
        paragraph: "Choose when you want your clip to be shared",
      },
      {
        icon: iconValidation,
        title: "Confirm your order",
        paragraph:
          "You can pay online directly on Youtrace Pro, *Maximum 72h before the broadcast",
      },
      {
        icon: iconPartager,
        title: "We broadcast your music video ",
        paragraph:
          "on our YouTube channel (Youtrace) and our website Youtrace.tv",
      },
      {
        icon: iconRoyalties2,
        title: "We transfer you your royalties",
        paragraph: `YouTrace collects and transfers your royalties (requires a minimum of 20$), YouTrace pays your royalties twice a year`,
      },
    ],
  },
  tarifs: {
    title: `<span>poio</span> broadcast </br> your clip on YouTrace`,
    subtitle: `Select the country where you want to make your clip to display the prices :`,
    serviceName: "UPLOAD",
    serviceVar: [
      {
        featureList: [
          {
            included: true,
            name: `Share on <span>YouTrace YouTube channel</span>`,
          },
          { included: true, name: `Share on <span>YouTrace.tv</span>` },
          { included: true, name: `Highlighting on playlists by country` },
        ],
      },
    ],
  },
  cta: {
    title: `YouTrace <span>offers you the broadcast</span> of your video clip !`,
    subtitle: `If you make your music video with our teams, we offer you its broadcast on our YouTube channel and on YouTrace.tv`,
    button: `I make my music video with YouTrace`,
  },
  services: {
    title: "Other YouTrace services",
    items: [
      {
        title: "Shoot your videoclip",
        cta: "Find out",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Make my digital promo",
        cta: "Find out",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};

const pt = {
  video: trailerVideo,
  title: "Diffuser mon clip",
  subtitle:
    "<span>Partage ton clip</span> sur la chaîne Youtube de YouTrace et touche un public de <span>plus de 250 000 personnes</span>",
  callToAction: "Commencer",
  advantages: {
    title: "<span>Pourquoi diffuser ton clip</span> sur </br>YouTrace",
    advantagesList: [
      {
        title: "Fais découvrir ton clip",
        icon: iconDecouvrir,
        text: `grâce à notre chaîne Youtube qui compte + de 200 000 abonnés`,
      },
      {
        title: "Touche des royalties",
        icon: iconRoyalties,
        text: `grâce aux vues que t’apportera notre chaîne Youtube`,
      },
      {
        title: "Représente ton pays",
        icon: iconNational,
        text: "avec ton clip sur nos playlists nationales",
      },
      {
        title: "Profite d’une visibilité internationale",
        icon: iconInternational,
        text: "et fais connaître ta musique dans le monde entier",
      },
    ],
  },
  steps: {
    title: "<span>Comment diffuser</span> ton clip sur</br> YouTrace",
    subtitle:
      "Si tu as déjà réalisé ton clip, tu peux le diffuser directement sur notre chaîne Youtube et sur le site Youtrace.tv",
    step: [
      {
        icon: iconEnvoyer,
        title: "Envoies nous ton clip",
        paragraph:
          "Envoies nous ton clip avec l'URL de ta vidéo et quelques infos pour permettre la validation de ton clip. *Tous les clips seront vérifiés",
      },
      {
        icon: iconDate,
        title: "Choisis la date de diffusion",
        paragraph:
          "Sélectionne le jour où tu souhaites que ton clip soit diffusé",
      },
      {
        icon: iconValidation,
        title: "Valide ta commande",
        paragraph:
          "Tu peux payer en ligne ou directement au représentant de YouTrace (minimum 72H avant la diffusion de ton clip)",
      },
      {
        icon: iconPartager,
        title: "Nous diffusons ton clip ",
        paragraph:
          "sur notre Chaine YouTube (YouTrace) et sur notre site Youtrace.tv",
      },
      {
        icon: iconRoyalties2,
        title: "Nous te reversons tes royalties",
        paragraph: `YouTrace te verse les redevances de ta vidéo en fonction du nombre de vues sur notre chaîne Youtube (à partir de 20$ généré)*. *YouTrace te communiquera tes gains 2 fois par an`,
      },
    ],
  },
  tarifs: {
    title: `<span>Nos tarifs pour</span> diffuser </br> ton clip sur YouTrace`,
    subtitle: `Sélectionne le pays où tu souhaites réaliser ton clip pour afficher les prix :`,
    serviceName: "UPLOAD",
    serviceVar: [
      {
        featureList: [
          {
            included: true,
            name: `Partage sur la <span>chaîne Youtube de YouTrace</span>`,
          },
          { included: true, name: `Partage sur <span>YouTrace.tv</span>` },
          { included: true, name: `Mise en avant sur des playlists par pays` },
          { included: true, name: `Collecte et distribution de royalties` },
        ],
      },
    ],
  },
  cta: {
    title: `YouTrace <span>t'offre la diffusion</span> de ton clip !`,
    subtitle: `Si tu réalises ton clip avec nos équipes, nous t’offrons sa diffusion de notre chaîne YouTube et sur YouTrace.tv`,
    button: `Je réalise mon clip avec YouTrace`,
  },
  services: {
    title: "Les autres services YouTrace",
    items: [
      {
        title: "Réaliser mon clip",
        cta: "Découvrir",
        link: "/services/realisation",
        img: imgRealiser,
      },
      {
        title: "Faire ma promo digitale",
        cta: "Découvrir",
        link: "/services/promo-tv",
        img: imgPromo,
      },
    ],
  },
};
export { fr, en, pt };
