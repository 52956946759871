import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";

import Container, { Box, Flex } from "../../Components/styles/Container";
import { Title2, Title4 } from "../../Components/typography/Titles";
import Link from "../../Components/dashboard/Link";
import { userContext } from "../../context/userContext";
import { fixerApiKey } from "../../config/config";
import api from "../../client/api";
import YearToDate from "../../Components/dashboard/YearToDate";

import CaImg from "../../img/ca-mois.jpg";
import StatusImg from "../../img/order-status.png";
import AllOrders from "../../img/order-list.png";
import SellsImg from "../../img/ventes-mois.jpg";
import CountryImg from "../../img/datas-pays.jpg";
import OffresImg from "../../img/chiffre-offre.jpg";

export const StyledDashboard = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.colors.primary};
`;

export default function Dashboard(props) {
  const { t } = useTranslation();

  const [state, setState] = useState({
    ca: "",
    mb: "",
    sells: "",
    caNotPayed: "",
    mbNotPayed: "",
    sellsNotPayed: "",
  });
  const [currencyRate, setCurrencyRate] = useState(null);

  useEffect(() => {
    api.landing((data) => {
      setState({
        ca: data.caPayed,
        mb: data.margePayed,
        sells: data.nbPayed,
        caNotPayed: data.caNotPayed,
        mbNotPayed: data.margeNotPayed,
        sellsNotPayed: data.nbNotPayed,
      });
    });
    fetch(`https://data.fixer.io/api/latest?access_key=${fixerApiKey}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        setCurrencyRate(data.rates[props.currency]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.currency]);

  function roundDec(nb) {
    return Math.round((nb * currencyRate + Number.EPSILON) * 100) / 100;
  }

  const { caNotPayed, mbNotPayed, sellsNotPayed, mb, ca, sells } = state;
  return (
    <userContext.Consumer>
      {({ user, currency }) => {
        if (user) {
          if (user.idRole === "1") {
            return <Redirect to='/' />;
          } else {
            return (
              <StyledDashboard>
                <Container small>
                  <Box py={4} center>
                    <Title2>
                      {t("hello")}, {user && <span>{user.firstName}</span>}
                    </Title2>
                  </Box>
                  <Box style={{ borderTop: "1px solid" }} center>
                    <Title4 mt={4} mb={0}>
                      {t("bo.titleTop")}
                    </Title4>
                    {ca && mb && sells && currencyRate ? (
                      <Flex
                        justifyContent='space-between'
                        alignItems='center'
                        my={1}
                      >
                        <YearToDate
                          data={`${roundDec(ca)} ${currency}`}
                          title={t("bo.ca")}
                        />
                        <Box style={{ border: "1px solid", height: "50px" }} />
                        <YearToDate
                          data={`${roundDec(mb)} ${currency}`}
                          title={
                            user.idRole === "2"
                              ? t("bo.marge")
                              : t("bo.marge.trace")
                          }
                        />
                        <Box style={{ border: "1px solid", height: "50px" }} />
                        <YearToDate data={sells} title={t("bo.sells")} />
                      </Flex>
                    ) : (
                      <Loader
                        type='TailSpin'
                        color='#fff'
                        height={25}
                        width={25}
                        style={{ textAlign: "center", margin: "5rem" }}
                      />
                    )}
                  </Box>
                  <Box center>
                    <Title4 mb={0}>{t("bo.titleBottom")}</Title4>
                    {caNotPayed &&
                    mbNotPayed &&
                    sellsNotPayed &&
                    currencyRate ? (
                      <Flex
                        justifyContent='space-between'
                        alignItems='center'
                        mt={0}
                        mb='20px'
                      >
                        <YearToDate
                          data={`${roundDec(caNotPayed)} ${currency}`}
                          title={t("bo.caNotPayed")}
                        />
                        <Box style={{ border: "1px solid", height: "50px" }} />
                        <YearToDate
                          data={`${roundDec(mbNotPayed)} ${currency}`}
                          title={
                            user.idRole === "2"
                              ? t("bo.margeNotPayed")
                              : t("bo.margeNotPayed.trace")
                          }
                        />
                        <Box style={{ border: "1px solid", height: "50px" }} />
                        <YearToDate
                          data={sellsNotPayed}
                          title={t("bo.sellsNotPayed")}
                        />
                      </Flex>
                    ) : (
                      <Loader
                        type='TailSpin'
                        color='#fff'
                        height={25}
                        width={25}
                        style={{ textAlign: "center", margin: "5rem" }}
                      />
                    )}
                  </Box>
                  <Box style={{ border: "1px solid" }} mx={3} mb={3} />
                  <Link
                    href='/dashboard/vue-d-ensemble'
                    icon={CountryImg}
                    title={t("bo.datacountry")}
                  />
                  <Link
                    href='/commandes'
                    icon={AllOrders}
                    title={t("bo.orderList")}
                  />
                  <Link
                    href='/dashboard/commandes-statuts'
                    icon={StatusImg}
                    title={t("bo.statusorder")}
                  />
                  <Link
                    href='/dashboard/chiffre-mois'
                    icon={CaImg}
                    title={t("bo.camonth")}
                  />
                  <Link
                    href='/dashboard/chiffre-service'
                    icon={OffresImg}
                    title={t("bo.caservice")}
                  />
                  <Link
                    href='/dashboard/ventes'
                    icon={SellsImg}
                    title={t("bo.sellsmonth")}
                  />
                </Container>
              </StyledDashboard>
            );
          }
        }
      }}
    </userContext.Consumer>
  );
}
