import React from "react";

import Container, { Box } from "../Components/styles/Container";
import Button from "../Components/buttons/Button";
import { Text } from "../Components/typography/Texts";
import { Title1 } from "../Components/typography/Titles";

import error from "../img/404.png";

export default function NotFound() {
  return (
    <Container small>
      <Box center>
        <img src={error} alt='error 404' style={{ height: "250px" }} />
        <Title1 bold>
          <span>404</span> Not Found
        </Title1>
        <Text>
          Désolé ! La page à laquelle tu tente d'accéder semble ne pas
          exister...
        </Text>
        <Button mt={4} href='/'>
          Retourner à l'accueil
        </Button>
      </Box>
    </Container>
  );
}
