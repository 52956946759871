import React, { useEffect } from "react";
import api from "../client/api";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Box } from "../Components/styles/Container";

export default function Sso() {
  useEffect(() => {
    api.sso();
  }, []);
  return <Box pb={5} />;
}
