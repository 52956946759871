import styled from "styled-components";

export const AvantagesItem = styled.div`
  padding: 10px;
  line-height: 124%;
  text-align: center;
  .title {
    line-height: 124%;
  }
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    width: 220px;
    margin: 0 auto;
  }
  @media ${(props) => props.theme.mediaQueries.minMediumWidth} {
    flex-shrink: 0;
    line-height: 124%;
  }
`;
export const AvantagesIcon = styled.div`
  height: 54px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 26px;
  img {
    max-height: 52px;
  }
`;
export const AvantagesWrapper = styled.div`
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    margin-bottom: 30px;
  }
  @media ${(props) => props.theme.mediaQueries.minMediumWidth} {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`;
