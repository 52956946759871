import { useState } from "react";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { PreviewWrap } from "./lang.style";

function LangSelect() {
  const [displayList, setDisplayList] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const languages = [
    { format: "en-US", lang: "en" },
    { format: "fr-FR", lang: "fr" },
    // { format: "pt-BR", lang: "pt" },
  ];
  const { i18n } = useTranslation();
  const changeLanguage = (format) => {
    i18n.changeLanguage(format);
  };
  return (
    <PreviewWrap>
      <ul
        onClick={width < 789 ? () => setDisplayList(!displayList) : null}
        onMouseEnter={width > 789 ? () => setDisplayList(!displayList) : null}
        onMouseLeave={width > 789 ? () => setDisplayList(!displayList) : null}
        className={displayList ? "open" : "close"}
      >
        {languages.map((el, i) => {
          return (
            <li
              onClick={() => changeLanguage(el.format)}
              key={i}
              className={i18n.language.startsWith(el.lang) ? "active" : null}
            >
              <div>{el.lang.charAt(0).toUpperCase() + el.lang.slice(1)}</div>
            </li>
          );
        })}
        <i className="icon-chevron-left" />
      </ul>
    </PreviewWrap>
  );
}

export default LangSelect;
