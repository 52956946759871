import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box } from "../styles/Container";
import worldCountries from "../../utils/worldCountries";
import CountryProps from "../../interfaces/CountryProps";
import AllCountriesProps from "../../interfaces/AllCountriesProps";
import { useLocation } from "react-router-dom";

export default function CountriesAvaible({
  countries,
  country,
  onChangeCountry,
  countryLabel,
  handleSelectOption,
}) {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [language, setLanguage] = useState("");
  const worldCountriesTranslated: AllCountriesProps[] =
    language && Object.values(worldCountries[language]);
  const { i18n } = useTranslation();
  const location = useLocation();

  function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  const sortedCountries = countries.sort(dynamicSort("country"));

  useEffect(() => {
    setLanguage(i18n.language.slice(0, 2));
  }, [i18n.language]);

  function showDropdownMenu() {
    if (location.pathname !== "/panier") {
      handleSelectOption();
    }
    setLanguage(i18n.language.slice(0, 2));
    if (displayMenu) {
      setDisplayMenu(!displayMenu);
    } else {
      setDisplayMenu(!displayMenu);
    }
  }

  function changeCountry(name) {
    let country: CountryProps = countries.find((c) => c.country === name);
    setLanguage(i18n.language.slice(0, 2));
    onChangeCountry(country);
    setDisplayMenu(!displayMenu);
  }

  function translateActiveCountry() {
    return (
      language &&
      worldCountriesTranslated.find(
        (el: AllCountriesProps) => el.alpha_2 === country?.code
      )?.name
    );
  }
  return (
    <Box style={{ position: "relative" }}>
      <Select onClick={() => showDropdownMenu()}>
        {translateActiveCountry() || countryLabel}
        <span>{displayMenu ? "▲" : "▼"}</span>
      </Select>
      {displayMenu ? (
        <List>
          {sortedCountries.map((country, i) => {
            const translatedCountries: any = Object.values(
              worldCountries[language]
            ).find((el: AllCountriesProps) => el.alpha_2 === country.code);
            return (
              <li key={i} onClick={() => changeCountry(country.country)}>
                {translatedCountries?.name}
              </li>
            );
          })}
        </List>
      ) : null}
    </Box>
  );
}

const Select = styled(Box)`
  padding: 10px;
  outline: none;
  font-size: 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.inter};
  box-sizing: border-box;
  transition: 0.18s ${(props) => props.theme.transitions.easeInCubic};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  border: 1px solid;
  color: ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.primary};
`;

const List = styled.ul`
  border: 1px solid;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.primary};
  position: absolute;
  z-index: 9999999;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid;
    &::before {
      content: none;
    }
  }
`;
