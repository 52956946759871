import React from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";

import {
  CartWidgetWrapper,
  CartWidgetTitle,
  CartWidgetTotal,
  CartWidgetLine,
  ModifyButton,
} from "./CartWidget.style";
import IconCart from "../../img/icon-cart.png";
import { Text, TextS } from "../typography/Texts";
import { Box } from "../styles/Container";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import { CartProps } from "../../interfaces/CartProps";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Button from "../buttons/Button";
import Link from "../styles/Link";
import IconModify from "../../img/icon-modify.png";
import IconDelete from "../../img/icon-delete.png";
import { formatPrice } from "../../utils/format";

export default function CartWidget({
  cart,
  setCart,
  cartOpen,
  setCartOpen,
  totalPrice,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  cartOpen: boolean;
  setCartOpen: () => void;
  totalPrice: number;
}) {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const currrency = cart.country.currency;
  
  return (
    <CartWidgetWrapper className={cartOpen && "open"}>
      {width < 991 && (
        <Box mb={4}>
          <Link onClick={() => setCartOpen()}>← {t("back")}</Link>
        </Box>
      )}
      <CartWidgetTitle>
        <img src={IconCart} alt="icon panier" />
        {t("cart")}
      </CartWidgetTitle>
      <ul>
        {cart.services?.map((service, i) => {
          function modifyEl() {
            let servArray = [...cart.services];

            if (service.name === "PACK") {
              setCart(
                produce((draft) => {
                  draft.services = servArray.splice(0, 1);
                  draft.modify = "service_" + service.name;
                  draft.step = 1;
                })
              );
            } else {
              setCart(
                produce((draft) => {
                  draft.modify = "service_" + service.name;
                })
              );
            }
          }

          function deleteEl() {
            let servArray = [...cart.services];
            if (
              servArray.length > 1 &&
              (service.name === "UPLOAD" || service.name === "PROMOTV")
            ) {
              setCart(
                produce((draft) => {
                  draft.step = 0;
                })
              );
            }
            for (var i = 0; i < servArray.length; i++) {
              if (servArray[i].name === service.name) {
                servArray.splice(i, 1);
              }
            }
            setCart(
              produce((draft) => {
                draft.services =
                  service.name === "PACK"
                    ? servArray.filter((serv) => serv.price > 0)
                    : servArray;
              })
            );
          }

          const title =
            service.name === "PROMO"
              ? t("promo")
              : service.name === "PROMOTV"
                ? t("promoTV")
                : service.name === "PACK"
                  ? t("real")
                  // : service.name === "UPLOAD"
                  // ? t("upload")
                  : null;
          return (
            <li
              key={i}
              style={
                cart.services?.length - 1 !== i
                  ? {
                    borderBottom: "1px solid rgba(255,255,255, 0.5)",
                  }
                  : {}
              }
            >
              <CartWidgetLine>
                <Text mr={1} bold>
                  {title}
                </Text>
                <Text bold uppercase style={{ textAlign: "right" }}>
                  {service.price
                    ? formatPrice(service.price) + " " + currrency
                    : service.name === "UPLOAD"
                      ? t("")
                      : t("free")}
                </Text>
              </CartWidgetLine>

              {service.name === "PROMOTV" && service.channel && (
                <CartWidgetLine>
                  <Text
                    style={{
                      fontStyle: "italic",
                      lineHeight: "100%",
                      fontSize: "15px",
                    }}
                  >
                    {service.channel.channel}
                  </Text>
                </CartWidgetLine>
              )}

              {service.offer && service.price ? (
                <CartWidgetLine>
                  <TextS>{service.offer}</TextS>
                  {service.name === "PACK" ? (
                    <ModifyButton onClick={() => modifyEl()}>
                      <img src={IconModify} alt="icon modify" />
                    </ModifyButton>
                  ) : service.name === "PROMOTV" && cart.services?.length === 1 &&
                  <ModifyButton onClick={() => modifyEl()}>
                    <img src={IconModify} alt="icon modify" />
                  </ModifyButton>
                  }
                  <ModifyButton
                    style={{ marginLeft: "15px" }}
                    onClick={() => deleteEl()}
                  >
                    <img src={IconDelete} alt="icon delete" />
                  </ModifyButton>
                </CartWidgetLine>
              ) : null}
              {service.options && (
                <Box>
                  {service.options.map((el, index) => {
                    return (
                      <CartWidgetLine key={index}>
                        {el.description && <TextS>{el.description}</TextS>}
                        <ToggleSwitch
                          toggleState={el.active}
                          toggle={() =>
                            setCart(
                              produce(cart, (draft) => {
                                draft.services[i].options[index] = {
                                  name: el.name,
                                  description: el.description,
                                  active: !el.active,
                                };
                              })
                            )
                          }
                        />
                      </CartWidgetLine>
                    );
                  })}
                </Box>
              )}
            </li>
          );
        })}
      </ul>
      <CartWidgetTotal>
        <Text>TOTAL</Text>
        <Text>
          {formatPrice(totalPrice)} {currrency}
        </Text>
      </CartWidgetTotal>
      {width < 991 && (
        <Box mt="30px">
          <Button fullWidth onClick={() => setCartOpen()}>
            {t("backToMyOrder")}
          </Button>
        </Box>
      )}
    </CartWidgetWrapper>
  );
}
