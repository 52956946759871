import { Component } from "react";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { Title2, Title4 } from "../../Components/typography/Titles.tsx";
import Container, { Box, Flex } from "../../Components/styles/Container.tsx";
import Button from "../../Components/buttons/Button.tsx";
import Link from "../../Components/styles/Link";
import SimpleLine from "../../Components/dashboard/graphics/SimpleLine";
import api from "../../client/api";
import Donut from "../../Components/dashboard/graphics/Donut";
import months from "../../utils/months";
import { activeLang } from "../../utils/translate";
import { fixerApiKey } from "../../config/config";
import { userContext } from "../../context/userContext";

class ChiffreDaffaireService extends Component {
  constructor(props) {
    const t = new Date();
    super(props);
    this.state = {
      caPerServiceData: [],
      annuel: false,
      month: t.getMonth(),
      year: t.getYear() + 1900,
      currencyRate: null,
    };
    this.switchView = this.switchView.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.convertCurrency = this.convertCurrency.bind(this);
  }

  convertCurrency(data) {
    return (data.caPerService[this.state.year] = data.caPerService[
      this.state.year
    ].map((item) => {
      item.data = item.data.map((el) => {
        el = Math.round(el * this.state.currencyRate);
        return el;
      });
      return item;
    }));
  }

  componentDidMount() {
    fetch(`https://data.fixer.io/api/latest?access_key=${fixerApiKey}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        this.setState({ currencyRate: data.rates[this.props.currency] });
      })
      .then((data) => {
        api.ca((data) => {
          this.convertCurrency(data);
          this.setState({
            caPerServiceData: data.caPerService[this.state.year],
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.year !== prevState.year) {
      api.ca((data) => {
        this.convertCurrency(data);
        this.setState({ caPerServiceData: data.caPerService[this.state.year] });
      });
    }
  }

  switchView() {
    this.setState((prevState) => ({
      annuel: !prevState.annuel,
    }));
  }

  prevMonth() {
    if (this.state.month > 0) {
      this.setState((prevState) => ({
        month: prevState.month - 1,
      }));
    } else if (this.state.month === 0 && this.state.year > 2020) {
      this.setState((prevState) => ({
        month: 11,
        year: prevState.year - 1,
      }));
    }
  }

  nextMonth() {
    const d = new Date();
    if (this.state.month < d.getMonth()) {
      this.setState((prevState) => ({
        month: prevState.month + 1,
      }));
    } else if (this.state.month > d.getMonth()) {
      this.setState((prevState) => ({
        month: 0,
        year: prevState.year + 1,
      }));
    }
  }

  render() {
    const { theme, currency } = this.props;
    const { month, year, caPerServiceData, annuel } = this.state;
    return (
      <userContext.Consumer>
        {({ user }) => {
          if (user) {
            if (user.idRole === "1") {
              return <Redirect to='/' />;
            } else {
              return (
                <Container>
                  <Box py={4} center>
                    <Title2>
                      <span>{this.props.t("bo.ca")}</span>
                    </Title2>
                  </Box>
                  {annuel ? (
                    <Box mb={4} center>
                      <Title4 bold>{this.props.t("bo.caservice")} YTD</Title4>
                      <SimpleLine theme={theme} chartData={caPerServiceData} />
                    </Box>
                  ) : (
                    <Box mb={4} center>
                      <Flex justifyContent='space-between'>
                        <i
                          style={{ cursor: "pointer" }}
                          className='icon-chevron-left'
                          onClick={this.prevMonth}
                        />
                        <Title4 bold mt={0}>
                          {`${months[activeLang][month]} ${year}`}
                        </Title4>
                        <i
                          style={{ cursor: "pointer" }}
                          className='icon-chevron-right'
                          onClick={this.nextMonth}
                        />
                      </Flex>
                      {caPerServiceData ? (
                        <Donut
                          theme={theme}
                          data={caPerServiceData}
                          month={month}
                          unit={currency}
                        />
                      ) : (
                        <Loader
                          type='TailSpin'
                          color='#fff'
                          height={25}
                          width={25}
                          style={{ textAlign: "center", margin: "5rem" }}
                        />
                      )}
                    </Box>
                  )}
                  <Box my={4} center>
                    {this.state.annuel ? (
                      <Button
                        fullWidth
                        mb={4}
                        href='#'
                        onClick={this.switchView}
                      >
                        {`${this.props.t("bo.caservice")} ${this.props.t(
                          "mensual"
                        )}`}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        mb={4}
                        href='#'
                        onClick={this.switchView}
                      >
                        {`${this.props.t("bo.caservice")} ${this.props.t(
                          "annual"
                        )}`}
                      </Button>
                    )}
                    <Box>
                      <Link orange href='/dashboard'>
                        Dashboard
                      </Link>
                    </Box>
                  </Box>
                </Container>
              );
            }
          }
        }}
      </userContext.Consumer>
    );
  }
}

export default withTranslation()(ChiffreDaffaireService);
