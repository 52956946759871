import { SERVER_RESOURCES } from "../config/config";
import { fixerApiKey } from "../config/config";

let headers = new Headers();
loadHeaders();

function lang() {
  let lang = localStorage.getItem("i18nextLng").split("-")[0];
  return lang;
}

function loadHeaders() {
  headers.set(
    "Authorization",
    `Bearer ${localStorage.getItem("access_token")}`
  );
  headers.set("Content-Type", "application/json");
}

function fetchClip(identity, cb) {
  fetch(`${SERVER_RESOURCES}/orders/${identity}`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function fetchClips(cb) {
  fetch(`${SERVER_RESOURCES}/orders`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => {
      cb(data);
    });
}

function fetchCountries(cb) {
  fetch(`${SERVER_RESOURCES}/countries`)
    .then((response) => response.json())
    .then((data) => cb(data));
}

function order(pOrder, cb, ecb) {
  fetch(`${SERVER_RESOURCES}/orders`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers,
    body: JSON.stringify(pOrder), // body data type must match "Content-Type" header
  })
  .then((response) => response.json())
  .then((data) => cb(data))
  .catch((e) => ecb(e));
}

function register(user, cb, ecb) {
  user.lang = lang();
  fetch(`${SERVER_RESOURCES}/register`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user), // body data type must match "Content-Type" header
  })
    .then((response) => response.json())
    .then((data) => cb(data))
    .catch((e) => ecb(e));
}

function fetchPayments(cb) {
  fetch(`${SERVER_RESOURCES}/payments`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function createPayment(payment) {
  fetch(`${SERVER_RESOURCES}/payments`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers,
    body: JSON.stringify(payment), // body data type must match "Content-Type" header
  }).then((response) => response.json());
}

function getUser(cb) {
  const header = new Headers();
  header.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
  header.set("Content-Type", "application/json");
  fetch(`${SERVER_RESOURCES}/user`, { method: "GET", headers: header })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function getCountries(cb) {
  fetch(`${SERVER_RESOURCES}/register/countries`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function reset(identity, cb) {
  fetch(`${SERVER_RESOURCES}/reset/${lang()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: identity,
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function deleteUser(identity, cb) {
  fetch(`${SERVER_RESOURCES}/users/${identity}`, { method: "DELETE", headers })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function invoice(identity, cb, locale) {
  const header = new Headers();
  header.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
  header.set("Content-Type", "multipart/form-data");
  fetch(`${SERVER_RESOURCES}/invoice/${identity}/${locale}`, {
    method: "GET",
    headers: header,
  })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function ccprod(identity, cb, locale) {
  const header = new Headers();
  header.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
  header.set("Content-Type", "multipart/form-data");
  fetch(`${SERVER_RESOURCES}/contratcoprod/${identity}/${locale}`, {
    method: "GET",
    headers: header,
  })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function cgv(cb, locale) {
  const header = new Headers();
  header.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
  header.set("Content-Type", "multipart/form-data");
  fetch(`${SERVER_RESOURCES}/cgv/${locale}`, { method: "GET", headers: header })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function updateUser(user, cb, ecb) {
  fetch(`${SERVER_RESOURCES}/users`, {
    method: "PUT",
    headers,
    body: JSON.stringify(user), // body data type must match "Content-Type" header
  })
  .then((response) => response.json())
  // .then(data => cb(data))
  // .catch((e) => ecb(e));
}

function checkToken(token, cb) {
  fetch(`${SERVER_RESOURCES}/reset/${token}/${lang()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function updatePassword(password, cb) {
  fetch(`${SERVER_RESOURCES}/reset/password`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(password), // body data type must match "Content-Type" header
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function ccprod2(clip, cb, locale) {
  fetch(`${SERVER_RESOURCES}/contratcoprod2/${locale}`, {
    method: "POST",
    headers,
    body: JSON.stringify(clip),
  })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function contact(contact, cb) {
  fetch(`${SERVER_RESOURCES}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contact),
  })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function ventes(cb) {
  fetch(`${SERVER_RESOURCES}/ventes`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function ca(cb) {
  fetch(`${SERVER_RESOURCES}/ca`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function landing(cb) {
  fetch(`${SERVER_RESOURCES}/landing`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function pay(payment, cb) {
  fetch(`${SERVER_RESOURCES}/payments`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers,
    body: JSON.stringify(payment), // body data type must match "Content-Type" header
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function repGetPaid(order, cb) {
  order.lang = lang();
  fetch(`${SERVER_RESOURCES}/orders`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function updateComment(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/comment`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function touchpayCallback(callback) {
  callback.lang = lang();
  fetch(`${SERVER_RESOURCES}/payments/touchpay`, {
    method: "PUT",
    headers,
    body: JSON.stringify(callback),
  }).then((response) => response.json());
}

function touchpayCallbackAsync(callback) {
  callback.lang = lang();
  fetch(`${SERVER_RESOURCES}/payments/touchpay/async`, {
    method: "PUT",
    headers,
    body: JSON.stringify(callback),
  }).then((response) => response.json());
}

function cancelOrder(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/cancel`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function customerContacted(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/customer`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function progressOrder(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/progress`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function prodOrder(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/prod`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function terminateOrder(order, cb) {
  fetch(`${SERVER_RESOURCES}/orders/terminate`, {
    method: "PUT",
    headers,
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function overview(cb) {
  fetch(`${SERVER_RESOURCES}/overview`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function countryOverview(country, cb) {
  fetch(`${SERVER_RESOURCES}/overview/${country}`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function schedule(cb) {
  fetch(`${SERVER_RESOURCES}/schedule/`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function updatePayment(order, cb) {
  fetch(`${SERVER_RESOURCES}/payment`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers,
    body: JSON.stringify(order), // body data type must match "Content-Type" header
  })
    .then((response) => response.json())
    .then((data) => cb(data));
}

function sso() {
  fetch(`${SERVER_RESOURCES}/sso`, { method: "GET", headers })
    .then((response) => response.json())
    .then((data) => {
      window.location.href = `https://distribution.pro.youtrace.tv/albums?jwt=${data.token}`;
    });
}

function dist(cb, locale) {
  const header = new Headers();
  header.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
  header.set("Content-Type", "multipart/form-data");
  fetch(`${SERVER_RESOURCES}/dist/${locale}`, {
    method: "GET",
    headers: header,
  })
    .then((response) => response.blob())
    .then((blob) => cb(blob));
}

function convert(baseCurrency, targetCurrency, amount, cb) {
  fetch(
    `https://data.fixer.io/api/convert?access_key=${fixerApiKey}&from=${baseCurrency}&to=${targetCurrency}&amount=${amount}`
  )
    .then((response) => response.json())
    .then((data) => cb(data.result));
}

const api = {
  fetchClip,
  fetchClips,
  order,
  fetchCountries,
  register,
  fetchPayments,
  createPayment,
  getUser,
  updateUser,
  getCountries,
  reset,
  invoice,
  ccprod,
  cgv,
  checkToken,
  updatePassword,
  ccprod2,
  contact,
  deleteUser,
  ventes,
  ca,
  landing,
  pay,
  repGetPaid,
  overview,
  countryOverview,
  schedule,
  updatePayment,
  sso,
  dist,
  cancelOrder,
  touchpayCallback,
  touchpayCallbackAsync,
  updateComment,
  progressOrder,
  prodOrder,
  terminateOrder,
  customerContacted,
  convert,
  loadHeaders,
};
export default api;
