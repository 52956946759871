import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import logoBlack from "../../img/logo-negativ.png";

function Logo({ theme }) {
  return (
    <Link style={{ display: "inline-block" }} to='/'>
      <img
        style={{ height: 25 }}
        src={theme === "dark" ? logo : logoBlack}
        alt='logo'
      />
    </Link>
  );
}

export default Logo;
