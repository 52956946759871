import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CartStep from './cartStep/CartStep';
import RegistrationForm from '../registration/RegistrationForm';
import Link from '../styles/Link';
import LoginForm from '../login/LoginForm';
import Icon from '../../img/icon-user.png';
import { Box } from '../styles/Container';
import { userContext } from '../../context/userContext';

export default function Registration() {
  const { t } = useTranslation();
  const [loginForm, setLoginForm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <userContext.Consumer>
      {({ loginUser }) => {
        return (
          <CartStep title={t('registration.account')} icon={Icon}>
            {loginForm ? (
              <>
                <LoginForm handleLogin={() => loginUser} />

                <Box center mt={4}>
                  <Link onClick={() => setLoginForm(!loginForm)}>
                    {t('createAnAccount')}
                  </Link>
                </Box>
              </>
            ) : (
              <>
                <RegistrationForm handleValidation={() => loginUser} />
                <Box center mt={4}>
                  <Link onClick={() => setLoginForm(!loginForm)}>
                    {t('registration.already')}
                  </Link>
                </Box>
              </>
            )}
          </CartStep>
        );
      }}
    </userContext.Consumer>
  );
}