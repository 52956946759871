import React, { useState } from "react";
import produce from "immer";
import Loader from "react-loader-spinner";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { useTranslation } from "react-i18next";

import { CartProps } from "../../interfaces/CartProps";
import { UserProps } from "../../interfaces/UserProps";
import CartStep from "./cartStep/CartStep";
import { TextL } from "../typography/Texts";
import Button from "../buttons/Button";
import { Box } from "../styles/Container";
import PaymentSolution from "../paymentSolution/PaymentSolution";
import api from "../../client/api";

import Icon from "../../img/icon-payment.png";
import carteCredit from "../../img/pay/logo-carte-credit.png";
import wari from "../../img/pay/logo-wari.png";
import youtrace from "../../img/pay/logo-yt-pay.png";
import paypal from "../../img/pay/logo-paypal.png";
import airtel from "../../img/pay/airtel.png";
import eMoney from "../../img/pay/e-money.png";
import free from "../../img/pay/free.png";
import mpesa from "../../img/pay/m-pesa-sc.png";
import mobicash from "../../img/pay/mobicash.png";
import moov from "../../img/pay/moov.png";
import vodacom from "../../img/pay/vodacom.png";
import airteltigo from "../../img/pay/airteltigo.png";
import ecocash from "../../img/pay/ecocash.png";
import wizall from "../../img/pay/wizall.png";
import yup from "../../img/pay/yup.png";
import mtn from "../../img/pay/logo-mtn.png";
import orangeMoney from "../../img/pay/logo-orange-money.png";

export default function Payment({
  cart,
  user,
  setCart,
  active,
}: {
  cart: CartProps;
  user: UserProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  active: boolean;
}) {
  const [allowValidation, setAllowValidation] = useState(false);
  const [orderIdentity, setOrderIdentity] = useState(0);
  const { t, i18n } = useTranslation();
  const client = {
    sandbox:
      "AZJh46YVuv27iExNHHsUdaklCVw_cArFBSlo83FMVnmMMkZAPGo0gutkKXOMmV5WKT0M3uOchRO7N_lc",
    production:
      "AbsUCzukK8SE8CyeYGSffqo98Fzw3rMk0biu33HVJw3oit_5CAQQ8tjutwuhQzb4l7C0gQxImgFRvQli",
  };

  const stylePaypal = {
    size: "responsive",
    color: "gold",
    shape: "rect",
  };

  const buttonStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let env = user?.connectedOn === "production" ? "production" : "sandbox";
  let totalPrice = 0;
  cart.services.map((el) => (totalPrice = totalPrice + el.price));
  let [totalRateAmount, setTotalRateAmount] = useState(totalPrice);

  function onSuccess(payment) {
    order(payment);
    nextStep(7);
  }

  function handleRadios(id) {
    setAllowValidation(true);
    setCart(
      produce((draft) => {
        draft.paymentMethod = id;
      })
    );
  }

  function uploadDate() {
    let service = cart.services.find((s) => s.name === "UPLOAD");
    if (null != service) {
      let date = ("0" + service.date.getDate()).slice(-2);
      let month = ("0" + (service.date.getMonth() + 1)).slice(-2);
      return date + "/" + month + "/" + service.date.getFullYear();
    }
    return null;
  }

  function onOrder(order) {
    setOrderIdentity(order.identity);
    setCart(
      produce((draft) => {
        draft.cru = order.cru;
        draft.status = order.status;
        draft.paymentMethod = order.paymentMethod;
      })
    );
  }
  const optionalChannel = cart.services.find((el) => el.channel);
  function order(payment) {
    api.order(
      {
        pack: null,
        production: cart.contracts.coProd,
        promotion: false,
        amount: totalPrice,
        producer: cart.informations.producerName,
        producerStreet: cart.informations.street,
        producerCity: cart.informations.city,
        producerCountry: cart.informations.country,
        artist: cart.informations.artistName,
        title: cart.informations.songName,
        country: cart.country,
        label: cart.informations.label,
        channel: optionalChannel ? optionalChannel.channel.channel : null,
        diffusion: uploadDate(),
        url: cart.informations.clipUrl,
        orderServices: cart.services.map((s) =>
          s.name === "PACK" || s.name === "PROMOTV"
            ? s.name + "_" + s.offer.toUpperCase()
            : s.name
        ),
        idTransaction: payment ? payment.paymentID : "000",
        paymentMethod: cart.paymentMethod,
        lang: localStorage.getItem("i18nextLng").split("-")[0],
      },
      (order) => onOrder(order)
    );
  }

  function nextStep(step) {
    setCart(
      produce((draft) => {
        draft.step = step;
      })
    );
  }

  function payWithTouchPay() {
    window.scrollTo(0, 0);
    order(null);
    (window as any).sendPaymentInfos(
      orderIdentity,
      cart.country.agency,
      cart.country.token,
      "trace.tv",
      window.location.origin + "/touchpay/success",
      window.location.origin + "/touchpay/failed",
      totalPrice,
      "",
      "",
      "",
      "",
      ""
    );
  }

  function payWithMaxiCash() {
    window.scrollTo(0, 0);
    order(null);
    let amount = null;
    api.convert(
      cart.country.currency,
      "USD",
      totalPrice,
      (result) => (amount = result)
    );
    var maxicashPop = null;
    if (env === "production") {
      maxicashPop = (window as any).maxicash(
        "67a6be171bf1492cbdc8e1c3762578b2",
        "1b22ce6b95c64777bd1810296766a10e"
      );
    } else {
      maxicashPop = (window as any).maxicash(
        "2e18df5fe20d43f381701fe75dae64cc",
        "386ea680a5e44ebe88a5aa287755410b"
      );
    }
    maxicashPop.initialize(
      env === "production" ? "Live" : "Test",
      function (s, r) {
        if (s === "Success") {
          maxicashPop.popup({
            paytype: "MaxiCash",
            currency: "USD",
            amount: amount * 100,
            language: i18n.language.slice(0, 2),
            reference: orderIdentity,
            accepturl: window.location.origin + "/maxicash",
            cancelurl: window.location.origin + "/maxicash",
            declineurl: window.location.origin + "/maxicash",
            notifyurl: window.location.origin + "/maxicash/asynch",
          });
        } else {
          // Display your error or failed status
        }
      }
    );
  }

  function payWithWari() {
    window.scrollTo(0, 0);
    order(null);
    nextStep(7);
  }

  function payWithCoordinator() {
    window.scrollTo(0, 0);
    order(null);
    nextStep(7);
  }

  function countryPaymentSolution() {
    switch (cart.country.code) {
      case "SN":
        return {
          label: "Orange Money	/ Free / E-Money / Yup / Wizall",
          logos: [orangeMoney, free, eMoney, yup, wizall],
        };
      case "CI":
        return {
          label: "Orange Money	/ MTN / Moov",
          logos: [orangeMoney, mtn, moov],
        };
      case "GN":
        return { label: "MTN", logos: [mtn] };
      case "BF":
        return {
          label: "Orange Money / Yup / Mobicash",
          logos: [orangeMoney, yup, mobicash],
        };
      case "CM":
        return { label: "MTN", logos: [mtn] };
      case "CD":
        return {
          label: "Vodacom MPesa / Orange Money / Airtel Money",
          logos: [mpesa, orangeMoney, airtel],
        };
      case "UG":
        return { label: "MTN Mobile / Airtel Money", logos: [mtn, airtel] };
      case "RW":
        return {
          label: "MTN Mobile / AirtelTigo",
          logos: [orangeMoney, airteltigo],
        };
      case "KE":
        return { label: "Safaricom M-Pesa", logos: [mpesa] };
      case "BI":
        return { label: "Ecocash", logos: [ecocash] };
      case "GH":
        return {
          label: "MTN Mobile	/ Vodacom Cash / AirtelTigo",
          logos: [mtn, vodacom, airteltigo],
        };
      case "ZM":
        return { label: "MTN Mobile", logos: [mtn] };
      default:
        return null;
    }
  }

  const convert = () => {
    api.convert(
      cart.country.currency,
      ["USD", "CAD"].includes(cart.country.currency)
        ? cart.country.currency
        : "EUR",
      totalPrice,
      (result: number) => (setTotalRateAmount(result))
    );
  }

  React.useEffect((): void => {
    convert();
  });

  return (
    <CartStep title="Paiement" icon={Icon} active={active}>
      <Box marginBottom="20px">
        <TextL bold>{t("selectPaymentMet")}</TextL>
      </Box>
      <Box pb={3}>
        <PaymentSolution
          cart={cart}
          id="paypal_cb"
          label={t("creditCard")}
          minText=" (via PayPal)"
          handleChange={handleRadios}
          img={[carteCredit]}
        />
        {cart.country.touchPayAvailable && (
          <>
            <PaymentSolution
              cart={cart}
              id="touchpay_orange"
              label={countryPaymentSolution().label}
              handleChange={handleRadios}
              img={countryPaymentSolution().logos}
            />
          </>
        )}
        {cart.country.maxiCashAvailable && (
          <>
            <PaymentSolution
              cart={cart}
              id="maxiCash_orange"
              label={countryPaymentSolution().label}
              handleChange={handleRadios}
              img={countryPaymentSolution().logos}
            />
          </>
        )}
        {cart.country.wariAvailable && (
          <PaymentSolution
            cart={cart}
            id="wari"
            label="Wari Mobile Money"
            handleChange={handleRadios}
            img={[wari]}
          />
        )}
        <PaymentSolution
          cart={cart}
          id="paypal"
          label="Paypal"
          handleChange={handleRadios}
          img={[paypal]}
        />
        {cart.country.coordinatorAvailable && (
          <PaymentSolution
            cart={cart}
            id="coordinateur"
            label={t("coordinatorLabel")}
            smallText={t("coordinatorSmallText")}
            handleChange={handleRadios}
            img={[youtrace]}
          />
        )}
      </Box>

      <Box mt={3} center>
        {cart.paymentMethod?.startsWith("paypal") ? (
          <Box style={{ position: "relative" }}>
            {cart.contracts.termsConditions && (
              <PaypalExpressBtn
                style={stylePaypal}
                client={client}
                currency={
                  ["USD", "CAD"].includes(cart.country.currency)
                    ? cart.country.currency
                    : "EUR"
                }
                total={parseFloat(totalRateAmount.toFixed(2))}
                env={env}
                onSuccess={onSuccess}
                onError={() => {
                  console.log("onError Paypal");
                }}
                onCancel={() => {
                  console.log("onError Paypal");
                }}
              />
            )}

            <Button style={buttonStyle}>
              <Loader
                type="TailSpin"
                color="#fff"
                height={25}
                width={25}
                timeout={3000}
              />
              {t("proceedPay")}
            </Button>
          </Box>
        ) : cart.paymentMethod?.startsWith("touchpay") ? (
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={payWithTouchPay}
          >
            {t("proceedPay")}
          </Button>
        ) : cart.paymentMethod?.startsWith("maxiCash") ? (
          <Button style={buttonStyle} onClick={payWithMaxiCash}>
            {t("maxicash")}
          </Button>
        ) : cart.paymentMethod?.startsWith("wari") ? (
          <Button style={buttonStyle} onClick={payWithWari}>
            {t("Wari")}
          </Button>
        ) : (
          <Button
            disabled={!allowValidation}
            style={buttonStyle}
            onClick={payWithCoordinator}
          >
            {t("proceedPay")}
          </Button>
        )}
      </Box>
    </CartStep>
  );
}
