/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import produce from "immer";
import { useTranslation } from "react-i18next";

import CartStep from "./cartStep/CartStep";
import { Form, FormCol } from "../styles/Form";
import { Text, TextL } from "../typography/Texts";
import InputText from "../form/NewInputText";
import { Box, Flex } from "../styles/Container";
import Button from "../buttons/Button";
import { CartProps } from "../../interfaces/CartProps";
import Icon from "../../img/icon-play.png";
import CountryDropdown from "../form/CountryDropdown";
import api from "../../client/api";

export default function InformationsClip({
  cart,
  setCart,
  active,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  active: boolean;
}) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<any>();

  useEffect(() => {
    api.fetchCountries((countries) => {
      setCountries(countries);
    });
  }, []);

  function deleteEmpty(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }
  const formik = useFormik({
    initialValues: {
      artistName: cart.informations?.artistName || "",
      songName: cart.informations?.songName || "",
      producerName: cart.informations?.producerName || "",
      street: cart.informations?.street || "",
      country: cart.informations?.country || "",
      label: cart.informations?.label || "",
      clipUrl: cart.informations?.clipUrl || "",
    },
    onSubmit: (values) => {
      setCart(
        produce((draft) => {
          if (draft.step < 3) {
            draft.step = 3;
          }
          draft.modify = false;
          draft.informations = deleteEmpty(values);
        })
      );
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      artistName: Yup.string(t("completeField")).required(t("completeField")),
      songName:
        cart.services.some(
          (el) => el.name === "PACK" || el.name === "UPLOAD"
        ) && Yup.string(t("completeField")).required(t("completeField")),
      producerName:
        cart.services.some((el) => el.name === "PACK") &&
        Yup.string(t("completeField")).required(t("completeField")),
      street:
        cart.services.some((el) => el.name === "PACK") &&
        Yup.string(t("completeField")).required(t("completeField")),
      country:
        cart.services.some((el) => el.name === "PACK") &&
        Yup.string(t("completeField")).required(t("completeField")).nullable(),
      label:
        cart.services.some((el) => el.name === "PACK") &&
        Yup.string(t("completeField")),
      clipUrl:
        !cart.services.some((el) => el.name === "PACK") &&
        Yup.string(t("completeField")).required(t("completeField")),
    }),
  });

  function handleCountryChange(suggestions) {
    formik.setFieldValue("country", suggestions[0].name);
  }

  function onSuggestions(suggestions) {
    if (suggestions.length === 1) {
      formik.setFieldValue("country", suggestions[0].name);
    } else {
      formik.setFieldValue("country", null);
    }
  }

  function modifyThisStep() {
    setCart(
      produce((draft) => {
        draft.modify = 2;
      })
    );
  }
  return (
    <CartStep
      title={t("infosClip.title")}
      icon={Icon}
      active={active}
      modify={() => modifyThisStep()}
    >
      {active ? (
        <>
          <TextL bold>{t("infosClip.subtitle")}</TextL>
          <Form onSubmit={formik.handleSubmit}>
            <Flex justifyContent="space-between">
              <Box fWidth marginY="20px">
                <Text bold>{t("infosClip.text")}</Text>
              </Box>
              <Box />
              <FormCol>
                <InputText
                  name="artistName"
                  label={t("artistName") + "* :"}
                  type="text"
                  handleChange={formik.handleChange}
                  value={formik.values.artistName}
                  error={formik.errors.artistName}
                />
              </FormCol>
              {cart.services.some(
                (el) => el.name === "UPLOAD" || el.name === "PACK"
              ) && (
                <FormCol>
                  <InputText
                    name="songName"
                    label={t("songName") + "* :"}
                    type="text"
                    handleChange={formik.handleChange}
                    value={formik.values.songName}
                    error={formik.errors.songName}
                  />
                </FormCol>
              )}
            </Flex>
            {!cart.services.some((el) => el.name === "PACK") && (
              <Flex>
                <Box fWidth marginY="20px">
                  <Text bold>{t("shareLink")}</Text>
                </Box>
                <Box />
                <FormCol>
                  <InputText
                    name="clipUrl"
                    label={t("enterClipUrl") + "* :"}
                    handleChange={formik.handleChange}
                    value={formik.values.clipUrl}
                    error={formik.errors.clipUrl}
                  />
                </FormCol>
              </Flex>
            )}
            {cart.services.some((el) => el.name === "PACK") && (
              <Flex>
                <Box fWidth marginY="20px">
                  <Text bold>{t("whoProducer")}</Text>
                </Box>
                <Box />
                <FormCol>
                  <InputText
                    name="producerName"
                    label={t("SurnameFirstName") + "* :"}
                    type="text"
                    handleChange={formik.handleChange}
                    value={formik.values.producerName}
                    error={formik.errors.producerName}
                  />
                </FormCol>
                <FormCol>
                  <InputText
                    name="street"
                    label={t("StreetNoAndName") + "* :"}
                    type="text"
                    handleChange={formik.handleChange}
                    value={formik.values.street}
                    error={formik.errors.street}
                  />
                </FormCol>
                <FormCol>
                  <CountryDropdown
                    label={`${t("country")} * :`}
                    value={formik.values.country}
                    onSuggestions={onSuggestions}
                    onCountryChange={handleCountryChange}
                    countries={countries}
                  />
                  {formik.errors.country && (
                    <Text error>{formik.errors.country}</Text>
                  )}
                </FormCol>
                <FormCol>
                  <InputText
                    name="label"
                    label={t("label")}
                    type="text"
                    handleChange={formik.handleChange}
                    value={formik.values.label}
                    error={formik.errors.label}
                  />
                </FormCol>
              </Flex>
            )}
            <Box mt="20px" center>
              <button
                style={{ width: "100%", backgroundColor: "transparent" }}
                type="submit"
              >
                <Button fullWidth>{t("next")}</Button>
              </button>
            </Box>
          </Form>
        </>
      ) : (
        <Flex>
          <Box style={{ flex: 1 }}>
            <TextL mb="10px">{t("yourVideo")}</TextL>
            <Text>{cart.informations?.artistName}</Text>
            <Text>{cart.informations?.songName}</Text>
          </Box>
          {cart.informations?.clipUrl && (
            <Box style={{ flex: 1 }}>
              <TextL mb="10px">{t("linkVideo")}</TextL>
              <Text>{cart.informations.clipUrl}</Text>
            </Box>
          )}
        </Flex>
      )}
    </CartStep>
  );
}
