import styled from "styled-components";

export const ServiceCardWrapper = styled.div`
  min-height: 100px;
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #fff;
  text-decoration: none;
  box-shadow: 0px 0px #ea6c13, 0em 0 0.4em #ea6c13;
  border: 1px solid ${(props) => props.theme.colors.primary};

  p,
  h1,
  h2,
  h3,
  h4 {
    text-shadow: 0 0 3px black;
    margin: 10px 0;
  }

  .overlay {
    background: #000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: 0.2s ${(props) => props.theme.transitions.easeInCubic};
  }
  .icon {
    color: ${(props) => props.theme.colors.secondary};
    margin: 2px 0 0 10px;
    opacity: 0;
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    .overlay {
      opacity: 0.5;
    }
    .icon {
      opacity: 1;
    }
  }
`;

export const ServiceCardContent = styled.div`
  position: relative;
`;
