import React, { useRef, useState } from "react";
import styled from "styled-components";

const DropdownWrapper = styled.div`
  overflow: hidden;
  text-align: left;
`;

const DropdownTitle = styled.h3`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 5px 0;
  padding-right: 15px;
  &.active i {
    transform: rotate(180deg);
  }
  i {
    font-size: 0.375;
    color: ${(props) => props.theme.colors.grey};
    transition: 0.3s ${(props) => props.theme.transitions.easeInOutCubic};
  }
`;

const DropdownContent = styled.div`
  padding-top: 20px;
  line-height: 130%;
  transition: 0.3s ${(props) => props.theme.transitions.easeInOutCubic};
`;

const DropDownFaq = styled(DropdownWrapper)`
  border-bottom: 1px solid rgba(180, 180, 180, 0.2);
  &:nth-child(1) {
    border-top: 1px solid rgba(180, 180, 180, 0.2);
  }
`;

const DropdownTitleFaq = styled(DropdownTitle)`
  height: 70px;
  font-size: 1.188rem;

  .dropdown__icon {
    width: 14px;
    height: 14px;
    position: relative;
    transition: 0.2s $easeInOutCubic;

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 1.1px;
      background: ${(props) => props.theme.colors.orange};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: 0.2s $easeInOutCubic;
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &.active {
    .dropdown__icon:after {
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      opacity: 0;
    }
  }
`;

const DropdownContentFaq = styled(DropdownContent)`
  transition: max-height 0.4s ease;
  padding: 0;
  p {
    padding: 0 0 25px 0;
    margin: 0 10px;
  }
`;

const DropdownIconFaq = styled.div`
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    height: 7px;
    width: 7px;
  }
`;

function FaqDropdown({ text, title }) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <DropDownFaq>
      <DropdownTitleFaq
        className={`accordion ${setActive}`}
        onClick={toggleAccordion}
      >
        {title}
        <DropdownIconFaq>
          <div className='dropdown__icon' />
        </DropdownIconFaq>
      </DropdownTitleFaq>
      <DropdownContentFaq ref={content} style={{ maxHeight: `${setHeight}` }}>
        <p>{text}</p>
      </DropdownContentFaq>
    </DropDownFaq>
  );
}

function DropdownServices({ title, subtitle }) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <>
      <DropdownWrapper>
        <DropdownTitle
          className={`accordion ${setActive}`}
          onClick={toggleAccordion}
        >
          {title} <i className='icon-arrow' />
        </DropdownTitle>
        <DropdownContent ref={content} style={{ maxHeight: `${setHeight}` }}>
          {subtitle}
        </DropdownContent>
      </DropdownWrapper>
    </>
  );
}

export { FaqDropdown, DropdownServices };
