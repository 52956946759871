import styled from "styled-components";

export const CartWidgetWrapper = styled.aside`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};
  width: 320px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 40px 20px;
  max-height: 100vh;
  overflow-y: auto;

  @media ${(props) => props.theme.mediaQueries.minMediumWidth} {
    flex-grow: 1;
    flex-basis: 320px;
    align-self: start;
    position: sticky;
    top: 1rem;
  }
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    z-index: 99;
    display: none;
    &.open {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999999;
      background: ${(props) => props.theme.colors.primary};
      margin-left: 0;
    }
  }
  li {
    display: block;
    margin: 20px 0;
  }
`;

export const CartWidgetTitle = styled.h3`
  border-bottom: 1px solid;
  margin: 0 0 10px 0;
  padding-bottom: 10px;
  font-size: 26px;
  text-align: left;
  img {
    margin-right: 10px;
  }
`;

export const CartWidgetTotal = styled.div`
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

export const CartWidgetLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
`;

export const ModifyButton = styled.div`
  cursor: pointer;
  margin-left: auto;
  img {
    height: 18px !important;
  }
`;
