import { useTranslation } from "react-i18next";

import { BreadcrumbWrapper } from "./Breadcrumb.style";

export default function Breadcrumb({ currentStep }: { currentStep: number }) {
  const { t } = useTranslation();
  const steps = [
    { label: t("completeOrder"), stepNumber: [0] },
    { label: t("infos"), stepNumber: [1] },
    { label: t("myVideo"), stepNumber: [2, 3, 4] },
    { label: t("payment"), stepNumber: [5, 6] },
  ];
  return (
    <BreadcrumbWrapper>
      {steps.map((step, i) => {
        return (
          <li
            key={i}
            className={
              step.stepNumber.some((nb) => nb === currentStep) ? "active" : ""
            }
          >
            {step.label}
          </li>
        );
      })}
    </BreadcrumbWrapper>
  );
}
