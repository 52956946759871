import Button from "../buttons/Button";
import Container from "../styles/Container";
import { TextL } from "../typography/Texts";
import { Title2 } from "../typography/Titles";
import { CallToActionWrapper } from "./CallToAction.style";

export default function CallToAction({
  content,
}: {
  content: { title: string; subtitle: string; button: string };
}) {
  const { title, subtitle, button } = content;
  return (
    <Container my={[4, 6]}>
      <CallToActionWrapper>
        <Title2 dangerouslySetInnerHTML={{ __html: title }} />
        <TextL dangerouslySetInnerHTML={{ __html: subtitle }} />
        <Button href='#go-to-start'>{button}</Button>
      </CallToActionWrapper>
    </Container>
  );
}
