import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Box, Flex } from "../styles/Container";
import { Form, FormCol } from "../styles/Form";
import Button from "../buttons/Button";
import CountryDropdown from "../form/CountryDropdown";
import InputText from "../form/NewInputText";
import { Text } from "../typography/Texts";
import api from "../../client/api";
import auth from "../../auth/auth";
import InputPassword from "../form/InputPassword";

export default function RegistrationForm({
  handleValidation,
}: {
  handleValidation?: any;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>();
  const [countries, setCountries] = useState<any>();
  useEffect(() => {
    api.getCountries((countries) => {
      setCountries(countries);
    });
  }, []);

  function register(values) {
    api.register(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        password: values.password,
        countryCode: values.country?.alpha_2,
        phone: values.tel,
      },
      (data) => onRegister(data, values),
      (e) => {
        //console.log(e);
      }
    );
  }

  function onRegister(data?: any, values?: any) {
    if (
      data.message &&
      data.message.includes("Response status 406 with reason")
    ) {
      setError(t("alreadyUsed"));
    } else {
      auth.fetchAccessToken(
        handleValidation(),
        (e) => {
          //console.log(e);
        },
        values.emailAddress,
        values.password
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      tel: "",
      lastPhone: "",
      emailAddress: "",
      password: "",
      country: "",
    },
    onSubmit: (values) => {
      register(values);
    },

    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, t("firstNameLeast"))
        .max(20, t("firstNameMax"))
        .required(t("firstNameEnter"))
        .matches(/^[a-zA-Z]/, {
          message: t("firstNameCheck"),
          excludeEmptyString: true,
        }),
      lastName: Yup.string()
        .min(2, t("surnameLeast"))
        .max(20, t("surnameMax"))
        .required(t("surnameEnter"))
        .matches(/^[a-zA-Z]/, {
          message: t("surnameCheck"),
          excludeEmptyString: true,
        }),
      tel: Yup.string()
        .min(6, t("phoneLeast"))
        .max(15, t("phoneMax"))
        .required(t("phoneEnter"))
        .matches(/^[+]/, {
          message: t("phoneCheck"),
          excludeEmptyString: true,
        })
        .matches(
          /^[+]((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          {
            message: t("phoneInvalid"),
            excludeEmptyString: true,
          }
        ),
      emailAddress: Yup.string()
        .email(t("emailInvalid"))
        .required(t("emailEnter")),
      password: Yup.string()
        .min(8, t("passwordLeast"))
        .required(t("enterPassword"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          t("passwordIncorrect")
        ),
      country: Yup.string()
        // .test(
        //   "country",
        //   t("countryEnter"),
        //   () => formik.values.country !== null || formik.values.country !== ""
        // )
        .required(t("countryEnter"))
        .nullable(),
    }),
  });

  // onSuggestions(suggestions) {
  //   if (suggestions.length === 1) {
  //     if (this.state.country != suggestions[0]) {
  //       this.setState({
  //         country: suggestions[0],
  //         lastPhone: '+' + suggestions[0].phone_code,
  //         tel: this.state.tel
  //           .toString()
  //           .replace(this.state.lastPhone, '+' + suggestions[0].phone_code)
  //       });
  //     }
  //   } else {
  //     this.setState({ country: null });
  //   }
  // }

  function handleCountryChange(suggestions) {
    formik.setFieldValue("country", suggestions[0]);
    formik.setFieldValue("lastPhone", "+" + suggestions[0].phone_code);
    formik.setFieldValue(
      "tel",
      formik.values.tel
        .toString()
        .replace(formik.values.lastPhone, "+" + suggestions[0].phone_code)
    );
  }

  function onSuggestions(suggestions) {
    if (suggestions.length === 1) {
      formik.setFieldValue("country", suggestions[0]);
    } else {
      formik.setFieldValue("country", null);
    }
  }

  return (
    <Form style={{ margin: "auto" }} onSubmit={formik.handleSubmit}>
      <Flex justifyContent="space-between">
        <FormCol>
          <InputText
            name="firstName"
            label={t("registration.firstName") + "* :"}
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.errors.firstName}
          />
        </FormCol>
        <FormCol>
          <InputText
            name="lastName"
            label={t("registration.surname") + "* :"}
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.errors.lastName}
          />
        </FormCol>
        <FormCol>
          <CountryDropdown
            label={`${t("country")} * :`}
            value={formik.values.country}
            onSuggestions={onSuggestions}
            onCountryChange={handleCountryChange}
            countries={countries}
            error={formik.errors.country}
          />
          {formik.errors.country && <Text error>{formik.errors.country}</Text>}
        </FormCol>
        <FormCol>
          <InputText
            name="tel"
            label={t("registration.phone") + "* :"}
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.tel}
            error={formik.errors.tel}
          />
        </FormCol>
        <FormCol>
          <InputText
            name="emailAddress"
            label={t("registration.email") + "* :"}
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.emailAddress}
            error={formik.errors.emailAddress}
          />
        </FormCol>
        <FormCol>
          <InputPassword
            name="password"
            label={t("registration.password") + "* :"}
            handleChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />
        </FormCol>
        {error && !formik.errors.emailAddress && <Text error>{error}</Text>}
        <Box mt="20px" center>
          <button
            style={{ width: "100%", backgroundColor: "transparent" }}
            type="submit"
          >
            <Button fullWidth>{t("registration.subscribe")}</Button>
          </button>
        </Box>
      </Flex>
    </Form>
  );
}
