import React, { Component } from "react";
import api from "../../client/api";
import ResetPasswordRender from "./ResetPasswordRender";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      error: false,
      success: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  onChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  handleReset() {
    api.reset(this.state.login, () => {});
  }

  changeStatus() {
    this.setState({ success: true });
  }

  render() {
    return (
      <ResetPasswordRender
        onChange={this.onChange}
        handleReset={this.handleReset}
        changeStatus={this.changeStatus}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default ResetPassword;
