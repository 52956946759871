import React from "react";
import Highcharts from "highcharts";
import uuid from "uuid";
import HighchartsReact from "highcharts-react-official";

import { Box } from "../../styles/Container.tsx";
import { Text } from "../../typography/Texts.tsx";
import { translateServicesName } from "../../../utils/translate";
import { useDarkMode } from "../../../hooks/useDarkMode";

const Donut = (props) => {
  const [theme] = useDarkMode();
  const translatedData = translateServicesName(props.data);
  const options = {
    colors: props.data.map((x) => x.color),
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: theme === "light" ? "#fff" : "#000",
      style: {
        fontFamily: '"Inter",Sans-Serif',
        fontWeight: "bold",
      },
    },
    title: {
      text: "",
    },
    series: [
      {
        colorByPoint: true,
        innerSize: "80%",
        data: translatedData.map((serie) => [
          serie.name,
          serie.data[props.month],
        ]),
      },
    ],
    legend: {
      itemStyle: {
        color: theme === "light" ? "#000" : "#fff",
      },
    },
    tooltip: {
      pointFormat: `{point.y}${props.unit} -  {point.percentage:.1f}%`,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    credits: {
      enabled: false,
    },
  };
  return (
    <Box style={{ position: "relative" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <Box
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {translatedData.map((serie) => (
          <Text mb={2} key={uuid()}>
            {serie.name} :
            <br /> {serie.data[props.month]} {props.unit}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default Donut;
