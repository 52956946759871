/**
 * Transform number like 10000 to currency like "10 000 Francs"
 * @param {string} number
 */
 export const formatPrice = amount =>
 `${amount
 .toString()
 .split('')
 .reverse()
 .map((letter, i) => ((i + 1) % 3 === 0 ? ` ${letter}` : letter))
 .reverse()
 .join('')}`;
