// import { useRef } from "react";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";

import api from "../../client/api";
import AccountInfo from "./AccountInfo";
import ModifyInfo from "./ModifyAccount";

export default function AccountWrapper() {
  const [modifying, setModifying] = useState(false);
  const [user, setUser] = useState(null);
  const isLogged = localStorage.getItem("access_token") != null;
  const { t } = useTranslation();
  useEffect(() => {
    if (isLogged) {
      api.getUser((user) => {
        const newUser = { ...user };
        newUser.password = null;
        setUser(newUser);
      });
    }
  }, [isLogged]);

  function confirmDelete() {
    confirmAlert({
      title: "Confirmation",
      message: t("deleteAccountMessage"),
      buttons: [
        {
          label: t("deleteAccountMessageYes"),
          onClick: () => deleteAccount(),
        },
        {
          label: t("deleteAccountMessageNo"),
          onClick: () => console.log("Don't Delete!"),
        },
      ],
    });
  }

  function deleteAccount() {
    api.deleteUser(user.identity, (response) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("idRole");
      localStorage.removeItem("expires_in");
      window.location.href = "/compte-supprime";
    });
  }

  function onChangePassword(e, field) {
    const newUser = { ...user };
    newUser[field] = e.target.value;
    setUser(newUser);
  }

  function handleCountryChange(suggestions) {
    const newUser = { ...user };
    newUser.country.country = suggestions[0].name;
    newUser.countryCode = suggestions[0].alpha_2;
    setUser(newUser);
  }

  function onSuggestions(suggestions) {
    const newUser = { ...user };
    if (suggestions.length === 1) {
      newUser.country.country = suggestions[0].name;
      newUser.countryCode = suggestions[0].alpha_2;
      setUser(newUser);
    } else {
      newUser.country.country = null;
      setUser(newUser);
    }
  }

  function onChange(e, field) {
    const newUser = { ...user };
    newUser[field] = e.target.value;
    setUser(newUser);
  }

  function toggleModifying() {
    api.updateUser(user)
    setModifying(!modifying);
  }

  return (
    <div>
      {modifying ? (
        <ModifyInfo
          user={user}
          onSuggestions={onSuggestions}
          onCountryChange={handleCountryChange}
          onChangePassword={onChangePassword}
          toggleModifying={toggleModifying}
          onChange={onChange}
          handleDeleteAccount={confirmDelete}
        />
      ) : (
        <AccountInfo user={user} toggleModifying={toggleModifying} />
      )}
    </div>
  );
}
