import React, { useEffect } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";

import Button from "../buttons/Button";
import CartStep from "./cartStep/CartStep";
import { UserProps } from "../../interfaces/UserProps";
import { CartProps } from "../../interfaces/CartProps";
import { Text } from "../typography/Texts";
import AccountWrapper from "../account/AccountWrapper";
import Icon from "../../img/icon-user.png";

export default function AccountStep({
  user,
  setCart,
  active,
}: {
  user: UserProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  active: boolean;
}) {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function nextStep() {
    setCart(
      produce((draft) => {
        if (draft.step < 2) {
          draft.step = 2;
        }
        draft.modify = false;
      })
    );
  }
  function modifyThisStep() {
    setCart(
      produce((draft) => {
        draft.modify = 1;
      })
    );
  }
  return (
    <CartStep
      title={t("registration.account")}
      icon={Icon}
      active={active}
      modify={() => modifyThisStep()}
    >
      {active ? (
        <>
          <AccountWrapper />
          <Button
            fullWidth
            style={{ left: "15%" }}
            onClick={(e) => {
              if (user.country === null) {
                e.preventDefault()
              } else {
                nextStep();
              }
            }}
          >
            {t("validation")}
          </Button>
        </>
      ) : (
        <Text>
          {t("usingEmail")} <b>{user.emailAddress}</b>
        </Text>
      )}
    </CartStep>
  );
}
