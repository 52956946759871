import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import $ from "jquery";

import { PricingTablesContainerWrapper } from "./PricingTablesContainer.style";
import Container from "../styles/Container";
import CountriesAvaible from "../form/CountriesAvaible";
import api from "../../client/api";
import PricingTable from "../pricingTable/PricingTable";
import { CartProps } from "../../interfaces/CartProps";
import { UserProps } from "../../interfaces/UserProps";
import CountryProps from "../../interfaces/CountryProps";
import Channels from "../channels/Channels";
import { Title4 } from "../typography/Titles";

export default function PricingTablesContainer({
  content,
  handleSelectOption,
  cart,
  user,
}: {
  content: { serviceVar: any[]; serviceName: string };
  handleSelectOption: any;
  cart?: CartProps;
  user: UserProps;
}) {
  const [pricing, setPricing] = useState<{ title: string; price: number }[]>(
    []
  );
  const [countries, setCountries] = useState<CountryProps[]>();
  const [selectedCountry, setSelectedCountry] = useState<CountryProps>();
  const [channel, setChannel] = useState<any>({
    channel: "",
    identity: 0,
    platinum: 0,
    premium: 0,
  });

  const { t } = useTranslation();
  const location = useLocation();

  function onSelectChannel(c) {
    setChannel(c);
    pricing.find((p) => p.title === "PROMOTV_PREMIUM").price = c.premium;
    pricing.find((p) => p.title === "PROMOTV_PLATINIUM").price = c.platinum;
  }

  $(document).ready(function () {
    $(".Channels").click(function () {
      $("html,body").animate(
        {
          scrollTop: $("#go-to-price").offset().top + 20,
        },
        "slow"
      );
      $("html,body").clearQueue();
    });
  });

  useEffect(() => {
    api.fetchCountries((countries) => {
      setCountries(countries);
    });
    if (cart) {
      setSelectedCountry(cart.country);
    }
  }, [cart]);

  useEffect(() => {
    setPricing([]);
    if (selectedCountry) {
      setChannel("");
      selectedCountry.countryServices.forEach((el) => {
        setPricing((pricing) => [
          ...pricing,
          { title: el.service.serviceType, price: el.amount },
        ]);
      });
    }
  }, [selectedCountry]);

  const servName = cart.services
    ? cart?.services[0]?.name
    : content.serviceName;

  const countriesAvailables = countries
    ?.filter((item) =>
      item.countryServices.some((el) =>
        el.service.serviceType.startsWith(servName)
      )
    )
    .filter((country) =>
      content.serviceName === "PROMOTV"
        ? country.promoChannels.length > 0
        : country
    );

  if (content && countries) {
    if (
      user &&
      !selectedCountry &&
      countriesAvailables.some((country) => country.code === user?.country.code)
    ) {
      user && setSelectedCountry(user.country);
    }

    return (
      <div>
        {(location.pathname !== "/panier" ||
          (typeof cart.modify === "string" &&
            ((cart.services[0].name === "PACK" &&
              cart.modify === "service_PACK") ||
              cart.services[0].name === "PROMOTV"))) &&
          countries && (
            <Container small pt={[3, 4]} mb={3}>
              <CountriesAvaible
                onChangeCountry={setSelectedCountry}
                countryLabel={t("registration.country")}
                country={selectedCountry || null}
                countries={countriesAvailables}
                handleSelectOption={handleSelectOption}
              />
            </Container>
          )}
        {content.serviceName === "PROMOTV" && selectedCountry && (
          <>
            <Container
              pt={4}
              style={{
                padding: location.pathname === "/panier" ? 0 : "15px",
              }}
            >
              <Title4>{t("selectChannel")} :</Title4>
            </Container>
            <Channels
              channelsList={selectedCountry.promoChannels}
              selected={channel}
              handleClick={(e) => onSelectChannel(e)}
            />
          </>
        )}
        <div id="go-to-price"></div>
        {(content.serviceName !== "PROMOTV" || channel) && (
          <Container
            pt={4}
            style={{
              padding: location.pathname === "/panier" ? 0 : "15px",
            }}
          >
            <PricingTablesContainerWrapper>
              {content.serviceVar?.map(
                (el: { title: string; featureList: any }, i: number) => {
                  const serviceName = el.title
                    ? content.serviceName
                    : content.serviceName;
                  const matchedPrice = pricing
                    .filter((price) => price.title.includes(serviceName))
                    .sort(function (a, b) {
                      return a.price - b.price;
                    });
                  return (
                    <PricingTable
                      selectedCountry={selectedCountry}
                      serviceList={el.featureList}
                      title={el.title}
                      pricing={matchedPrice[i]}
                      channel={channel}
                      handleSelectOption={handleSelectOption}
                      active={cart?.services.some(
                        (serv) =>
                          serv.offer === el.title &&
                          serv.name === content.serviceName
                      )}
                      key={i}
                    />
                  );
                }
              )}
            </PricingTablesContainerWrapper>
          </Container>
        )}
      </div>
    );
  } else {
    return null;
  }
}
