import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";

import CartStep from "./cartStep/CartStep";
import { CartProps } from "../../interfaces/CartProps";
import { TextL, TextXS } from "../typography/Texts";
import Button from "../buttons/Button";
import { BlocCheckboxes } from "../styles/Bloc";
import { Checkbox } from "../styles/Form";
import Link from "../styles/Link";
import { Box } from "../styles/Container";
import api from "../../client/api";

import Icon from "../../img/icon-contrats.png";

export default function Contrats({
  cart,
  setCart,
  active,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  active: boolean;
}) {
  const [allowValidation, setAllowValidation] = useState(true);
  const { t, i18n } = useTranslation();
  let locale = i18n.language.slice(0, 2);

  let totalPrice = 0;
  cart.services.map((el) => (totalPrice = totalPrice + el.price));

  useEffect(() => {
    setCart(
      produce((draft) => {
        draft.contracts = {
          coProd: false,
          termsConditions: false,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cart.contracts?.termsConditions === false) {
      setAllowValidation(false);
    } else if (
      cart.services.some((serv) => serv.name === "PACK") &&
      cart.contracts?.coProd === false
    ) {
      setAllowValidation(false);
    } else {
      setAllowValidation(true);
    }
  }, [cart]);

  function nextStep() {
    setCart(
      produce((draft) => {
        if (draft.step < 6) {
          draft.step = 6;
        }
        draft.modify = false;
      })
    );
  }
  function modifyThisStep() {
    setCart(
      produce((draft) => {
        draft.modify = 5;
      })
    );
  }
  function handleCgv(locale) {
    api.cgv((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "Terms_And_Conditions.pdf");
      document.body.appendChild(link);
      link.click();
    }, locale);
  }

  function handleCCProd(locale) {
    api.ccprod2(
      {
        pack: cart.services.some((el) => el.name === "PACK")
          ? "PACK"
          : cart.services.some((el) => el.name === "UPLOAD")
          ? "UPLOAD"
          : "PROMOTV",
        amount: totalPrice,
        producer: cart.informations.producerName,
        producerStreet: cart.informations.street,
        producerCity: cart.informations.city,
        producerCountry: cart.informations.country,
        artist: cart.informations.artistName,
        title: cart.informations.songName,
        country: cart.informations.country,
        label: cart.informations.label,
        signed: cart.contracts.coProd,
      },
      (blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Music_Video_Coproduction_Contract.pdf");
        document.body.appendChild(link);
        link.click();
      },
      locale
    );
  }

  return (
    <CartStep
      title={t("validateMyContrat")}
      icon={Icon}
      active={active}
      modify={() => modifyThisStep()}
    >
      {active && (
        <>
          <Box marginBottom="20px">
            <TextL bold>{t("contractsSubtitle")}</TextL>
          </Box>
          <BlocCheckboxes>
            {cart.services.some((serv) => serv.name === "PACK") && (
              <Checkbox>
                <input
                  type="checkbox"
                  name="accept_monetisation"
                  readOnly={true}
                  checked={cart.contracts?.coProd || false}
                />
                <label
                  onClick={() =>
                    setCart(
                      produce((draft) => {
                        draft.contracts.coProd = !cart.contracts.coProd;
                      })
                    )
                  }
                  htmlFor="accept_monetisation"
                >
                  <span></span>
                  <TextXS>{t("acceptCP")}</TextXS>
                </label>
                <TextXS
                  my={2}
                  onClick={() => handleCCProd(locale)}
                  style={{ textAlign: "left" }}
                >
                  <Link style={{ fontStyle: "italic" }}>{t("seeCP")}</Link>
                </TextXS>
              </Checkbox>
            )}
            <Checkbox>
              <input
                type="checkbox"
                name="accept_tc"
                readOnly={true}
                checked={cart.contracts?.termsConditions || false}
              />
              <label
                htmlFor="accept_tc"
                onClick={() =>
                  setCart(
                    produce((draft) => {
                      draft.contracts.termsConditions =
                        !cart.contracts?.termsConditions;
                    })
                  )
                }
              >
                <span></span>
                <TextXS>{t("acceptTC")}</TextXS>
              </label>
              <TextXS
                my={2}
                onClick={() => handleCgv(locale)}
                style={{ textAlign: "left" }}
              >
                <Link style={{ fontStyle: "italic" }}>{t("seeTC")}</Link>
              </TextXS>
            </Checkbox>
          </BlocCheckboxes>
          <Button
            disabled={!allowValidation}
            fullWidth
            style={{ left: "15%" }}
            onClick={() => {
              allowValidation && nextStep();
            }}
          >
            {t("validation")}
          </Button>
        </>
      )}
    </CartStep>
  );
}
