import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container, { Box } from "../styles/Container";
import { Title2 } from "../typography/Titles";
import { Avantage } from "../avantages/Avantages";

import imgOffer from "../../img/cover-trace.jpg";
import iconSecurite from "../../img/securite.svg";
import iconNotoriete from "../../img/notoriete.svg";
import iconCoordinateur from "../../img/coordinateur.svg";
import iconArgument from "../../img/clip.svg";
import { AvantagesWrapper } from "../avantages/Avantages.style";
import { BannerImg, BannerWrapper } from "../banner/Banner.style";

function Banner() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const avantages = [
    {
      icon: iconSecurite,
      title: t("offer1"),
    },
    {
      icon: iconNotoriete,
      title: t("offer2"),
    },
    {
      icon: iconCoordinateur,
      title: t("offer3"),
    },
    {
      icon: iconArgument,
      title: t("offer4"),
    },
  ];
  return (
    <BannerWrapper style={{ minHeight: "300px" }}>
      <BannerImg src={imgOffer} alt="YouTracePro" />
      <Container my={[4, 6]}>
        <Title2 mb={4}>
          You<span>Trace</span>Pro
        </Title2>
        <Box>
          <AvantagesWrapper>
            <Slider {...settings}>
              {avantages.map((avantage, i) => (
                <Avantage key={i} {...avantage} />
              ))}
            </Slider>
          </AvantagesWrapper>
        </Box>
      </Container>
    </BannerWrapper>
  );
}

export default Banner;
