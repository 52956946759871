import { ReactNode } from "react";

import { CartStepTitle, CartStepWrapper } from "./CartStep.style";
import { Title3 } from "../../typography/Titles";
import { ModifyButton } from "../../cartWidget/CartWidget.style";
import IconModify from "../../../img/icon-modify.png";

export default function CartStep({
  title,
  icon,
  children,
  modify,
  active,
}: {
  title?: string;
  icon?: string;
  children?: ReactNode;
  active?: boolean;
  modify?: () => void;
}) {
  return (
    <CartStepWrapper>
      <CartStepTitle>
        <div>
          <img src={icon} alt='' />
        </div>
        <Title3>{title}</Title3>
        {!active && modify && (
          <ModifyButton onClick={modify}>
            <img src={IconModify} alt='icon modify' />
          </ModifyButton>
        )}
      </CartStepTitle>
      {children}
    </CartStepWrapper>
  );
}
