const worldCountries: { fr: any; en: any; pt: any } = {
  fr: {
    afghanistan: {
      name: "Afghanistan",
      en_name: "Afghanistan",
      native_name: "افغانستان",
      capital: "Kaboul",
      en_capital: "Kabul",
      alpha_2: "AF",
      alpha_3: "AFG",
      phone_code: "93",
    },
    Ålandislands: {
      name: "Les Îles D'Åland",
      en_name: "Åland Islands",
      native_name: "Åland",
      capital: "Mariehamn",
      en_capital: "Mariehamn",
      alpha_2: "AX",
      alpha_3: "ALA",
      phone_code: "358",
    },
    albania: {
      name: "Albanie",
      en_name: "Albania",
      native_name: "Shqipëria",
      capital: "Tirana",
      en_capital: "Tirana",
      alpha_2: "AL",
      alpha_3: "ALB",
      phone_code: "355",
    },
    algeria: {
      name: "Algérie",
      en_name: "Algeria",
      native_name: "الجزائر",
      capital: "Alger",
      en_capital: "Algiers",
      alpha_2: "DZ",
      alpha_3: "DZA",
      phone_code: "213",
    },
    americansamoa: {
      name: "Samoa Américaines",
      en_name: "American Samoa",
      native_name: "American Samoa",
      capital: "Pago Pago",
      en_capital: "Pago Pago",
      alpha_2: "AS",
      alpha_3: "ASM",
      phone_code: "1684",
    },
    andorra: {
      name: "Andorre",
      en_name: "Andorra",
      native_name: "Andorra",
      capital: "Andorre-la-Vieille",
      en_capital: "Andorra la Vella",
      alpha_2: "AD",
      alpha_3: "AND",
      phone_code: "376",
    },
    angola: {
      name: "Angola",
      en_name: "Angola",
      native_name: "Angola",
      capital: "Luanda",
      en_capital: "Luanda",
      alpha_2: "AO",
      alpha_3: "AGO",
      phone_code: "244",
    },
    anguilla: {
      name: "Anguilla",
      en_name: "Anguilla",
      native_name: "Anguilla",
      capital: "Vallée",
      en_capital: "The Valley",
      alpha_2: "AI",
      alpha_3: "AIA",
      phone_code: "1264",
    },
    antiguaandbarbuda: {
      name: "Antigua-et-Barbuda",
      en_name: "Antigua and Barbuda",
      native_name: "Antigua and Barbuda",
      capital: "Saint John's",
      en_capital: "Saint John's",
      alpha_2: "AG",
      alpha_3: "ATG",
      phone_code: "1268",
    },
    argentina: {
      name: "Argentine",
      en_name: "Argentina",
      native_name: "Argentina",
      capital: "Buenos Aires",
      en_capital: "Buenos Aires",
      alpha_2: "AR",
      alpha_3: "ARG",
      phone_code: "54",
    },
    armenia: {
      name: "Arménie",
      en_name: "Armenia",
      native_name: "Հայաստան",
      capital: "Erevan",
      en_capital: "Yerevan",
      alpha_2: "AM",
      alpha_3: "ARM",
      phone_code: "374",
    },
    aruba: {
      name: "Aruba",
      en_name: "Aruba",
      native_name: "Aruba",
      capital: "Oranjestad",
      en_capital: "Oranjestad",
      alpha_2: "AW",
      alpha_3: "ABW",
      phone_code: "297",
    },
    australia: {
      name: "Australie",
      en_name: "Australia",
      native_name: "Australia",
      capital: "Canberra",
      en_capital: "Canberra",
      alpha_2: "AU",
      alpha_3: "AUS",
      phone_code: "61",
    },
    austria: {
      name: "Autriche",
      en_name: "Austria",
      native_name: "Österreich",
      capital: "Vienne",
      en_capital: "Vienna",
      alpha_2: "AT",
      alpha_3: "AUT",
      phone_code: "43",
    },
    azerbaijan: {
      name: "Azerbaïdjan",
      en_name: "Azerbaijan",
      native_name: "Azərbaycan",
      capital: "Bakou",
      en_capital: "Baku",
      alpha_2: "AZ",
      alpha_3: "AZE",
      phone_code: "994",
    },
    thebahamas: {
      name: "Les Bahamas",
      en_name: "The Bahamas",
      native_name: "Bahamas",
      capital: "Nassau",
      en_capital: "Nassau",
      alpha_2: "BS",
      alpha_3: "BHS",
      phone_code: "1242",
    },
    bahrain: {
      name: "Bahreïn",
      en_name: "Bahrain",
      native_name: "\u200fالبحرين",
      capital: "Manama",
      en_capital: "Manama",
      alpha_2: "BH",
      alpha_3: "BHR",
      phone_code: "973",
    },
    bangladesh: {
      name: "Bangladesh",
      en_name: "Bangladesh",
      native_name: "Bangladesh",
      capital: "Dhaka",
      en_capital: "Dhaka",
      alpha_2: "BD",
      alpha_3: "BGD",
      phone_code: "880",
    },
    barbados: {
      name: "Barbade",
      en_name: "Barbados",
      native_name: "Barbados",
      capital: "Bridgetown",
      en_capital: "Bridgetown",
      alpha_2: "BB",
      alpha_3: "BRB",
      phone_code: "1246",
    },
    belarus: {
      name: "Biélorussie",
      en_name: "Belarus",
      native_name: "Белару́сь",
      capital: "Minsk",
      en_capital: "Minsk",
      alpha_2: "BY",
      alpha_3: "BLR",
      phone_code: "375",
    },
    belgium: {
      name: "Belgique",
      en_name: "Belgium",
      native_name: "België",
      capital: "Bruxelles",
      en_capital: "Brussels",
      alpha_2: "BE",
      alpha_3: "BEL",
      phone_code: "32",
    },
    belize: {
      name: "Belize",
      en_name: "Belize",
      native_name: "Belize",
      capital: "Belmopan",
      en_capital: "Belmopan",
      alpha_2: "BZ",
      alpha_3: "BLZ",
      phone_code: "501",
    },
    benin: {
      name: "Bénin",
      en_name: "Benin",
      native_name: "Bénin",
      capital: "Porto-Novo",
      en_capital: "Porto-Novo",
      alpha_2: "BJ",
      alpha_3: "BEN",
      phone_code: "229",
    },
    bermuda: {
      name: "Bermudes",
      en_name: "Bermuda",
      native_name: "Bermuda",
      capital: "Hamilton",
      en_capital: "Hamilton",
      alpha_2: "BM",
      alpha_3: "BMU",
      phone_code: "1441",
    },
    bhutan: {
      name: "Bhoutan",
      en_name: "Bhutan",
      native_name: "ʼbrug-yul",
      capital: "Thimphu",
      en_capital: "Thimphu",
      alpha_2: "BT",
      alpha_3: "BTN",
      phone_code: "975",
    },
    bolivia: {
      name: "Bolivie",
      en_name: "Bolivia",
      native_name: "Bolivia",
      capital: "Sucre",
      en_capital: "Sucre",
      alpha_2: "BO",
      alpha_3: "BOL",
      phone_code: "591",
    },
    bonaire: {
      name: "Bonaire",
      en_name: "Bonaire",
      native_name: "Bonaire",
      capital: ": Kralendijk",
      en_capital: "Kralendijk",
      alpha_2: "BQ",
      alpha_3: "BES",
      phone_code: "5997",
    },
    botswana: {
      name: "Botswana",
      en_name: "Botswana",
      native_name: "Botswana",
      capital: "Gaborone",
      en_capital: "Gaborone",
      alpha_2: "BW",
      alpha_3: "BWA",
      phone_code: "267",
    },
    brazil: {
      name: "Brésil",
      en_name: "Brazil",
      native_name: "Brasil",
      capital: "Brasília",
      en_capital: "Brasília",
      alpha_2: "BR",
      alpha_3: "BRA",
      phone_code: "55",
    },
    britishindianoceanterritory: {
      name: "Territoire Britannique De L'Océan Indien",
      en_name: "British Indian Ocean Territory",
      native_name: "British Indian Ocean Territory",
      capital: "Diego Garcia",
      en_capital: "Diego Garcia",
      alpha_2: "IO",
      alpha_3: "IOT",
      phone_code: "246",
    },
    britishvirginislands: {
      name: "Îles Vierges Britanniques",
      en_name: "British Virgin Islands",
      native_name: "British Virgin Islands",
      capital: "Road Town",
      en_capital: "Road Town",
      alpha_2: "VG",
      alpha_3: "VGB",
      phone_code: "1284",
    },
    brunei: {
      name: "Brunei",
      en_name: "Brunei",
      native_name: "Negara Brunei Darussalam",
      capital: "Bandar Seri Begawan",
      en_capital: "Bandar Seri Begawan",
      alpha_2: "BN",
      alpha_3: "BRN",
      phone_code: "673",
    },
    bulgaria: {
      name: "Bulgarie",
      en_name: "Bulgaria",
      native_name: "България",
      capital: "Sofia",
      en_capital: "Sofia",
      alpha_2: "BG",
      alpha_3: "BGR",
      phone_code: "359",
    },
    burkinafaso: {
      name: "Burkina Faso",
      en_name: "Burkina Faso",
      native_name: "Burkina Faso",
      capital: "Ouagadougou",
      en_capital: "Ouagadougou",
      alpha_2: "BF",
      alpha_3: "BFA",
      phone_code: "226",
    },
    burundi: {
      name: "Burundi",
      en_name: "Burundi",
      native_name: "Burundi",
      capital: "Bujumbura",
      en_capital: "Bujumbura",
      alpha_2: "BI",
      alpha_3: "BDI",
      phone_code: "257",
    },
    cambodia: {
      name: "Cambodge",
      en_name: "Cambodia",
      native_name: "Kâmpŭchéa",
      capital: "Phnom Penh",
      en_capital: "Phnom Penh",
      alpha_2: "KH",
      alpha_3: "KHM",
      phone_code: "855",
    },
    cameroon: {
      name: "Cameroun",
      en_name: "Cameroon",
      native_name: "Cameroon",
      capital: "Yaoundé",
      en_capital: "Yaoundé",
      alpha_2: "CM",
      alpha_3: "CMR",
      phone_code: "237",
    },
    canada: {
      name: "Canada",
      en_name: "Canada",
      native_name: "Canada",
      capital: "Ottawa",
      en_capital: "Ottawa",
      alpha_2: "CA",
      alpha_3: "CAN",
      phone_code: "1",
    },
    capeverde: {
      name: "Cap-Vert",
      en_name: "Cape Verde",
      native_name: "Cabo Verde",
      capital: "Praia",
      en_capital: "Praia",
      alpha_2: "CV",
      alpha_3: "CPV",
      phone_code: "238",
    },
    caymanislands: {
      name: "Îles Caïmans",
      en_name: "Cayman Islands",
      native_name: "Cayman Islands",
      capital: "George Town",
      en_capital: "George Town",
      alpha_2: "KY",
      alpha_3: "CYM",
      phone_code: "1345",
    },
    centralafricanrepublic: {
      name: "République Centrafricaine",
      en_name: "Central African Republic",
      native_name: "Ködörösêse tî Bêafrîka",
      capital: "Bangui",
      en_capital: "Bangui",
      alpha_2: "CF",
      alpha_3: "CAF",
      phone_code: "236",
    },
    chad: {
      name: "Tchad",
      en_name: "Chad",
      native_name: "Tchad",
      capital: "De N'Djamena",
      en_capital: "N'Djamena",
      alpha_2: "TD",
      alpha_3: "TCD",
      phone_code: "235",
    },
    chile: {
      name: "Chili",
      en_name: "Chile",
      native_name: "Chile",
      capital: "Santiago",
      en_capital: "Santiago",
      alpha_2: "CL",
      alpha_3: "CHL",
      phone_code: "56",
    },
    china: {
      name: "Chine",
      en_name: "China",
      native_name: "中国",
      capital: "Pékin",
      en_capital: "Beijing",
      alpha_2: "CN",
      alpha_3: "CHN",
      phone_code: "86",
    },
    christmasisland: {
      name: "Île Christmas",
      en_name: "Christmas Island",
      native_name: "Christmas Island",
      capital: "Flying Fish Cove",
      en_capital: "Flying Fish Cove",
      alpha_2: "CX",
      alpha_3: "CXR",
      phone_code: "61",
    },
    "cocos(keeling)islands": {
      name: "Îles Cocos (Keeling ",
      en_name: "Cocos (Keeling) Islands",
      native_name: "Cocos (Keeling) Islands",
      capital: "Ouest De L'Île",
      en_capital: "West Island",
      alpha_2: "CC",
      alpha_3: "CCK",
      phone_code: "61",
    },
    colombia: {
      name: "Colombie",
      en_name: "Colombia",
      native_name: "Colombia",
      capital: "Bogotá",
      en_capital: "Bogotá",
      alpha_2: "CO",
      alpha_3: "COL",
      phone_code: "57",
    },
    comoros: {
      name: "Comores",
      en_name: "Comoros",
      native_name: "Komori",
      capital: "Moroni",
      en_capital: "Moroni",
      alpha_2: "KM",
      alpha_3: "COM",
      phone_code: "269",
    },
    republicofthecongo: {
      name: "République du Congo",
      en_name: "Republic of the Congo",
      native_name: "République du Congo",
      capital: "Brazzaville",
      en_capital: "Brazzaville",
      alpha_2: "CG",
      alpha_3: "COG",
      phone_code: "242",
    },
    democraticrepublicofthecongo: {
      name: "République démocratique du Congo",
      en_name: "Democratic Republic of the Congo",
      native_name: "République démocratique du Congo",
      capital: "Kinshasa",
      en_capital: "Kinshasa",
      alpha_2: "CD",
      alpha_3: "COD",
      phone_code: "243",
    },
    cookislands: {
      name: "Îles Cook",
      en_name: "Cook Islands",
      native_name: "Cook Islands",
      capital: "Avarua",
      en_capital: "Avarua",
      alpha_2: "CK",
      alpha_3: "COK",
      phone_code: "682",
    },
    costarica: {
      name: "Costa Rica",
      en_name: "Costa Rica",
      native_name: "Costa Rica",
      capital: "San José",
      en_capital: "San José",
      alpha_2: "CR",
      alpha_3: "CRI",
      phone_code: "506",
    },
    croatia: {
      name: "Croatie",
      en_name: "Croatia",
      native_name: "Hrvatska",
      capital: "Zagreb",
      en_capital: "Zagreb",
      alpha_2: "HR",
      alpha_3: "HRV",
      phone_code: "385",
    },
    cuba: {
      name: "Cuba",
      en_name: "Cuba",
      native_name: "Cuba",
      capital: "La havane",
      en_capital: "Havana",
      alpha_2: "CU",
      alpha_3: "CUB",
      phone_code: "53",
    },
    curaçao: {
      name: "Curaçao",
      en_name: "Curaçao",
      native_name: "Curaçao",
      capital: "Willemstad",
      en_capital: "Willemstad",
      alpha_2: "CW",
      alpha_3: "CUW",
      phone_code: "5999",
    },
    cyprus: {
      name: "Chypre",
      en_name: "Cyprus",
      native_name: "Κύπρος",
      capital: "Nicosie",
      en_capital: "Nicosia",
      alpha_2: "CY",
      alpha_3: "CYP",
      phone_code: "357",
    },
    czechrepublic: {
      name: "République Tchèque",
      en_name: "Czech Republic",
      native_name: "Česká republika",
      capital: "Prague",
      en_capital: "Prague",
      alpha_2: "CZ",
      alpha_3: "CZE",
      phone_code: "420",
    },
    denmark: {
      name: "Danemark",
      en_name: "Denmark",
      native_name: "Danmark",
      capital: "Copenhague",
      en_capital: "Copenhagen",
      alpha_2: "DK",
      alpha_3: "DNK",
      phone_code: "45",
    },
    djibouti: {
      name: "Djibouti",
      en_name: "Djibouti",
      native_name: "Djibouti",
      capital: "Djibouti",
      en_capital: "Djibouti",
      alpha_2: "DJ",
      alpha_3: "DJI",
      phone_code: "253",
    },
    dominica: {
      name: "Dominique",
      en_name: "Dominica",
      native_name: "Dominica",
      capital: "Roseau",
      en_capital: "Roseau",
      alpha_2: "DM",
      alpha_3: "DMA",
      phone_code: "1767",
    },
    dominicanrepublic: {
      name: "République Dominicaine",
      en_name: "Dominican Republic",
      native_name: "República Dominicana",
      capital: "Santo Domingo",
      en_capital: "Santo Domingo",
      alpha_2: "DO",
      alpha_3: "DOM",
      phone_code: "1809",
    },
    ecuador: {
      name: "Équateur",
      en_name: "Ecuador",
      native_name: "Ecuador",
      capital: "Quito",
      en_capital: "Quito",
      alpha_2: "EC",
      alpha_3: "ECU",
      phone_code: "593",
    },
    egypt: {
      name: "Egypte",
      en_name: "Egypt",
      native_name: "مصر\u200e",
      capital: "Le caire",
      en_capital: "Cairo",
      alpha_2: "EG",
      alpha_3: "EGY",
      phone_code: "20",
    },
    elsalvador: {
      name: "El Salvador",
      en_name: "El Salvador",
      native_name: "El Salvador",
      capital: "San Salvador",
      en_capital: "San Salvador",
      alpha_2: "SV",
      alpha_3: "SLV",
      phone_code: "503",
    },
    equatorialguinea: {
      name: "Guinée Équatoriale",
      en_name: "Equatorial Guinea",
      native_name: "Guinea Ecuatorial",
      capital: "Malabo",
      en_capital: "Malabo",
      alpha_2: "GQ",
      alpha_3: "GNQ",
      phone_code: "240",
    },
    eritrea: {
      name: "Érythrée",
      en_name: "Eritrea",
      native_name: "ኤርትራ",
      capital: "Asmara",
      en_capital: "Asmara",
      alpha_2: "ER",
      alpha_3: "ERI",
      phone_code: "291",
    },
    estonia: {
      name: "Estonie",
      en_name: "Estonia",
      native_name: "Eesti",
      capital: "Tallinn",
      en_capital: "Tallinn",
      alpha_2: "EE",
      alpha_3: "EST",
      phone_code: "372",
    },
    ethiopia: {
      name: "Ethiopie",
      en_name: "Ethiopia",
      native_name: "ኢትዮጵያ",
      capital: "Addis-Abeba",
      en_capital: "Addis Ababa",
      alpha_2: "ET",
      alpha_3: "ETH",
      phone_code: "251",
    },
    falklandislands: {
      name: "Îles Falkland",
      en_name: "Falkland Islands",
      native_name: "Falkland Islands",
      capital: "Stanley",
      en_capital: "Stanley",
      alpha_2: "FK",
      alpha_3: "FLK",
      phone_code: "500",
    },
    faroeislands: {
      name: "Îles Féroé",
      en_name: "Faroe Islands",
      native_name: "Føroyar",
      capital: "Tórshavn",
      en_capital: "Tórshavn",
      alpha_2: "FO",
      alpha_3: "FRO",
      phone_code: "298",
    },
    fiji: {
      name: "Fidji",
      en_name: "Fiji",
      native_name: "Fiji",
      capital: "Suva",
      en_capital: "Suva",
      alpha_2: "FJ",
      alpha_3: "FJI",
      phone_code: "679",
    },
    finland: {
      name: "Finlande",
      en_name: "Finland",
      native_name: "Suomi",
      capital: "Helsinki",
      en_capital: "Helsinki",
      alpha_2: "FI",
      alpha_3: "FIN",
      phone_code: "358",
    },
    france: {
      name: "France",
      en_name: "France",
      native_name: "France",
      capital: "Paris",
      en_capital: "Paris",
      alpha_2: "FR",
      alpha_3: "FRA",
      phone_code: "33",
    },
    frenchguiana: {
      name: "Guyane Française",
      en_name: "French Guiana",
      native_name: "Guyane française",
      capital: "Cayenne",
      en_capital: "Cayenne",
      alpha_2: "GF",
      alpha_3: "GUF",
      phone_code: "594",
    },
    frenchpolynesia: {
      name: "Polynésie Française",
      en_name: "French Polynesia",
      native_name: "Polynésie française",
      capital: "Papeetē",
      en_capital: "Papeetē",
      alpha_2: "PF",
      alpha_3: "PYF",
      phone_code: "689",
    },
    frenchsouthernandantarcticlands: {
      name: "Terres australes et Antarctiques françaises",
      en_name: "French Southern and Antarctic Lands",
      native_name: "Territoire des Terres australes et antarctiques françaises",
      capital: "Port-aux-Français",
      en_capital: "Port-aux-Français",
      alpha_2: "TF",
      alpha_3: "ATF",
      phone_code: "262",
    },
    gabon: {
      name: "Gabon",
      en_name: "Gabon",
      native_name: "Gabon",
      capital: "Libreville",
      en_capital: "Libreville",
      alpha_2: "GA",
      alpha_3: "GAB",
      phone_code: "241",
    },
    thegambia: {
      name: "Gambie",
      en_name: "The Gambia",
      native_name: "Gambia",
      capital: "Banjul",
      en_capital: "Banjul",
      alpha_2: "GM",
      alpha_3: "GMB",
      phone_code: "220",
    },
    georgia: {
      name: "Géorgie",
      en_name: "Georgia",
      native_name: "საქართველო",
      capital: "Tbilissi",
      en_capital: "Tbilisi",
      alpha_2: "GE",
      alpha_3: "GEO",
      phone_code: "995",
    },
    germany: {
      name: "Allemagne",
      en_name: "Germany",
      native_name: "Deutschland",
      capital: "Berlin",
      en_capital: "Berlin",
      alpha_2: "DE",
      alpha_3: "DEU",
      phone_code: "49",
    },
    ghana: {
      name: "Ghana",
      en_name: "Ghana",
      native_name: "Ghana",
      capital: "Accra",
      en_capital: "Accra",
      alpha_2: "GH",
      alpha_3: "GHA",
      phone_code: "233",
    },
    gibraltar: {
      name: "Gibraltar",
      en_name: "Gibraltar",
      native_name: "Gibraltar",
      capital: "Gibraltar",
      en_capital: "Gibraltar",
      alpha_2: "GI",
      alpha_3: "GIB",
      phone_code: "350",
    },
    greece: {
      name: "Grèce",
      en_name: "Greece",
      native_name: "Ελλάδα",
      capital: "Athènes",
      en_capital: "Athens",
      alpha_2: "GR",
      alpha_3: "GRC",
      phone_code: "30",
    },
    greenland: {
      name: "Groenland",
      en_name: "Greenland",
      native_name: "Kalaallit Nunaat",
      capital: "Nuuk",
      en_capital: "Nuuk",
      alpha_2: "GL",
      alpha_3: "GRL",
      phone_code: "299",
    },
    grenada: {
      name: "Grenade",
      en_name: "Grenada",
      native_name: "Grenada",
      capital: "Saint-George",
      en_capital: "St. George's",
      alpha_2: "GD",
      alpha_3: "GRD",
      phone_code: "1473",
    },
    guadeloupe: {
      name: "Guadeloupe",
      en_name: "Guadeloupe",
      native_name: "Guadeloupe",
      capital: "Basse-Terre",
      en_capital: "Basse-Terre",
      alpha_2: "GP",
      alpha_3: "GLP",
      phone_code: "590",
    },
    guam: {
      name: "Guam",
      en_name: "Guam",
      native_name: "Guam",
      capital: "Hagåtña",
      en_capital: "Hagåtña",
      alpha_2: "GU",
      alpha_3: "GUM",
      phone_code: "1671",
    },
    guatemala: {
      name: "Guatemala",
      en_name: "Guatemala",
      native_name: "Guatemala",
      capital: "La Ville De Guatemala",
      en_capital: "Guatemala City",
      alpha_2: "GT",
      alpha_3: "GTM",
      phone_code: "502",
    },
    guernsey: {
      name: "Guernesey",
      en_name: "Guernsey",
      native_name: "Guernsey",
      capital: "Port De Saint-Pierre",
      en_capital: "St. Peter Port",
      alpha_2: "GG",
      alpha_3: "GGY",
      phone_code: "44",
    },
    guinea: {
      name: "Guinée",
      en_name: "Guinea",
      native_name: "Guinée",
      capital: "Conakry",
      en_capital: "Conakry",
      alpha_2: "GN",
      alpha_3: "GIN",
      phone_code: "224",
    },
    guineabissau: {
      name: "Guinée-Bissau",
      en_name: "Guinea-Bissau",
      native_name: "Guiné-Bissau",
      capital: "Bissau",
      en_capital: "Bissau",
      alpha_2: "GW",
      alpha_3: "GNB",
      phone_code: "245",
    },
    guyana: {
      name: "Guyane",
      en_name: "Guyana",
      native_name: "Guyana",
      capital: "Georgetown",
      en_capital: "Georgetown",
      alpha_2: "GY",
      alpha_3: "GUY",
      phone_code: "592",
    },
    haiti: {
      name: "Haïti",
      en_name: "Haiti",
      native_name: "Haïti",
      capital: "Port-au-Prince",
      en_capital: "Port-au-Prince",
      alpha_2: "HT",
      alpha_3: "HTI",
      phone_code: "509",
    },
    honduras: {
      name: "Honduras",
      en_name: "Honduras",
      native_name: "Honduras",
      capital: "Tegucigalpa",
      en_capital: "Tegucigalpa",
      alpha_2: "HN",
      alpha_3: "HND",
      phone_code: "504",
    },
    hongkong: {
      name: "Hong Kong",
      en_name: "Hong Kong",
      native_name: "香港",
      capital: "La ville de Victoria",
      en_capital: "City of Victoria",
      alpha_2: "HK",
      alpha_3: "HKG",
      phone_code: "852",
    },
    hungary: {
      name: "Hongrie",
      en_name: "Hungary",
      native_name: "Magyarország",
      capital: "Budapest",
      en_capital: "Budapest",
      alpha_2: "HU",
      alpha_3: "HUN",
      phone_code: "36",
    },
    iceland: {
      name: "Islande",
      en_name: "Iceland",
      native_name: "Ísland",
      capital: "Reykjavik",
      en_capital: "Reykjavik",
      alpha_2: "IS",
      alpha_3: "ISL",
      phone_code: "354",
    },
    india: {
      name: "Inde",
      en_name: "India",
      native_name: "भारत",
      capital: "New Delhi",
      en_capital: "New Delhi",
      alpha_2: "IN",
      alpha_3: "IND",
      phone_code: "91",
    },
    indonesia: {
      name: "Indonésie",
      en_name: "Indonesia",
      native_name: "Indonesia",
      capital: "Jakarta",
      en_capital: "Jakarta",
      alpha_2: "ID",
      alpha_3: "IDN",
      phone_code: "62",
    },
    ivorycoast: {
      name: "Côte D'Ivoire",
      en_name: "Ivory Coast",
      native_name: "Côte d'Ivoire",
      capital: "Yamoussoukro",
      en_capital: "Yamoussoukro",
      alpha_2: "CI",
      alpha_3: "CIV",
      phone_code: "225",
    },
    iran: {
      name: "Iran",
      en_name: "Iran",
      native_name: "Irān",
      capital: "Téhéran",
      en_capital: "Tehran",
      alpha_2: "IR",
      alpha_3: "IRN",
      phone_code: "98",
    },
    iraq: {
      name: "Irak",
      en_name: "Iraq",
      native_name: "العراق",
      capital: "Bagdad",
      en_capital: "Baghdad",
      alpha_2: "IQ",
      alpha_3: "IRQ",
      phone_code: "964",
    },
    republicofireland: {
      name: "République d'Irlande",
      en_name: "Republic of Ireland",
      native_name: "Éire",
      capital: "Dublin",
      en_capital: "Dublin",
      alpha_2: "IE",
      alpha_3: "IRL",
      phone_code: "353",
    },
    isleofman: {
      name: "île de Man",
      en_name: "Isle of Man",
      native_name: "Isle of Man",
      capital: "Douglas",
      en_capital: "Douglas",
      alpha_2: "IM",
      alpha_3: "IMN",
      phone_code: "44",
    },
    israel: {
      name: "Israël",
      en_name: "Israel",
      native_name: "יִשְׂרָאֵל",
      capital: "Tel-Aviv",
      en_capital: "Tel Aviv",
      alpha_2: "IL",
      alpha_3: "ISR",
      phone_code: "972",
    },
    italy: {
      name: "Italie",
      en_name: "Italy",
      native_name: "Italia",
      capital: "Rome",
      en_capital: "Rome",
      alpha_2: "IT",
      alpha_3: "ITA",
      phone_code: "39",
    },
    jamaica: {
      name: "Jamaïque",
      en_name: "Jamaica",
      native_name: "Jamaica",
      capital: "Kingston",
      en_capital: "Kingston",
      alpha_2: "JM",
      alpha_3: "JAM",
      phone_code: "1876",
    },
    japan: {
      name: "Japon",
      en_name: "Japan",
      native_name: "日本",
      capital: "Tokyo",
      en_capital: "Tokyo",
      alpha_2: "JP",
      alpha_3: "JPN",
      phone_code: "81",
    },
    jordan: {
      name: "Jordanie",
      en_name: "Jordan",
      native_name: "الأردن",
      capital: "Amman",
      en_capital: "Amman",
      alpha_2: "JO",
      alpha_3: "JOR",
      phone_code: "962",
    },
    kazakhstan: {
      name: "Kazakhstan",
      en_name: "Kazakhstan",
      native_name: "Қазақстан",
      capital: "Astana",
      en_capital: "Astana",
      alpha_2: "KZ",
      alpha_3: "KAZ",
      phone_code: "76",
    },
    kenya: {
      name: "Kenya",
      en_name: "Kenya",
      native_name: "Kenya",
      capital: "Nairobi",
      en_capital: "Nairobi",
      alpha_2: "KE",
      alpha_3: "KEN",
      phone_code: "254",
    },
    kiribati: {
      name: "Kiribati",
      en_name: "Kiribati",
      native_name: "Kiribati",
      capital: "South Tarawa",
      en_capital: "South Tarawa",
      alpha_2: "KI",
      alpha_3: "KIR",
      phone_code: "686",
    },
    kuwait: {
      name: "Koweït",
      en_name: "Kuwait",
      native_name: "الكويت",
      capital: "La Ville De Koweït",
      en_capital: "Kuwait City",
      alpha_2: "KW",
      alpha_3: "KWT",
      phone_code: "965",
    },
    kyrgyzstan: {
      name: "Kirghizistan",
      en_name: "Kyrgyzstan",
      native_name: "Кыргызстан",
      capital: "Bichkek",
      en_capital: "Bishkek",
      alpha_2: "KG",
      alpha_3: "KGZ",
      phone_code: "996",
    },
    laos: {
      name: "Laos",
      en_name: "Laos",
      native_name: "ສປປລາວ",
      capital: "Vientiane",
      en_capital: "Vientiane",
      alpha_2: "LA",
      alpha_3: "LAO",
      phone_code: "856",
    },
    latvia: {
      name: "Lettonie",
      en_name: "Latvia",
      native_name: "Latvija",
      capital: "Riga",
      en_capital: "Riga",
      alpha_2: "LV",
      alpha_3: "LVA",
      phone_code: "371",
    },
    lebanon: {
      name: "Liban",
      en_name: "Lebanon",
      native_name: "لبنان",
      capital: "Beyrouth",
      en_capital: "Beirut",
      alpha_2: "LB",
      alpha_3: "LBN",
      phone_code: "961",
    },
    lesotho: {
      name: "Lesotho",
      en_name: "Lesotho",
      native_name: "Lesotho",
      capital: "Maseru",
      en_capital: "Maseru",
      alpha_2: "LS",
      alpha_3: "LSO",
      phone_code: "266",
    },
    liberia: {
      name: "Libéria",
      en_name: "Liberia",
      native_name: "Liberia",
      capital: "Monrovia",
      en_capital: "Monrovia",
      alpha_2: "LR",
      alpha_3: "LBR",
      phone_code: "231",
    },
    libya: {
      name: "Libye",
      en_name: "Libya",
      native_name: "\u200fليبيا",
      capital: "Tripoli",
      en_capital: "Tripoli",
      alpha_2: "LY",
      alpha_3: "LBY",
      phone_code: "218",
    },
    liechtenstein: {
      name: "Liechtenstein",
      en_name: "Liechtenstein",
      native_name: "Liechtenstein",
      capital: "Vaduz",
      en_capital: "Vaduz",
      alpha_2: "LI",
      alpha_3: "LIE",
      phone_code: "423",
    },
    lithuania: {
      name: "Lituanie",
      en_name: "Lithuania",
      native_name: "Lietuva",
      capital: "Vilnius",
      en_capital: "Vilnius",
      alpha_2: "LT",
      alpha_3: "LTU",
      phone_code: "370",
    },
    luxembourg: {
      name: "Luxembourg",
      en_name: "Luxembourg",
      native_name: "Luxembourg",
      capital: "Luxembourg",
      en_capital: "Luxembourg",
      alpha_2: "LU",
      alpha_3: "LUX",
      phone_code: "352",
    },
    republicofmacedonia: {
      name: "République de Macédoine",
      en_name: "Republic of Macedonia",
      native_name: "Македонија",
      capital: "Skopje",
      en_capital: "Skopje",
      alpha_2: "MK",
      alpha_3: "MKD",
      phone_code: "389",
    },
    madagascar: {
      name: "Madagascar",
      en_name: "Madagascar",
      native_name: "Madagasikara",
      capital: "Antananarivo",
      en_capital: "Antananarivo",
      alpha_2: "MG",
      alpha_3: "MDG",
      phone_code: "261",
    },
    malawi: {
      name: "Malawi",
      en_name: "Malawi",
      native_name: "Malawi",
      capital: "Lilongwe",
      en_capital: "Lilongwe",
      alpha_2: "MW",
      alpha_3: "MWI",
      phone_code: "265",
    },
    malaysia: {
      name: "Malaisie",
      en_name: "Malaysia",
      native_name: "Malaysia",
      capital: "Kuala Lumpur",
      en_capital: "Kuala Lumpur",
      alpha_2: "MY",
      alpha_3: "MYS",
      phone_code: "60",
    },
    maldives: {
      name: "Maldives",
      en_name: "Maldives",
      native_name: "Maldives",
      capital: "Malé",
      en_capital: "Malé",
      alpha_2: "MV",
      alpha_3: "MDV",
      phone_code: "960",
    },
    mali: {
      name: "Mali",
      en_name: "Mali",
      native_name: "Mali",
      capital: "Bamako",
      en_capital: "Bamako",
      alpha_2: "ML",
      alpha_3: "MLI",
      phone_code: "223",
    },
    malta: {
      name: "Malte",
      en_name: "Malta",
      native_name: "Malta",
      capital: "La valette",
      en_capital: "Valletta",
      alpha_2: "MT",
      alpha_3: "MLT",
      phone_code: "356",
    },
    marshallislands: {
      name: "Îles Marshall",
      en_name: "Marshall Islands",
      native_name: "M̧ajeļ",
      capital: "Majuro",
      en_capital: "Majuro",
      alpha_2: "MH",
      alpha_3: "MHL",
      phone_code: "692",
    },
    martinique: {
      name: "Martinique",
      en_name: "Martinique",
      native_name: "Martinique",
      capital: "Fort-de-France",
      en_capital: "Fort-de-France",
      alpha_2: "MQ",
      alpha_3: "MTQ",
      phone_code: "596",
    },
    mauritania: {
      name: "Mauritanie",
      en_name: "Mauritania",
      native_name: "موريتانيا",
      capital: "Nouakchott",
      en_capital: "Nouakchott",
      alpha_2: "MR",
      alpha_3: "MRT",
      phone_code: "222",
    },
    mauritius: {
      name: "île maurice",
      en_name: "Mauritius",
      native_name: "Maurice",
      capital: "Port-Louis",
      en_capital: "Port Louis",
      alpha_2: "MU",
      alpha_3: "MUS",
      phone_code: "230",
    },
    mayotte: {
      name: "Mayotte",
      en_name: "Mayotte",
      native_name: "Mayotte",
      capital: "Mamoudzou",
      en_capital: "Mamoudzou",
      alpha_2: "YT",
      alpha_3: "MYT",
      phone_code: "262",
    },
    mexico: {
      name: "Mexique",
      en_name: "Mexico",
      native_name: "México",
      capital: "La Ville De Mexico",
      en_capital: "Mexico City",
      alpha_2: "MX",
      alpha_3: "MEX",
      phone_code: "52",
    },
    federatedstatesofmicronesia: {
      name: "États fédérés de Micronésie",
      en_name: "Federated States of Micronesia",
      native_name: "Micronesia",
      capital: "Palikir",
      en_capital: "Palikir",
      alpha_2: "FM",
      alpha_3: "FSM",
      phone_code: "691",
    },
    moldova: {
      name: "Moldavie",
      en_name: "Moldova",
      native_name: "Moldova",
      capital: "Chișinău",
      en_capital: "Chișinău",
      alpha_2: "MD",
      alpha_3: "MDA",
      phone_code: "373",
    },
    monaco: {
      name: "Monaco",
      en_name: "Monaco",
      native_name: "Monaco",
      capital: "Monaco",
      en_capital: "Monaco",
      alpha_2: "MC",
      alpha_3: "MCO",
      phone_code: "377",
    },
    mongolia: {
      name: "Mongolie",
      en_name: "Mongolia",
      native_name: "Монгол улс",
      capital: "Oulan-Bator",
      en_capital: "Ulan Bator",
      alpha_2: "MN",
      alpha_3: "MNG",
      phone_code: "976",
    },
    montenegro: {
      name: "Monténégro",
      en_name: "Montenegro",
      native_name: "Црна Гора",
      capital: "Podgorica",
      en_capital: "Podgorica",
      alpha_2: "ME",
      alpha_3: "MNE",
      phone_code: "382",
    },
    montserrat: {
      name: "Montserrat",
      en_name: "Montserrat",
      native_name: "Montserrat",
      capital: "Plymouth",
      en_capital: "Plymouth",
      alpha_2: "MS",
      alpha_3: "MSR",
      phone_code: "1664",
    },
    morocco: {
      name: "Maroc",
      en_name: "Morocco",
      native_name: "المغرب",
      capital: "Rabat",
      en_capital: "Rabat",
      alpha_2: "MA",
      alpha_3: "MAR",
      phone_code: "212",
    },
    mozambique: {
      name: "Mozambique",
      en_name: "Mozambique",
      native_name: "Moçambique",
      capital: "Maputo",
      en_capital: "Maputo",
      alpha_2: "MZ",
      alpha_3: "MOZ",
      phone_code: "258",
    },
    myanmar: {
      name: "Myanmar",
      en_name: "Myanmar",
      native_name: "Myanma",
      capital: "Naypyidaw",
      en_capital: "Naypyidaw",
      alpha_2: "MM",
      alpha_3: "MMR",
      phone_code: "95",
    },
    namibia: {
      name: "Namibie",
      en_name: "Namibia",
      native_name: "Namibia",
      capital: "Windhoek",
      en_capital: "Windhoek",
      alpha_2: "NA",
      alpha_3: "NAM",
      phone_code: "264",
    },
    nauru: {
      name: "Nauru",
      en_name: "Nauru",
      native_name: "Nauru",
      capital: "Yaren",
      en_capital: "Yaren",
      alpha_2: "NR",
      alpha_3: "NRU",
      phone_code: "674",
    },
    nepal: {
      name: "Népal",
      en_name: "Nepal",
      native_name: "नपल",
      capital: "Katmandou",
      en_capital: "Kathmandu",
      alpha_2: "NP",
      alpha_3: "NPL",
      phone_code: "977",
    },
    netherlands: {
      name: "Pays-bas",
      en_name: "Netherlands",
      native_name: "Nederland",
      capital: "Amsterdam",
      en_capital: "Amsterdam",
      alpha_2: "NL",
      alpha_3: "NLD",
      phone_code: "31",
    },
    newcaledonia: {
      name: "Nouvelle-Calédonie",
      en_name: "New Caledonia",
      native_name: "Nouvelle-Calédonie",
      capital: "Nouméa",
      en_capital: "Nouméa",
      alpha_2: "NC",
      alpha_3: "NCL",
      phone_code: "687",
    },
    newzealand: {
      name: "Nouvelle-Zélande",
      en_name: "New Zealand",
      native_name: "New Zealand",
      capital: "Wellington",
      en_capital: "Wellington",
      alpha_2: "NZ",
      alpha_3: "NZL",
      phone_code: "64",
    },
    nicaragua: {
      name: "Nicaragua",
      en_name: "Nicaragua",
      native_name: "Nicaragua",
      capital: "Managua",
      en_capital: "Managua",
      alpha_2: "NI",
      alpha_3: "NIC",
      phone_code: "505",
    },
    niger: {
      name: "Niger",
      en_name: "Niger",
      native_name: "Niger",
      capital: "Niamey",
      en_capital: "Niamey",
      alpha_2: "NE",
      alpha_3: "NER",
      phone_code: "227",
    },
    nigeria: {
      name: "Nigeria",
      en_name: "Nigeria",
      native_name: "Nigeria",
      capital: "Abuja",
      en_capital: "Abuja",
      alpha_2: "NG",
      alpha_3: "NGA",
      phone_code: "234",
    },
    niue: {
      name: "Niue",
      en_name: "Niue",
      native_name: "Niuē",
      capital: "Alofi",
      en_capital: "Alofi",
      alpha_2: "NU",
      alpha_3: "NIU",
      phone_code: "683",
    },
    norfolkisland: {
      name: "Île De Norfolk",
      en_name: "Norfolk Island",
      native_name: "Norfolk Island",
      capital: "Kingston",
      en_capital: "Kingston",
      alpha_2: "NF",
      alpha_3: "NFK",
      phone_code: "672",
    },
    northkorea: {
      name: "Corée Du Nord",
      en_name: "North Korea",
      native_name: "북한",
      capital: "Pyongyang",
      en_capital: "Pyongyang",
      alpha_2: "KP",
      alpha_3: "PRK",
      phone_code: "850",
    },
    northernmarianaislands: {
      name: "Îles Mariannes Du Nord",
      en_name: "Northern Mariana Islands",
      native_name: "Northern Mariana Islands",
      capital: "Saipan",
      en_capital: "Saipan",
      alpha_2: "MP",
      alpha_3: "MNP",
      phone_code: "1670",
    },
    norway: {
      name: "Norvège",
      en_name: "Norway",
      native_name: "Norge",
      capital: "Oslo",
      en_capital: "Oslo",
      alpha_2: "NO",
      alpha_3: "NOR",
      phone_code: "47",
    },
    oman: {
      name: "Oman",
      en_name: "Oman",
      native_name: "عمان",
      capital: "Muscat",
      en_capital: "Muscat",
      alpha_2: "OM",
      alpha_3: "OMN",
      phone_code: "968",
    },
    pakistan: {
      name: "Pakistan",
      en_name: "Pakistan",
      native_name: "Pakistan",
      capital: "Islamabad",
      en_capital: "Islamabad",
      alpha_2: "PK",
      alpha_3: "PAK",
      phone_code: "92",
    },
    palau: {
      name: "Palau",
      en_name: "Palau",
      native_name: "Palau",
      capital: "Ngerulmud",
      en_capital: "Ngerulmud",
      alpha_2: "PW",
      alpha_3: "PLW",
      phone_code: "680",
    },
    palestine: {
      name: "Palestine",
      en_name: "Palestine",
      native_name: "فلسطين",
      capital: "Jérusalem",
      en_capital: "Jerusalem",
      alpha_2: "PS",
      alpha_3: "PSE",
      phone_code: "970",
    },
    panama: {
      name: "Panama",
      en_name: "Panama",
      native_name: "Panamá",
      capital: "Ville De Panama",
      en_capital: "Panama City",
      alpha_2: "PA",
      alpha_3: "PAN",
      phone_code: "507",
    },
    papuanewguinea: {
      name: "Papouasie-Nouvelle-Guinée",
      en_name: "Papua New Guinea",
      native_name: "Papua Niugini",
      capital: "Port Moresby",
      en_capital: "Port Moresby",
      alpha_2: "PG",
      alpha_3: "PNG",
      phone_code: "675",
    },
    paraguay: {
      name: "Paraguay",
      en_name: "Paraguay",
      native_name: "Paraguay",
      capital: "Asunción",
      en_capital: "Asunción",
      alpha_2: "PY",
      alpha_3: "PRY",
      phone_code: "595",
    },
    peru: {
      name: "Pérou",
      en_name: "Peru",
      native_name: "Perú",
      capital: "Lima",
      en_capital: "Lima",
      alpha_2: "PE",
      alpha_3: "PER",
      phone_code: "51",
    },
    philippines: {
      name: "Philippines",
      en_name: "Philippines",
      native_name: "Pilipinas",
      capital: "Manille",
      en_capital: "Manila",
      alpha_2: "PH",
      alpha_3: "PHL",
      phone_code: "63",
    },
    pitcairnislands: {
      name: "Îles Pitcairn",
      en_name: "Pitcairn Islands",
      native_name: "Pitcairn Islands",
      capital: "Adamstown",
      en_capital: "Adamstown",
      alpha_2: "PN",
      alpha_3: "PCN",
      phone_code: "64",
    },
    poland: {
      name: "Pologne",
      en_name: "Poland",
      native_name: "Polska",
      capital: "Varsovie",
      en_capital: "Warsaw",
      alpha_2: "PL",
      alpha_3: "POL",
      phone_code: "48",
    },
    portugal: {
      name: "Portugal",
      en_name: "Portugal",
      native_name: "Portugal",
      capital: "Lisbonne",
      en_capital: "Lisbon",
      alpha_2: "PT",
      alpha_3: "PRT",
      phone_code: "351",
    },
    puertorico: {
      name: "Puerto Rico",
      en_name: "Puerto Rico",
      native_name: "Puerto Rico",
      capital: "San Juan",
      en_capital: "San Juan",
      alpha_2: "PR",
      alpha_3: "PRI",
      phone_code: "1787",
    },
    qatar: {
      name: "Qatar",
      en_name: "Qatar",
      native_name: "قطر",
      capital: "Doha",
      en_capital: "Doha",
      alpha_2: "QA",
      alpha_3: "QAT",
      phone_code: "974",
    },
    republicofkosovo: {
      name: "République du Kosovo",
      en_name: "Republic of Kosovo",
      native_name: "Republika e Kosovës",
      capital: "Pristina",
      en_capital: "Pristina",
      alpha_2: "XK",
      alpha_3: "KOS",
      phone_code: "383",
    },
    réunion: {
      name: "Réunion",
      en_name: "Réunion",
      native_name: "La Réunion",
      capital: "Saint-Denis",
      en_capital: "Saint-Denis",
      alpha_2: "RE",
      alpha_3: "REU",
      phone_code: "262",
    },
    romania: {
      name: "Roumanie",
      en_name: "Romania",
      native_name: "România",
      capital: "Bucarest",
      en_capital: "Bucharest",
      alpha_2: "RO",
      alpha_3: "ROU",
      phone_code: "40",
    },
    russia: {
      name: "Russie",
      en_name: "Russia",
      native_name: "Россия",
      capital: "Moscou",
      en_capital: "Moscow",
      alpha_2: "RU",
      alpha_3: "RUS",
      phone_code: "7",
    },
    rwanda: {
      name: "Rwanda",
      en_name: "Rwanda",
      native_name: "Rwanda",
      capital: "Kigali",
      en_capital: "Kigali",
      alpha_2: "RW",
      alpha_3: "RWA",
      phone_code: "250",
    },
    saintbarthélemy: {
      name: "Saint-Barthélemy",
      en_name: "Saint Barthélemy",
      native_name: "Saint-Barthélemy",
      capital: "Gustavia",
      en_capital: "Gustavia",
      alpha_2: "BL",
      alpha_3: "BLM",
      phone_code: "590",
    },
    saintkittsandnevis: {
      name: "Saint-Kitts-et-Nevis",
      en_name: "Saint Kitts and Nevis",
      native_name: "Saint Kitts and Nevis",
      capital: "Basseterre",
      en_capital: "Basseterre",
      alpha_2: "KN",
      alpha_3: "KNA",
      phone_code: "1869",
    },
    saintlucia: {
      name: "Sainte-Lucie",
      en_name: "Saint Lucia",
      native_name: "Saint Lucia",
      capital: "Castries",
      en_capital: "Castries",
      alpha_2: "LC",
      alpha_3: "LCA",
      phone_code: "1758",
    },
    saintmartin: {
      name: "Saint Martin",
      en_name: "Saint Martin",
      native_name: "Saint-Martin",
      capital: "Marigot",
      en_capital: "Marigot",
      alpha_2: "MF",
      alpha_3: "MAF",
      phone_code: "590",
    },
    saintpierreandmiquelon: {
      name: "Saint-Pierre-et-Miquelon",
      en_name: "Saint Pierre and Miquelon",
      native_name: "Saint-Pierre-et-Miquelon",
      capital: "Saint-Pierre",
      en_capital: "Saint-Pierre",
      alpha_2: "PM",
      alpha_3: "SPM",
      phone_code: "508",
    },
    saintvincentandthegrenadines: {
      name: "Saint-Vincent-et-les-Grenadines",
      en_name: "Saint Vincent and the Grenadines",
      native_name: "Saint Vincent and the Grenadines",
      capital: "Kingstown",
      en_capital: "Kingstown",
      alpha_2: "VC",
      alpha_3: "VCT",
      phone_code: "1784",
    },
    samoa: {
      name: "Samoa",
      en_name: "Samoa",
      native_name: "Samoa",
      capital: "Apia",
      en_capital: "Apia",
      alpha_2: "WS",
      alpha_3: "WSM",
      phone_code: "685",
    },
    sanmarino: {
      name: "San Marino",
      en_name: "San Marino",
      native_name: "San Marino",
      capital: "Ville de saint-Marin",
      en_capital: "City of San Marino",
      alpha_2: "SM",
      alpha_3: "SMR",
      phone_code: "378",
    },
    sãotoméandpríncipe: {
      name: "São Tomé et Príncipe",
      en_name: "São Tomé and Príncipe",
      native_name: "São Tomé e Príncipe",
      capital: "São Tomé",
      en_capital: "São Tomé",
      alpha_2: "ST",
      alpha_3: "STP",
      phone_code: "239",
    },
    saudiarabia: {
      name: "Arabie Saoudite",
      en_name: "Saudi Arabia",
      native_name: "العربية السعودية",
      capital: "Riyad",
      en_capital: "Riyadh",
      alpha_2: "SA",
      alpha_3: "SAU",
      phone_code: "966",
    },
    senegal: {
      name: "Sénégal",
      en_name: "Senegal",
      native_name: "Sénégal",
      capital: "Dakar",
      en_capital: "Dakar",
      alpha_2: "SN",
      alpha_3: "SEN",
      phone_code: "221",
    },
    serbia: {
      name: "Serbie",
      en_name: "Serbia",
      native_name: "Србија",
      capital: "Belgrade",
      en_capital: "Belgrade",
      alpha_2: "RS",
      alpha_3: "SRB",
      phone_code: "381",
    },
    seychelles: {
      name: "Seychelles",
      en_name: "Seychelles",
      native_name: "Seychelles",
      capital: "Victoria",
      en_capital: "Victoria",
      alpha_2: "SC",
      alpha_3: "SYC",
      phone_code: "248",
    },
    sierraleone: {
      name: "Sierra Leone",
      en_name: "Sierra Leone",
      native_name: "Sierra Leone",
      capital: "Freetown",
      en_capital: "Freetown",
      alpha_2: "SL",
      alpha_3: "SLE",
      phone_code: "232",
    },
    singapore: {
      name: "Singapour",
      en_name: "Singapore",
      native_name: "Singapore",
      capital: "Singapour",
      en_capital: "Singapore",
      alpha_2: "SG",
      alpha_3: "SGP",
      phone_code: "65",
    },
    sintmaarten: {
      name: "Sint Maarten",
      en_name: "Sint Maarten",
      native_name: "Sint Maarten",
      capital: "Philipsburg",
      en_capital: "Philipsburg",
      alpha_2: "SX",
      alpha_3: "SXM",
      phone_code: "1721",
    },
    slovakia: {
      name: "Slovaquie",
      en_name: "Slovakia",
      native_name: "Slovensko",
      capital: "Bratislava",
      en_capital: "Bratislava",
      alpha_2: "SK",
      alpha_3: "SVK",
      phone_code: "421",
    },
    slovenia: {
      name: "Slovénie",
      en_name: "Slovenia",
      native_name: "Slovenija",
      capital: "Ljubljana",
      en_capital: "Ljubljana",
      alpha_2: "SI",
      alpha_3: "SVN",
      phone_code: "386",
    },
    solomonislands: {
      name: "Îles Salomon",
      en_name: "Solomon Islands",
      native_name: "Solomon Islands",
      capital: "Honiara",
      en_capital: "Honiara",
      alpha_2: "SB",
      alpha_3: "SLB",
      phone_code: "677",
    },
    somalia: {
      name: "Somalie",
      en_name: "Somalia",
      native_name: "Soomaaliya",
      capital: "Mogadiscio",
      en_capital: "Mogadishu",
      alpha_2: "SO",
      alpha_3: "SOM",
      phone_code: "252",
    },
    southafrica: {
      name: "Afrique Du Sud",
      en_name: "South Africa",
      native_name: "South Africa",
      capital: "Pretoria",
      en_capital: "Pretoria",
      alpha_2: "ZA",
      alpha_3: "ZAF",
      phone_code: "27",
    },
    southgeorgia: {
      name: "Géorgie Du Sud",
      en_name: "South Georgia",
      native_name: "South Georgia",
      capital: "King Edward Point",
      en_capital: "King Edward Point",
      alpha_2: "GS",
      alpha_3: "SGS",
      phone_code: "500",
    },
    southkorea: {
      name: "Corée Du Sud",
      en_name: "South Korea",
      native_name: "대한민국",
      capital: "Séoul",
      en_capital: "Seoul",
      alpha_2: "KR",
      alpha_3: "KOR",
      phone_code: "82",
    },
    southsudan: {
      name: "Soudan Du Sud",
      en_name: "South Sudan",
      native_name: "South Sudan",
      capital: "Juba",
      en_capital: "Juba",
      alpha_2: "SS",
      alpha_3: "SSD",
      phone_code: "211",
    },
    spain: {
      name: "Espagne",
      en_name: "Spain",
      native_name: "España",
      capital: "Madrid",
      en_capital: "Madrid",
      alpha_2: "ES",
      alpha_3: "ESP",
      phone_code: "34",
    },
    srilanka: {
      name: "Sri Lanka",
      en_name: "Sri Lanka",
      native_name: "śrī laṃkāva",
      capital: "Colombo",
      en_capital: "Colombo",
      alpha_2: "LK",
      alpha_3: "LKA",
      phone_code: "94",
    },
    sudan: {
      name: "Soudan",
      en_name: "Sudan",
      native_name: "السودان",
      capital: "Khartoum",
      en_capital: "Khartoum",
      alpha_2: "SD",
      alpha_3: "SDN",
      phone_code: "249",
    },
    suriname: {
      name: "Suriname",
      en_name: "Suriname",
      native_name: "Suriname",
      capital: "Paramaribo",
      en_capital: "Paramaribo",
      alpha_2: "SR",
      alpha_3: "SUR",
      phone_code: "597",
    },
    svalbardandjanmayen: {
      name: "Svalbard et Jan Mayen",
      en_name: "Svalbard and Jan Mayen",
      native_name: "Svalbard og Jan Mayen",
      capital: "Longyearbyen",
      en_capital: "Longyearbyen",
      alpha_2: "SJ",
      alpha_3: "SJM",
      phone_code: "4779",
    },
    swaziland: {
      name: "Swaziland",
      en_name: "Swaziland",
      native_name: "Swaziland",
      capital: "Lobamba",
      en_capital: "Lobamba",
      alpha_2: "SZ",
      alpha_3: "SWZ",
      phone_code: "268",
    },
    sweden: {
      name: "Suède",
      en_name: "Sweden",
      native_name: "Sverige",
      capital: "Stockholm",
      en_capital: "Stockholm",
      alpha_2: "SE",
      alpha_3: "SWE",
      phone_code: "46",
    },
    switzerland: {
      name: "Suisse",
      en_name: "Switzerland",
      native_name: "Schweiz",
      capital: "Berne",
      en_capital: "Bern",
      alpha_2: "CH",
      alpha_3: "CHE",
      phone_code: "41",
    },
    syria: {
      name: "Syrie",
      en_name: "Syria",
      native_name: "سوريا",
      capital: "Damas",
      en_capital: "Damascus",
      alpha_2: "SY",
      alpha_3: "SYR",
      phone_code: "963",
    },
    taiwan: {
      name: "Taiwan",
      en_name: "Taiwan",
      native_name: "臺灣",
      capital: "Taipei",
      en_capital: "Taipei",
      alpha_2: "TW",
      alpha_3: "TWN",
      phone_code: "886",
    },
    tajikistan: {
      name: "Tadjikistan",
      en_name: "Tajikistan",
      native_name: "Тоҷикистон",
      capital: "Douchanbé",
      en_capital: "Dushanbe",
      alpha_2: "TJ",
      alpha_3: "TJK",
      phone_code: "992",
    },
    tanzania: {
      name: "Tanzanie",
      en_name: "Tanzania",
      native_name: "Tanzania",
      capital: "Dodoma",
      en_capital: "Dodoma",
      alpha_2: "TZ",
      alpha_3: "TZA",
      phone_code: "255",
    },
    thailand: {
      name: "Thaïlande",
      en_name: "Thailand",
      native_name: "ประเทศไทย",
      capital: "Bangkok",
      en_capital: "Bangkok",
      alpha_2: "TH",
      alpha_3: "THA",
      phone_code: "66",
    },
    easttimor: {
      name: "Timor Oriental",
      en_name: "East Timor",
      native_name: "Timor-Leste",
      capital: "Dili",
      en_capital: "Dili",
      alpha_2: "TL",
      alpha_3: "TLS",
      phone_code: "670",
    },
    togo: {
      name: "Togo",
      en_name: "Togo",
      native_name: "Togo",
      capital: "Lomé",
      en_capital: "Lomé",
      alpha_2: "TG",
      alpha_3: "TGO",
      phone_code: "228",
    },
    tokelau: {
      name: "Tokelau",
      en_name: "Tokelau",
      native_name: "Tokelau",
      capital: "Fakaofo",
      en_capital: "Fakaofo",
      alpha_2: "TK",
      alpha_3: "TKL",
      phone_code: "690",
    },
    tonga: {
      name: "Tonga",
      en_name: "Tonga",
      native_name: "Tonga",
      capital: "Nuku'alofa",
      en_capital: "Nuku'alofa",
      alpha_2: "TO",
      alpha_3: "TON",
      phone_code: "676",
    },
    trinidadandtobago: {
      name: "Trinité-et-Tobago",
      en_name: "Trinidad and Tobago",
      native_name: "Trinidad and Tobago",
      capital: "Port d'Espagne",
      en_capital: "Port of Spain",
      alpha_2: "TT",
      alpha_3: "TTO",
      phone_code: "1868",
    },
    tunisia: {
      name: "Tunisie",
      en_name: "Tunisia",
      native_name: "تونس",
      capital: "Tunis",
      en_capital: "Tunis",
      alpha_2: "TN",
      alpha_3: "TUN",
      phone_code: "216",
    },
    turkey: {
      name: "Turquie",
      en_name: "Turkey",
      native_name: "Türkiye",
      capital: "Ankara",
      en_capital: "Ankara",
      alpha_2: "TR",
      alpha_3: "TUR",
      phone_code: "90",
    },
    turkmenistan: {
      name: "Turkménistan",
      en_name: "Turkmenistan",
      native_name: "Türkmenistan",
      capital: "Ashgabat",
      en_capital: "Ashgabat",
      alpha_2: "TM",
      alpha_3: "TKM",
      phone_code: "993",
    },
    turksandcaicosislands: {
      name: "Îles turques et Caïques",
      en_name: "Turks and Caicos Islands",
      native_name: "Turks and Caicos Islands",
      capital: "Cockburn Town",
      en_capital: "Cockburn Town",
      alpha_2: "TC",
      alpha_3: "TCA",
      phone_code: "1649",
    },
    tuvalu: {
      name: "Tuvalu",
      en_name: "Tuvalu",
      native_name: "Tuvalu",
      capital: "Funafuti",
      en_capital: "Funafuti",
      alpha_2: "TV",
      alpha_3: "TUV",
      phone_code: "688",
    },
    uganda: {
      name: "Ouganda",
      en_name: "Uganda",
      native_name: "Uganda",
      capital: "Kampala",
      en_capital: "Kampala",
      alpha_2: "UG",
      alpha_3: "UGA",
      phone_code: "256",
    },
    ukraine: {
      name: "Ukraine",
      en_name: "Ukraine",
      native_name: "Україна",
      capital: "Kiev",
      en_capital: "Kiev",
      alpha_2: "UA",
      alpha_3: "UKR",
      phone_code: "380",
    },
    unitedarabemirates: {
      name: "Émirats Arabes Unis",
      en_name: "United Arab Emirates",
      native_name: "دولة الإمارات العربية المتحدة",
      capital: "Abu Dhabi",
      en_capital: "Abu Dhabi",
      alpha_2: "AE",
      alpha_3: "ARE",
      phone_code: "971",
    },
    unitedkingdom: {
      name: "Royaume-Uni",
      en_name: "United Kingdom",
      native_name: "United Kingdom",
      capital: "Londres",
      en_capital: "London",
      alpha_2: "GB",
      alpha_3: "GBR",
      phone_code: "44",
    },
    unitedstates: {
      name: "États-Unis",
      en_name: "United States",
      native_name: "United States",
      capital: "Washington DC",
      en_capital: "Washington D.C.",
      alpha_2: "US",
      alpha_3: "USA",
      phone_code: "1",
    },
    uruguay: {
      name: "Uruguay",
      en_name: "Uruguay",
      native_name: "Uruguay",
      capital: "Montevideo",
      en_capital: "Montevideo",
      alpha_2: "UY",
      alpha_3: "URY",
      phone_code: "598",
    },
    uzbekistan: {
      name: "Ouzbékistan",
      en_name: "Uzbekistan",
      native_name: "O‘zbekiston",
      capital: "Tachkent",
      en_capital: "Tashkent",
      alpha_2: "UZ",
      alpha_3: "UZB",
      phone_code: "998",
    },
    vanuatu: {
      name: "Vanuatu",
      en_name: "Vanuatu",
      native_name: "Vanuatu",
      capital: "Port Vila",
      en_capital: "Port Vila",
      alpha_2: "VU",
      alpha_3: "VUT",
      phone_code: "678",
    },
    venezuela: {
      name: "Venezuela",
      en_name: "Venezuela",
      native_name: "Venezuela",
      capital: "Caracas",
      en_capital: "Caracas",
      alpha_2: "VE",
      alpha_3: "VEN",
      phone_code: "58",
    },
    vietnam: {
      name: "Vietnam",
      en_name: "Vietnam",
      native_name: "Việt Nam",
      capital: "Hanoi",
      en_capital: "Hanoi",
      alpha_2: "VN",
      alpha_3: "VNM",
      phone_code: "84",
    },
    wallisandfutuna: {
      name: "Wallis-et-Futuna",
      en_name: "Wallis and Futuna",
      native_name: "Wallis et Futuna",
      capital: "Mata-Utu",
      en_capital: "Mata-Utu",
      alpha_2: "WF",
      alpha_3: "WLF",
      phone_code: "681",
    },
    westernsahara: {
      name: "Sahara Occidental",
      en_name: "Western Sahara",
      native_name: "الصحراء الغربية",
      capital: "El Aaiun",
      en_capital: "El Aaiún",
      alpha_2: "EH",
      alpha_3: "ESH",
      phone_code: "212",
    },
    yemen: {
      name: "Yémen",
      en_name: "Yemen",
      native_name: "اليَمَن",
      capital: "Sana'a",
      en_capital: "Sana'a",
      alpha_2: "YE",
      alpha_3: "YEM",
      phone_code: "967",
    },
    zambia: {
      name: "Zambie",
      en_name: "Zambia",
      native_name: "Zambia",
      capital: "Lusaka",
      en_capital: "Lusaka",
      alpha_2: "ZM",
      alpha_3: "ZMB",
      phone_code: "260",
    },
    zimbabwe: {
      name: "Zimbabwe",
      en_name: "Zimbabwe",
      native_name: "Zimbabwe",
      capital: "Harare",
      en_capital: "Harare",
      alpha_2: "ZW",
      alpha_3: "ZWE",
      phone_code: "263",
    },
    other: {
      name: "Autre",
      en_name: "Other",
      native_name: "Other",
      alpha_2: "AA",
      alpha_3: "AAA",
      phone_code: "000",
    },
  },
  pt: {
    afghanistan: {
      name: "Afeganistão",
      en_name: "Afghanistan",
      native_name: "افغانستان",
      capital: "Cabul",
      en_capital: "Kabul",
      alpha_2: "AF",
      alpha_3: "AFG",
      phone_code: "93",
    },
    Ålandislands: {
      name: "Ilhas Aland",
      en_name: "Åland Islands",
      native_name: "Åland",
      capital: "Mariehamn",
      en_capital: "Mariehamn",
      alpha_2: "AX",
      alpha_3: "ALA",
      phone_code: "358",
    },
    albania: {
      name: "Albânia",
      en_name: "Albania",
      native_name: "Shqipëria",
      capital: "Tirana",
      en_capital: "Tirana",
      alpha_2: "AL",
      alpha_3: "ALB",
      phone_code: "355",
    },
    algeria: {
      name: "Argélia",
      en_name: "Algeria",
      native_name: "الجزائر",
      capital: "Argel",
      en_capital: "Algiers",
      alpha_2: "DZ",
      alpha_3: "DZA",
      phone_code: "213",
    },
    americansamoa: {
      name: "Samoa Americana",
      en_name: "American Samoa",
      native_name: "American Samoa",
      capital: "Pago Pago",
      en_capital: "Pago Pago",
      alpha_2: "AS",
      alpha_3: "ASM",
      phone_code: "1684",
    },
    andorra: {
      name: "Andorra",
      en_name: "Andorra",
      native_name: "Andorra",
      capital: "Andorra la Vella",
      en_capital: "Andorra la Vella",
      alpha_2: "AD",
      alpha_3: "AND",
      phone_code: "376",
    },
    angola: {
      name: "Angola",
      en_name: "Angola",
      native_name: "Angola",
      capital: "Luanda",
      en_capital: "Luanda",
      alpha_2: "AO",
      alpha_3: "AGO",
      phone_code: "244",
    },
    anguilla: {
      name: "Anguilla",
      en_name: "Anguilla",
      native_name: "Anguilla",
      capital: "Vale",
      en_capital: "The Valley",
      alpha_2: "AI",
      alpha_3: "AIA",
      phone_code: "1264",
    },
    antiguaandbarbuda: {
      name: "Antígua e Barbuda",
      en_name: "Antigua and Barbuda",
      native_name: "Antigua and Barbuda",
      capital: "São João",
      en_capital: "Saint John's",
      alpha_2: "AG",
      alpha_3: "ATG",
      phone_code: "1268",
    },
    argentina: {
      name: "Argentina",
      en_name: "Argentina",
      native_name: "Argentina",
      capital: "Buenos Aires",
      en_capital: "Buenos Aires",
      alpha_2: "AR",
      alpha_3: "ARG",
      phone_code: "54",
    },
    armenia: {
      name: "Arménia",
      en_name: "Armenia",
      native_name: "Հայաստան",
      capital: "Yerevan",
      en_capital: "Yerevan",
      alpha_2: "AM",
      alpha_3: "ARM",
      phone_code: "374",
    },
    aruba: {
      name: "Aruba",
      en_name: "Aruba",
      native_name: "Aruba",
      capital: "Oranjestad",
      en_capital: "Oranjestad",
      alpha_2: "AW",
      alpha_3: "ABW",
      phone_code: "297",
    },
    australia: {
      name: "Austrália",
      en_name: "Australia",
      native_name: "Australia",
      capital: "Canberra",
      en_capital: "Canberra",
      alpha_2: "AU",
      alpha_3: "AUS",
      phone_code: "61",
    },
    austria: {
      name: "Áustria",
      en_name: "Austria",
      native_name: "Österreich",
      capital: "Viena",
      en_capital: "Vienna",
      alpha_2: "AT",
      alpha_3: "AUT",
      phone_code: "43",
    },
    azerbaijan: {
      name: "Azerbaijão",
      en_name: "Azerbaijan",
      native_name: "Azərbaycan",
      capital: "Baku",
      en_capital: "Baku",
      alpha_2: "AZ",
      alpha_3: "AZE",
      phone_code: "994",
    },
    thebahamas: {
      name: "O Bahamas",
      en_name: "The Bahamas",
      native_name: "Bahamas",
      capital: "Nassau",
      en_capital: "Nassau",
      alpha_2: "BS",
      alpha_3: "BHS",
      phone_code: "1242",
    },
    bahrain: {
      name: "Bahrein",
      en_name: "Bahrain",
      native_name: "\u200fالبحرين",
      capital: "Manama",
      en_capital: "Manama",
      alpha_2: "BH",
      alpha_3: "BHR",
      phone_code: "973",
    },
    bangladesh: {
      name: "Bangladesh",
      en_name: "Bangladesh",
      native_name: "Bangladesh",
      capital: "Dhaka",
      en_capital: "Dhaka",
      alpha_2: "BD",
      alpha_3: "BGD",
      phone_code: "880",
    },
    barbados: {
      name: "Barbados",
      en_name: "Barbados",
      native_name: "Barbados",
      capital: "Bridgetown",
      en_capital: "Bridgetown",
      alpha_2: "BB",
      alpha_3: "BRB",
      phone_code: "1246",
    },
    belarus: {
      name: "Bielorrússia",
      en_name: "Belarus",
      native_name: "Белару́сь",
      capital: "Minsk",
      en_capital: "Minsk",
      alpha_2: "BY",
      alpha_3: "BLR",
      phone_code: "375",
    },
    belgium: {
      name: "Bélgica",
      en_name: "Belgium",
      native_name: "België",
      capital: "Bruxelas",
      en_capital: "Brussels",
      alpha_2: "BE",
      alpha_3: "BEL",
      phone_code: "32",
    },
    belize: {
      name: "Belize",
      en_name: "Belize",
      native_name: "Belize",
      capital: "Belmopan",
      en_capital: "Belmopan",
      alpha_2: "BZ",
      alpha_3: "BLZ",
      phone_code: "501",
    },
    benin: {
      name: "Benin",
      en_name: "Benin",
      native_name: "Bénin",
      capital: "Porto Novo",
      en_capital: "Porto-Novo",
      alpha_2: "BJ",
      alpha_3: "BEN",
      phone_code: "229",
    },
    bermuda: {
      name: "Bermudas",
      en_name: "Bermuda",
      native_name: "Bermuda",
      capital: "Hamilton",
      en_capital: "Hamilton",
      alpha_2: "BM",
      alpha_3: "BMU",
      phone_code: "1441",
    },
    bhutan: {
      name: "Butão",
      en_name: "Bhutan",
      native_name: "ʼbrug-yul",
      capital: "Thimphu",
      en_capital: "Thimphu",
      alpha_2: "BT",
      alpha_3: "BTN",
      phone_code: "975",
    },
    bolivia: {
      name: "Bolívia",
      en_name: "Bolivia",
      native_name: "Bolivia",
      capital: "Sucre",
      en_capital: "Sucre",
      alpha_2: "BO",
      alpha_3: "BOL",
      phone_code: "591",
    },
    bonaire: {
      name: "Bonaire",
      en_name: "Bonaire",
      native_name: "Bonaire",
      capital: "Kralendijk",
      en_capital: "Kralendijk",
      alpha_2: "BQ",
      alpha_3: "BES",
      phone_code: "5997",
    },
    botswana: {
      name: "Botswana",
      en_name: "Botswana",
      native_name: "Botswana",
      capital: "Gaborone",
      en_capital: "Gaborone",
      alpha_2: "BW",
      alpha_3: "BWA",
      phone_code: "267",
    },
    brazil: {
      name: "Brasil",
      en_name: "Brazil",
      native_name: "Brasil",
      capital: "Brasília",
      en_capital: "Brasília",
      alpha_2: "BR",
      alpha_3: "BRA",
      phone_code: "55",
    },
    britishindianoceanterritory: {
      name: "Território Britânico Do Oceano Índico",
      en_name: "British Indian Ocean Territory",
      native_name: "British Indian Ocean Territory",
      capital: "Diego Garcia",
      en_capital: "Diego Garcia",
      alpha_2: "IO",
      alpha_3: "IOT",
      phone_code: "246",
    },
    britishvirginislands: {
      name: "Ilhas Virgens Britânicas",
      en_name: "British Virgin Islands",
      native_name: "British Virgin Islands",
      capital: "Cidade Estrada",
      en_capital: "Road Town",
      alpha_2: "VG",
      alpha_3: "VGB",
      phone_code: "1284",
    },
    brunei: {
      name: "Brunei",
      en_name: "Brunei",
      native_name: "Negara Brunei Darussalam",
      capital: "Bandar Seri Begawan",
      en_capital: "Bandar Seri Begawan",
      alpha_2: "BN",
      alpha_3: "BRN",
      phone_code: "673",
    },
    bulgaria: {
      name: "Bulgária",
      en_name: "Bulgaria",
      native_name: "България",
      capital: "Sofia",
      en_capital: "Sofia",
      alpha_2: "BG",
      alpha_3: "BGR",
      phone_code: "359",
    },
    burkinafaso: {
      name: "Burkina Faso",
      en_name: "Burkina Faso",
      native_name: "Burkina Faso",
      capital: "Ouagadougou",
      en_capital: "Ouagadougou",
      alpha_2: "BF",
      alpha_3: "BFA",
      phone_code: "226",
    },
    burundi: {
      name: "Burundi",
      en_name: "Burundi",
      native_name: "Burundi",
      capital: "Bujumbura",
      en_capital: "Bujumbura",
      alpha_2: "BI",
      alpha_3: "BDI",
      phone_code: "257",
    },
    cambodia: {
      name: "Camboja",
      en_name: "Cambodia",
      native_name: "Kâmpŭchéa",
      capital: "Phnom Penh",
      en_capital: "Phnom Penh",
      alpha_2: "KH",
      alpha_3: "KHM",
      phone_code: "855",
    },
    cameroon: {
      name: "Camarões",
      en_name: "Cameroon",
      native_name: "Cameroon",
      capital: "Yaoundé",
      en_capital: "Yaoundé",
      alpha_2: "CM",
      alpha_3: "CMR",
      phone_code: "237",
    },
    canada: {
      name: "Canadá",
      en_name: "Canada",
      native_name: "Canada",
      capital: "Ottawa",
      en_capital: "Ottawa",
      alpha_2: "CA",
      alpha_3: "CAN",
      phone_code: "1",
    },
    capeverde: {
      name: "Cabo Verde",
      en_name: "Cape Verde",
      native_name: "Cabo Verde",
      capital: "Praia",
      en_capital: "Praia",
      alpha_2: "CV",
      alpha_3: "CPV",
      phone_code: "238",
    },
    caymanislands: {
      name: "Ilhas Cayman",
      en_name: "Cayman Islands",
      native_name: "Cayman Islands",
      capital: "George Town",
      en_capital: "George Town",
      alpha_2: "KY",
      alpha_3: "CYM",
      phone_code: "1345",
    },
    centralafricanrepublic: {
      name: "Central Africano República",
      en_name: "Central African Republic",
      native_name: "Ködörösêse tî Bêafrîka",
      capital: "Bangui",
      en_capital: "Bangui",
      alpha_2: "CF",
      alpha_3: "CAF",
      phone_code: "236",
    },
    chad: {
      name: "Chade",
      en_name: "Chad",
      native_name: "Tchad",
      capital: "N'Djamena",
      en_capital: "N'Djamena",
      alpha_2: "TD",
      alpha_3: "TCD",
      phone_code: "235",
    },
    chile: {
      name: "Chile",
      en_name: "Chile",
      native_name: "Chile",
      capital: "Santiago",
      en_capital: "Santiago",
      alpha_2: "CL",
      alpha_3: "CHL",
      phone_code: "56",
    },
    china: {
      name: "China",
      en_name: "China",
      native_name: "中国",
      capital: "Pequim",
      en_capital: "Beijing",
      alpha_2: "CN",
      alpha_3: "CHN",
      phone_code: "86",
    },
    christmasisland: {
      name: "Ilha Christmas",
      en_name: "Christmas Island",
      native_name: "Christmas Island",
      capital: "Flying Fish Cove",
      en_capital: "Flying Fish Cove",
      alpha_2: "CX",
      alpha_3: "CXR",
      phone_code: "61",
    },
    "cocos(keeling)islands": {
      name: "Ilhas Cocos (Keeling ",
      en_name: "Cocos (Keeling) Islands",
      native_name: "Cocos (Keeling) Islands",
      capital: "West Island",
      en_capital: "West Island",
      alpha_2: "CC",
      alpha_3: "CCK",
      phone_code: "61",
    },
    colombia: {
      name: "Colômbia",
      en_name: "Colombia",
      native_name: "Colombia",
      capital: "Bogotá",
      en_capital: "Bogotá",
      alpha_2: "CO",
      alpha_3: "COL",
      phone_code: "57",
    },
    comoros: {
      name: "Comores",
      en_name: "Comoros",
      native_name: "Komori",
      capital: "Morôni",
      en_capital: "Moroni",
      alpha_2: "KM",
      alpha_3: "COM",
      phone_code: "269",
    },
    republicofthecongo: {
      name: "República do Congo",
      en_name: "Republic of the Congo",
      native_name: "République du Congo",
      capital: "Brazzaville",
      en_capital: "Brazzaville",
      alpha_2: "CG",
      alpha_3: "COG",
      phone_code: "242",
    },
    democraticrepublicofthecongo: {
      name: "República democrática do Congo",
      en_name: "Democratic Republic of the Congo",
      native_name: "République démocratique du Congo",
      capital: "Kinshasa",
      en_capital: "Kinshasa",
      alpha_2: "CD",
      alpha_3: "COD",
      phone_code: "243",
    },
    cookislands: {
      name: "Ilhas Cook",
      en_name: "Cook Islands",
      native_name: "Cook Islands",
      capital: "Avarua",
      en_capital: "Avarua",
      alpha_2: "CK",
      alpha_3: "COK",
      phone_code: "682",
    },
    costarica: {
      name: "Costa Rica",
      en_name: "Costa Rica",
      native_name: "Costa Rica",
      capital: "San José",
      en_capital: "San José",
      alpha_2: "CR",
      alpha_3: "CRI",
      phone_code: "506",
    },
    croatia: {
      name: "Croácia",
      en_name: "Croatia",
      native_name: "Hrvatska",
      capital: "Zagreb",
      en_capital: "Zagreb",
      alpha_2: "HR",
      alpha_3: "HRV",
      phone_code: "385",
    },
    cuba: {
      name: "Cuba",
      en_name: "Cuba",
      native_name: "Cuba",
      capital: "Havana",
      en_capital: "Havana",
      alpha_2: "CU",
      alpha_3: "CUB",
      phone_code: "53",
    },
    curaçao: {
      name: "Curaçao",
      en_name: "Curaçao",
      native_name: "Curaçao",
      capital: "Willemstad",
      en_capital: "Willemstad",
      alpha_2: "CW",
      alpha_3: "CUW",
      phone_code: "5999",
    },
    cyprus: {
      name: "Chipre",
      en_name: "Cyprus",
      native_name: "Κύπρος",
      capital: "Nicósia",
      en_capital: "Nicosia",
      alpha_2: "CY",
      alpha_3: "CYP",
      phone_code: "357",
    },
    czechrepublic: {
      name: "República Checa",
      en_name: "Czech Republic",
      native_name: "Česká republika",
      capital: "Praga",
      en_capital: "Prague",
      alpha_2: "CZ",
      alpha_3: "CZE",
      phone_code: "420",
    },
    denmark: {
      name: "Dinamarca",
      en_name: "Denmark",
      native_name: "Danmark",
      capital: "Copenhaga",
      en_capital: "Copenhagen",
      alpha_2: "DK",
      alpha_3: "DNK",
      phone_code: "45",
    },
    djibouti: {
      name: "Djibuti",
      en_name: "Djibouti",
      native_name: "Djibouti",
      capital: "Djibuti",
      en_capital: "Djibouti",
      alpha_2: "DJ",
      alpha_3: "DJI",
      phone_code: "253",
    },
    dominica: {
      name: "Dominica",
      en_name: "Dominica",
      native_name: "Dominica",
      capital: "Roseau",
      en_capital: "Roseau",
      alpha_2: "DM",
      alpha_3: "DMA",
      phone_code: "1767",
    },
    dominicanrepublic: {
      name: "República Dominicana",
      en_name: "Dominican Republic",
      native_name: "República Dominicana",
      capital: "Santo Domingo",
      en_capital: "Santo Domingo",
      alpha_2: "DO",
      alpha_3: "DOM",
      phone_code: "1809",
    },
    ecuador: {
      name: "Equador",
      en_name: "Ecuador",
      native_name: "Ecuador",
      capital: "Quito",
      en_capital: "Quito",
      alpha_2: "EC",
      alpha_3: "ECU",
      phone_code: "593",
    },
    egypt: {
      name: "Egito",
      en_name: "Egypt",
      native_name: "مصر\u200e",
      capital: "Cairo",
      en_capital: "Cairo",
      alpha_2: "EG",
      alpha_3: "EGY",
      phone_code: "20",
    },
    elsalvador: {
      name: "El Salvador",
      en_name: "El Salvador",
      native_name: "El Salvador",
      capital: "San Salvador",
      en_capital: "San Salvador",
      alpha_2: "SV",
      alpha_3: "SLV",
      phone_code: "503",
    },
    equatorialguinea: {
      name: "Guiné Equatorial",
      en_name: "Equatorial Guinea",
      native_name: "Guinea Ecuatorial",
      capital: "Malabo",
      en_capital: "Malabo",
      alpha_2: "GQ",
      alpha_3: "GNQ",
      phone_code: "240",
    },
    eritrea: {
      name: "Eritreia",
      en_name: "Eritrea",
      native_name: "ኤርትራ",
      capital: "Asmara",
      en_capital: "Asmara",
      alpha_2: "ER",
      alpha_3: "ERI",
      phone_code: "291",
    },
    estonia: {
      name: "Estónia",
      en_name: "Estonia",
      native_name: "Eesti",
      capital: "Tallinn",
      en_capital: "Tallinn",
      alpha_2: "EE",
      alpha_3: "EST",
      phone_code: "372",
    },
    ethiopia: {
      name: "Etiópia",
      en_name: "Ethiopia",
      native_name: "ኢትዮጵያ",
      capital: "Addis Abeba",
      en_capital: "Addis Ababa",
      alpha_2: "ET",
      alpha_3: "ETH",
      phone_code: "251",
    },
    falklandislands: {
      name: "Ilhas Malvinas",
      en_name: "Falkland Islands",
      native_name: "Falkland Islands",
      capital: "Stanley",
      en_capital: "Stanley",
      alpha_2: "FK",
      alpha_3: "FLK",
      phone_code: "500",
    },
    faroeislands: {
      name: "Ilhas Faroé",
      en_name: "Faroe Islands",
      native_name: "Føroyar",
      capital: "Tórshavn",
      en_capital: "Tórshavn",
      alpha_2: "FO",
      alpha_3: "FRO",
      phone_code: "298",
    },
    fiji: {
      name: "Fiji",
      en_name: "Fiji",
      native_name: "Fiji",
      capital: "Suva",
      en_capital: "Suva",
      alpha_2: "FJ",
      alpha_3: "FJI",
      phone_code: "679",
    },
    finland: {
      name: "Finlândia",
      en_name: "Finland",
      native_name: "Suomi",
      capital: "Helsínquia",
      en_capital: "Helsinki",
      alpha_2: "FI",
      alpha_3: "FIN",
      phone_code: "358",
    },
    france: {
      name: "França",
      en_name: "France",
      native_name: "France",
      capital: "Paris",
      en_capital: "Paris",
      alpha_2: "FR",
      alpha_3: "FRA",
      phone_code: "33",
    },
    frenchguiana: {
      name: "Guiana Francesa",
      en_name: "French Guiana",
      native_name: "Guyane française",
      capital: "Cayenne",
      en_capital: "Cayenne",
      alpha_2: "GF",
      alpha_3: "GUF",
      phone_code: "594",
    },
    frenchpolynesia: {
      name: "Polinésia Francesa",
      en_name: "French Polynesia",
      native_name: "Polynésie française",
      capital: "Papeetē",
      en_capital: "Papeetē",
      alpha_2: "PF",
      alpha_3: "PYF",
      phone_code: "689",
    },
    frenchsouthernandantarcticlands: {
      name: "Terras austrais e antárcticas francesas",
      en_name: "French Southern and Antarctic Lands",
      native_name: "Territoire des Terres australes et antarctiques françaises",
      capital: "Port-aux-Français",
      en_capital: "Port-aux-Français",
      alpha_2: "TF",
      alpha_3: "ATF",
      phone_code: "262",
    },
    gabon: {
      name: "Gabão",
      en_name: "Gabon",
      native_name: "Gabon",
      capital: "Libreville",
      en_capital: "Libreville",
      alpha_2: "GA",
      alpha_3: "GAB",
      phone_code: "241",
    },
    thegambia: {
      name: "A Gâmbia",
      en_name: "The Gambia",
      native_name: "Gambia",
      capital: "Banjul",
      en_capital: "Banjul",
      alpha_2: "GM",
      alpha_3: "GMB",
      phone_code: "220",
    },
    georgia: {
      name: "Geórgia",
      en_name: "Georgia",
      native_name: "საქართველო",
      capital: "Tbilisi",
      en_capital: "Tbilisi",
      alpha_2: "GE",
      alpha_3: "GEO",
      phone_code: "995",
    },
    germany: {
      name: "Alemanha",
      en_name: "Germany",
      native_name: "Deutschland",
      capital: "Berlim",
      en_capital: "Berlin",
      alpha_2: "DE",
      alpha_3: "DEU",
      phone_code: "49",
    },
    ghana: {
      name: "Gana",
      en_name: "Ghana",
      native_name: "Ghana",
      capital: "Accra",
      en_capital: "Accra",
      alpha_2: "GH",
      alpha_3: "GHA",
      phone_code: "233",
    },
    gibraltar: {
      name: "Gibraltar",
      en_name: "Gibraltar",
      native_name: "Gibraltar",
      capital: "Gibraltar",
      en_capital: "Gibraltar",
      alpha_2: "GI",
      alpha_3: "GIB",
      phone_code: "350",
    },
    greece: {
      name: "Grécia",
      en_name: "Greece",
      native_name: "Ελλάδα",
      capital: "Atenas",
      en_capital: "Athens",
      alpha_2: "GR",
      alpha_3: "GRC",
      phone_code: "30",
    },
    greenland: {
      name: "Gronelândia",
      en_name: "Greenland",
      native_name: "Kalaallit Nunaat",
      capital: "Nuuk",
      en_capital: "Nuuk",
      alpha_2: "GL",
      alpha_3: "GRL",
      phone_code: "299",
    },
    grenada: {
      name: "Granada",
      en_name: "Grenada",
      native_name: "Grenada",
      capital: "São Jorge",
      en_capital: "St. George's",
      alpha_2: "GD",
      alpha_3: "GRD",
      phone_code: "1473",
    },
    guadeloupe: {
      name: "Guadalupe",
      en_name: "Guadeloupe",
      native_name: "Guadeloupe",
      capital: "Basse-Terre",
      en_capital: "Basse-Terre",
      alpha_2: "GP",
      alpha_3: "GLP",
      phone_code: "590",
    },
    guam: {
      name: "Guam",
      en_name: "Guam",
      native_name: "Guam",
      capital: "Hagåtña",
      en_capital: "Hagåtña",
      alpha_2: "GU",
      alpha_3: "GUM",
      phone_code: "1671",
    },
    guatemala: {
      name: "Guatemala",
      en_name: "Guatemala",
      native_name: "Guatemala",
      capital: "Cidade Da Guatemala",
      en_capital: "Guatemala City",
      alpha_2: "GT",
      alpha_3: "GTM",
      phone_code: "502",
    },
    guernsey: {
      name: "Guernsey",
      en_name: "Guernsey",
      native_name: "Guernsey",
      capital: "St. Peter Port",
      en_capital: "St. Peter Port",
      alpha_2: "GG",
      alpha_3: "GGY",
      phone_code: "44",
    },
    guinea: {
      name: "Guiné",
      en_name: "Guinea",
      native_name: "Guinée",
      capital: "- Conacri",
      en_capital: "Conakry",
      alpha_2: "GN",
      alpha_3: "GIN",
      phone_code: "224",
    },
    guineabissau: {
      name: "Guiné-Bissau",
      en_name: "Guinea-Bissau",
      native_name: "Guiné-Bissau",
      capital: "Bissau",
      en_capital: "Bissau",
      alpha_2: "GW",
      alpha_3: "GNB",
      phone_code: "245",
    },
    guyana: {
      name: "Guiana",
      en_name: "Guyana",
      native_name: "Guyana",
      capital: "Georgetown",
      en_capital: "Georgetown",
      alpha_2: "GY",
      alpha_3: "GUY",
      phone_code: "592",
    },
    haiti: {
      name: "Haiti",
      en_name: "Haiti",
      native_name: "Haïti",
      capital: "Port-au-Prince",
      en_capital: "Port-au-Prince",
      alpha_2: "HT",
      alpha_3: "HTI",
      phone_code: "509",
    },
    honduras: {
      name: "Honduras",
      en_name: "Honduras",
      native_name: "Honduras",
      capital: "Tegucigalpa",
      en_capital: "Tegucigalpa",
      alpha_2: "HN",
      alpha_3: "HND",
      phone_code: "504",
    },
    hongkong: {
      name: "Hong Kong",
      en_name: "Hong Kong",
      native_name: "香港",
      capital: "Cidade de Victoria",
      en_capital: "City of Victoria",
      alpha_2: "HK",
      alpha_3: "HKG",
      phone_code: "852",
    },
    hungary: {
      name: "Hungria",
      en_name: "Hungary",
      native_name: "Magyarország",
      capital: "Budapeste",
      en_capital: "Budapest",
      alpha_2: "HU",
      alpha_3: "HUN",
      phone_code: "36",
    },
    iceland: {
      name: "Islândia",
      en_name: "Iceland",
      native_name: "Ísland",
      capital: "Reykjavik",
      en_capital: "Reykjavik",
      alpha_2: "IS",
      alpha_3: "ISL",
      phone_code: "354",
    },
    india: {
      name: "Índia",
      en_name: "India",
      native_name: "भारत",
      capital: "Nova Deli",
      en_capital: "New Delhi",
      alpha_2: "IN",
      alpha_3: "IND",
      phone_code: "91",
    },
    indonesia: {
      name: "Indonésia",
      en_name: "Indonesia",
      native_name: "Indonesia",
      capital: "Jacarta",
      en_capital: "Jakarta",
      alpha_2: "ID",
      alpha_3: "IDN",
      phone_code: "62",
    },
    ivorycoast: {
      name: "Costa Do Marfim",
      en_name: "Ivory Coast",
      native_name: "Côte d'Ivoire",
      capital: "Yamoussoukro",
      en_capital: "Yamoussoukro",
      alpha_2: "CI",
      alpha_3: "CIV",
      phone_code: "225",
    },
    iran: {
      name: "Irã",
      en_name: "Iran",
      native_name: "Irān",
      capital: "Teerã",
      en_capital: "Tehran",
      alpha_2: "IR",
      alpha_3: "IRN",
      phone_code: "98",
    },
    iraq: {
      name: "Iraque",
      en_name: "Iraq",
      native_name: "العراق",
      capital: "Bagdá",
      en_capital: "Baghdad",
      alpha_2: "IQ",
      alpha_3: "IRQ",
      phone_code: "964",
    },
    republicofireland: {
      name: "República da Irlanda",
      en_name: "Republic of Ireland",
      native_name: "Éire",
      capital: "Dublin",
      en_capital: "Dublin",
      alpha_2: "IE",
      alpha_3: "IRL",
      phone_code: "353",
    },
    isleofman: {
      name: "Ilha de Man",
      en_name: "Isle of Man",
      native_name: "Isle of Man",
      capital: "Douglas",
      en_capital: "Douglas",
      alpha_2: "IM",
      alpha_3: "IMN",
      phone_code: "44",
    },
    israel: {
      name: "Israel",
      en_name: "Israel",
      native_name: "יִשְׂרָאֵל",
      capital: "Tel Aviv",
      en_capital: "Tel Aviv",
      alpha_2: "IL",
      alpha_3: "ISR",
      phone_code: "972",
    },
    italy: {
      name: "Itália",
      en_name: "Italy",
      native_name: "Italia",
      capital: "Roma",
      en_capital: "Rome",
      alpha_2: "IT",
      alpha_3: "ITA",
      phone_code: "39",
    },
    jamaica: {
      name: "Jamaica",
      en_name: "Jamaica",
      native_name: "Jamaica",
      capital: "Kingston",
      en_capital: "Kingston",
      alpha_2: "JM",
      alpha_3: "JAM",
      phone_code: "1876",
    },
    japan: {
      name: "Japão",
      en_name: "Japan",
      native_name: "日本",
      capital: "Tóquio",
      en_capital: "Tokyo",
      alpha_2: "JP",
      alpha_3: "JPN",
      phone_code: "81",
    },
    jordan: {
      name: "Jordão",
      en_name: "Jordan",
      native_name: "الأردن",
      capital: "Amã",
      en_capital: "Amman",
      alpha_2: "JO",
      alpha_3: "JOR",
      phone_code: "962",
    },
    kazakhstan: {
      name: "Cazaquistão",
      en_name: "Kazakhstan",
      native_name: "Қазақстан",
      capital: "Astana",
      en_capital: "Astana",
      alpha_2: "KZ",
      alpha_3: "KAZ",
      phone_code: "76",
    },
    kenya: {
      name: "Quênia",
      en_name: "Kenya",
      native_name: "Kenya",
      capital: "Nairobi",
      en_capital: "Nairobi",
      alpha_2: "KE",
      alpha_3: "KEN",
      phone_code: "254",
    },
    kiribati: {
      name: "Kiribati",
      en_name: "Kiribati",
      native_name: "Kiribati",
      capital: "Tarawa Do Sul",
      en_capital: "South Tarawa",
      alpha_2: "KI",
      alpha_3: "KIR",
      phone_code: "686",
    },
    kuwait: {
      name: "Kuwait",
      en_name: "Kuwait",
      native_name: "الكويت",
      capital: "Cidade Do Kuwait",
      en_capital: "Kuwait City",
      alpha_2: "KW",
      alpha_3: "KWT",
      phone_code: "965",
    },
    kyrgyzstan: {
      name: "Quirguistão",
      en_name: "Kyrgyzstan",
      native_name: "Кыргызстан",
      capital: "Bishkek",
      en_capital: "Bishkek",
      alpha_2: "KG",
      alpha_3: "KGZ",
      phone_code: "996",
    },
    laos: {
      name: "Laos",
      en_name: "Laos",
      native_name: "ສປປລາວ",
      capital: "Vientiane",
      en_capital: "Vientiane",
      alpha_2: "LA",
      alpha_3: "LAO",
      phone_code: "856",
    },
    latvia: {
      name: "Letónia",
      en_name: "Latvia",
      native_name: "Latvija",
      capital: "Riga",
      en_capital: "Riga",
      alpha_2: "LV",
      alpha_3: "LVA",
      phone_code: "371",
    },
    lebanon: {
      name: "Líbano",
      en_name: "Lebanon",
      native_name: "لبنان",
      capital: "Beirute",
      en_capital: "Beirut",
      alpha_2: "LB",
      alpha_3: "LBN",
      phone_code: "961",
    },
    lesotho: {
      name: "Lesotho",
      en_name: "Lesotho",
      native_name: "Lesotho",
      capital: "Maseru",
      en_capital: "Maseru",
      alpha_2: "LS",
      alpha_3: "LSO",
      phone_code: "266",
    },
    liberia: {
      name: "A libéria",
      en_name: "Liberia",
      native_name: "Liberia",
      capital: "Monróvia",
      en_capital: "Monrovia",
      alpha_2: "LR",
      alpha_3: "LBR",
      phone_code: "231",
    },
    libya: {
      name: "Líbia",
      en_name: "Libya",
      native_name: "\u200fليبيا",
      capital: "Trípoli",
      en_capital: "Tripoli",
      alpha_2: "LY",
      alpha_3: "LBY",
      phone_code: "218",
    },
    liechtenstein: {
      name: "Liechtenstein",
      en_name: "Liechtenstein",
      native_name: "Liechtenstein",
      capital: "Vaduz",
      en_capital: "Vaduz",
      alpha_2: "LI",
      alpha_3: "LIE",
      phone_code: "423",
    },
    lithuania: {
      name: "Lituânia",
      en_name: "Lithuania",
      native_name: "Lietuva",
      capital: "Vilnius",
      en_capital: "Vilnius",
      alpha_2: "LT",
      alpha_3: "LTU",
      phone_code: "370",
    },
    luxembourg: {
      name: "Luxemburgo",
      en_name: "Luxembourg",
      native_name: "Luxembourg",
      capital: "Luxemburgo",
      en_capital: "Luxembourg",
      alpha_2: "LU",
      alpha_3: "LUX",
      phone_code: "352",
    },
    republicofmacedonia: {
      name: "República da Macedónia",
      en_name: "Republic of Macedonia",
      native_name: "Македонија",
      capital: "Skopje",
      en_capital: "Skopje",
      alpha_2: "MK",
      alpha_3: "MKD",
      phone_code: "389",
    },
    madagascar: {
      name: "Madagascar",
      en_name: "Madagascar",
      native_name: "Madagasikara",
      capital: "Antananarivo",
      en_capital: "Antananarivo",
      alpha_2: "MG",
      alpha_3: "MDG",
      phone_code: "261",
    },
    malawi: {
      name: "Malawi",
      en_name: "Malawi",
      native_name: "Malawi",
      capital: "Lilongwe",
      en_capital: "Lilongwe",
      alpha_2: "MW",
      alpha_3: "MWI",
      phone_code: "265",
    },
    malaysia: {
      name: "Malásia",
      en_name: "Malaysia",
      native_name: "Malaysia",
      capital: "Kuala Lumpur",
      en_capital: "Kuala Lumpur",
      alpha_2: "MY",
      alpha_3: "MYS",
      phone_code: "60",
    },
    maldives: {
      name: "Maldivas",
      en_name: "Maldives",
      native_name: "Maldives",
      capital: "Malé",
      en_capital: "Malé",
      alpha_2: "MV",
      alpha_3: "MDV",
      phone_code: "960",
    },
    mali: {
      name: "Mali",
      en_name: "Mali",
      native_name: "Mali",
      capital: "Bamako",
      en_capital: "Bamako",
      alpha_2: "ML",
      alpha_3: "MLI",
      phone_code: "223",
    },
    malta: {
      name: "Malta",
      en_name: "Malta",
      native_name: "Malta",
      capital: "Valletta",
      en_capital: "Valletta",
      alpha_2: "MT",
      alpha_3: "MLT",
      phone_code: "356",
    },
    marshallislands: {
      name: "Ilhas Marshall",
      en_name: "Marshall Islands",
      native_name: "M̧ajeļ",
      capital: "Majuro",
      en_capital: "Majuro",
      alpha_2: "MH",
      alpha_3: "MHL",
      phone_code: "692",
    },
    martinique: {
      name: "Martinica",
      en_name: "Martinique",
      native_name: "Martinique",
      capital: "Fort-de-France",
      en_capital: "Fort-de-France",
      alpha_2: "MQ",
      alpha_3: "MTQ",
      phone_code: "596",
    },
    mauritania: {
      name: "Mauritânia",
      en_name: "Mauritania",
      native_name: "موريتانيا",
      capital: "Nouakchott",
      en_capital: "Nouakchott",
      alpha_2: "MR",
      alpha_3: "MRT",
      phone_code: "222",
    },
    mauritius: {
      name: "Maurício",
      en_name: "Mauritius",
      native_name: "Maurice",
      capital: "Port Louis",
      en_capital: "Port Louis",
      alpha_2: "MU",
      alpha_3: "MUS",
      phone_code: "230",
    },
    mayotte: {
      name: "Mayotte",
      en_name: "Mayotte",
      native_name: "Mayotte",
      capital: "Mamoudzou",
      en_capital: "Mamoudzou",
      alpha_2: "YT",
      alpha_3: "MYT",
      phone_code: "262",
    },
    mexico: {
      name: "México",
      en_name: "Mexico",
      native_name: "México",
      capital: "Cidade Do México",
      en_capital: "Mexico City",
      alpha_2: "MX",
      alpha_3: "MEX",
      phone_code: "52",
    },
    federatedstatesofmicronesia: {
      name: "Estados federados da Micronésia",
      en_name: "Federated States of Micronesia",
      native_name: "Micronesia",
      capital: "Palikir",
      en_capital: "Palikir",
      alpha_2: "FM",
      alpha_3: "FSM",
      phone_code: "691",
    },
    moldova: {
      name: "Moldávia",
      en_name: "Moldova",
      native_name: "Moldova",
      capital: "Chișinău",
      en_capital: "Chișinău",
      alpha_2: "MD",
      alpha_3: "MDA",
      phone_code: "373",
    },
    monaco: {
      name: "Mônaco",
      en_name: "Monaco",
      native_name: "Monaco",
      capital: "Mônaco",
      en_capital: "Monaco",
      alpha_2: "MC",
      alpha_3: "MCO",
      phone_code: "377",
    },
    mongolia: {
      name: "Mongólia",
      en_name: "Mongolia",
      native_name: "Монгол улс",
      capital: "Ulan Bator",
      en_capital: "Ulan Bator",
      alpha_2: "MN",
      alpha_3: "MNG",
      phone_code: "976",
    },
    montenegro: {
      name: "Montenegro",
      en_name: "Montenegro",
      native_name: "Црна Гора",
      capital: "Podgorica",
      en_capital: "Podgorica",
      alpha_2: "ME",
      alpha_3: "MNE",
      phone_code: "382",
    },
    montserrat: {
      name: "Montserrat",
      en_name: "Montserrat",
      native_name: "Montserrat",
      capital: "Plymouth",
      en_capital: "Plymouth",
      alpha_2: "MS",
      alpha_3: "MSR",
      phone_code: "1664",
    },
    morocco: {
      name: "Marrocos",
      en_name: "Morocco",
      native_name: "المغرب",
      capital: "Rabat",
      en_capital: "Rabat",
      alpha_2: "MA",
      alpha_3: "MAR",
      phone_code: "212",
    },
    mozambique: {
      name: "Moçambique",
      en_name: "Mozambique",
      native_name: "Moçambique",
      capital: "Maputo",
      en_capital: "Maputo",
      alpha_2: "MZ",
      alpha_3: "MOZ",
      phone_code: "258",
    },
    myanmar: {
      name: "Mianmar",
      en_name: "Myanmar",
      native_name: "Myanma",
      capital: "Naypyidaw",
      en_capital: "Naypyidaw",
      alpha_2: "MM",
      alpha_3: "MMR",
      phone_code: "95",
    },
    namibia: {
      name: "Namíbia",
      en_name: "Namibia",
      native_name: "Namibia",
      capital: "Windhoek",
      en_capital: "Windhoek",
      alpha_2: "NA",
      alpha_3: "NAM",
      phone_code: "264",
    },
    nauru: {
      name: "Nauru",
      en_name: "Nauru",
      native_name: "Nauru",
      capital: "Yaren",
      en_capital: "Yaren",
      alpha_2: "NR",
      alpha_3: "NRU",
      phone_code: "674",
    },
    nepal: {
      name: "Nepal",
      en_name: "Nepal",
      native_name: "नपल",
      capital: "Kathmandu",
      en_capital: "Kathmandu",
      alpha_2: "NP",
      alpha_3: "NPL",
      phone_code: "977",
    },
    netherlands: {
      name: "Países baixos",
      en_name: "Netherlands",
      native_name: "Nederland",
      capital: "Amesterdão",
      en_capital: "Amsterdam",
      alpha_2: "NL",
      alpha_3: "NLD",
      phone_code: "31",
    },
    newcaledonia: {
      name: "Nova Caledônia",
      en_name: "New Caledonia",
      native_name: "Nouvelle-Calédonie",
      capital: "Nouméa",
      en_capital: "Nouméa",
      alpha_2: "NC",
      alpha_3: "NCL",
      phone_code: "687",
    },
    newzealand: {
      name: "Nova Zelândia",
      en_name: "New Zealand",
      native_name: "New Zealand",
      capital: "Wellington",
      en_capital: "Wellington",
      alpha_2: "NZ",
      alpha_3: "NZL",
      phone_code: "64",
    },
    nicaragua: {
      name: "Nicarágua",
      en_name: "Nicaragua",
      native_name: "Nicaragua",
      capital: "Manágua",
      en_capital: "Managua",
      alpha_2: "NI",
      alpha_3: "NIC",
      phone_code: "505",
    },
    niger: {
      name: "Níger",
      en_name: "Niger",
      native_name: "Niger",
      capital: "Niamey",
      en_capital: "Niamey",
      alpha_2: "NE",
      alpha_3: "NER",
      phone_code: "227",
    },
    nigeria: {
      name: "Nigéria",
      en_name: "Nigeria",
      native_name: "Nigeria",
      capital: "Abuja",
      en_capital: "Abuja",
      alpha_2: "NG",
      alpha_3: "NGA",
      phone_code: "234",
    },
    niue: {
      name: "Niue",
      en_name: "Niue",
      native_name: "Niuē",
      capital: "Alofi",
      en_capital: "Alofi",
      alpha_2: "NU",
      alpha_3: "NIU",
      phone_code: "683",
    },
    norfolkisland: {
      name: "Ilha Norfolk",
      en_name: "Norfolk Island",
      native_name: "Norfolk Island",
      capital: "Kingston",
      en_capital: "Kingston",
      alpha_2: "NF",
      alpha_3: "NFK",
      phone_code: "672",
    },
    northkorea: {
      name: "Coreia Do Norte",
      en_name: "North Korea",
      native_name: "북한",
      capital: "Pyongyang",
      en_capital: "Pyongyang",
      alpha_2: "KP",
      alpha_3: "PRK",
      phone_code: "850",
    },
    northernmarianaislands: {
      name: "Ilhas Marianas Do Norte",
      en_name: "Northern Mariana Islands",
      native_name: "Northern Mariana Islands",
      capital: "Saipan",
      en_capital: "Saipan",
      alpha_2: "MP",
      alpha_3: "MNP",
      phone_code: "1670",
    },
    norway: {
      name: "Noruega",
      en_name: "Norway",
      native_name: "Norge",
      capital: "Oslo",
      en_capital: "Oslo",
      alpha_2: "NO",
      alpha_3: "NOR",
      phone_code: "47",
    },
    oman: {
      name: "Omã",
      en_name: "Oman",
      native_name: "عمان",
      capital: "Muscat",
      en_capital: "Muscat",
      alpha_2: "OM",
      alpha_3: "OMN",
      phone_code: "968",
    },
    pakistan: {
      name: "Paquistão",
      en_name: "Pakistan",
      native_name: "Pakistan",
      capital: "Islamabad",
      en_capital: "Islamabad",
      alpha_2: "PK",
      alpha_3: "PAK",
      phone_code: "92",
    },
    palau: {
      name: "Palau",
      en_name: "Palau",
      native_name: "Palau",
      capital: "Ngerulmud",
      en_capital: "Ngerulmud",
      alpha_2: "PW",
      alpha_3: "PLW",
      phone_code: "680",
    },
    palestine: {
      name: "Palestina",
      en_name: "Palestine",
      native_name: "فلسطين",
      capital: "Jerusalém",
      en_capital: "Jerusalem",
      alpha_2: "PS",
      alpha_3: "PSE",
      phone_code: "970",
    },
    panama: {
      name: "Panamá",
      en_name: "Panama",
      native_name: "Panamá",
      capital: "Cidade Do Panamá",
      en_capital: "Panama City",
      alpha_2: "PA",
      alpha_3: "PAN",
      phone_code: "507",
    },
    papuanewguinea: {
      name: "Papua-Nova Guiné",
      en_name: "Papua New Guinea",
      native_name: "Papua Niugini",
      capital: "Port Moresby",
      en_capital: "Port Moresby",
      alpha_2: "PG",
      alpha_3: "PNG",
      phone_code: "675",
    },
    paraguay: {
      name: "Paraguai",
      en_name: "Paraguay",
      native_name: "Paraguay",
      capital: "Assunção",
      en_capital: "Asunción",
      alpha_2: "PY",
      alpha_3: "PRY",
      phone_code: "595",
    },
    peru: {
      name: "Peru",
      en_name: "Peru",
      native_name: "Perú",
      capital: "Lima",
      en_capital: "Lima",
      alpha_2: "PE",
      alpha_3: "PER",
      phone_code: "51",
    },
    philippines: {
      name: "Filipinas",
      en_name: "Philippines",
      native_name: "Pilipinas",
      capital: "Manila",
      en_capital: "Manila",
      alpha_2: "PH",
      alpha_3: "PHL",
      phone_code: "63",
    },
    pitcairnislands: {
      name: "Ilhas Pitcairn",
      en_name: "Pitcairn Islands",
      native_name: "Pitcairn Islands",
      capital: "Adamstown",
      en_capital: "Adamstown",
      alpha_2: "PN",
      alpha_3: "PCN",
      phone_code: "64",
    },
    poland: {
      name: "Polónia",
      en_name: "Poland",
      native_name: "Polska",
      capital: "Varsóvia",
      en_capital: "Warsaw",
      alpha_2: "PL",
      alpha_3: "POL",
      phone_code: "48",
    },
    portugal: {
      name: "Portugal",
      en_name: "Portugal",
      native_name: "Portugal",
      capital: "Lisboa",
      en_capital: "Lisbon",
      alpha_2: "PT",
      alpha_3: "PRT",
      phone_code: "351",
    },
    puertorico: {
      name: "Porto Rico",
      en_name: "Puerto Rico",
      native_name: "Puerto Rico",
      capital: "San Juan",
      en_capital: "San Juan",
      alpha_2: "PR",
      alpha_3: "PRI",
      phone_code: "1787",
    },
    qatar: {
      name: "Qatar",
      en_name: "Qatar",
      native_name: "قطر",
      capital: "Doha",
      en_capital: "Doha",
      alpha_2: "QA",
      alpha_3: "QAT",
      phone_code: "974",
    },
    republicofkosovo: {
      name: "República do Kosovo",
      en_name: "Republic of Kosovo",
      native_name: "Republika e Kosovës",
      capital: "Pristina",
      en_capital: "Pristina",
      alpha_2: "XK",
      alpha_3: "KOS",
      phone_code: "383",
    },
    réunion: {
      name: "Reunião",
      en_name: "Réunion",
      native_name: "Réunion",
      capital: "Saint-Denis",
      en_capital: "Saint-Denis",
      alpha_2: "RE",
      alpha_3: "REU",
      phone_code: "262",
    },
    romania: {
      name: "Roménia",
      en_name: "Romania",
      native_name: "România",
      capital: "Bucareste",
      en_capital: "Bucharest",
      alpha_2: "RO",
      alpha_3: "ROU",
      phone_code: "40",
    },
    russia: {
      name: "Rússia",
      en_name: "Russia",
      native_name: "Россия",
      capital: "Moscovo",
      en_capital: "Moscow",
      alpha_2: "RU",
      alpha_3: "RUS",
      phone_code: "7",
    },
    rwanda: {
      name: "Ruanda",
      en_name: "Rwanda",
      native_name: "Rwanda",
      capital: "Kigali",
      en_capital: "Kigali",
      alpha_2: "RW",
      alpha_3: "RWA",
      phone_code: "250",
    },
    saintbarthélemy: {
      name: "Saint Barthélemy",
      en_name: "Saint Barthélemy",
      native_name: "Saint-Barthélemy",
      capital: "Gustavia",
      en_capital: "Gustavia",
      alpha_2: "BL",
      alpha_3: "BLM",
      phone_code: "590",
    },
    saintkittsandnevis: {
      name: "São Cristóvão e Nevis",
      en_name: "Saint Kitts and Nevis",
      native_name: "Saint Kitts and Nevis",
      capital: "Basseterre",
      en_capital: "Basseterre",
      alpha_2: "KN",
      alpha_3: "KNA",
      phone_code: "1869",
    },
    saintlucia: {
      name: "Santa Lúcia",
      en_name: "Saint Lucia",
      native_name: "Saint Lucia",
      capital: "Castries",
      en_capital: "Castries",
      alpha_2: "LC",
      alpha_3: "LCA",
      phone_code: "1758",
    },
    saintmartin: {
      name: "Saint Martin",
      en_name: "Saint Martin",
      native_name: "Saint-Martin",
      capital: "Marigot",
      en_capital: "Marigot",
      alpha_2: "MF",
      alpha_3: "MAF",
      phone_code: "590",
    },
    saintpierreandmiquelon: {
      name: "Saint Pierre e Miquelon",
      en_name: "Saint Pierre and Miquelon",
      native_name: "Saint-Pierre-et-Miquelon",
      capital: "Saint-Pierre",
      en_capital: "Saint-Pierre",
      alpha_2: "PM",
      alpha_3: "SPM",
      phone_code: "508",
    },
    saintvincentandthegrenadines: {
      name: "São Vicente e Granadinas",
      en_name: "Saint Vincent and the Grenadines",
      native_name: "Saint Vincent and the Grenadines",
      capital: "Kingstown",
      en_capital: "Kingstown",
      alpha_2: "VC",
      alpha_3: "VCT",
      phone_code: "1784",
    },
    samoa: {
      name: "Samoa",
      en_name: "Samoa",
      native_name: "Samoa",
      capital: "Apia",
      en_capital: "Apia",
      alpha_2: "WS",
      alpha_3: "WSM",
      phone_code: "685",
    },
    sanmarino: {
      name: "San Marino",
      en_name: "San Marino",
      native_name: "San Marino",
      capital: "A cidade de San Marino",
      en_capital: "City of San Marino",
      alpha_2: "SM",
      alpha_3: "SMR",
      phone_code: "378",
    },
    sãotoméandpríncipe: {
      name: "São Tomé e Príncipe",
      en_name: "São Tomé and Príncipe",
      native_name: "São Tomé e Príncipe",
      capital: "São Tomé",
      en_capital: "São Tomé",
      alpha_2: "ST",
      alpha_3: "STP",
      phone_code: "239",
    },
    saudiarabia: {
      name: "A Arábia Saudita",
      en_name: "Saudi Arabia",
      native_name: "العربية السعودية",
      capital: "Riad",
      en_capital: "Riyadh",
      alpha_2: "SA",
      alpha_3: "SAU",
      phone_code: "966",
    },
    senegal: {
      name: "Senegal",
      en_name: "Senegal",
      native_name: "Sénégal",
      capital: "Dakar",
      en_capital: "Dakar",
      alpha_2: "SN",
      alpha_3: "SEN",
      phone_code: "221",
    },
    serbia: {
      name: "Sérvia",
      en_name: "Serbia",
      native_name: "Србија",
      capital: "Belgrado",
      en_capital: "Belgrade",
      alpha_2: "RS",
      alpha_3: "SRB",
      phone_code: "381",
    },
    seychelles: {
      name: "Seychelles",
      en_name: "Seychelles",
      native_name: "Seychelles",
      capital: "Victoria",
      en_capital: "Victoria",
      alpha_2: "SC",
      alpha_3: "SYC",
      phone_code: "248",
    },
    sierraleone: {
      name: "Serra Leoa",
      en_name: "Sierra Leone",
      native_name: "Sierra Leone",
      capital: "Freetown",
      en_capital: "Freetown",
      alpha_2: "SL",
      alpha_3: "SLE",
      phone_code: "232",
    },
    singapore: {
      name: "Singapura",
      en_name: "Singapore",
      native_name: "Singapore",
      capital: "Singapura",
      en_capital: "Singapore",
      alpha_2: "SG",
      alpha_3: "SGP",
      phone_code: "65",
    },
    sintmaarten: {
      name: "Sint Maarten",
      en_name: "Sint Maarten",
      native_name: "Sint Maarten",
      capital: "Philipsburg",
      en_capital: "Philipsburg",
      alpha_2: "SX",
      alpha_3: "SXM",
      phone_code: "1721",
    },
    slovakia: {
      name: "Eslováquia",
      en_name: "Slovakia",
      native_name: "Slovensko",
      capital: "Bratislava",
      en_capital: "Bratislava",
      alpha_2: "SK",
      alpha_3: "SVK",
      phone_code: "421",
    },
    slovenia: {
      name: "Eslovénia",
      en_name: "Slovenia",
      native_name: "Slovenija",
      capital: "Ljubljana",
      en_capital: "Ljubljana",
      alpha_2: "SI",
      alpha_3: "SVN",
      phone_code: "386",
    },
    solomonislands: {
      name: "Ilhas Salomão",
      en_name: "Solomon Islands",
      native_name: "Solomon Islands",
      capital: "Honiara",
      en_capital: "Honiara",
      alpha_2: "SB",
      alpha_3: "SLB",
      phone_code: "677",
    },
    somalia: {
      name: "Somália",
      en_name: "Somalia",
      native_name: "Soomaaliya",
      capital: "Mogadíscio",
      en_capital: "Mogadishu",
      alpha_2: "SO",
      alpha_3: "SOM",
      phone_code: "252",
    },
    southafrica: {
      name: "África Do Sul",
      en_name: "South Africa",
      native_name: "South Africa",
      capital: "Pretória",
      en_capital: "Pretoria",
      alpha_2: "ZA",
      alpha_3: "ZAF",
      phone_code: "27",
    },
    southgeorgia: {
      name: "Geórgia Do Sul",
      en_name: "South Georgia",
      native_name: "South Georgia",
      capital: "O Rei Edward Ponto",
      en_capital: "King Edward Point",
      alpha_2: "GS",
      alpha_3: "SGS",
      phone_code: "500",
    },
    southkorea: {
      name: "Coreia Do Sul",
      en_name: "South Korea",
      native_name: "대한민국",
      capital: "Seul",
      en_capital: "Seoul",
      alpha_2: "KR",
      alpha_3: "KOR",
      phone_code: "82",
    },
    southsudan: {
      name: "O Sudão Do Sul",
      en_name: "South Sudan",
      native_name: "South Sudan",
      capital: "Juba",
      en_capital: "Juba",
      alpha_2: "SS",
      alpha_3: "SSD",
      phone_code: "211",
    },
    spain: {
      name: "Espanha",
      en_name: "Spain",
      native_name: "España",
      capital: "Madrid",
      en_capital: "Madrid",
      alpha_2: "ES",
      alpha_3: "ESP",
      phone_code: "34",
    },
    srilanka: {
      name: "Sri Lanka",
      en_name: "Sri Lanka",
      native_name: "śrī laṃkāva",
      capital: "Colombo",
      en_capital: "Colombo",
      alpha_2: "LK",
      alpha_3: "LKA",
      phone_code: "94",
    },
    sudan: {
      name: "Sudão",
      en_name: "Sudan",
      native_name: "السودان",
      capital: "Cartum",
      en_capital: "Khartoum",
      alpha_2: "SD",
      alpha_3: "SDN",
      phone_code: "249",
    },
    suriname: {
      name: "Suriname",
      en_name: "Suriname",
      native_name: "Suriname",
      capital: "Paramaribo",
      en_capital: "Paramaribo",
      alpha_2: "SR",
      alpha_3: "SUR",
      phone_code: "597",
    },
    svalbardandjanmayen: {
      name: "Svalbard e Jan Mayen",
      en_name: "Svalbard and Jan Mayen",
      native_name: "Svalbard og Jan Mayen",
      capital: "Longyearbyen",
      en_capital: "Longyearbyen",
      alpha_2: "SJ",
      alpha_3: "SJM",
      phone_code: "4779",
    },
    swaziland: {
      name: "Suazilândia",
      en_name: "Swaziland",
      native_name: "Swaziland",
      capital: "Lobamba",
      en_capital: "Lobamba",
      alpha_2: "SZ",
      alpha_3: "SWZ",
      phone_code: "268",
    },
    sweden: {
      name: "Suécia",
      en_name: "Sweden",
      native_name: "Sverige",
      capital: "Estocolmo",
      en_capital: "Stockholm",
      alpha_2: "SE",
      alpha_3: "SWE",
      phone_code: "46",
    },
    switzerland: {
      name: "Suíça",
      en_name: "Switzerland",
      native_name: "Schweiz",
      capital: "Berna",
      en_capital: "Bern",
      alpha_2: "CH",
      alpha_3: "CHE",
      phone_code: "41",
    },
    syria: {
      name: "Síria",
      en_name: "Syria",
      native_name: "سوريا",
      capital: "Damasco",
      en_capital: "Damascus",
      alpha_2: "SY",
      alpha_3: "SYR",
      phone_code: "963",
    },
    taiwan: {
      name: "Taiwan",
      en_name: "Taiwan",
      native_name: "臺灣",
      capital: "Taipei",
      en_capital: "Taipei",
      alpha_2: "TW",
      alpha_3: "TWN",
      phone_code: "886",
    },
    tajikistan: {
      name: "Tajiquistão",
      en_name: "Tajikistan",
      native_name: "Тоҷикистон",
      capital: "Dushanbe",
      en_capital: "Dushanbe",
      alpha_2: "TJ",
      alpha_3: "TJK",
      phone_code: "992",
    },
    tanzania: {
      name: "Tanzânia",
      en_name: "Tanzania",
      native_name: "Tanzania",
      capital: "Dodoma",
      en_capital: "Dodoma",
      alpha_2: "TZ",
      alpha_3: "TZA",
      phone_code: "255",
    },
    thailand: {
      name: "Tailândia",
      en_name: "Thailand",
      native_name: "ประเทศไทย",
      capital: "Banguecoque",
      en_capital: "Bangkok",
      alpha_2: "TH",
      alpha_3: "THA",
      phone_code: "66",
    },
    easttimor: {
      name: "Timor-Leste",
      en_name: "East Timor",
      native_name: "Timor-Leste",
      capital: "Díli",
      en_capital: "Dili",
      alpha_2: "TL",
      alpha_3: "TLS",
      phone_code: "670",
    },
    togo: {
      name: "Togo",
      en_name: "Togo",
      native_name: "Togo",
      capital: "Lomé",
      en_capital: "Lomé",
      alpha_2: "TG",
      alpha_3: "TGO",
      phone_code: "228",
    },
    tokelau: {
      name: "Tokelau",
      en_name: "Tokelau",
      native_name: "Tokelau",
      capital: "Fakaofo",
      en_capital: "Fakaofo",
      alpha_2: "TK",
      alpha_3: "TKL",
      phone_code: "690",
    },
    tonga: {
      name: "Tonga",
      en_name: "Tonga",
      native_name: "Tonga",
      capital: "Nuku'alofa",
      en_capital: "Nuku'alofa",
      alpha_2: "TO",
      alpha_3: "TON",
      phone_code: "676",
    },
    trinidadandtobago: {
      name: "Trinidad e Tobago",
      en_name: "Trinidad and Tobago",
      native_name: "Trinidad and Tobago",
      capital: "Port-of-Spain",
      en_capital: "Port of Spain",
      alpha_2: "TT",
      alpha_3: "TTO",
      phone_code: "1868",
    },
    tunisia: {
      name: "Tunísia",
      en_name: "Tunisia",
      native_name: "تونس",
      capital: "Tunis",
      en_capital: "Tunis",
      alpha_2: "TN",
      alpha_3: "TUN",
      phone_code: "216",
    },
    turkey: {
      name: "A turquia",
      en_name: "Turkey",
      native_name: "Türkiye",
      capital: "Ancara",
      en_capital: "Ankara",
      alpha_2: "TR",
      alpha_3: "TUR",
      phone_code: "90",
    },
    turkmenistan: {
      name: "Turcomenistão",
      en_name: "Turkmenistan",
      native_name: "Türkmenistan",
      capital: "Ashgabat",
      en_capital: "Ashgabat",
      alpha_2: "TM",
      alpha_3: "TKM",
      phone_code: "993",
    },
    turksandcaicosislands: {
      name: "Ilhas turcas e Caicos",
      en_name: "Turks and Caicos Islands",
      native_name: "Turks and Caicos Islands",
      capital: "Cockburn Town",
      en_capital: "Cockburn Town",
      alpha_2: "TC",
      alpha_3: "TCA",
      phone_code: "1649",
    },
    tuvalu: {
      name: "Tuvalu",
      en_name: "Tuvalu",
      native_name: "Tuvalu",
      capital: "Funafuti",
      en_capital: "Funafuti",
      alpha_2: "TV",
      alpha_3: "TUV",
      phone_code: "688",
    },
    uganda: {
      name: "Uganda",
      en_name: "Uganda",
      native_name: "Uganda",
      capital: "Kampala",
      en_capital: "Kampala",
      alpha_2: "UG",
      alpha_3: "UGA",
      phone_code: "256",
    },
    ukraine: {
      name: "Ucrânia",
      en_name: "Ukraine",
      native_name: "Україна",
      capital: "Kiev",
      en_capital: "Kiev",
      alpha_2: "UA",
      alpha_3: "UKR",
      phone_code: "380",
    },
    unitedarabemirates: {
      name: "Emirados Árabes Unidos",
      en_name: "United Arab Emirates",
      native_name: "دولة الإمارات العربية المتحدة",
      capital: "Abu Dhabi",
      en_capital: "Abu Dhabi",
      alpha_2: "AE",
      alpha_3: "ARE",
      phone_code: "971",
    },
    unitedkingdom: {
      name: "Reino Unido",
      en_name: "United Kingdom",
      native_name: "United Kingdom",
      capital: "Londres",
      en_capital: "London",
      alpha_2: "GB",
      alpha_3: "GBR",
      phone_code: "44",
    },
    unitedstates: {
      name: "Estados Unidos",
      en_name: "United States",
      native_name: "United States",
      capital: "Washington D.C.",
      en_capital: "Washington D.C.",
      alpha_2: "US",
      alpha_3: "USA",
      phone_code: "1",
    },
    uruguay: {
      name: "Uruguai",
      en_name: "Uruguay",
      native_name: "Uruguay",
      capital: "Montevidéu",
      en_capital: "Montevideo",
      alpha_2: "UY",
      alpha_3: "URY",
      phone_code: "598",
    },
    uzbekistan: {
      name: "Uzbequistão",
      en_name: "Uzbekistan",
      native_name: "O‘zbekiston",
      capital: "Tashkent",
      en_capital: "Tashkent",
      alpha_2: "UZ",
      alpha_3: "UZB",
      phone_code: "998",
    },
    vanuatu: {
      name: "Vanuatu",
      en_name: "Vanuatu",
      native_name: "Vanuatu",
      capital: "Porta Da Vila",
      en_capital: "Port Vila",
      alpha_2: "VU",
      alpha_3: "VUT",
      phone_code: "678",
    },
    venezuela: {
      name: "Venezuela",
      en_name: "Venezuela",
      native_name: "Venezuela",
      capital: "Caracas",
      en_capital: "Caracas",
      alpha_2: "VE",
      alpha_3: "VEN",
      phone_code: "58",
    },
    vietnam: {
      name: "Vietnã",
      en_name: "Vietnam",
      native_name: "Việt Nam",
      capital: "Hanói",
      en_capital: "Hanoi",
      alpha_2: "VN",
      alpha_3: "VNM",
      phone_code: "84",
    },
    wallisandfutuna: {
      name: "Wallis e Futuna",
      en_name: "Wallis and Futuna",
      native_name: "Wallis et Futuna",
      capital: "Mata-Utu",
      en_capital: "Mata-Utu",
      alpha_2: "WF",
      alpha_3: "WLF",
      phone_code: "681",
    },
    westernsahara: {
      name: "Saara Ocidental",
      en_name: "Western Sahara",
      native_name: "الصحراء الغربية",
      capital: "El Aaiún",
      en_capital: "El Aaiún",
      alpha_2: "EH",
      alpha_3: "ESH",
      phone_code: "212",
    },
    yemen: {
      name: "Iêmen",
      en_name: "Yemen",
      native_name: "اليَمَن",
      capital: "Sanaa",
      en_capital: "Sana'a",
      alpha_2: "YE",
      alpha_3: "YEM",
      phone_code: "967",
    },
    zambia: {
      name: "Zâmbia",
      en_name: "Zambia",
      native_name: "Zambia",
      capital: "Lusaka",
      en_capital: "Lusaka",
      alpha_2: "ZM",
      alpha_3: "ZMB",
      phone_code: "260",
    },
    zimbabwe: {
      name: "Zimbábue",
      en_name: "Zimbabwe",
      native_name: "Zimbabwe",
      capital: "Harare",
      en_capital: "Harare",
      alpha_2: "ZW",
      alpha_3: "ZWE",
      phone_code: "263",
    },
    other: {
      name: "De outros",
      en_name: "Other",
      native_name: "Other",
      alpha_2: "AA",
      alpha_3: "AAA",
      phone_code: "000",
    },
  },
  en: {
    afghanistan: {
      name: "Afghanistan",
      native_name: "افغانستان",
      capital: "Kabul",
      alpha_2: "AF",
      alpha_3: "AFG",
      phone_code: "93",
    },
    Ålandislands: {
      name: "Åland Islands",
      native_name: "Åland",
      capital: "Mariehamn",
      alpha_2: "AX",
      alpha_3: "ALA",
      phone_code: "358",
    },
    albania: {
      name: "Albania",
      native_name: "Shqipëria",
      capital: "Tirana",
      alpha_2: "AL",
      alpha_3: "ALB",
      phone_code: "355",
    },
    algeria: {
      name: "Algeria",
      native_name: "الجزائر",
      capital: "Algiers",
      alpha_2: "DZ",
      alpha_3: "DZA",
      phone_code: "213",
    },
    americansamoa: {
      name: "American Samoa",
      native_name: "American Samoa",
      capital: "Pago Pago",
      alpha_2: "AS",
      alpha_3: "ASM",
      phone_code: "1684",
    },
    andorra: {
      name: "Andorra",
      native_name: "Andorra",
      capital: "Andorra la Vella",
      alpha_2: "AD",
      alpha_3: "AND",
      phone_code: "376",
    },
    angola: {
      name: "Angola",
      native_name: "Angola",
      capital: "Luanda",
      alpha_2: "AO",
      alpha_3: "AGO",
      phone_code: "244",
    },
    anguilla: {
      name: "Anguilla",
      native_name: "Anguilla",
      capital: "The Valley",
      alpha_2: "AI",
      alpha_3: "AIA",
      phone_code: "1264",
    },
    antiguaandbarbuda: {
      name: "Antigua and Barbuda",
      native_name: "Antigua and Barbuda",
      capital: "Saint John's",
      alpha_2: "AG",
      alpha_3: "ATG",
      phone_code: "1268",
    },
    argentina: {
      name: "Argentina",
      native_name: "Argentina",
      capital: "Buenos Aires",
      alpha_2: "AR",
      alpha_3: "ARG",
      phone_code: "54",
    },
    armenia: {
      name: "Armenia",
      native_name: "Հայաստան",
      capital: "Yerevan",
      alpha_2: "AM",
      alpha_3: "ARM",
      phone_code: "374",
    },
    aruba: {
      name: "Aruba",
      native_name: "Aruba",
      capital: "Oranjestad",
      alpha_2: "AW",
      alpha_3: "ABW",
      phone_code: "297",
    },
    australia: {
      name: "Australia",
      native_name: "Australia",
      capital: "Canberra",
      alpha_2: "AU",
      alpha_3: "AUS",
      phone_code: "61",
    },
    austria: {
      name: "Austria",
      native_name: "Österreich",
      capital: "Vienna",
      alpha_2: "AT",
      alpha_3: "AUT",
      phone_code: "43",
    },
    azerbaijan: {
      name: "Azerbaijan",
      native_name: "Azərbaycan",
      capital: "Baku",
      alpha_2: "AZ",
      alpha_3: "AZE",
      phone_code: "994",
    },
    thebahamas: {
      name: "The Bahamas",
      native_name: "Bahamas",
      capital: "Nassau",
      alpha_2: "BS",
      alpha_3: "BHS",
      phone_code: "1242",
    },
    bahrain: {
      name: "Bahrain",
      native_name: "\u200fالبحرين",
      capital: "Manama",
      alpha_2: "BH",
      alpha_3: "BHR",
      phone_code: "973",
    },
    bangladesh: {
      name: "Bangladesh",
      native_name: "Bangladesh",
      capital: "Dhaka",
      alpha_2: "BD",
      alpha_3: "BGD",
      phone_code: "880",
    },
    barbados: {
      name: "Barbados",
      native_name: "Barbados",
      capital: "Bridgetown",
      alpha_2: "BB",
      alpha_3: "BRB",
      phone_code: "1246",
    },
    belarus: {
      name: "Belarus",
      native_name: "Белару́сь",
      capital: "Minsk",
      alpha_2: "BY",
      alpha_3: "BLR",
      phone_code: "375",
    },
    belgium: {
      name: "Belgium",
      native_name: "België",
      capital: "Brussels",
      alpha_2: "BE",
      alpha_3: "BEL",
      phone_code: "32",
    },
    belize: {
      name: "Belize",
      native_name: "Belize",
      capital: "Belmopan",
      alpha_2: "BZ",
      alpha_3: "BLZ",
      phone_code: "501",
    },
    benin: {
      name: "Benin",
      native_name: "Bénin",
      capital: "Porto-Novo",
      alpha_2: "BJ",
      alpha_3: "BEN",
      phone_code: "229",
    },
    bermuda: {
      name: "Bermuda",
      native_name: "Bermuda",
      capital: "Hamilton",
      alpha_2: "BM",
      alpha_3: "BMU",
      phone_code: "1441",
    },
    bhutan: {
      name: "Bhutan",
      native_name: "ʼbrug-yul",
      capital: "Thimphu",
      alpha_2: "BT",
      alpha_3: "BTN",
      phone_code: "975",
    },
    bolivia: {
      name: "Bolivia",
      native_name: "Bolivia",
      capital: "Sucre",
      alpha_2: "BO",
      alpha_3: "BOL",
      phone_code: "591",
    },
    bonaire: {
      name: "Bonaire",
      native_name: "Bonaire",
      capital: "Kralendijk",
      alpha_2: "BQ",
      alpha_3: "BES",
      phone_code: "5997",
    },
    bosniaandherzegovina: {
      name: "Bosnia and Herzegovina",
      native_name: "Bosna i Hercegovina",
      capital: "Sarajevo",
      alpha_2: "BA",
      alpha_3: "BIH",
      phone_code: "387",
    },
    botswana: {
      name: "Botswana",
      native_name: "Botswana",
      capital: "Gaborone",
      alpha_2: "BW",
      alpha_3: "BWA",
      phone_code: "267",
    },
    brazil: {
      name: "Brazil",
      native_name: "Brasil",
      capital: "Brasília",
      alpha_2: "BR",
      alpha_3: "BRA",
      phone_code: "55",
    },
    britishindianoceanterritory: {
      name: "British Indian Ocean Territory",
      native_name: "British Indian Ocean Territory",
      capital: "Diego Garcia",
      alpha_2: "IO",
      alpha_3: "IOT",
      phone_code: "246",
    },
    britishvirginislands: {
      name: "British Virgin Islands",
      native_name: "British Virgin Islands",
      capital: "Road Town",
      alpha_2: "VG",
      alpha_3: "VGB",
      phone_code: "1284",
    },
    brunei: {
      name: "Brunei",
      native_name: "Negara Brunei Darussalam",
      capital: "Bandar Seri Begawan",
      alpha_2: "BN",
      alpha_3: "BRN",
      phone_code: "673",
    },
    bulgaria: {
      name: "Bulgaria",
      native_name: "България",
      capital: "Sofia",
      alpha_2: "BG",
      alpha_3: "BGR",
      phone_code: "359",
    },
    burkinafaso: {
      name: "Burkina Faso",
      native_name: "Burkina Faso",
      capital: "Ouagadougou",
      alpha_2: "BF",
      alpha_3: "BFA",
      phone_code: "226",
    },
    burundi: {
      name: "Burundi",
      native_name: "Burundi",
      capital: "Bujumbura",
      alpha_2: "BI",
      alpha_3: "BDI",
      phone_code: "257",
    },
    cambodia: {
      name: "Cambodia",
      native_name: "Kâmpŭchéa",
      capital: "Phnom Penh",
      alpha_2: "KH",
      alpha_3: "KHM",
      phone_code: "855",
    },
    cameroon: {
      name: "Cameroon",
      native_name: "Cameroon",
      capital: "Yaoundé",
      alpha_2: "CM",
      alpha_3: "CMR",
      phone_code: "237",
    },
    canada: {
      name: "Canada",
      native_name: "Canada",
      capital: "Ottawa",
      alpha_2: "CA",
      alpha_3: "CAN",
      phone_code: "1",
    },
    capeverde: {
      name: "Cape Verde",
      native_name: "Cabo Verde",
      capital: "Praia",
      alpha_2: "CV",
      alpha_3: "CPV",
      phone_code: "238",
    },
    caymanislands: {
      name: "Cayman Islands",
      native_name: "Cayman Islands",
      capital: "George Town",
      alpha_2: "KY",
      alpha_3: "CYM",
      phone_code: "1345",
    },
    centralafricanrepublic: {
      name: "Central African Republic",
      native_name: "Ködörösêse tî Bêafrîka",
      capital: "Bangui",
      alpha_2: "CF",
      alpha_3: "CAF",
      phone_code: "236",
    },
    chad: {
      name: "Chad",
      native_name: "Tchad",
      capital: "N'Djamena",
      alpha_2: "TD",
      alpha_3: "TCD",
      phone_code: "235",
    },
    chile: {
      name: "Chile",
      native_name: "Chile",
      capital: "Santiago",
      alpha_2: "CL",
      alpha_3: "CHL",
      phone_code: "56",
    },
    china: {
      name: "China",
      native_name: "中国",
      capital: "Beijing",
      alpha_2: "CN",
      alpha_3: "CHN",
      phone_code: "86",
    },
    christmasisland: {
      name: "Christmas Island",
      native_name: "Christmas Island",
      capital: "Flying Fish Cove",
      alpha_2: "CX",
      alpha_3: "CXR",
      phone_code: "61",
    },
    "cocos(keeling)islands": {
      name: "Cocos (Keeling) Islands",
      native_name: "Cocos (Keeling) Islands",
      capital: "West Island",
      alpha_2: "CC",
      alpha_3: "CCK",
      phone_code: "61",
    },
    colombia: {
      name: "Colombia",
      native_name: "Colombia",
      capital: "Bogotá",
      alpha_2: "CO",
      alpha_3: "COL",
      phone_code: "57",
    },
    comoros: {
      name: "Comoros",
      native_name: "Komori",
      capital: "Moroni",
      alpha_2: "KM",
      alpha_3: "COM",
      phone_code: "269",
    },
    republicofthecongo: {
      name: "Republic of the Congo",
      native_name: "République du Congo",
      capital: "Brazzaville",
      alpha_2: "CG",
      alpha_3: "COG",
      phone_code: "242",
    },
    democraticrepublicofthecongo: {
      name: "Democratic Republic of the Congo",
      native_name: "République démocratique du Congo",
      capital: "Kinshasa",
      alpha_2: "CD",
      alpha_3: "COD",
      phone_code: "243",
    },
    cookislands: {
      name: "Cook Islands",
      native_name: "Cook Islands",
      capital: "Avarua",
      alpha_2: "CK",
      alpha_3: "COK",
      phone_code: "682",
    },
    costarica: {
      name: "Costa Rica",
      native_name: "Costa Rica",
      capital: "San José",
      alpha_2: "CR",
      alpha_3: "CRI",
      phone_code: "506",
    },
    croatia: {
      name: "Croatia",
      native_name: "Hrvatska",
      capital: "Zagreb",
      alpha_2: "HR",
      alpha_3: "HRV",
      phone_code: "385",
    },
    cuba: {
      name: "Cuba",
      native_name: "Cuba",
      capital: "Havana",
      alpha_2: "CU",
      alpha_3: "CUB",
      phone_code: "53",
    },
    curaçao: {
      name: "Curaçao",
      native_name: "Curaçao",
      capital: "Willemstad",
      alpha_2: "CW",
      alpha_3: "CUW",
      phone_code: "5999",
    },
    cyprus: {
      name: "Cyprus",
      native_name: "Κύπρος",
      capital: "Nicosia",
      alpha_2: "CY",
      alpha_3: "CYP",
      phone_code: "357",
    },
    czechrepublic: {
      name: "Czech Republic",
      native_name: "Česká republika",
      capital: "Prague",
      alpha_2: "CZ",
      alpha_3: "CZE",
      phone_code: "420",
    },
    denmark: {
      name: "Denmark",
      native_name: "Danmark",
      capital: "Copenhagen",
      alpha_2: "DK",
      alpha_3: "DNK",
      phone_code: "45",
    },
    djibouti: {
      name: "Djibouti",
      native_name: "Djibouti",
      capital: "Djibouti",
      alpha_2: "DJ",
      alpha_3: "DJI",
      phone_code: "253",
    },
    dominica: {
      name: "Dominica",
      native_name: "Dominica",
      capital: "Roseau",
      alpha_2: "DM",
      alpha_3: "DMA",
      phone_code: "1767",
    },
    dominicanrepublic: {
      name: "Dominican Republic",
      native_name: "República Dominicana",
      capital: "Santo Domingo",
      alpha_2: "DO",
      alpha_3: "DOM",
      phone_code: "1809",
    },
    ecuador: {
      name: "Ecuador",
      native_name: "Ecuador",
      capital: "Quito",
      alpha_2: "EC",
      alpha_3: "ECU",
      phone_code: "593",
    },
    egypt: {
      name: "Egypt",
      native_name: "مصر\u200e",
      capital: "Cairo",
      alpha_2: "EG",
      alpha_3: "EGY",
      phone_code: "20",
    },
    elsalvador: {
      name: "El Salvador",
      native_name: "El Salvador",
      capital: "San Salvador",
      alpha_2: "SV",
      alpha_3: "SLV",
      phone_code: "503",
    },
    equatorialguinea: {
      name: "Equatorial Guinea",
      native_name: "Guinea Ecuatorial",
      capital: "Malabo",
      alpha_2: "GQ",
      alpha_3: "GNQ",
      phone_code: "240",
    },
    eritrea: {
      name: "Eritrea",
      native_name: "ኤርትራ",
      capital: "Asmara",
      alpha_2: "ER",
      alpha_3: "ERI",
      phone_code: "291",
    },
    estonia: {
      name: "Estonia",
      native_name: "Eesti",
      capital: "Tallinn",
      alpha_2: "EE",
      alpha_3: "EST",
      phone_code: "372",
    },
    ethiopia: {
      name: "Ethiopia",
      native_name: "ኢትዮጵያ",
      capital: "Addis Ababa",
      alpha_2: "ET",
      alpha_3: "ETH",
      phone_code: "251",
    },
    falklandislands: {
      name: "Falkland Islands",
      native_name: "Falkland Islands",
      capital: "Stanley",
      alpha_2: "FK",
      alpha_3: "FLK",
      phone_code: "500",
    },
    faroeislands: {
      name: "Faroe Islands",
      native_name: "Føroyar",
      capital: "Tórshavn",
      alpha_2: "FO",
      alpha_3: "FRO",
      phone_code: "298",
    },
    fiji: {
      name: "Fiji",
      native_name: "Fiji",
      capital: "Suva",
      alpha_2: "FJ",
      alpha_3: "FJI",
      phone_code: "679",
    },
    finland: {
      name: "Finland",
      native_name: "Suomi",
      capital: "Helsinki",
      alpha_2: "FI",
      alpha_3: "FIN",
      phone_code: "358",
    },
    france: {
      name: "France",
      native_name: "France",
      capital: "Paris",
      alpha_2: "FR",
      alpha_3: "FRA",
      phone_code: "33",
    },
    frenchguiana: {
      name: "French Guiana",
      native_name: "Guyane française",
      capital: "Cayenne",
      alpha_2: "GF",
      alpha_3: "GUF",
      phone_code: "594",
    },
    frenchpolynesia: {
      name: "French Polynesia",
      native_name: "Polynésie française",
      capital: "Papeetē",
      alpha_2: "PF",
      alpha_3: "PYF",
      phone_code: "689",
    },
    frenchsouthernandantarcticlands: {
      name: "French Southern and Antarctic Lands",
      native_name: "Territoire des Terres australes et antarctiques françaises",
      capital: "Port-aux-Français",
      alpha_2: "TF",
      alpha_3: "ATF",
      phone_code: "262",
    },
    gabon: {
      name: "Gabon",
      native_name: "Gabon",
      capital: "Libreville",
      alpha_2: "GA",
      alpha_3: "GAB",
      phone_code: "241",
    },
    thegambia: {
      name: "The Gambia",
      native_name: "Gambia",
      capital: "Banjul",
      alpha_2: "GM",
      alpha_3: "GMB",
      phone_code: "220",
    },
    georgia: {
      name: "Georgia",
      native_name: "საქართველო",
      capital: "Tbilisi",
      alpha_2: "GE",
      alpha_3: "GEO",
      phone_code: "995",
    },
    germany: {
      name: "Germany",
      native_name: "Deutschland",
      capital: "Berlin",
      alpha_2: "DE",
      alpha_3: "DEU",
      phone_code: "49",
    },
    ghana: {
      name: "Ghana",
      native_name: "Ghana",
      capital: "Accra",
      alpha_2: "GH",
      alpha_3: "GHA",
      phone_code: "233",
    },
    gibraltar: {
      name: "Gibraltar",
      native_name: "Gibraltar",
      capital: "Gibraltar",
      alpha_2: "GI",
      alpha_3: "GIB",
      phone_code: "350",
    },
    greece: {
      name: "Greece",
      native_name: "Ελλάδα",
      capital: "Athens",
      alpha_2: "GR",
      alpha_3: "GRC",
      phone_code: "30",
    },
    greenland: {
      name: "Greenland",
      native_name: "Kalaallit Nunaat",
      capital: "Nuuk",
      alpha_2: "GL",
      alpha_3: "GRL",
      phone_code: "299",
    },
    grenada: {
      name: "Grenada",
      native_name: "Grenada",
      capital: "St. George's",
      alpha_2: "GD",
      alpha_3: "GRD",
      phone_code: "1473",
    },
    guadeloupe: {
      name: "Guadeloupe",
      native_name: "Guadeloupe",
      capital: "Basse-Terre",
      alpha_2: "GP",
      alpha_3: "GLP",
      phone_code: "590",
    },
    guam: {
      name: "Guam",
      native_name: "Guam",
      capital: "Hagåtña",
      alpha_2: "GU",
      alpha_3: "GUM",
      phone_code: "1671",
    },
    guatemala: {
      name: "Guatemala",
      native_name: "Guatemala",
      capital: "Guatemala City",
      alpha_2: "GT",
      alpha_3: "GTM",
      phone_code: "502",
    },
    guernsey: {
      name: "Guernsey",
      native_name: "Guernsey",
      capital: "St. Peter Port",
      alpha_2: "GG",
      alpha_3: "GGY",
      phone_code: "44",
    },
    guinea: {
      name: "Guinea",
      native_name: "Guinée",
      capital: "Conakry",
      alpha_2: "GN",
      alpha_3: "GIN",
      phone_code: "224",
    },
    guineabissau: {
      name: "Guinea-Bissau",
      native_name: "Guiné-Bissau",
      capital: "Bissau",
      alpha_2: "GW",
      alpha_3: "GNB",
      phone_code: "245",
    },
    guyana: {
      name: "Guyana",
      native_name: "Guyana",
      capital: "Georgetown",
      alpha_2: "GY",
      alpha_3: "GUY",
      phone_code: "592",
    },
    haiti: {
      name: "Haiti",
      native_name: "Haïti",
      capital: "Port-au-Prince",
      alpha_2: "HT",
      alpha_3: "HTI",
      phone_code: "509",
    },
    honduras: {
      name: "Honduras",
      native_name: "Honduras",
      capital: "Tegucigalpa",
      alpha_2: "HN",
      alpha_3: "HND",
      phone_code: "504",
    },
    hongkong: {
      name: "Hong Kong",
      native_name: "香港",
      capital: "City of Victoria",
      alpha_2: "HK",
      alpha_3: "HKG",
      phone_code: "852",
    },
    hungary: {
      name: "Hungary",
      native_name: "Magyarország",
      capital: "Budapest",
      alpha_2: "HU",
      alpha_3: "HUN",
      phone_code: "36",
    },
    iceland: {
      name: "Iceland",
      native_name: "Ísland",
      capital: "Reykjavik",
      alpha_2: "IS",
      alpha_3: "ISL",
      phone_code: "354",
    },
    india: {
      name: "India",
      native_name: "भारत",
      capital: "New Delhi",
      alpha_2: "IN",
      alpha_3: "IND",
      phone_code: "91",
    },
    indonesia: {
      name: "Indonesia",
      native_name: "Indonesia",
      capital: "Jakarta",
      alpha_2: "ID",
      alpha_3: "IDN",
      phone_code: "62",
    },
    ivorycoast: {
      name: "Ivory Coast",
      native_name: "Côte d'Ivoire",
      capital: "Yamoussoukro",
      alpha_2: "CI",
      alpha_3: "CIV",
      phone_code: "225",
    },
    iran: {
      name: "Iran",
      native_name: "Irān",
      capital: "Tehran",
      alpha_2: "IR",
      alpha_3: "IRN",
      phone_code: "98",
    },
    iraq: {
      name: "Iraq",
      native_name: "العراق",
      capital: "Baghdad",
      alpha_2: "IQ",
      alpha_3: "IRQ",
      phone_code: "964",
    },
    republicofireland: {
      name: "Republic of Ireland",
      native_name: "Éire",
      capital: "Dublin",
      alpha_2: "IE",
      alpha_3: "IRL",
      phone_code: "353",
    },
    isleofman: {
      name: "Isle of Man",
      native_name: "Isle of Man",
      capital: "Douglas",
      alpha_2: "IM",
      alpha_3: "IMN",
      phone_code: "44",
    },
    israel: {
      name: "Israel",
      native_name: "יִשְׂרָאֵל",
      capital: "Tel Aviv",
      alpha_2: "IL",
      alpha_3: "ISR",
      phone_code: "972",
    },
    italy: {
      name: "Italy",
      native_name: "Italia",
      capital: "Rome",
      alpha_2: "IT",
      alpha_3: "ITA",
      phone_code: "39",
    },
    jamaica: {
      name: "Jamaica",
      native_name: "Jamaica",
      capital: "Kingston",
      alpha_2: "JM",
      alpha_3: "JAM",
      phone_code: "1876",
    },
    japan: {
      name: "Japan",
      native_name: "日本",
      capital: "Tokyo",
      alpha_2: "JP",
      alpha_3: "JPN",
      phone_code: "81",
    },
    jersey: {
      name: "Jersey",
      native_name: "Jersey",
      capital: "Saint Helier",
      alpha_2: "JE",
      alpha_3: "JEY",
      phone_code: "44",
    },
    jordan: {
      name: "Jordan",
      native_name: "الأردن",
      capital: "Amman",
      alpha_2: "JO",
      alpha_3: "JOR",
      phone_code: "962",
    },
    kazakhstan: {
      name: "Kazakhstan",
      native_name: "Қазақстан",
      capital: "Astana",
      alpha_2: "KZ",
      alpha_3: "KAZ",
      phone_code: "76",
    },
    kenya: {
      name: "Kenya",
      native_name: "Kenya",
      capital: "Nairobi",
      alpha_2: "KE",
      alpha_3: "KEN",
      phone_code: "254",
    },
    kiribati: {
      name: "Kiribati",
      native_name: "Kiribati",
      capital: "South Tarawa",
      alpha_2: "KI",
      alpha_3: "KIR",
      phone_code: "686",
    },
    kuwait: {
      name: "Kuwait",
      native_name: "الكويت",
      capital: "Kuwait City",
      alpha_2: "KW",
      alpha_3: "KWT",
      phone_code: "965",
    },
    kyrgyzstan: {
      name: "Kyrgyzstan",
      native_name: "Кыргызстан",
      capital: "Bishkek",
      alpha_2: "KG",
      alpha_3: "KGZ",
      phone_code: "996",
    },
    laos: {
      name: "Laos",
      native_name: "ສປປລາວ",
      capital: "Vientiane",
      alpha_2: "LA",
      alpha_3: "LAO",
      phone_code: "856",
    },
    latvia: {
      name: "Latvia",
      native_name: "Latvija",
      capital: "Riga",
      alpha_2: "LV",
      alpha_3: "LVA",
      phone_code: "371",
    },
    lebanon: {
      name: "Lebanon",
      native_name: "لبنان",
      capital: "Beirut",
      alpha_2: "LB",
      alpha_3: "LBN",
      phone_code: "961",
    },
    lesotho: {
      name: "Lesotho",
      native_name: "Lesotho",
      capital: "Maseru",
      alpha_2: "LS",
      alpha_3: "LSO",
      phone_code: "266",
    },
    liberia: {
      name: "Liberia",
      native_name: "Liberia",
      capital: "Monrovia",
      alpha_2: "LR",
      alpha_3: "LBR",
      phone_code: "231",
    },
    libya: {
      name: "Libya",
      native_name: "\u200fليبيا",
      capital: "Tripoli",
      alpha_2: "LY",
      alpha_3: "LBY",
      phone_code: "218",
    },
    liechtenstein: {
      name: "Liechtenstein",
      native_name: "Liechtenstein",
      capital: "Vaduz",
      alpha_2: "LI",
      alpha_3: "LIE",
      phone_code: "423",
    },
    lithuania: {
      name: "Lithuania",
      native_name: "Lietuva",
      capital: "Vilnius",
      alpha_2: "LT",
      alpha_3: "LTU",
      phone_code: "370",
    },
    luxembourg: {
      name: "Luxembourg",
      native_name: "Luxembourg",
      capital: "Luxembourg",
      alpha_2: "LU",
      alpha_3: "LUX",
      phone_code: "352",
    },
    republicofmacedonia: {
      name: "Republic of Macedonia",
      native_name: "Македонија",
      capital: "Skopje",
      alpha_2: "MK",
      alpha_3: "MKD",
      phone_code: "389",
    },
    madagascar: {
      name: "Madagascar",
      native_name: "Madagasikara",
      capital: "Antananarivo",
      alpha_2: "MG",
      alpha_3: "MDG",
      phone_code: "261",
    },
    malawi: {
      name: "Malawi",
      native_name: "Malawi",
      capital: "Lilongwe",
      alpha_2: "MW",
      alpha_3: "MWI",
      phone_code: "265",
    },
    malaysia: {
      name: "Malaysia",
      native_name: "Malaysia",
      capital: "Kuala Lumpur",
      alpha_2: "MY",
      alpha_3: "MYS",
      phone_code: "60",
    },
    maldives: {
      name: "Maldives",
      native_name: "Maldives",
      capital: "Malé",
      alpha_2: "MV",
      alpha_3: "MDV",
      phone_code: "960",
    },
    mali: {
      name: "Mali",
      native_name: "Mali",
      capital: "Bamako",
      alpha_2: "ML",
      alpha_3: "MLI",
      phone_code: "223",
    },
    malta: {
      name: "Malta",
      native_name: "Malta",
      capital: "Valletta",
      alpha_2: "MT",
      alpha_3: "MLT",
      phone_code: "356",
    },
    marshallislands: {
      name: "Marshall Islands",
      native_name: "M̧ajeļ",
      capital: "Majuro",
      alpha_2: "MH",
      alpha_3: "MHL",
      phone_code: "692",
    },
    martinique: {
      name: "Martinique",
      native_name: "Martinique",
      capital: "Fort-de-France",
      alpha_2: "MQ",
      alpha_3: "MTQ",
      phone_code: "596",
    },
    mauritania: {
      name: "Mauritania",
      native_name: "موريتانيا",
      capital: "Nouakchott",
      alpha_2: "MR",
      alpha_3: "MRT",
      phone_code: "222",
    },
    mauritius: {
      name: "Mauritius",
      native_name: "Maurice",
      capital: "Port Louis",
      alpha_2: "MU",
      alpha_3: "MUS",
      phone_code: "230",
    },
    mayotte: {
      name: "Mayotte",
      native_name: "Mayotte",
      capital: "Mamoudzou",
      alpha_2: "YT",
      alpha_3: "MYT",
      phone_code: "262",
    },
    mexico: {
      name: "Mexico",
      native_name: "México",
      capital: "Mexico City",
      alpha_2: "MX",
      alpha_3: "MEX",
      phone_code: "52",
    },
    federatedstatesofmicronesia: {
      name: "Federated States of Micronesia",
      native_name: "Micronesia",
      capital: "Palikir",
      alpha_2: "FM",
      alpha_3: "FSM",
      phone_code: "691",
    },
    moldova: {
      name: "Moldova",
      native_name: "Moldova",
      capital: "Chișinău",
      alpha_2: "MD",
      alpha_3: "MDA",
      phone_code: "373",
    },
    monaco: {
      name: "Monaco",
      native_name: "Monaco",
      capital: "Monaco",
      alpha_2: "MC",
      alpha_3: "MCO",
      phone_code: "377",
    },
    mongolia: {
      name: "Mongolia",
      native_name: "Монгол улс",
      capital: "Ulan Bator",
      alpha_2: "MN",
      alpha_3: "MNG",
      phone_code: "976",
    },
    montenegro: {
      name: "Montenegro",
      native_name: "Црна Гора",
      capital: "Podgorica",
      alpha_2: "ME",
      alpha_3: "MNE",
      phone_code: "382",
    },
    montserrat: {
      name: "Montserrat",
      native_name: "Montserrat",
      capital: "Plymouth",
      alpha_2: "MS",
      alpha_3: "MSR",
      phone_code: "1664",
    },
    morocco: {
      name: "Morocco",
      native_name: "المغرب",
      capital: "Rabat",
      alpha_2: "MA",
      alpha_3: "MAR",
      phone_code: "212",
    },
    mozambique: {
      name: "Mozambique",
      native_name: "Moçambique",
      capital: "Maputo",
      alpha_2: "MZ",
      alpha_3: "MOZ",
      phone_code: "258",
    },
    myanmar: {
      name: "Myanmar",
      native_name: "Myanma",
      capital: "Naypyidaw",
      alpha_2: "MM",
      alpha_3: "MMR",
      phone_code: "95",
    },
    namibia: {
      name: "Namibia",
      native_name: "Namibia",
      capital: "Windhoek",
      alpha_2: "NA",
      alpha_3: "NAM",
      phone_code: "264",
    },
    nauru: {
      name: "Nauru",
      native_name: "Nauru",
      capital: "Yaren",
      alpha_2: "NR",
      alpha_3: "NRU",
      phone_code: "674",
    },
    nepal: {
      name: "Nepal",
      native_name: "नपल",
      capital: "Kathmandu",
      alpha_2: "NP",
      alpha_3: "NPL",
      phone_code: "977",
    },
    netherlands: {
      name: "Netherlands",
      native_name: "Nederland",
      capital: "Amsterdam",
      alpha_2: "NL",
      alpha_3: "NLD",
      phone_code: "31",
    },
    newcaledonia: {
      name: "New Caledonia",
      native_name: "Nouvelle-Calédonie",
      capital: "Nouméa",
      alpha_2: "NC",
      alpha_3: "NCL",
      phone_code: "687",
    },
    newzealand: {
      name: "New Zealand",
      native_name: "New Zealand",
      capital: "Wellington",
      alpha_2: "NZ",
      alpha_3: "NZL",
      phone_code: "64",
    },
    nicaragua: {
      name: "Nicaragua",
      native_name: "Nicaragua",
      capital: "Managua",
      alpha_2: "NI",
      alpha_3: "NIC",
      phone_code: "505",
    },
    niger: {
      name: "Niger",
      native_name: "Niger",
      capital: "Niamey",
      alpha_2: "NE",
      alpha_3: "NER",
      phone_code: "227",
    },
    nigeria: {
      name: "Nigeria",
      native_name: "Nigeria",
      capital: "Abuja",
      alpha_2: "NG",
      alpha_3: "NGA",
      phone_code: "234",
    },
    niue: {
      name: "Niue",
      native_name: "Niuē",
      capital: "Alofi",
      alpha_2: "NU",
      alpha_3: "NIU",
      phone_code: "683",
    },
    norfolkisland: {
      name: "Norfolk Island",
      native_name: "Norfolk Island",
      capital: "Kingston",
      alpha_2: "NF",
      alpha_3: "NFK",
      phone_code: "672",
    },
    northkorea: {
      name: "North Korea",
      native_name: "북한",
      capital: "Pyongyang",
      alpha_2: "KP",
      alpha_3: "PRK",
      phone_code: "850",
    },
    northernmarianaislands: {
      name: "Northern Mariana Islands",
      native_name: "Northern Mariana Islands",
      capital: "Saipan",
      alpha_2: "MP",
      alpha_3: "MNP",
      phone_code: "1670",
    },
    norway: {
      name: "Norway",
      native_name: "Norge",
      capital: "Oslo",
      alpha_2: "NO",
      alpha_3: "NOR",
      phone_code: "47",
    },
    oman: {
      name: "Oman",
      native_name: "عمان",
      capital: "Muscat",
      alpha_2: "OM",
      alpha_3: "OMN",
      phone_code: "968",
    },
    pakistan: {
      name: "Pakistan",
      native_name: "Pakistan",
      capital: "Islamabad",
      alpha_2: "PK",
      alpha_3: "PAK",
      phone_code: "92",
    },
    palau: {
      name: "Palau",
      native_name: "Palau",
      capital: "Ngerulmud",
      alpha_2: "PW",
      alpha_3: "PLW",
      phone_code: "680",
    },
    palestine: {
      name: "Palestine",
      native_name: "فلسطين",
      capital: "Jerusalem",
      alpha_2: "PS",
      alpha_3: "PSE",
      phone_code: "970",
    },
    panama: {
      name: "Panama",
      native_name: "Panamá",
      capital: "Panama City",
      alpha_2: "PA",
      alpha_3: "PAN",
      phone_code: "507",
    },
    papuanewguinea: {
      name: "Papua New Guinea",
      native_name: "Papua Niugini",
      capital: "Port Moresby",
      alpha_2: "PG",
      alpha_3: "PNG",
      phone_code: "675",
    },
    paraguay: {
      name: "Paraguay",
      native_name: "Paraguay",
      capital: "Asunción",
      alpha_2: "PY",
      alpha_3: "PRY",
      phone_code: "595",
    },
    peru: {
      name: "Peru",
      native_name: "Perú",
      capital: "Lima",
      alpha_2: "PE",
      alpha_3: "PER",
      phone_code: "51",
    },
    philippines: {
      name: "Philippines",
      native_name: "Pilipinas",
      capital: "Manila",
      alpha_2: "PH",
      alpha_3: "PHL",
      phone_code: "63",
    },
    pitcairnislands: {
      name: "Pitcairn Islands",
      native_name: "Pitcairn Islands",
      capital: "Adamstown",
      alpha_2: "PN",
      alpha_3: "PCN",
      phone_code: "64",
    },
    poland: {
      name: "Poland",
      native_name: "Polska",
      capital: "Warsaw",
      alpha_2: "PL",
      alpha_3: "POL",
      phone_code: "48",
    },
    portugal: {
      name: "Portugal",
      native_name: "Portugal",
      capital: "Lisbon",
      alpha_2: "PT",
      alpha_3: "PRT",
      phone_code: "351",
    },
    puertorico: {
      name: "Puerto Rico",
      native_name: "Puerto Rico",
      capital: "San Juan",
      alpha_2: "PR",
      alpha_3: "PRI",
      phone_code: "1787",
    },
    qatar: {
      name: "Qatar",
      native_name: "قطر",
      capital: "Doha",
      alpha_2: "QA",
      alpha_3: "QAT",
      phone_code: "974",
    },
    republicofkosovo: {
      name: "Republic of Kosovo",
      native_name: "Republika e Kosovës",
      capital: "Pristina",
      alpha_2: "XK",
      alpha_3: "KOS",
      phone_code: "383",
    },
    réunion: {
      name: "Réunion",
      native_name: "Réunion",
      capital: "Saint-Denis",
      alpha_2: "RE",
      alpha_3: "REU",
      phone_code: "262",
    },
    romania: {
      name: "Romania",
      native_name: "România",
      capital: "Bucharest",
      alpha_2: "RO",
      alpha_3: "ROU",
      phone_code: "40",
    },
    russia: {
      name: "Russia",
      native_name: "Россия",
      capital: "Moscow",
      alpha_2: "RU",
      alpha_3: "RUS",
      phone_code: "7",
    },
    rwanda: {
      name: "Rwanda",
      native_name: "Rwanda",
      capital: "Kigali",
      alpha_2: "RW",
      alpha_3: "RWA",
      phone_code: "250",
    },
    saintbarthélemy: {
      name: "Saint Barthélemy",
      native_name: "Saint-Barthélemy",
      capital: "Gustavia",
      alpha_2: "BL",
      alpha_3: "BLM",
      phone_code: "590",
    },
    sainthelena: {
      name: "Saint Helena",
      native_name: "Saint Helena",
      capital: "Jamestown",
      alpha_2: "SH",
      alpha_3: "SHN",
      phone_code: "290",
    },
    saintkittsandnevis: {
      name: "Saint Kitts and Nevis",
      native_name: "Saint Kitts and Nevis",
      capital: "Basseterre",
      alpha_2: "KN",
      alpha_3: "KNA",
      phone_code: "1869",
    },
    saintlucia: {
      name: "Saint Lucia",
      native_name: "Saint Lucia",
      capital: "Castries",
      alpha_2: "LC",
      alpha_3: "LCA",
      phone_code: "1758",
    },
    saintmartin: {
      name: "Saint Martin",
      native_name: "Saint-Martin",
      capital: "Marigot",
      alpha_2: "MF",
      alpha_3: "MAF",
      phone_code: "590",
    },
    saintpierreandmiquelon: {
      name: "Saint Pierre and Miquelon",
      native_name: "Saint-Pierre-et-Miquelon",
      capital: "Saint-Pierre",
      alpha_2: "PM",
      alpha_3: "SPM",
      phone_code: "508",
    },
    saintvincentandthegrenadines: {
      name: "Saint Vincent and the Grenadines",
      native_name: "Saint Vincent and the Grenadines",
      capital: "Kingstown",
      alpha_2: "VC",
      alpha_3: "VCT",
      phone_code: "1784",
    },
    samoa: {
      name: "Samoa",
      native_name: "Samoa",
      capital: "Apia",
      alpha_2: "WS",
      alpha_3: "WSM",
      phone_code: "685",
    },
    sanmarino: {
      name: "San Marino",
      native_name: "San Marino",
      capital: "City of San Marino",
      alpha_2: "SM",
      alpha_3: "SMR",
      phone_code: "378",
    },
    sãotoméandpríncipe: {
      name: "São Tomé and Príncipe",
      native_name: "São Tomé e Príncipe",
      capital: "São Tomé",
      alpha_2: "ST",
      alpha_3: "STP",
      phone_code: "239",
    },
    saudiarabia: {
      name: "Saudi Arabia",
      native_name: "العربية السعودية",
      capital: "Riyadh",
      alpha_2: "SA",
      alpha_3: "SAU",
      phone_code: "966",
    },
    senegal: {
      name: "Senegal",
      native_name: "Sénégal",
      capital: "Dakar",
      alpha_2: "SN",
      alpha_3: "SEN",
      phone_code: "221",
    },
    serbia: {
      name: "Serbia",
      native_name: "Србија",
      capital: "Belgrade",
      alpha_2: "RS",
      alpha_3: "SRB",
      phone_code: "381",
    },
    seychelles: {
      name: "Seychelles",
      native_name: "Seychelles",
      capital: "Victoria",
      alpha_2: "SC",
      alpha_3: "SYC",
      phone_code: "248",
    },
    sierraleone: {
      name: "Sierra Leone",
      native_name: "Sierra Leone",
      capital: "Freetown",
      alpha_2: "SL",
      alpha_3: "SLE",
      phone_code: "232",
    },
    singapore: {
      name: "Singapore",
      native_name: "Singapore",
      capital: "Singapore",
      alpha_2: "SG",
      alpha_3: "SGP",
      phone_code: "65",
    },
    sintmaarten: {
      name: "Sint Maarten",
      native_name: "Sint Maarten",
      capital: "Philipsburg",
      alpha_2: "SX",
      alpha_3: "SXM",
      phone_code: "1721",
    },
    slovakia: {
      name: "Slovakia",
      native_name: "Slovensko",
      capital: "Bratislava",
      alpha_2: "SK",
      alpha_3: "SVK",
      phone_code: "421",
    },
    slovenia: {
      name: "Slovenia",
      native_name: "Slovenija",
      capital: "Ljubljana",
      alpha_2: "SI",
      alpha_3: "SVN",
      phone_code: "386",
    },
    solomonislands: {
      name: "Solomon Islands",
      native_name: "Solomon Islands",
      capital: "Honiara",
      alpha_2: "SB",
      alpha_3: "SLB",
      phone_code: "677",
    },
    somalia: {
      name: "Somalia",
      native_name: "Soomaaliya",
      capital: "Mogadishu",
      alpha_2: "SO",
      alpha_3: "SOM",
      phone_code: "252",
    },
    southafrica: {
      name: "South Africa",
      native_name: "South Africa",
      capital: "Pretoria",
      alpha_2: "ZA",
      alpha_3: "ZAF",
      phone_code: "27",
    },
    southgeorgia: {
      name: "South Georgia",
      native_name: "South Georgia",
      capital: "King Edward Point",
      alpha_2: "GS",
      alpha_3: "SGS",
      phone_code: "500",
    },
    southkorea: {
      name: "South Korea",
      native_name: "대한민국",
      capital: "Seoul",
      alpha_2: "KR",
      alpha_3: "KOR",
      phone_code: "82",
    },
    southsudan: {
      name: "South Sudan",
      native_name: "South Sudan",
      capital: "Juba",
      alpha_2: "SS",
      alpha_3: "SSD",
      phone_code: "211",
    },
    spain: {
      name: "Spain",
      native_name: "España",
      capital: "Madrid",
      alpha_2: "ES",
      alpha_3: "ESP",
      phone_code: "34",
    },
    srilanka: {
      name: "Sri Lanka",
      native_name: "śrī laṃkāva",
      capital: "Colombo",
      alpha_2: "LK",
      alpha_3: "LKA",
      phone_code: "94",
    },
    sudan: {
      name: "Sudan",
      native_name: "السودان",
      capital: "Khartoum",
      alpha_2: "SD",
      alpha_3: "SDN",
      phone_code: "249",
    },
    suriname: {
      name: "Suriname",
      native_name: "Suriname",
      capital: "Paramaribo",
      alpha_2: "SR",
      alpha_3: "SUR",
      phone_code: "597",
    },
    svalbardandjanmayen: {
      name: "Svalbard and Jan Mayen",
      native_name: "Svalbard og Jan Mayen",
      capital: "Longyearbyen",
      alpha_2: "SJ",
      alpha_3: "SJM",
      phone_code: "4779",
    },
    swaziland: {
      name: "Swaziland",
      native_name: "Swaziland",
      capital: "Lobamba",
      alpha_2: "SZ",
      alpha_3: "SWZ",
      phone_code: "268",
    },
    sweden: {
      name: "Sweden",
      native_name: "Sverige",
      capital: "Stockholm",
      alpha_2: "SE",
      alpha_3: "SWE",
      phone_code: "46",
    },
    switzerland: {
      name: "Switzerland",
      native_name: "Schweiz",
      capital: "Bern",
      alpha_2: "CH",
      alpha_3: "CHE",
      phone_code: "41",
    },
    syria: {
      name: "Syria",
      native_name: "سوريا",
      capital: "Damascus",
      alpha_2: "SY",
      alpha_3: "SYR",
      phone_code: "963",
    },
    taiwan: {
      name: "Taiwan",
      native_name: "臺灣",
      capital: "Taipei",
      alpha_2: "TW",
      alpha_3: "TWN",
      phone_code: "886",
    },
    tajikistan: {
      name: "Tajikistan",
      native_name: "Тоҷикистон",
      capital: "Dushanbe",
      alpha_2: "TJ",
      alpha_3: "TJK",
      phone_code: "992",
    },
    tanzania: {
      name: "Tanzania",
      native_name: "Tanzania",
      capital: "Dodoma",
      alpha_2: "TZ",
      alpha_3: "TZA",
      phone_code: "255",
    },
    thailand: {
      name: "Thailand",
      native_name: "ประเทศไทย",
      capital: "Bangkok",
      alpha_2: "TH",
      alpha_3: "THA",
      phone_code: "66",
    },
    easttimor: {
      name: "East Timor",
      native_name: "Timor-Leste",
      capital: "Dili",
      alpha_2: "TL",
      alpha_3: "TLS",
      phone_code: "670",
    },
    togo: {
      name: "Togo",
      native_name: "Togo",
      capital: "Lomé",
      alpha_2: "TG",
      alpha_3: "TGO",
      phone_code: "228",
    },
    tokelau: {
      name: "Tokelau",
      native_name: "Tokelau",
      capital: "Fakaofo",
      alpha_2: "TK",
      alpha_3: "TKL",
      phone_code: "690",
    },
    tonga: {
      name: "Tonga",
      native_name: "Tonga",
      capital: "Nuku'alofa",
      alpha_2: "TO",
      alpha_3: "TON",
      phone_code: "676",
    },
    trinidadandtobago: {
      name: "Trinidad and Tobago",
      native_name: "Trinidad and Tobago",
      capital: "Port of Spain",
      alpha_2: "TT",
      alpha_3: "TTO",
      phone_code: "1868",
    },
    tunisia: {
      name: "Tunisia",
      native_name: "تونس",
      capital: "Tunis",
      alpha_2: "TN",
      alpha_3: "TUN",
      phone_code: "216",
    },
    turkey: {
      name: "Turkey",
      native_name: "Türkiye",
      capital: "Ankara",
      alpha_2: "TR",
      alpha_3: "TUR",
      phone_code: "90",
    },
    turkmenistan: {
      name: "Turkmenistan",
      native_name: "Türkmenistan",
      capital: "Ashgabat",
      alpha_2: "TM",
      alpha_3: "TKM",
      phone_code: "993",
    },
    turksandcaicosislands: {
      name: "Turks and Caicos Islands",
      native_name: "Turks and Caicos Islands",
      capital: "Cockburn Town",
      alpha_2: "TC",
      alpha_3: "TCA",
      phone_code: "1649",
    },
    tuvalu: {
      name: "Tuvalu",
      native_name: "Tuvalu",
      capital: "Funafuti",
      alpha_2: "TV",
      alpha_3: "TUV",
      phone_code: "688",
    },
    uganda: {
      name: "Uganda",
      native_name: "Uganda",
      capital: "Kampala",
      alpha_2: "UG",
      alpha_3: "UGA",
      phone_code: "256",
    },
    ukraine: {
      name: "Ukraine",
      native_name: "Україна",
      capital: "Kiev",
      alpha_2: "UA",
      alpha_3: "UKR",
      phone_code: "380",
    },
    unitedarabemirates: {
      name: "United Arab Emirates",
      native_name: "دولة الإمارات العربية المتحدة",
      capital: "Abu Dhabi",
      alpha_2: "AE",
      alpha_3: "ARE",
      phone_code: "971",
    },
    unitedkingdom: {
      name: "United Kingdom",
      native_name: "United Kingdom",
      capital: "London",
      alpha_2: "GB",
      alpha_3: "GBR",
      phone_code: "44",
    },
    unitedstates: {
      name: "United States",
      native_name: "United States",
      capital: "Washington D.C.",
      alpha_2: "US",
      alpha_3: "USA",
      phone_code: "1",
    },
    uruguay: {
      name: "Uruguay",
      native_name: "Uruguay",
      capital: "Montevideo",
      alpha_2: "UY",
      alpha_3: "URY",
      phone_code: "598",
    },
    uzbekistan: {
      name: "Uzbekistan",
      native_name: "O‘zbekiston",
      capital: "Tashkent",
      alpha_2: "UZ",
      alpha_3: "UZB",
      phone_code: "998",
    },
    vanuatu: {
      name: "Vanuatu",
      native_name: "Vanuatu",
      capital: "Port Vila",
      alpha_2: "VU",
      alpha_3: "VUT",
      phone_code: "678",
    },
    venezuela: {
      name: "Venezuela",
      native_name: "Venezuela",
      capital: "Caracas",
      alpha_2: "VE",
      alpha_3: "VEN",
      phone_code: "58",
    },
    vietnam: {
      name: "Vietnam",
      native_name: "Việt Nam",
      capital: "Hanoi",
      alpha_2: "VN",
      alpha_3: "VNM",
      phone_code: "84",
    },
    wallisandfutuna: {
      name: "Wallis and Futuna",
      native_name: "Wallis et Futuna",
      capital: "Mata-Utu",
      alpha_2: "WF",
      alpha_3: "WLF",
      phone_code: "681",
    },
    westernsahara: {
      name: "Western Sahara",
      native_name: "الصحراء الغربية",
      capital: "El Aaiún",
      alpha_2: "EH",
      alpha_3: "ESH",
      phone_code: "212",
    },
    yemen: {
      name: "Yemen",
      native_name: "اليَمَن",
      capital: "Sana'a",
      alpha_2: "YE",
      alpha_3: "YEM",
      phone_code: "967",
    },
    zambia: {
      name: "Zambia",
      native_name: "Zambia",
      capital: "Lusaka",
      alpha_2: "ZM",
      alpha_3: "ZMB",
      phone_code: "260",
    },
    zimbabwe: {
      name: "Zimbabwe",
      native_name: "Zimbabwe",
      capital: "Harare",
      alpha_2: "ZW",
      alpha_3: "ZWE",
      phone_code: "263",
    },
    other: {
      name: "Other",
      en_name: "Other",
      native_name: "Other",
      alpha_2: "AA",
      alpha_3: "AAA",
      phone_code: "000",
    },
  },
};
export default worldCountries;
