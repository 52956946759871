import React, { useEffect, useState } from "react";
import produce from "immer";
import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import $ from "jquery";

import CartStep from "./cartStep/CartStep";
import { CartProps } from "../../interfaces/CartProps";
import { Text, TextL } from "../typography/Texts";
import Theme from "../../theme";
import Button from "../buttons/Button";
import { Box } from "../styles/Container";
import api from "../../client/api";

import Icon from "../../img/icon-date.png";

export default function DateSelection({
  cart,
  setCart,
  service,
  title,
  subtitle,
  active,
  step,
}: {
  cart: CartProps;
  setCart: React.Dispatch<React.SetStateAction<CartProps>>;
  service: string;
  title: string;
  subtitle: string;
  active: boolean;
  step: number;
}) {
  const [schedules, setSchedules] = useState<any>();
  const { t, i18n } = useTranslation();
  const localeActive = require(`date-fns/locale/${i18n.language.slice(
    0,
    2
  )}/index.js`);

  useEffect(() => {
    api.schedule((schedules) => setSchedules(schedules));
  }, []);

  const today = new Date();
  today.setDate(today.getDate() + 15);
  // if (
  //   cart.services.some((serv) => serv.name === "PROMOTV") &&
  //   (cart.step === 4 || cart.modify === 4)
  // ) {
  //   today.setDate(today.getDate() + 14);
  // } else {
  //   today.setDate(today.getDate() + 3);
  // }
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 12);

  function handleDate(field, date) {
    $("html,body").animate(
      {
        scrollTop:
          service === "UPLOAD"
            ? $("#validate-date").offset().top - 250
            : service === "PROMOTV" && $("#validate-date").offset().top - 230,
      },
      "slow"
    );
    $("html,body").clearQueue();
    if (cart.services.some((el) => el.name === field)) {
      setCart(
        produce((draft) => {
          draft.services.filter((el) => el.name === field)[0].date = date;
        })
      );
    }
  }
  function nextStep() {
    setCart(
      produce((draft) => {
        draft.step = step + 1;
        draft.modify = false;
      })
    );
  }
  function modifyThisStep() {
    setCart(
      produce((draft) => {
        draft.modify = step;
      })
    );
  }
  const defaultDate = cart.services.filter((el) => {
    return el.name === service;
  })[0].date;

  function disabledDates(service) {
    let disabledDates = schedules
      ?.filter(
        (sc) =>
          sc.unavailableServices.find((s) => s.serviceType === service) != null
      )
      .map((d) => new Date(d.date));
    return disabledDates;
  }

  let dates = [];
  if (cart.services.some((serv) => serv.name === "UPLOAD")) {
    disabledDates("UPLOAD")?.forEach((d) => {
      dates.push(d);
    });
  }

  if (cart.services.some((serv) => serv.name === "PROMOTV")) {
    if (
      cart.services.filter(
        (serv) => serv.name === "PROMOTV" && serv.offer === "Premium"
      )
    ) {
      disabledDates("PROMO_PREMIUM")?.forEach((d) => {
        dates.push(d);
      });
    }
    if (
      cart.services.filter(
        (serv) => serv.name === "PROMOTV" && serv.offer === "Platinium"
      )
    ) {
      disabledDates("PROMO_PLATINIUM")?.forEach((d) => {
        dates.push(d);
      });
    }
  }

  const allowNextStep = cart.services
    .filter((serv) => serv.name === service)
    .some((el) => el.date);

  return (
    <CartStep
      title={title}
      icon={Icon}
      active={active}
      modify={() => modifyThisStep()}
    >
      {active ? (
        <>
          <TextL bold>{subtitle}</TextL>
          <Box my="20px">
            <InfiniteCalendar
              theme={{
                selectionColor: Theme.dark.colors.orange,
                weekdayColor: Theme.dark.colors.orange,
                headerColor: Theme.dark.colors.orange,
                floatingNav: {
                  background: Theme.dark.colors.orange,
                },
              }}
              width="100%"
              height={300}
              min={today}
              max={maxDate}
              minDate={today}
              maxDate={maxDate}
              locale={{
                locale: localeActive,
                headerFormat: "dddd D MMM",
                weekdays: [
                  t("dim"),
                  t("lun"),
                  t("mard"),
                  t("mer"),
                  t("jeu"),
                  t("ven"),
                  t("sam"),
                ],
                blank: t("noDateSelected"),
                todayLabel: {
                  long: t("today"),
                },
              }}
              selected={defaultDate}
              disabledDates={dates}
              onSelect={(date) => handleDate(service, date)}
            />
          </Box>
          <Button
            fullWidth
            style={{ left: "15%" }}
            disabled={!allowNextStep}
            onClick={() => {
              allowNextStep && nextStep();
            }}
            id="validate-date"
          >
            {t("validation")}
          </Button>
        </>
      ) : (
        <Box>
          <TextL mb="10px">{t("selectedDate")}</TextL>
          <Text>
            {format(
              cart.services.filter((serv) => serv.name === service)[0].date,
              "DD/MM/YYYY"
            )}
          </Text>
        </Box>
      )}
    </CartStep>
  );
}
