import { Redirect } from 'react-router-dom';
import { Title4 } from "../Components/typography/Titles";
import { Text } from "../Components/typography/Texts";
import { Box } from '../Components/styles/Container';
import Banner from '../Components/banner/Banner';
import imgCover from '../img/confirmation.jpg';
import { AvantageSlider } from '../Components/avantages/Avantages';
import api from '../client/api';
import { UserProps } from "../interfaces/UserProps";

export default function MaxiCash(props: { async: any; user: UserProps }) {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');
  const reference = urlParams.get('reference');
  if (props.user === null) {
    return <Redirect to={window.location.pathname + window.location.search} />;
  } else {
    if (props.async) {
      api.touchpayCallbackAsync({
        status: status,
        orderNumber: reference
      });
    } else {
      api.touchpayCallback({
        code: null,
        status: status,
        touchPayOrderNumber: null,
        orderNumber: reference,
        amount: null
      });
    };
  }

  return (
    <>
      <Banner
        children={
          <>
            <Box center>
              <Title4 bold>
                Cher(e) <span>{props.user.firstName}</span>,
                {status === 'success' && (
                  <>
                    <br /> Merci pour ta commande.
                  </>
                )}
                {status !== 'success' && (
                  <>
                    <br /> Votre commande n'a pas pu aboutir.
                  </>
                )}
              </Title4>
            </Box>
            <Text mb={4}>
              Toutes ces informations ont aussi été envoyées sur ton mail :
              <Text my={2} bold orange center>
                {props.user.emailAddress}
              </Text>
              {status === 'success' && props.user.firstName && (
                <Text>
                  Tu peux tout de suite commencer à vendre ta musique en cliquant{' '}
                  <a
                    href="https://distribution.pro.youtrace.tv/albums"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ici
                  </a>
                  .
                </Text>
              )}
              <br /> Si tu as des questions, n’hésites pas à nous écrire à :{' '}
              <Text my={2} bold orange center>
                contact@youtrace.tv
              </Text>
            </Text>
            <Text>À très vite sur YouTrace.</Text>
          </>
        }
        img={imgCover}
        alt="YouTrace confirmation"
        imgHeight="500px"
      />
      <AvantageSlider />
    </>
  );
}
