import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Form, FormCol } from "../styles/Form";
import { Box, Flex } from "../styles/Container";
import InputText from "../form/NewInputText";
import InputPassword from "../form/InputPassword";
import Button from "../buttons/Button";
import api from "../../auth/auth";
import { Text } from "../typography/Texts";

export default function LoginForm({
  handleLogin,
}: {
  handleLogin?: () => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  function onError(error) {
    setError(true);
  }
  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (values) => {
      api.fetchAccessToken(
        handleLogin(),
        onError,
        values.login,
        values.password
      );
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .min(5, t("loginLeast"))
        .max(50, t("loginMax"))
        .email(t("emailInvalid"))
        .required(t("completeField")),
      password: Yup.string().required(t("completeField")),
    }),
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Flex justifyContent="space-between">
        <FormCol>
          <InputText
            name="login"
            label={t("registration.email") + "* :"}
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.login}
            error={formik.errors.login}
          />
        </FormCol>
        <FormCol>
          <InputPassword
            name="password"
            label={t("registration.password") + "* :"}
            handleChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />
        </FormCol>
        <Box mt="20px" center>
          {error && (
            <Text center error>
              {t("loginError")}
            </Text>
          )}
          <button
            style={{ width: "100%", backgroundColor: "transparent" }}
            type="submit"
          >
            <Button fullWidth>{t("login.connect")}</Button>
          </button>
        </Box>
      </Flex>
    </Form>
  );
}
