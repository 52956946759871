import styled, { css } from "styled-components";

const Social = styled.a`
  margin: 0 5px;
  color: white;
  text-decoration: none;
  transition: 0.4s;
  &:hover {
    opacity: 0.6;
  }
`;

const FooterTop = styled.div<{ center?: boolean }>`
  .b2c {
    text-decoration: none;
    position: relative;
    margin-left: 20px;
    color: white !important;
    font-size: 1rem;
    &::before {
      content: "‹";
      background: ${(props) => props.theme.colors.orange};
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: -20px;
      top: 0px;
      border-radius: 50%;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      line-height: 13px;
    }
  }
  .b2c-orange {
    font-weight: bold;
    color: ${(props) => props.theme.colors.orange};
  }
  display: flex;
  justify-content: space-between;
  padding: 24px 60px 17px 60px;
  background: ${(props) => props.theme.colors.grey2};
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    padding: 17px 20px 15px 20px;
  }
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
`;

const FooterNav = styled.div`
  padding: 20px 60px 19px 60px;
  font-size: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.grey};
  background: ${(props) => props.theme.colors.grey2};
  a {
    transition: 0.3s ${(props) => props.theme.transitions.easeInOutCubic};
    cursor: pointer;
    letter-spacing: -0.4px;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    & + a {
      margin-left: 18px;
    }
    &.active {
      color: ${(props) => props.theme.colors.orange};
    }
    &:not(.active):not(.social) {
      color: #fff;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    padding: 17px 20px 17px 20px;
    li {
      font-size: 1rem;
      letter-spacing: -0.3px;
    }
  }
`;
export { Social, FooterTop, FooterNav };
