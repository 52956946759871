import styled, { css } from "styled-components";

export const BannerWrapper = styled.div<{ center?: boolean }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  text-align: center;
  min-height: 170px;
  flex-direction: column;
  background: #000;
  color: #fff;
  ${(props) =>
    props.center &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const BannerImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  z-index: 0;
  left: 0;
`;
