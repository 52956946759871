import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Box } from "../Components/styles/Container";
import { Title4 } from "../Components/typography/Titles";
import { Text } from "../Components/typography/Texts";
import Banner from "../Components/banner/Banner";
import { AvantageSlider } from "../Components/avantages/Avantages";
import api from "../client/api";

import imgCover from "../img/confirmation.jpg";
import { UserProps } from "../interfaces/UserProps";

export default function InTouch(props: { status: any; user: UserProps }) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (props.status != null) {
      api.touchpayCallback({
        code: urlParams.get("errorCode"),
        status: props.status,
        touchPayOrderNumber: urlParams.get("num_transaction_from_gu"),
        orderNumber: urlParams.get("num_command"),
        amount: urlParams.get("amount"),
      });
    } else {
      api.touchpayCallbackAsync({
        status: urlParams.get("status"),
        touchPayOrderNumber: urlParams.get("num_from_gu"),
      });
    }
  }, [props.status]);

  if (props.user === null) {
    return <Redirect to={window.location.pathname + window.location.search} />;
  }

  return (
    <>
      <Banner
        children={
          <>
            <Box center>
              <Title4 bold>
                Cher(e) <span>{props.user.firstName}</span>,
                {props.status === "success" && (
                  <>
                    <br /> Merci pour ta commande.
                  </>
                )}
                {props.status === "failed" && (
                  <>
                    <br /> Votre commande n'a pas pu aboutir.
                  </>
                )}
              </Title4>
            </Box>
            <Text mb={4}>
              Toutes ces informations ont aussi été envoyées sur ton mail :
              <Text my={2} bold orange center>
                {props.user.emailAddress}
              </Text>
              {props.status === "success" && props.user.firstName && (
                <Text>
                  Tu peux tout de suite commencer à vendre ta musique en
                  cliquant{" "}
                  <a
                    href='https://distribution.pro.youtrace.tv/albums'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    ici
                  </a>
                  .
                </Text>
              )}
              <br /> Si tu as des questions, n’hésites pas à nous écrire à :{" "}
              <Text my={2} bold orange center>
                contact@youtrace.tv
              </Text>
            </Text>
            <Text>À très vite sur YouTrace.</Text>
          </>
        }
        img={imgCover}
        alt='YouTrace confirmation'
        imgHeight='500px'
      />
      <AvantageSlider />
    </>
  );
}
