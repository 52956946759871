import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { Title2, Title4 } from "../../Components/typography/Titles.tsx";
import Container, { Box, Flex } from "../../Components/styles/Container.tsx";
import Button from "../../Components/buttons/Button.tsx";
import Link from "../../Components/styles/Link";
import SimpleLine from "../../Components/dashboard/graphics/SimpleLine";
import api from "../../client/api";
import Donut from "../../Components/dashboard/graphics/Donut";
import months from "../../utils/months";
import { activeLang } from "../../utils/translate";
import { userContext } from "../../context/userContext";

class Ventes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      volumeVentesData: [],
      annuel: false,
      month: new Date().getMonth(),
      year: new Date().getYear() + 1900,
    };
    this.switchView = this.switchView.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
  }

  componentDidMount() {
    api.ventes((data) => {
      this.setState({ volumeVentesData: data[this.state.year] });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.year !== prevState.year) {
      api.ventes((data) => {
        this.setState({ volumeVentesData: data[this.state.year] });
      });
    }
  }

  switchView() {
    this.setState((prevState) => ({
      annuel: !prevState.annuel,
    }));
  }

  prevMonth() {
    if (this.state.month > 0) {
      this.setState((prevState) => ({
        month: prevState.month - 1,
      }));
    } else if (this.state.month === 0 && this.state.year > 2020) {
      this.setState((prevState) => ({
        month: 11,
        year: prevState.year - 1,
      }));
    }
  }

  nextMonth() {
    const d = new Date();
    if (this.state.month < d.getMonth()) {
      this.setState((prevState) => ({
        month: prevState.month + 1,
      }));
    } else if (this.state.month > d.getMonth()) {
      this.setState((prevState) => ({
        month: 0,
        year: prevState.year + 1,
      }));
    }
  }

  render() {
    return (
      <userContext.Consumer>
        {({ user }) => {
          if (user) {
            if (user.idRole === "1") {
              return <Redirect to='/' />;
            } else {
              return (
                <Container>
                  <Box py={4} center>
                    <Title2>
                      <span>{this.props.t("bo.volume")}</span>
                    </Title2>
                  </Box>
                  {this.state.annuel ? (
                    <Box mb={4} center>
                      <Title4 bold>{this.props.t("bo.caservice")} YTD</Title4>
                      <SimpleLine
                        chartData={this.state.volumeVentesData}
                        yLabel={this.props.t("bo.ordersnb")}
                      />
                    </Box>
                  ) : (
                    <Box mb={4} center>
                      <Flex justifyContent='space-between'>
                        <i
                          style={{ cursor: "pointer" }}
                          className='icon-chevron-left'
                          onClick={this.prevMonth}
                        />
                        <Title4 bold mt={0}>
                          {`${months[activeLang][this.state.month]} ${
                            this.state.year
                          }`}
                        </Title4>
                        <i
                          style={{ cursor: "pointer" }}
                          className='icon-chevron-right'
                          onClick={this.nextMonth}
                        />
                      </Flex>
                      <Donut
                        data={this.state.volumeVentesData}
                        month={this.state.month}
                      />
                    </Box>
                  )}
                  <Box my={4} center>
                    {this.state.annuel ? (
                      <Button
                        fullWidth
                        mb={4}
                        href='#'
                        onClick={this.switchView}
                      >
                        {this.props.t("bo.caservice")} mensuel
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        mb={4}
                        href='#'
                        onClick={this.switchView}
                      >
                        {this.props.t("bo.caservice")} annuel
                      </Button>
                    )}
                    <Box>
                      <Link orange href='/dashboard'>
                        Dashboard
                      </Link>
                    </Box>
                  </Box>
                </Container>
              );
            }
          }
        }}
      </userContext.Consumer>
    );
  }
}

export default withTranslation()(Ventes);
