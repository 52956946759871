import React from "react";
import { DidomiSDK } from "@didomi/react";
import { useTranslation } from "react-i18next";

export default function CookieNotice() {
  const publicKey = "e6eff6cb-441a-47d0-9aa9-b8e9dbc448b0";
  const { i18n } = useTranslation();
  const didomiConfig = {
    languages: {
      enabled: [i18n.language?.slice(0, 2)],
      default: i18n.language?.slice(0, 2),
    },
  };
  return (
    <DidomiSDK
      apiKey={publicKey}
      config={didomiConfig}
      gdprAppliesGlobally={true}
    />
  );
}
