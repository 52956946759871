import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Container, { Box } from "../styles/Container";
import Button from "../buttons/Button";
import { Form, FormCol } from "../styles/Form";
import { Text } from "../typography/Texts";
import CountryDropdown from "../form/CountryDropdown";
import Link from "../styles/Link";
import InputText from "../form/InputText";
import InputPassword from "../form/InputPassword";

import worldCountries from "../../utils/worldCountries";
import i18n from "i18next";
import AllCountriesProps from "../../interfaces/AllCountriesProps";

export default function ModifyInfo({
  toggleModifying,
  onChange,
  onChangePassword,
  onSuggestions,
  onCountryChange,
  handleDeleteAccount,
  user,
}) {

  const { t } = useTranslation();
  const activeLang = i18n.language?.slice(0, 2);
  const countriesList: AllCountriesProps[] = Object?.values(worldCountries[activeLang]);
  // const labelString: any = `${t("registration.country")} :`

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("firstNameLeast"))
      .max(50, t("firstNameMax"))
      .required(t("firstNameEnter"))
      .matches(/^[a-zA-Z]/, {
        message: t("firstNameCheck"),
        excludeEmptyString: true,
      }),
    lastName: Yup.string()
      .min(2, t("surnameLeast"))
      .max(20, t("surnameMax"))
      .required(t("surnameEnter"))
      .matches(/^[a-zA-Z]/, {
        message: t("surnameCheck"),
        excludeEmptyString: true,
      }),
    phone: Yup.string()
      .min(8, t("phoneLeast"))
      .max(15, t("phoneMax"))
      .required(t("phoneEnter"))
      .matches(/^[+]/, {
        message: t("phoneCheck"),
        excludeEmptyString: true,
      })
      .matches(
        /^[+]((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        {
          message: t("phoneInvalid"),
          excludeEmptyString: true,
        }
      ),
    password: Yup.string()
      .min(8, t("passwordLeast"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t("passwordIncorrect")
      ),
    country: Yup.string()
      .test(
        t("countryEnter"),
        () => user.country != null
      )
      .test(
        t("countryValid"),
        () => countriesList.some((countries) => countries.name === user.country.country)
      )
      .nullable(),
  });

  return (
    <>
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        }}
        validationSchema={SignUpSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {
          toggleModifying();
          if (window.location.pathname === "/mon-compte") {
            window.location.href = "/mon-compte"
          }
        }}
      >
        {({ errors, handleChange, handleSubmit }: any) => (
          <>
            <Container small mt={4}>
              <Form>
                <FormCol style={{ padding: 0 }}>
                  <InputText
                    name='firstName'
                    label={`${t("registration.firstName")} :`}
                    value={user.firstName || ""}
                    handleChange={(e) => {
                      onChange(e, "firstName");
                      handleChange(e);
                    }}
                  />
                  {errors.firstName && <Text error>{errors.firstName}</Text>}
                </FormCol>
                <FormCol style={{ padding: 0 }}>
                  <InputText
                    name='lastName'
                    label={`${t("registration.surname")} :`}
                    value={user.lastName || ""}
                    handleChange={(e) => {
                      onChange(e, "lastName");
                      handleChange(e);
                    }}
                  />
                  {errors.lastName && <Text error>{errors.lastName}</Text>}
                </FormCol>
                <FormCol style={{ padding: 0 }}>
                  <InputText
                    name='phone'
                    label={`${t("registration.phone")} :`}
                    value={user.phone || ""}
                    handleChange={(e) => {
                      onChange(e, "phone");
                      handleChange(e);
                    }}
                  />
                  {errors.phone && <Text error>{errors.phone}</Text>}
                </FormCol>
                <FormCol style={{ padding: 0 }}>
                  <InputPassword
                    label={`${t("registration.password")} :`}
                    name='password'
                    value={user.password}
                    handleChange={(e) => {
                      onChangePassword(e, "password");
                      handleChange(e);
                    }}
                    error={errors.password}
                  />
                </FormCol>
                {user.country && (
                  <FormCol style={{ padding: 0 }}>
                    <CountryDropdown
                      label={`${t("registration.country")} :`}
                      value={user.country?.country}
                      onSuggestions={onSuggestions}
                      onCountryChange={onCountryChange}
                    />

                    {errors.country && <Text error>{errors.country}</Text>}
                  </FormCol>
                )}
                <FormCol dSNone />
                <Box center>
                  <Box mb={3}>
                    <Button fullWidth onClick={handleSubmit} mb={2}>
                      {t("save")}
                    </Button>
                  </Box>
                  <Link onClick={handleDeleteAccount} red>
                    {t("deleteMyAccount")}
                  </Link>
                </Box>
              </Form>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
}
