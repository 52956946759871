import styled from "styled-components";
import { Box } from "../styles/Container";

export const StepsWrap = styled(Box)`
  position: relative;
  &::after {
    content: "";
    height: 100%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    background: ${(props) => props.theme.colors.secondary};
    @media ${(props) => props.theme.mediaQueries.smallWidth} {
      content: none;
    }
  }
`;

export const StepsItem = styled.div`
  display: flex;
  @media ${(props) => props.theme.mediaQueries.minSmallWidth} {
    width: 50%;
    &:nth-child(odd) {
      margin-left: auto;
      .bullet {
        margin-left: -18px;
        margin-top: -2px;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .bullet {
        margin-right: -18px;
      }
    }
  }
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    margin-bottom: 0px;
  }
`;

export const StepsBullet = styled.div`
  width: 35px;
  flex-shrink: 0;
  height: 35px;
  font-weight: 900;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  z-index: 999;
  padding: 5px;
  border: 1px solid ${(props) => props.theme.colors.orange};
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.orange};
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    display: none;
  }
`;

export const StepIcon = styled.div`
  background: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 10px;
  width 90px;
  height 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -40px;
  margin-left: 30px;
  position: relative;
  z-index: 9;
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    margin: 0 10px 0 0;
    width 60px;
    height 60px;
    border: 0;
    float: left;
    img {
      width 40px;
    }
  }
`;

export const StepsText = styled.div`
  position: relative;
  padding: 70px 20px 50px 20px;
  width: 100%;
  background: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 0px;
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    border: 0;
    padding: 10px 0;
  }
  h4 {
    margin-bottom: 15px;
    @media ${(props) => props.theme.mediaQueries.smallWidth} {
      margin-bottom: 5px;
    }
  }
  .numberResp {
    margin-right: 5px;
    @media ${(props) => props.theme.mediaQueries.minSmallWidth} {
      display: none;
    }
  }
  .chevron {
    margin-left: auto;
    @media ${(props) => props.theme.mediaQueries.minSmallWidth} {
      display: none;
    }
  }
  .close:focus,
  .close:hover {
    cursor: pointer;
    color: inherit;
  }
  @media ${(props) => props.theme.mediaQueries.smallWidth} {
    .close {
      display: none;
    }
  }
`;
