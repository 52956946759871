import React from "react";
import Autosuggest from "react-autosuggest";
import { withTheme } from "styled-components";
import worldCountries from "../../utils/worldCountries";
import { withTranslation } from "react-i18next";

class CountryDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      countries: [],
    };
    this.activateFocus = this.activateFocus.bind(this);
    this.disableFocus = this.disableFocus.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }
  
  componentDidMount() {

    if (this.props.country && this.state.value === "") {
      this.setState({ value: this.props.country });
    }

    if (this.props.value !== "") {
      this.setState({
        fieldActive: true,
        value: this.props.value,
      });
    }
    const activeLang = this.props.i18n.language?.slice(0, 2);
    const countriesList = Object?.values(worldCountries[activeLang]);

    this.setState({ countries: countriesList });
  }

  getSuggestions = (value) => {
    const inputValue = value
      .trim()
      .toLowerCase()
      .replace(/ô/g, "o")
      .replace(/é|è/g, "e");
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.countries.filter(
          (lang) =>
            lang.name
              .toLowerCase()
              .replace(/ô/g, "o")
              .replace(/é|è/g, "e")
              .slice(0, inputLength) === inputValue
        );
  };

  getSuggestionValue = (suggestion) => suggestion.name;
  
  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
  
  
  activateFocus() {
    this.setState({
      fieldActive: true,
    });
  }

  disableFocus(e) {
    if (e.target.value === "") {
      this.setState({
        fieldActive: false,
      });
    }
  }

  onChange = (event, { newValue }) => {
    if (event.type === "click") {
      this.props.onCountryChange(this.getSuggestions(newValue));
    }
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    let suggestions = this.getSuggestions(value);
    this.props.onSuggestions(suggestions);
    this.setState({
      suggestions: suggestions,
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      onFocus: this.activateFocus,
      onBlur: this.disableFocus,
    };

    const theme = {
      container: {
        position: "relative",
      },
      input: {
        background: "transparent",
        height: "40px",
        padding: "10px",
        outline: "none",
        marginTop: "10px",
        fontSize: "1rem",
        width: "100%",
        color: this.props.theme.colors.secondary,
        border: "1px solid" + this.props.theme.colors.secondary,
        fontFamily: this.props.theme.fonts.inter,
        boxSizing: "border-box",
        transition: "0.18s" + this.props.theme.transitions.easeInCubic,
      },
      inputFocused: {
        outline: "none",
      },
      inputOpen: {
        borderRadius: 0,
      },
      suggestionsContainer: {
        display: "none",
      },
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        top: 43,
        width: "100%",
        border: "1px solid white",
        color: "white",
        maxHeight: "200px",
        overflow: "auto",
        fontFamily: "Helvetica, sans-serif",
        fontWeight: 300,
        fontSize: "1rem",
        zIndex: 999,
      },
      suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
        background: "black",
      },
      suggestion: {
        cursor: "pointer",
        padding: "0.98rem",
        background: "black",
        fontWeight: "400",
      },
      suggestionHighlighted: {
        background: "black",
        color: this.props.theme.colors.orange,
      },
    };

    return (
      <div className='autosuggest'>
        <label
          className={this.state.fieldActive ? "field field-active" : "field"}
        >
          {this.props.label}
        </label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={theme}
        />
      </div>
    );
  }
}

export default withTranslation()(withTheme(CountryDropdown));
