import styled from "styled-components";
import { Radio } from "../styles/Form";

export const PaymentSolutionWrapper = styled(Radio)`
  margin: 10px;
  text-align: left;
  div {
    margin-right: 12px;
    display: inline-block;
    vertical-align: top;
  }
  label {
    display: flex;
    span {
      margin-right: 15px;
    }
  }
  img {
    margin: 10px 0;
    max-height: 36px;
  }
`;
