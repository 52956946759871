import uuid from "uuid";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { Text, TextL, TextXS } from "../typography/Texts";
import Container, { Box } from "../styles/Container";
import { AvantagesItem, AvantagesIcon } from "./Avantages.style";

import iconClip from "../../img/clip.svg";
// import iconDiffusion from "../../img/diffusion.png";
import iconYoutube from "../../img/youtube.png";

function Avantage({
  icon,
  title,
  text,
}: {
  icon?: string;
  title?: string;
  text?: string;
}) {
  return (
    <AvantagesItem>
      <AvantagesIcon>
        <img src={icon} alt={title} />
      </AvantagesIcon>
      <Text bold mb={2}>
        {title}
      </Text>
      {text && <TextXS mb={2} dangerouslySetInnerHTML={{ __html: text }} />}
    </AvantagesItem>
  );
}

function AvantageSlider() {
  const { t } = useTranslation();
  const avantages = [
    {
      icon: iconClip,
      title: t("makeMyVideo.title"),
      text: t("makeMyVideo.text"),
    },
    // {
    //   icon: iconDiffusion,
    //   title: t("shareMyVideo.title"),
    //   text: t("shareMyVideo.text"),
    // },
    {
      icon: iconYoutube,
      title: t("digitalPromo.title"),
      text: t("digitalPromo.text"),
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          autoplay: true,
          autoplaySpeed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Box my={5}>
      <Container fluid>
        <TextL my={4} style={{ textAlign: "center" }}>
          {t("home.title2.part1")}
          <span>{t("home.title2.part2")}</span>
          <br /> {t("home.title2.part3")} <span>{t("home.title2.part4")}</span>
        </TextL>
        <Box>
          <Slider {...settings}>
            {avantages.map((avantage) => (
              <Avantage key={uuid} {...avantage} />
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}

export { Avantage, AvantageSlider };
