import styled from "styled-components";

const Modal = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  @media ${(props) => props.theme.mediaQueries.mediumWidth} {
    padding: 20px;
  }
`;

const ModalWrapper = styled.div`
  border: 1px solid;
  background: #000;
  padding: 30px 40px;
  position: relative;
  min-height: 400px;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  transition: all 0.4s;

  &:hover {
    transform: rotate(90deg);
  }
`;

export default Modal;
export { ModalWrapper, ModalClose };
