import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import produce from "immer";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import $ from "jquery";

import Container, { Box, Flex } from "../Components/styles/Container";
import Breadcrumb from "../Components/breadcrumb/Breadcrumb";
import CartWidget from "../Components/cartWidget/CartWidget";
// import CrossSelling from "../Components/cart/CrossSelling";
import { AvantageSlider } from "../Components/avantages/Avantages";
import { userContext } from "../context/userContext";
import { CartProps } from "../interfaces/CartProps";
import InformationsClip from "../Components/cart/InformationsClip";
import DateSelection from "../Components/cart/DateSelection";
import Contrats from "../Components/cart/Contrats";
import Payment from "../Components/cart/Payment";
import { UserProps } from "../interfaces/UserProps";
import AccountStep from "../Components/cart/Account";
import Options from "../Components/subscription/Options";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ResponsiveTotal from "../Components/cart/ResponsiveTotal";
import Registration from "../Components/cart/Registration";

export default function Cart() {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [cart, setCart] = useState<CartProps>();
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [crossSellService, setCrossSellService] = useState<string>("");
  const { state }: { state: { cart: CartProps } } = useLocation();
  const isLogged = localStorage.getItem("access_token") != null;

  let totalPrice = 0;
  cart?.services.map((el) => (totalPrice = totalPrice + el.price));

  useEffect(() => {
    if (!state) {
      window.location.href = "/services";
    } else {
      setCart(state.cart);
      setCart(
        produce((draft) => {
          draft.step = 1;
        })
      );
      // if (state.cart.services[0].name === "PROMOTV") {
      //   setCrossSellService("UPLOAD");
      // } else if (state.cart.services[0].name === "PACK") {
      //   setCrossSellService("PROMOTV");
      // } else {
      //   nextStep();
      // }
      // if (
      //   state.cart.services.some((serv) => serv.name === "PACK") &&
      //   !cart?.services.some((serv) => serv.name === "UPLOAD")
      // ) {
      //   setCart(
      //     produce((draft) => {
      //       draft.services.push({
      //         name: "UPLOAD",
      //         offer: "Classic",
      //         price: 0,
      //         options: [
      // {
      //   name: "Youtube",
      //   description: t("upload.youtube"),
      //   active: true,
      // },
      // {
      //   name: "Youtrace.tv",
      //   description: t("upload.youtrace"),
      //   active: true,
      // },
      //         ],
      //       });
      //     })
      //   );
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // animation
  if (cart?.step === 2) {
    $("html,body").animate(
      {
        scrollTop:
          width > 992
            ? $("#infos-clip").offset().top - 420
            : $("#infos-clip").offset().top - 540,
      },
      "fast"
    );
    $("html,body").clearQueue();
  } else if (cart?.step === 3 && !$("#hidden-upload-date").length) {
    $("html,body").animate(
      {
        scrollTop:
          width > 992
            ? $("#upload-date").offset().top - 380
            : state.cart.services[0].name === "PACK"
              ? $("#upload-date").offset().top - 730
              : $("#upload-date").offset().top - 400,
      },
      "fast"
    );
    $("html,body").clearQueue();
  } else if (cart?.step === 4 && !$("#hidden-promo-date").length) {
    $("html,body").animate(
      {
        scrollTop:
          width > 992
            ? $("#promo-date").offset().top - 460
            : $("#promo-date").offset().top - 480,
      },
      "fast"
    );
    $("html,body").clearQueue();
  }

  function nextStep() {
    setCart(
      produce((draft) => {
        draft.step += 1;
        draft.modify = false;
      })
    );
  }

  function previousStep() {
    let servArray = [...cart.services];
    if (cart.step <= 1 && cart.services.length === 1) {
      if (cart.services[0].name === "PACK") {
        setCart(
          produce((draft) => {
            draft.services = servArray.splice(0, 1);
            draft.modify = "service_" + cart.services[0].name;
            draft.step = 1;
          })
        )
      } else {
        setCart(
          produce((draft) => {
            draft.modify = "service_" + cart.services[0].name;
          })
        );
      }
    } else if (cart.step === 1 && cart.services.length === 2) {
      setCart(
        produce((draft) => {
          draft.services = servArray.splice(0, 1);
          draft.step = 0;
        })
      )
    } else if (cart.step === 2) {
      setCart(
        produce((draft) => {
          draft.step = 1;
        })
      )
    } else if (cart.step === 4) {
      setCart(
        produce((draft) => {
          draft.step = 2;
        })
      )
    } else if (cart.step === 5) {
      if (cart.services.some((el) => el.name === "PROMOTV")) {
        setCart(
          produce((draft) => {
            draft.step = 4;
          })
        )
      } else {
        setCart(
          produce((draft) => {
            draft.step = 2;
          })
        )
      }
    } else {
      setCart(
        produce((draft) => {
          draft.step = cart.step - 1;
        })
      )
    }
    // setCart(
    //   produce((draft) => {
    //     draft.step -= 1;
    //     draft.modify = false;
    //   })
    // );
  }

  // if (cart?.step === 0 &&
  //   cart.services.some((el) => el.name === "PROMOTV") &&
  //   !cart.services.some((el) => el.name === "UPLOAD")) {
  //   nextStep();
  // }

  if (cart?.step === 4 && !cart.services.some((el) => el.name === "PROMOTV")) {
    nextStep();
  } else if (
    cart?.step === 3 &&
    !cart.services.some((el) => el.name === "UPLOAD")
  ) {
    nextStep();
  }

  if (cart) {
    if (!cart.services.some((el) => el.price > 0)) {
      window.location.href = "/services";
    }
    
    return (
      <userContext.Consumer>
        {({ user }: { user: UserProps }) => (
          <Box my={["20px", "50px"]}>
            <Container style={{ width: "100%" }}>
              {cart.modify?.toString().startsWith("service_") ? (
                <Options cart={cart} setCart={setCart} />
              ) : (
                <Flex style={{ position: "relative" }}>
                  <div
                    style={{
                      width: width > 992 ? "calc( 100% - 340px)" : "100%",
                    }}
                  >
                    {width > 767 && cart.step <= 6 && (
                      <Breadcrumb currentStep={cart.step} />
                    )}

                    {cart.step >= 0 && cart.step <= 6 && (
                      <button style={{ fontSize: width < 992 ? 17 : 20, color: "#EA6C13", backgroundColor: "transparent", marginBottom: 20, textDecoration: "underline", cursor: "pointer" }} onClick={() => previousStep()}> &#60; {t("goToPreviousStep")}</button>
                    )}

                    {/* {cart.step === 0 && (
                      <CrossSelling
                        cart={cart}
                        setCart={setCart}
                        active={cart.step === 0}
                        service={crossSellService}
                      />
                    )} */}
                    {cart.step === 1 && !isLogged && <Registration />}
                    {cart.step >= 1 && cart.step < 7 && isLogged && (
                      <AccountStep
                        user={user}
                        setCart={setCart}
                        active={
                          (cart.step === 1 &&
                            !(cart.modify && cart.modify !== 1)) ||
                          cart.modify === 1
                        }
                      />
                    )}
                    <div id="infos-clip"></div>
                    {cart.step >= 2 && cart.step < 7 && (
                      <InformationsClip
                        cart={cart}
                        setCart={setCart}
                        active={
                          (cart.step === 2 &&
                            !(cart.modify && cart.modify !== 2)) ||
                          cart.modify === 2
                        }
                      />
                    )}
                    <div id="upload-date"></div>
                    {cart.step === 3 && <div id="hidden-upload-date"></div>}
                    {user &&
                      cart.step >= 3 &&
                      cart.step < 7 &&
                      cart.services.some((el) => el.name === "UPLOAD") && (
                        <DateSelection
                          title={t("uploadTitle")}
                          subtitle={t("uploadSubtitle")}
                          cart={cart}
                          setCart={setCart}
                          service="UPLOAD"
                          active={
                            (cart.step === 3 &&
                              !(cart.modify && cart.modify !== 3)) ||
                            cart.modify === 3
                          }
                          step={3}
                        />
                      )}
                    <div id="promo-date"></div>
                    {cart.step === 4 && <div id="hidden-promo-date"></div>}
                    {user &&
                      cart.step >= 4 &&
                      cart.step < 7 &&
                      cart.services.some((el) => el.name === "PROMOTV") && (
                        <DateSelection
                          title={t("promotionTvTitle")}
                          subtitle={t("promotionTvSubtitle")}
                          cart={cart}
                          setCart={setCart}
                          service="PROMOTV"
                          active={
                            (cart.step === 4 &&
                              !(cart.modify && cart.modify !== 4)) ||
                            cart.modify === 4
                          }
                          step={4}
                        />
                      )}
                    {cart.step >= 5 && cart.step < 7 && (
                      <Contrats
                        cart={cart}
                        setCart={setCart}
                        active={
                          (cart.step === 5 &&
                            !(cart.modify && cart.modify !== 5)) ||
                          cart.modify === 5
                        }
                      />
                    )}
                    {cart.step === 6 && (
                      <Payment
                        cart={cart}
                        setCart={setCart}
                        user={user}
                        active={
                          (cart.step === 6 &&
                            !(cart.modify && cart.modify !== 6)) ||
                          cart.modify === 6
                        }
                      />
                    )}
                    {cart.step === 7 && cart.cru ? (
                      <Redirect
                        to={{
                          pathname: "/confirmation",
                          state: { cart: cart, user: user },
                        }}
                      />
                    ) : cart.step === 7 && !cart.cru ? (
                      <Loader
                        type="TailSpin"
                        color="#fff"
                        height={25}
                        width={25}
                        timeout={3000}
                      />
                    ) : null}
                  </div>
                  {cart.step <= 6 && (
                    <CartWidget
                      cart={cart}
                      setCart={setCart}
                      setCartOpen={() => setCartOpen(!cartOpen)}
                      cartOpen={cartOpen}
                      totalPrice={totalPrice}
                    />
                  )}
                </Flex>
              )}
            </Container>
            {width < 992 && (
              <ResponsiveTotal
                cart={cart}
                setCart={setCart}
                totalPrice={totalPrice}
                currency={cart?.country.currency}
                openCart={() => setCartOpen(!cartOpen)}
                service={crossSellService}
              />
            )}

            <AvantageSlider />
          </Box>
        )}
      </userContext.Consumer>
    );
  } else {
    return null;
  }
}
